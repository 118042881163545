import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {environment} from '../../environments/environment';
import {ClientView} from './types/client-view-type';
import {AdminReport} from './types/admin-report-type';
import {BillingReport} from './types/billing-type';
import {map} from 'rxjs/operators';
import {AdminProductPrice} from './types/admin-product-price';
import {UserProductPrice} from '../account/user-product-price';
import {UserProductPriceRepresentations} from '../account/user-product-price-representation';
import {AdminProductPriceRepresentations} from './types/admin-product-price-representations';
import {FileDownloadService} from '../shared/file-download-service';
import {DateTimeRangeRepresentation} from '../shared/date-time-range-representation';
import {SelfBuyersStatistics} from './types/self-buyers-statistics';
import {SelfBuyerStatisticsRepresentation} from './types/self-buyers.statistics-representation';
import {SelfBuyersPaymentsRecommendation} from './types/self-buyer-payment-recommendation';
import {SelfBuyerPaymentsRecommendationRepresentation} from './types/self-buyer-payments-recommendation-representation';
import {SelfBuyersPrice} from './types/self-buyer-price';
import {SelfBuyerPriceRepresentation} from './types/self-buyer-price-representation';
import {DiscountCodeGenerateRepresentation} from './types/discount-code-generate-representation';
import {PositionUserReportRepresentation} from './types/position-user-report-representation';
import {ClientAcquisitionAccountsView} from './types/client-acquisition-accounts-view';

const API_URL = environment.API_URL;


@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private http: HttpClient, private fileDownloadService: FileDownloadService) {
  }

  public loadClients(pageNumber: number, pageSize: number): Observable<ClientView[]> {
    return this.http.get<ClientView[]>(
      API_URL + '/admin?pageNumber=' + pageNumber + '&pageSize=' + pageSize,
      {
        headers: new HttpHeaders({
          Accept: 'application/json; version=1'
        })
      }
    );
  }

  public deleteCustomers(customersForDeletion: ClientView[]): Observable<ClientView[]> {
    return this.http.post<ClientView[]>(
      API_URL + '/admin',
      customersForDeletion,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; version=1',
          Accept: 'application/json; version=1'
        })
      }
    );
  }

  public adminReport(): Observable<AdminReport> {
    return this.http.get<AdminReport>(API_URL + '/admin/report');
  }

  public billingReport(): Observable<BillingReport[]> {
    return this.http.get<{ entries: BillingReport[] }>(API_URL + '/billing',
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; version=2',
          Accept: 'application/json; version=2'
        })
      }).pipe(map(result => result.entries));
  }

  public getAllMasters(): Observable<AdminProductPrice[]> {
    return this.http.get<AdminProductPriceRepresentations[]>(API_URL + '/admin/masters',
      {
        headers: new HttpHeaders({
          Accept: 'application/json; version=1'
        })
      }).pipe(map((adminProductPriceRepresentations: AdminProductPriceRepresentations[]) =>
      adminProductPriceRepresentations.map(adminProductPriceRepresentation =>
        AdminProductPriceRepresentations.toModelEntity(adminProductPriceRepresentation))));
  }

  public getAllPartners(): Observable<AdminProductPrice[]> {
    return this.http.get<AdminProductPriceRepresentations[]>(
      API_URL + '/admin/partners',
      {
        headers: new HttpHeaders({
          Accept: 'application/json; version=1'
        })
      }).pipe(map((adminProductPriceRepresentations: AdminProductPriceRepresentations[]) =>
      adminProductPriceRepresentations.map(adminProductPriceRepresentation =>
        AdminProductPriceRepresentations.toModelEntity(adminProductPriceRepresentation))));
  }

  public getDefaultProductPrices2(): Observable<UserProductPriceRepresentations[]> {
    return this.http.get<UserProductPriceRepresentations[]>(
      API_URL + '/admin/defaultprices',
      {
        headers: new HttpHeaders({
          Accept: 'application/json; version=1'
        })
      });
  }

  public getDefaultProductPrices(): Observable<UserProductPrice[]> {
    return this.http.get<UserProductPriceRepresentations[]>(
      API_URL + '/admin/defaultprices',
      {
        headers: new HttpHeaders({
          Accept: 'application/json; version=1'
        })
      }).pipe(map((userProductPriceRepresentations: UserProductPriceRepresentations[]) =>
      userProductPriceRepresentations.map(userProductPriceRepresentation =>
        UserProductPriceRepresentations.toModelEntity(userProductPriceRepresentation))));
  }

  public updateUserProductPrice(userProductPriceToUpdate: UserProductPrice): Observable<void> {
    return this.http.patch<void>(
      API_URL + '/admin/setprice',
      new UserProductPriceRepresentations(
        userProductPriceToUpdate.userKeycloakId,
        userProductPriceToUpdate.product,
        userProductPriceToUpdate.netPrice),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; version=1',
          Accept: 'application/json; version=1'
        })
      }
    );
  }

  public setNetPriceForMastersWithDefaultPrice(userProductPriceToUpdate: UserProductPrice): Observable<void> {
    return this.http.post<void>(
      API_URL + '/admin/setPriceForAlleUsersWithDefault',
      new UserProductPriceRepresentations(
        userProductPriceToUpdate.userKeycloakId,
        userProductPriceToUpdate.product,
        userProductPriceToUpdate.netPrice),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; version=1',
          Accept: 'application/json; version=1'
        })
      }
    );
  }

  public deleteUserProductPrice(userProductPriceToUpdate: UserProductPrice): Observable<void> {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: new UserProductPriceRepresentations(
        userProductPriceToUpdate.userKeycloakId,
        userProductPriceToUpdate.product,
        userProductPriceToUpdate.netPrice)
    };
    return this.http.delete<void>(
      API_URL + '/admin/deleteprice',
      options
    );
  }

  generateCode(body: DiscountCodeGenerateRepresentation): Observable<any> {
    return this.fileDownloadService.getFile(API_URL + '/discountCodes/generate',
      body);
  }

  getPositionUserReport(body: PositionUserReportRepresentation): Observable<any> {
    return this.fileDownloadService.getFile(API_URL + '/admin/position-user-report/export-excel',
      body);
  }

  downloadBillingDetails(ofDate: string, toDate: string): Observable<any> {
    return this.fileDownloadService.getFile(API_URL + '/billing/exportExcel',
      new DateTimeRangeRepresentation(ofDate, toDate));
  }

  downloadPartnerBillingDetails(ofDate: string, toDate: string): Observable<any> {
    return this.fileDownloadService.getFile(API_URL + '/billing/exportExcel/partner',
      new DateTimeRangeRepresentation(ofDate, toDate));
  }

  public getStatistics(): Observable<SelfBuyersStatistics> {
    return this.http.get<SelfBuyerStatisticsRepresentation>(
      API_URL + '/statistics',
      {
        headers: new HttpHeaders({
          Accept: 'application/json; version=1'
        })
      });
  }

  public getPaymentRecommendationCount(): Observable<SelfBuyersPaymentsRecommendation> {
    return this.http.get<SelfBuyerPaymentsRecommendationRepresentation>(
      API_URL + '/statistics/payment',
      {
        headers: new HttpHeaders({
          Accept: 'application/json; version=1'
        })
      });
  }

  public getPriceCount(): Observable<SelfBuyersPrice> {
    return this.http.get<SelfBuyerPriceRepresentation>(
      API_URL + '/statistics/price',
      {
        headers: new HttpHeaders({
          Accept: 'application/json; version=1'
        })
      });
  }

  public get(): Observable<SelfBuyersStatistics> {
    return this.http.get<SelfBuyerStatisticsRepresentation>(
      API_URL + '/statistics',
      {
        headers: new HttpHeaders({
          Accept: 'application/json; version=1'
        })
      });
  }

  public getInvitedUsers(): Observable<ClientAcquisitionAccountsView[]> {
    return this.http.get<ClientAcquisitionAccountsView[]>(
      API_URL + '/admin/invited-chatbot-users',
      {
        headers: new HttpHeaders({
          Accept: 'application/json; version=1'
        })
      }
    );
  }

  public toggleChatbotInvite(invitedUser: ClientAcquisitionAccountsView): Observable<void>{
    return this.http.patch<void>(
      API_URL + '/user/chatbot-invitation/' + invitedUser.keycloakId,
      {},
      {
        headers: new HttpHeaders({
          Accept: 'application/json; version=1'
        })
      }
    );
  }
}
