<div class="error-container" *ngIf="creditCardError || defaultError || chatbotUserStatus==='CHATBOT_ERROR'">
  <div class="text-container">
    <div class="headline">
      <h3>{{ __('errorHeadline') }}</h3>
    </div>
    <div *ngIf="creditCardError" class="id37-text1">{{ __('errorTextCreditCard') }}</div>
    <div *ngIf="defaultError" [innerHTML]="__('errorTextDefault')" class="id37-text1"></div>
    <div *ngIf="chatbotUserStatus==='CHATBOT_ERROR'" [innerHTML]="__('errorTextChatbot')" class="id37-text1"></div>
  </div>
</div>
