import {Injectable} from '@angular/core';
import {CurrentUser} from '../user/current-user.service';
import {prices} from '../product/prices';


const TRANSLATIONS = {
  // LANGUAGES
  languages: {
    de: 'Sprachen',
    en: 'Languages',
    ru: 'Языки'
  },
  language: {
    de: 'Sprache',
    en: 'Language',
    ru: 'Язык',
    nl: 'Taal',
    fr: 'Langue',
    es: 'Idioma',
    it: 'Linguaggio',
    ka: 'ენა'
  },
  'language.de': {
    de: 'Deutsch',
    en: 'German',
    ru: 'немецкий',
    nl: 'Duits',
    fr: 'Allemand',
    es: 'Alemán',
    it: 'Tedesco',
    ka: 'გერმანული'
  },
  'language.en': {
    de: 'Englisch',
    en: 'English',
    ru: 'английский',
    nl: 'Engels',
    fr: 'Anglais',
    es: 'Inglés',
    it: 'Inglese',
    ka: 'ინგლისური'
  },
  'language.ru': {
    de: 'Russisch',
    en: 'Russian',
    ru: 'русский',
    nl: 'Russisch',
    fr: 'Russe',
    es: 'Ruso',
    it: 'Russo',
    ka: 'რუსული'
  },
  'language.nl': {
    de: 'Niederländisch',
    en: 'Dutch',
    ru: 'нидерландский язык',
    nl: 'Nederlands',
    fr: 'Néerlandais',
    es: 'Holandés',
    it: 'Olandese',
    ka: 'ჰოლანდიური'
  },
  'language.fr': {
    de: 'Französisch',
    en: 'French',
    ru: 'Французский',
    nl: 'Frans',
    fr: 'Français',
    es: 'Francés',
    it: 'Francese',
    ka: 'ფრანგული'
  },
  'language.es': {
    de: 'Spanisch',
    en: 'Spanish',
    ru: 'испанский',
    nl: 'Spaans',
    fr: 'Espagnol',
    es: 'Español',
    it: 'Spagnolo',
    ka: 'ესპანური'
  },
  'language.it': {
    de: 'Italienisch',
    en: 'Italian',
    ru: 'Итальянский',
    nl: 'Italiaans',
    fr: 'Italien',
    es: 'Italiano',
    it: 'Italiano',
    ka: 'იტალიური'
  },
  'language.ka': {
    de: 'Georgisch',
    en: 'Georgian',
    ru: 'грузинский',
    nl: 'Georgisch',
    fr: 'Géorgien',
    es: 'Georgiano',
    it: 'Georgiano',
    ka: 'ქართული'
  },
  // Navigation
  'nav.hello': {
    de: 'Hallo',
    en: 'Hello',
    ru: 'Здравствуйте',
    nl: 'Hoi'
  },
  'nav.dashboard': {
    de: 'Dashboard',
    en: 'Dashboard',
    ru: 'Панель',
    nl: 'Dashboard'
  },
  'nav.analysis': {
    de: 'Analyse',
    en: 'Analysis',
    ru: 'Панель',
    nl: 'Analyse'
  },
  'nav.graphic': {
    de: 'Grafik',
    en: 'Graphic',
    ru: 'график',
    nl: 'Grafiek'
  },
  'nav.customers': {
    de: 'Kunden',
    en: 'Customers',
    ru: 'клиенты',
    nl: 'Customers'
  },
  'nav.network': {
    de: 'Netzwerk',
    en: 'Network',
    ru: 'Контакты',
    nl: 'Netwerk'
  },
  'nav.teams': {
    de: 'Teams',
    en: 'Teams',
    ru: 'Команда',
    nl: 'Teams'
  },
  'nav.toolbox': {
    de: 'Toolbox',
    en: 'Toolbox',
    ru: 'Меню инструментов',
    nl: 'Toolbox'
  },
  'nav.recommend': {
    de: 'ID37 empfehlen',
    en: 'Recommend ID37',
    ru: 'Порекомендовать ID37',
    nl: 'ID37 aanbevelen'
  },
  'nav.chatbot': {
    de: 'Chatbot Jay',
    en: 'Chatbot Jay',
    ru: 'Chatbot Jay',
    nl: 'Chatbot Jay'
  },
  'nav.myAccount': {
    de: 'Mein Account',
    en: 'My account',
    ru: 'Моя учётная запись',
    nl: 'Mijn account'
  },
  'nav.orders': {
    de: 'Bestellungen',
    en: 'Orders',
    ru: 'заказы',
    nl: 'Bestellingen'
  },
  'nav.logOut': {
    de: 'Abmelden',
    en: 'Log out',
    ru: 'Выйти из учетной записи',
    nl: 'Log out'
  },
  'nav.notificationOverview.markAllAsRead': {
    de: 'Alle als gelesen markieren',
    en: 'Mark all as read'
  },
  // Progressive Web App
  'pwa.install': {
    de: 'App installieren:',
    en: 'Install this app:'
  },
  'pwa.headline': {
    de: 'ID37 App installieren',
    en: 'Install ID37 App'
  },
  'pwa.installStep1': {
    de: '1. Drücken Sie auf den Button:',
    en: '1. Tap on the button:'
  },
  'pwa.installButton': {
    de: 'Installieren',
    en: 'Install'
  },
  'pwa.closeButton': {
    de: 'Schließen',
    en: 'Dismiss'
  },
  'pwa.iosStep1': {
    de: 'Auf „teilen“ klicken',
    en: 'Press the “share“ button'
  },
  'pwa.iosStep2': {
    de: 'Scrollen, „Zum Home-Bildschirm” wählen',
    en: 'Scroll, select “Add to Home Screen“'
  },
  'pwa.iosStep3': {
    de: 'Dann „Hinzufügen“ wählen',
    en: 'Press the “Add“ button'
  },
  // Dashboard
  'dashboard.hello': {
    de: 'Hallo',
    en: 'Hello',
    ru: 'Здравствуйте,',
    nl: 'Hoi'
  },
  'dashboard.afterName': {
    de: '.',
    en: '.',
    ru: '!',
    nl: '.'
  },
  'dashboard.teams': {
    de: 'Meine Teams',
    en: 'My teams'
  },
  'dashboard.generalGreetingClient': {
    de: 'Schön, dass Du da bist. Mit Deinem ID37 Persönlichkeitsprofil erfährst Du wissenschaftlich fundiert, was Dein Denken, Fühlen ' +
      'und Handeln leitet, was Dich zufrieden und erfolgreich macht. Auf Basis Deines individuellen Profils kannst Du bessere ' +
      'Lebensentscheidungen treffen und Deine Identität so gestalten, wie sie wirklich zu Dir passt.',
    en: 'We\'re glad you\'re here. Your backed-by-science ID37 personality profile will help you learn what it is' +
      ' that directs your thinking, emotions and actions. It will also help you better understand the things that ' +
      'bring you satisfaction and success. Thanks to your individual profile, you can make better decisions that ' +
      'affect your life and attune your identity to what truly suits you.',
    ru: 'Рады Вас приветствовать. Ваш личный профиль ID37 даст Вам научное обоснование и понимание того, ' +
      'какие внутренние потребности удовлетворяются через Ваше мышление, чувства и действия и что делает Вас ' +
      'удовлетворенными и успешными. Зная свой индивидуальный профиль, Вы сможете принимать самые оптимальные ' +
      'жизненные решения и формировать свою личность, используя для этого уже имеющиеся в Вас уникальные способности.',
    nl: 'We zijn blij dat je hier bent. Dit wetenschappelijk onderbouwde ID37-persoonlijkheidsprofiel helpt u te leren wat het is ' +
      'dat je denken, emoties en acties stuurt. Het zal je ook helpen om beter te begrijpen welke dingen jou' +
      'tevredenheid en succes geven. Dankzij je individuele profiel kun je betere beslissingen nemen die ' +
      'je leven beïnvloeden en je identiteit afstemmen op wat echt bij jou past.'
  },
  'dashboard.generalGreeting': {
    de: 'Schön, dass Du da bist.',
    en: 'We\'re glad you\'re here.',
    ru: 'Приветствуем Вас на странице диагностики ID37.',
    nl: 'Wij zijn blij dat je er bent.'
  },
  'dashboard.additionalMasterGreeting': {
    de: 'Hier findest Du Materialien und Services, die Dich in Deiner Arbeit unterstützen. Los geht\'s!',
    en: 'Here you will find materials and services that support you in your work. Here we go!',
    ru: 'Здесь Вы найдете материалы и услуги, которые помогут Вам в Вашей работе.',
    nl: 'Hier vind je materialen en diensten die je ondersteunen bij je werk. Daar gaan we!'
  },
  'testInfo.test': {
    de: 'Test',
    en: 'Test',
    ru: 'тест',
    nl: 'Test'
  },
  'testInfo.testNotStarted': {
    de: 'Erstelle hier Deine ID37 Persönlichkeitsanalyse.',
    en: 'Create your ID37 personality profile here.',
    ru: 'Создайте свой личностный профиль ID37 здесь.',
    nl: 'Creeer je ID37 persoonlijkheids profiel hier.'
  },
  'testInfo.testInProgress': {
    de: 'Vervollständige hier Deine ID37 Persönlichkeitsanalyse - Du wirst an die Stelle geführt, wo Du unterbrochen hast.',
    en: 'Complete your ID37 personality profile here - you will be taken to where you left off.',
    ru: '«Завершите свою диагностику личности ID37 здесь - Вы начнете с того момента, где остановились.',
    nl: 'Maak je ID37-persoonlijkheidsprofiel compleet - Je wordt naar het punt gebracht waar je was gebleven. '
  },
  'testInfo.testFinished': {
    de: 'Dein ID37 Persönlichkeitsprofil wurde erfolgreich erstellt. ' +
      'Dein ID37 Master ist informiert und setzt sich mit Dir in Verbindung. ' +
      'Wir wünschen Dir viele neue Erkenntnisse über Deine inneren Antriebe und ihre Wirkung auf Dein Denken, Fühlen und Handeln.',
    en: 'Your ID37 personality profile has been successfully created. ' +
      'Your ID37 Master has been informed and will contact you to set up a feedback session with you. ' +
      'We hope you will gain many new insights into your inner drives and how they effect your thinking, emotions and behavior.',
    ru: 'Ваша учетная запись ID37 была успешно создана. ' +
      'Ваш мастер ID37 был уведомлен и свяжется с Вами, чтобы обсудить Ваш анализ личности. ' +
      'Мы желаем Вам получить как можно больше знаний о Ваших внутренних побуждениях и их влиянии на Ваши мышление, чувства и действия.',
    nl: 'Je ID37 persoonlijkheidsprofiel is succesvol aangemaakt. ' +
      'Je ID37 Master is geïnformeerd en zal contact met je opnemen om een feedbacksessie in te plannen. ' +
      'We hopen dat je veel nieuwe inzichten krijgt in je innerlijke drijfveren en hoe deze je denken, emoties en gedrag beïnvloeden.'
  },
  'testInfo.testAccessGranted': {
    de: 'Die Auseinandersetzung mit sich selbst und anderen ist eine persönliche Bereicherung. ' +
      'Nutze Deine ID37 Analyse, um Kommunikation zu fördern und Menschen besser zu verstehen. Los geht\'s!',
    en: 'The confrontation with oneself and others is a personal enrichment. ' +
      'Use your ID37 analysis to promote communication and to understand people better. Here we go!',
    ru: 'Изучение себя и других — ключ к собственному обогащению. ' +
      'Используйте свой профиль ID37, чтобы поддерживать общение и лучше понимать людей. Вперед!',
    nl: 'De confrontatie met jezelf en anderen is een persoonlijke verrijking. ' +
      'Gebruik je ID37-analyse om communicatie te bevorderen en mensen beter te begrijpen. Daar gaan we!'
  },
  'testInfo.myTestBtn': {
    de: 'Dein ID37 Test',
    en: 'Your ID37 Test',
    ru: 'Ваш тест ID37',
    nl: 'Jouw ID37 Test'
  },
  'testInfo.info': {
    de: 'Info',
    en: 'Info',
    ru: 'Информация',
    nl: 'Info'
  },
  'testInfo.status': {
    de: 'Status',
    en: 'Status',
    ru: 'Статус',
    nl: 'Status'
  },
  'testInfo.status.new': {
    de: 'Neu',
    en: 'New',
    ru: 'новый',
    nl: 'Nieuw'
  },
  'testInfo.status.started': {
    de: 'Angefangen',
    en: 'Started',
    ru: 'Началось',
    nl: 'Begonnen'
  },
  'testInfo.status.finished': {
    de: 'Abgeschlossen',
    en: 'Finished',
    ru: 'Законченный',
    nl: 'Afgerond'
  },
  'testInfo.status.accessGranted': {
    de: 'Abgeschlossen & Zugriff gewährt',
    en: 'Finished & Access granted',
    ru: 'Законченный & Доступ разрешен',
    nl: 'Afgerond & Toegang gegeven'
  },
  'testInfo.testResetAllowed': {
    de: 'Reset erlaubt',
    en: 'reset allowed',
    ru: 'reset allowed',
    nl: 'reset allowed'
  },
  'testInfo.variation': {
    de: 'Produkt',
    en: 'Product',
    ru: 'Продукт',
    nl: 'Product'
  },
  'testInfo.variationUpdatePossible': {
    de: 'Wechsel erlaubt',
    en: 'Switch allowed',
    ru: 'Переключение разрешено',
    nl: 'Switch allowed'
  },
  'testInfo.origin': {
    de: 'Herkunft',
    en: 'Origin',
    ru: 'происхождения',
    nl: 'Origin'
  },
  'testInfo.origin.unknown': {
    de: 'Test unbekannter Herkunft.',
    en: 'Test of unknown origin.',
    ru: 'Тест неизвестного происхождения.',
    nl: 'Test of unknown origin.'
  },
  'testInfo.origin.buyA': {
    de: 'Durch Dich in Bestellung',
    en: 'Bought by you with order',
    ru: 'Bought by you with order',
    nl: 'Bought by you with order'
  },
  'testInfo.origin.buyB': {
    de: 'gekauft.',
    en: '.',
    ru: '.',
    nl: '.'
  },
  'testInfo.origin.importA': {
    de: 'Importiert von User',
    en: 'Imported by user',
    ru: 'Imported by user',
    nl: 'Imported by user'
  },
  'testInfo.origin.importB': {
    de: '.',
    en: '.',
    ru: '.',
    nl: '.'
  },
  'testInfo.origin.invitationA': {
    de: 'Eingeladen von User',
    en: 'Invited by user',
    ru: 'Invited by user',
    nl: 'Invited by user'
  },
  'testInfo.origin.invitationB': {
    de: '.',
    en: '.',
    ru: '.',
    nl: '.'
  },
  'testInfo.id': {
    de: 'ID',
    en: 'ID',
    ru: 'ID',
    nl: 'ID'
  },
  'testInfo.myId37Analysis': {
    de: 'Meine ID37 Analyse',
    en: 'My ID37 Analysis',
    ru: 'Мой анализ ID37',
    nl: 'My ID37 Analysis'
  },
  'testInfo.myId37Graphic': {
    de: 'Meine ID37 Grafik',
    en: 'My ID37 Graphic',
    ru: 'Мой график ID37',
    nl: 'My ID37 Graphic'
  },

  /* TestReset */

  'testReset.title': {
    de: 'ID37 Test zurücksetzen',
    en: 'Reset the ID37 test'
  },
  'testReset.info1': {
    de: 'Hier kannst Du Deinen ID37 Test zurücksetzen. Dies erlaubt es dir, die Items neu zu beantworten.',
    en: 'Here you can reset your ID37 test. This gives you the chance you to answer the items again.'
  },
  'testReset.info2': {
    de: 'Bitte bedenke, dass dies eine Hilfestellung für all diejenigen ist,\n' +
      ' die bei der initialen Beantwortung der Fragen einen Fehler begangen haben\n' +
      ' oder durch Eingabe von zufälligen Antworten nicht ihr eigenes Profil erstellt haben.',
    en: 'Please note that this is an aid for all those who made a mistake when answering the questions initially ' +
      'or who did not create their own profile by entering random answers.'
  },
  'testReset.noTests': {
    de: 'Du besitzt keinen Test, den du zurücksetzen könntest.',
    en: 'You don\'t have a test that you can reset.'
  },
  'testReset.testInfo.dateLabel': {
    de: 'Test vom: ',
    en: 'Test from: '
  },
  'testReset.testInfo.idLabel': {
    de: 'Test-ID: ',
    en: 'Test-ID: '
  },
  'testReset.resetAction': {
    de: 'Meinen ID37 Test zurücksetzen',
    en: 'Reset my ID37 test'
  },
  'testReset.success': {
    de: 'ERFOLGREICH',
    en: 'SUCCESS'
  },
  'testReset.failure': {
    de: 'FEHLSCHLAG',
    en: 'FAILURE'
  },
  'testReset.confirmationModal.title': {
    de: 'Bist Du Dir sicher?',
    en: 'Are you sure?'
  },
  'testReset.confirmationModal.body': {
    de: 'Du musst Deinen Test anschließend neu ausfüllen.',
    en: 'You will then have to complete your test again.'
  },
  'testReset.confirmationModal.confirm': {
    de: 'Ja, setze meinen Test zurück',
    en: 'Yes, reset my test'
  },
  'testReset.confirmationModal.abort': {
    de: 'Abbrechen',
    en: 'Abort'
  },

  /* Dashboard */

  'dashboard.buyTest': {
    de: 'ID37 Test kaufen',
    en: 'Buy ID37 Test',
    ru: 'купить тест ID37',
    nl: 'Buy ID37 Test'
  },
  'dashboard.defaultCalendly': {
    de: 'Gespräch mit WW Coach buchen',
    en: 'Book date with WW Coach'
  },
  'dashboard.coachCalendly_A': {
    de: 'Gespräch mit ',
    en: 'Book date with'
  },
  'dashboard.coachCalendly_B': {
    de: ' buchen',
    en: ''
  },
  'dashboard.myCustomers': {
    de: 'Meine Kunden',
    en: 'My Customers',
    ru: 'Мои клиенты',
    nl: 'My Customers'
  },
  'dashboard.toolbox': {
    de: 'ID37 Toolbox',
    en: 'ID37 Toolbox',
    ru: 'Меню инструментов',
    nl: 'ID37 Toolbox'
  },
  'dashboard.id37Documents': {
    de: 'ID37 Dokumente',
    en: 'ID37 Documents',
    ru: 'Документы ID37',
    nl: 'ID37 Documents'
  },
  'dashboard.id37ExplanatoryVideos': {
    de: 'ID37 Erklärvideos',
    en: 'ID37 How to Videos',
    ru: 'Пояснительные видео ID37',
    nl: 'ID37 How to Videos'
  },
  'dashboard.id37AnalysisSupport': {
    de: 'ID37 Analyse Service',
    en: 'ID37 Analysis Service',
    ru: 'Аналитика ID37',
    nl: 'ID37 Analysis Service'
  },
  'dashboard.allusers': {
    de: 'Alle Benutzer',
    en: 'All users',
    ru: 'Все пользователи',
    nl: 'All users'
  },
  'dashboard.statistics': {
    de: 'Statistik',
    en: 'Statistics',
    ru: 'Статистика',
    nl: 'Statistiek'
  },
  'dashboard.chatbotAccess': {
    de: 'User Status Jay',
    en: 'Jay User Status'
  },
  'dashboard.vertellis.support': {
    de: 'Vertellis Support',
    en: 'Vertellis Support',
    ru: 'Vertellis Support',
    nl: 'Vertellis Support'
  },
  'dashboard.vertellis.shop': {
    de: 'Vertellis Shop',
    en: 'Vertellis Shop',
    ru: 'Vertellis Shop',
    nl: 'Vertellis Shop'
  },
  'dashboard.messages': {
    de: 'Benachrichtigungen',
    en: 'Messages'
  },
  'dashboard.discountcode': {
    de: 'Rabattcode',
    en: 'Discountcode'
  },
  'dashboard.discountcodeUsagesExport': {
    de: 'Rabattcode Verwendungen',
    en: 'Discountcode usages'
  },
  'dashboard.positionUserReportExport': {
    de: 'Position User Report',
    en: 'Position User Report'
  },
  'dashboard.service': {
    de: 'Service',
    en: 'Service'
  },
  'dashboard.metrics': {
    de: 'Metriken',
    en: 'Metrics'
  },
  'dashboard.billingDetails': {
    de: 'Rechnungsdata',
    en: 'Billing details'
  },
  'dashboard.friendshipGraph': {
    de: 'Freundschaftsgraph',
    en: 'Friendship graph'
  },
  'dashboard.chatbot-documents': {
    de: 'Chatbot Dokumente',
    en: 'Chatbot Documents'
  },
  'dashboard.chatbot': {
    de: 'Chatbot Jay',
    en: 'Chatbot Jay'
  },
  'dashboard.friends': {
    de: 'Mein Netzwerk',
    en: 'My Network'
  },
  'dashboard.interferer.new': {
    de: 'Neu',
    en: 'New'
  },
  'dashboard.chat': {
    de: 'Chat',
    en: 'Chat'
  },
  'dashboard.suggest.friend': {
    de: 'ID37 empfehlen',
    en: 'Recommend ID37'
  },
  'dashboard.suggest.friend.discount': {
    de: 'Rabatt',
    en: 'discount'
  },
  'dashboard.chatbot-prompt': {
    de: 'Chatbot System-Prompt',
    en: 'Chatbot system-prompt'
  },
  'dashboard.new': {
    de: 'NEU',
    en: 'NEW'
  },
  // My-Test -> Start
  'my-test.start.headline': {
    de: 'Was solltest Du vorab noch wissen?',
    en: 'What do I need to know before I start?',
    ru: 'Что я должен знать заранее?',
    nl: 'Wat moet ik weten voordat ik begin',
    ka: 'რა უნდა ვიცოდე სანამ დავიწყებ ტესტის შევსებას?'
  },
  'my-test.start.explanation1': {
    de: 'Der ID37 Fragebogen enthält Aussagen zu alltäglichen Verhaltensweisen, Einstellungen und Gewohnheiten. Diese ' +
      'Aussagen können zur Beschreibung von Personen verwendet werden (Beispiel: „Ich habe einen starken Drang nach körperlicher ' +
      'Aktivität“). Auf Dich persönlich kann jede dieser Aussagen mehr oder weniger zutreffen. Bitte lese jede Aussage aufmerksam' +
      ' durch und entscheide, wie sehr diese Aussage auf Dich persönlich zutrifft.',
    en: 'The ID37 questionnaire contains several statements on everyday behavior, attitudes and habits. These statements ' +
      'can be used to describe people (for example: "I have a strong urge to be physically active"). Each of these statements may be ' +
      'more or less true for you, personally. Please read each statement carefully and decide how much this statement applies to you ' +
      'personally.',
    ru: 'Опросник ID37 содержит утверждения о повседневном поведении, отношениях и привычках. ' +
      'Эти утверждения могут быть использованы для описания людей (например: «У меня сильная тяга к физической активности»). ' +
      'Каждое из этих утверждений может более или менее затрагивать Вас лично. Пожалуйста, внимательно прочитайте каждое высказывание ' +
      'и решите, насколько это высказывание затрагивает Вас лично.',
    nl: 'De ID37-vragenlijst bevat verschillende uitspraken over alledaags gedrag, attitudes en gewoonten. Deze verklaringen ' +
      'kunnen worden gebruikt om mensen te beschrijven (bijvoorbeeld: "Ik heb een sterke drang om lichamelijk actief te zijn."). ' +
      'Elk van deze uitspraken kan ' +
      'meer of minder waar zijn voor jou, persoonlijk. Lees elke verklaring zorgvuldig door en beslis in hoeverre deze ' +
      'verklaring op jou van toepassing is.',
    ka: 'ID37 კითხვარი შეიცავს რამდენიმე წინადადებას ყოველდღიური ქცევის, დამოკიდებულებებისა და ჩვევების შესახებ. ' +
        'ეს წინადადებები შეიძლება გამოყენებულ იქნას ადამიანების აღსაწერად (მაგალითად: "მე მაქვს ძლიერი სურვილი ვიყო ფიზიკურად აქტიური"). ' +
        'თითოეული წინადადება პირადად თქვენთვის შესაძლებელია მეტ ნაკლებად შეესაბამებოდეს სიმართლეს. ' +
        'გთხოვთ, ყურადღებით წაიკითხოთ თითოეული წინადადება და გადაწყვიტოთ რამდენად გეხებათ პირადად თქვენ.'
  },
  'my-test.start.explanation2': {
    de: 'Zur Bewertung jeder Aussage steht Dir eine sechsfach abgestufte Antwortskala zur Verfügung (siehe Abbildung unten).',
    en: 'A six-step graded response scale is available for evaluating each statement (see figure below).',
    ru: 'Вы можете оценить каждое высказывание, используя шесть вариантов ответов (см. рисунок ниже).',
    nl: 'Voor het beoordelen van elke stelling is een antwoordschaal in zes schalen beschikbaar (zie onderstaande figuur).',
    ka: 'თითოეული წინადადების შესაფასებლად ხელმისაწვდომია ექვსსაფეხურიანი შეფასების სკალა (იხ. სურათი ქვემოთ).'
  },
  'my-test.start.explanation3': {
    de: 'Wenn Du zum Beispiel der Meinung bist, dass eine Aussage auf Dich persönlich „überhaupt nicht“ zutrifft, wähle ' +
      'bitte die Antwortkategorie „0“.',
    en: 'If, for example, you think that a statement does not apply to you “at all", please select the answer category "0".',
    ru: 'Например, если Вы считаете, что высказывание абсолютно Вас не затрагивает, выберите категорию ответа «0».',
    nl: 'Als je bijvoorbeeld denkt dat een verklaring helemaal niet op jou van toepassing is, selecteer dan de antwoordcategorie "0".',
    ka: 'თუ ფიქრობთ, რომ წინადადება კატეგორიულად არ შეეფერება თქვენს მოსაზრებას, გთხოვთ აირჩიოთ "0".'
  },
  'my-test.start.explanation4': {
    de: 'Wenn eine Aussage „voll und ganz“ auf Dich zutrifft, dann wähle bitte die Antwortkategorie „5“.',
    en: 'If a statement "fully" applies to you, please select the answer category "5".',
    ru: 'Если высказывание полностью Вам соответствует, выберите категорию ответа «5».',
    nl: 'Als je het helemaal eens bent met een stelling, kies dan "5".',
    ka: 'თუ წინადადებას აბსოლიტურად ეთანხმებით, გთხოვთ აირჩიოთ "5".'
  },
  'my-test.start.explanation5': {
    de: 'Du kannst Deine Antwort auch abstufen, je nachdem wie sehr eine Aussage auf Dich zutrifft, und eine ' +
      'Antwortkategorie zwischen „0“ und „5“ auswählen.',
    en: 'You can also rate your response, depending on how much a statement applies to you, and select a response ' +
      'category between "0" and "5".',
    ru: 'Вы также можете оценить свой ответ в зависимости от того, насколько сильно высказывание относится к Вам, и выбрать ' +
      'категорию ответа от «0» до «5».',
    nl: 'Je kunt je reactie ook beoordelen, afhankelijk van hoeveel een verklaring op jou van toepassing is, tussen de "0" en "5"',
    ka: 'თქვენ ასევე შეგიძლიათ შეაფასოთ თქვენი პასუხი, იმისდა მიხედვით, თუ რამდენად ვრცელდება წინადადება თქვენზე ' +
        'და აირჩიეთ პასუხის კატეგორია "0"-დან "5"-მდე.'
  },
  'my-test.start.explanation6': {
    de: 'Eine Antwort kann entweder per Mausklick oder über die Zahlentasten ausgewählt werden.',
    en: 'A response can either be selected via mouse click or by using the number keys.',
    ru: 'Ответ можно выбрать либо щелчком мыши, либо с помощью цифровых клавиш.',
    nl: 'Een reactie kan worden geselecteerd met een muisklik of met behulp van de cijfertoetsen.',
    ka: 'პასუხის არჩევა შესაძლებელია მაუსის დაწკაპუნებით ან ნომრის ღილაკების გამოყენებით.'
  },
  'my-test.start.exampleItemHeadline': {
    de: 'Muster einer Antwortskala',
    en: 'Sample of a response scale',
    ru: 'Примеры ответов',
    nl: 'Voorbeeld van een antwoordschaal',
    ka: 'შეფასების მაგალითი'
  },
  'my-test.start.exampleItemText': {
    de: 'Ich habe einen starken Drang nach körperlicher Aktivität.',
    en: 'I have a strong urge to be physically active.',
    ru: 'У меня сильная тяга к физической активности.',
    nl: 'Ik heb een sterke drang om lichamelijk actief te zijn.',
    ka: 'მე მაქვს ძლიერი სურვილი ვიყო ფიზიკურად აქტიური'
  },
  'my-test.start.subline1': {
    de: 'Beachte bitte folgende Aspekte:',
    en: 'Please note the following:',
    ru: 'Обратите внимание на следующие аспекты:',
    nl: 'Let op het volgende:',
    ka: 'გთხოვთ გაითვალისწინოთ შემდეგი:'
  },
  'my-test.start.listing1': {
    de: 'Einige der Aussagen können Dir sehr persönlich vorkommen. Bitte beachte, dass wir Deine Antworten vertraulich behandeln.',
    en: 'Some of the statements may appear very personal to you. Please bear in mind that we will treat your answers confidentially.',
    ru: 'Некоторые из высказываний могут показаться Вам очень личными. Обратите внимание, что мы конфиденциально обрабатываем Ваши ответы.',
    nl: 'Sommige verklaringen kunnen erg persoonlijk overkomen. Houd er rekening mee ' +
        'dat we je antwoorden vertrouwelijk zullen behandelen. ',
    ka: 'ზოგიერთი განცხადება შეიძლება ძალიან პირადული მოგეჩვენოთ. გთხოვთ გაითვალისწინოთ, რომ ჩვენ თქვენს ' +
        'პასუხებს კონფიდენციალურად განვიხილავთ.'
  },
  'my-test.start.listing2': {
    de: 'Es kann passieren, dass Dir manche der Aussagen doppelt oder ähnlich vorkommen. Das ist so gewollt. Bitte beantworte ' +
      'jede einzelne Aussage.',
    en: 'It may happen that some of the statements seem to be duplicated or similar. This is intended. Please answer every ' +
      'single statement.',
    ru: 'Возможно, некоторые высказывания покажутся Вам повторяющимися или похожими. ' +
      'Так было задумано. Пожалуйста, отвечайте на каждое высказывание по отдельности.',
    nl: 'Het kan voorkomen dat sommige vragen dubbel of vergelijkbaar lijken. Dit is de bedoeling en beantwoord elke vraag ',
    ka: 'შეიძლება მოხდეს, რომ ზოგიერთი წინადადება გაორმაგებული ან მსგავსი ჩანდეს. ეს გამიზნულად არის. გთხოვთ უპასუხეთ თითოეულს.'
  },
  'my-test.start.listing3': {
    de: 'Denke nicht lange über einzelnen Aussagen nach. Bitte bewerte sie möglichst spontan.',
    en: 'Do not think about individual statements, for too long. Please rate this as spontaneously as possible.',
    ru: 'Старайтесь долго не размышлять об отдельных высказываниях. Пожалуйста, оценивайте их как можно более спонтанно.',
    nl: 'Denk niet te lang na over de vragen, en beoordeel ze zo spontaan mogelijk. ',
    ka: 'არ იფიქროთ ინდივიდუალურ წინადადებებზე, ძალიან დიდხანს. გთხოვთ შეაფასოთ რაც შეიძლება სპონტანურად'
  },
  'my-test.start.listing4': {
    de: 'Überlege während der Bearbeitung nicht, welche Antwort den “besten Eindruck” machen könnte, sondern gib bei jeder ' +
      'Aussage bitte die Antwort, die Dich als Person am besten beschreibt.',
    en: 'Do not consider which answer could make the "best impression" during answering the items, but always give the answer ' +
      'that best describes you as a person.',
    ru: 'Не думайте о том, какой ответ может оставить «лучшее впечатление» при обработке результатов. ' +
      'Давайте ответы, которые лучше всего описывают Вас как личность.',
    nl: 'Overweeg niet welk antwoord de "beste indruk" zou kunnen make, maar geef altijd het antwoord' +
      'dat jou het beste omschrijft.',
    ka: 'ნუ იფიქრებთ იმაზე, თუ რომელ პასუხს შეუძლია მოახდინოს „საუკეთესო შთაბეჭდილება“, ყოველთვის შეარჩიეთ პასუხი, ' +
        'რომელიც საუკეთესოდ აღგწერთ თქვენ, როგორც პიროვნებას.'
  },
  'my-test.start.subline2': {
    de: 'Für die Erstellung Deines Persönlichkeitsprofils benötigen wir noch folgende Angaben:',
    en: 'To create your personality profile, we need the following information:',
    ru: 'Для создания Вашего профиля личности нам нужна следующая информация:',
    nl: 'Om je persoonlijkheidsprofiel te creëren, hebben we de volgende informatie nodig:',
    ka: 'თქვენი პირადი პროფილის შესაქმნელად გვჭირდება შემდეგი ინფორმაცია:'
  },
  'my-test.start.country_label': {
    de: 'Land*',
    en: 'Country*',
    ru: 'Страна*',
    nl: 'Land*',
    ka: 'ქვეყანა*'
  },
  'my-test.start.country_placeholder': {
    de: 'In welchem Land lebst Du derzeit?',
    en: 'What country do you currently live in?',
    ru: 'В какой стране Вы сейчас проживаете?',
    nl: 'In welk land woon je momenteel?',
    ka: 'რომელ ქვეყანაში ცხოვრობთ ახლა?'
  },
  'my-test.start.variationDescriptionID3714': {
    de: 'ID37/14 - wird bei Menschen mit wenig Lebenserfahrung eingesetzt. Es ist ohne die Motive SINNLICHKEIT und FAMILIE.',
    en: 'ID37/14 - Excluding the motives EROS and FAMILY. Recommendation: Application with younger people with little life experience.',
    ru: 'ID37/14 - С исключением мотивов "Чувственность" и "Семья". Рекомендация: для молодёжи с небольшим жизненным опытом.',
    nl: 'ID37/14 - Excluding the motives ZINNELIJKHEID and FAMILIE. Recommendation: Application with younger people with ' +
      'little life experience.',
    ka: 'ID37/14 - ოჯახისა და სენსუალურობის მოტივის გამოკლებით. რეკომენდაცია: ახალგაზრდა მონაწილეებთან ვისაც არ ' +
        'აქვს დიდი ცხოვრებისეული გამოყენება'
  },
  'my-test.start.variationDescriptionID3715': {
    de: 'ID37/15 - empfiehlt sich für den Business-Einsatz. Es ist ohne das Motiv SINNLICHKEIT.',
    en: 'ID37/15 - Excluding the life motive EROS. Recommendation: Application in a business setting.',
    ru: 'ID37/15 - С исключением мотива "Чувственность". Рекомендация: применение в деловой среде.',
    nl: 'ID37/15 - Excluding the life motive ZINNELIJKHEID. Recommendation: Application in a business setting.',
    ka: 'ID37/15 - სენსუალურობის მოტივის გამოკლებით. რეკომენდაცია: კორპორატიულ მოწყობაში.'
  },
  'my-test.start.variationDescriptionID3716': {
    de: 'ID37/16 - beschreibt die Persönlichkeit vollständig.',
    en: 'ID37/16 - Complete description of the personality.',
    ru: 'ID37/16 - Полное описание личности.',
    nl: 'ID37/16 - Complete description of the personality.',
    ka: 'ID37/16 - სრული აღწერა'
  },
  'my-test.start.variationPlaceholder': {
    de: 'Wähle bitte den Umfang Deiner Persönlichkeitsanalyse',
    en: 'Please choose the scope of your personality assessment',
    ru: 'Пожалуйста, выберите объём Вашей диагностики личности',
    nl: 'Please choose the scope of your personality assessment',
    ka: 'გთხოვთ აირჩიოთ პიროვნების შეფასების ჯგუფი'
  },
  'my-test.start.variationLabel': {
    de: 'Welches Produkt wählst Du?',
    en: 'Which product do you choose?',
    ru: 'Какой продукт вы выбираете?',
    nl: 'Which product do you choose?',
    ka: 'რომელი პროდუქტი შეარჩიეთ?'
  },
  'my-test.start.nationality_label': {
    de: 'Nationalität*',
    en: 'Nationality*',
    ru: 'Национальность*',
    nl: 'Nationaliteit*',
    ka: 'ეროვნება*'
  },
  'my-test.start.nationality_placeholder': {
    de: 'Welche ist Deine Nationalität?',
    en: 'What\'s your nationality?',
    ru: 'Ваша национальность?',
    nl: 'Wat is je nationaliteit?',
    ka: 'რა ეროვნების ხართ?'
  },
  'my-test.start.gender_label': {
    de: 'Geschlecht*',
    en: 'Gender*',
    ru: 'Пол*',
    nl: 'Geslacht*',
    ka: 'სქესი*'
  },
  'my-test.start.gender_placeholder': {
    de: 'Welches Geschlecht hast Du?',
    en: 'Which gender do you have?',
    ru: 'Какой у Вас пол?',
    nl: 'Wat is je geslacht?',
    ka: 'რომელ გენდერს მიეკუთვნებით?'
  },
  'my-test.start.dateofbirth_label': {
    de: 'Geburtstag*',
    en: 'Date of birth*',
    ru: 'День рождения*',
    nl: 'Geboorte datum*',
    ka: 'დაბადების თარიღი*'
  },
  'my-test.start.dateofbirth_placeholder': {
    de: 'Wann wurdest Du geboren?',
    en: 'When were you born?',
    ru: 'Когда Вы родились?',
    nl: 'Wat is je geboorte datum?',
    ka: 'როდის დაიბადეთ?'
  },
  'my-test.start.start_button': {
    de: 'Los geht\'s',
    en: 'Let\'s get started',
    ru: 'Старт',
    nl: 'Laten we beginnen!',
    ka: 'მოდით დავიწყოთ'
  },
  'my-test.start.language_headline': {
    de: 'Zu guter Letzt',
    en: 'Last, but not least',
    ru: 'Последний, но не менее важный момент',
    nl: 'Tot slot',
    ka: 'და ბოლოს'
  },
  'my-test.start.language_text': {
    de: 'Du kannst den ID37 Test, in einer anderen Sprache durchführen. Wähle hier die Sprache, die Dir am ' +
      'vertrautesten ist:',
    en: 'You can fill out the ID37 questionnaire in a different language. Choose your preferred language here:',
    ru: 'Вы можете заполнить анкету ID37 на другом языке. Выберите здесь язык, который вам знаком:',
    nl: 'Je kunt de ID37-test ook in een andere taal invullen. Kies hier de taal van je voorkeur:',
    ka: 'თქვენ შეგიძლიათ შეავსოთ ID37 კითხვარი სხვადასხვა ენაზე. აირჩიეთ თქვენთვის სასურველი ენა აქ:'
  },
  'my-test.start.language_label': {
    de: 'Sprache',
    en: 'Language',
    ru: 'Язык',
    ka: 'ენა'
  },
  'my-test.start.language_placeholder': {
    de: 'Wähle eine Sprache aus',
    en: 'Choose a language'
  },
  'my-test.start.errors.required': {
    de: 'Dieses Feld darf nicht leer sein.',
    en: 'Required field.',
    ru: 'Это поле не должно быть пустым.',
    ka: 'სავალდებულო ველი'
  },
  // My-Test -> Items
  'my-test.items.back': {
    de: 'Zurück',
    en: 'Back',
    ru: 'Назад',
    fr: 'Précédent',
    es: 'Atrás',
    it: 'Indietro',
    ka: 'უკან'
  },
  'my-test.items.next': {
    de: 'Weiter',
    en: 'Next',
    ru: 'Продолжить',
    fr: 'Suivant',
    es: 'Siguiente',
    it: 'Avanti',
    ka: 'შემდეგი'
  },
  'my-test.items.errorItemMustBeAnswered': {
    de: 'Bitte bewerte diese Aussage.',
    en: 'Please rate this statement.',
    ru: 'Пожалуйста, дайте ответ к этому высказыванию.'
  },
  // My-Test -> Item
  'my-test.item.applying': {
    de: 'Trifft voll und ganz zu',
    en: 'I completely agree',
    ru: 'Я полностью согласен',
    nl: 'Helemaal eens',
    fr: 'Me correspond parfaitement',
    es: 'Se aplica completamente a mi',
    it: 'Concordo pienamente',
    ka: 'მე სრულებით  ვეთანხმები'
  },
  'my-test.item.notApplying': {
    de: 'Trifft überhaupt nicht zu',
    en: 'I completely disagree',
    ru: 'Я полностью не согласен',
    nl: 'Helemaal oneens',
    fr: 'Ne me correspond pas du tout',
    es: 'Totalmente en desacuerdo',
    it: 'Non sono affatto d\'accordo',
    ka: 'მე კატეგორიულად არ ვეთანხმები'
  },
  'my-test.item.errorAnswerCouldNotBeSaved': {
    de: 'Deine Antwort konnte nicht gespeichert werden. Bitte versuche es erneut.',
    en: 'Your answer could not be saved. Please try again.',
    ru: 'Ваш ответ не может быть сохранен. Пожалуйста, попробуйте еще раз.',
    nl: 'Er ging iets fout. Probeer het nogmaals'
  },
  // My-Test -> Thank you
  'my-test.thank-you.headline': {
    de: 'Herzlichen Glückwunsch',
    en: 'Congratulations',
    ru: 'Поздравляем',
    nl: 'Gefeliciteerd!',
    fr: '\u0020',
    es: 'Enhorabuena,',
    it: 'Congratulazioni',
    ka: 'გილოცავთ'
  },
  'my-test.thank-you.headline2': {
    en: '\u0020',
    fr: ', félicitations!',
    es: '.',
    it: '!',
  },
  'my-test.thank-you.explanation': {
    de: 'Den wichtigsten Teil Deiner Selbsteinschätzung hast Du erledigt. Zur Vervollständigung Deines ID37 ' +
      'Persönlichkeitsprofils gehören weitere Angaben.',
    en: 'You\'ve already completed the most important part of your self-assessment. Further information is required ' +
      'to create your ID37 personality profile.',
    ru: 'Вы прошли самую важную часть Вашей диагностики. Чтобы завершить свой личностный профиль ID37, ' +
      'укажите дополнительную информацию о себе.',
    nl: 'Je hebt het belangrijkste deel van je zelfevaluatie afgerond. Verdere informatie is vereist om ' +
      'je ID37 persoonlijkheidsprofiel af te maken.',
    fr: 'Tu viens d’accomplir la partie la plus importante de ton auto-évaluation. Nous avons encore besoin ' +
      'd’informations supplémentaires afin de compléter ton profil ID37.',
    es: 'Has completado la parte más importante de tu autoevalución. Necesitamos alguna información adicional para ' +
      'completar tu test de personalidad ID37.',
    it: 'Hai completato la parte principale della tua autovalutazione. Inserisci i dati sottostanti per ultimare il ' +
      'tuo profilo della personalità ID37.',
    ka: 'თქვენ უკვე დაასრულეთ თქვენი თვითშეფასების ყველაზე მნიშვნელოვანი ნაწილი. დამატებითი ინფორმაცია საჭიროა თქვენი ' +
        'ID37 პიროვნების პროფილის შესაქმნელად.'
  },
  'my-test.thank-you.placeLabel': {
    de: 'Ort',
    en: 'Current location',
    ru: 'Место жительства',
    nl: 'Huidige locatie',
    fr: 'Lieu',
    es: 'Ciudad',
    it: 'Località',
    ka: 'ამჟამინდელი ადგილმდებარეობა'
  },
  'my-test.thank-you.placePlaceholder': {
    de: 'Wo lebst Du derzeit?',
    en: 'Where do you currently live?',
    ru: 'Где Вы сейчас проживаете?',
    nl: 'Waar woon je momenteel',
    fr: 'Où habites-tu actuellement?',
    es: '¿Dónde vives actualmente?',
    it: 'Dove vivi attualmente?',
    ka: 'სად ცხოვრობთ ამჟამად?'
  },
  'my-test.thank-you.schoolLabel': {
    de: 'Höchster Schulabschluss',
    en: 'Highest level of academic attainment',
    ru: 'Образование',
    nl: 'Hoogste afgemaakte school',
    fr: 'Scolarité',
    es: 'Título académico más alto',
    it: 'Titolo di studio',
    ka: 'აკადემიური მოსწრების უმაღლესი დონე'
  },
  'my-test.thank-you.schoolPlaceholder': {
    de: 'Welchen Schulabschluss hast Du?',
    en: 'What\'s your high school diploma?',
    ru: 'Какое образование Вы получили?',
    nl: 'What\'s your high school diploma?',
    fr: 'Quel diplôme a marqué la fin de ta scolarité?',
    es: '¿Cuál es tu titulación escolar?',
    it: 'Qual è l\'ultimo titolo di studio che hai conseguito?',
    ka: 'რა არის თქვენი უმაღლესი განათლების დიპლომი?',
  },
  'my-test.thank-you.jobLabel': {
    de: 'Beruf',
    en: 'Profession',
    ru: 'Профессия',
    nl: 'Beroep',
    fr: 'Emploi',
    es: 'Profesión',
    it: 'Professione',
    ka: 'პროფესია'
  },
  'my-test.thank-you.jobPlaceholder': {
    de: 'In welchem Beruf bist Du aktuell tätig?',
    en: 'What is your current profession?',
    ru: 'Кем Вы работаете на данный момент?',
    nl: 'Wat is je huidige beroep',
    fr: 'Quel est ton emploi actuel?',
    es: '¿Cuál es tu profesión actual?',
    it: 'Che professione eserciti attualmente?',
    ka: 'რა არის თქვენი პროფესია?'
  },
  'my-test.thank-you.businessLabel': {
    de: 'Branche',
    en: 'Industry',
    ru: 'Отрасль',
    nl: 'Industrie',
    fr: 'Secteur',
    es: 'Sector',
    it: 'Settore',
    ka: 'სექტორი'
  },
  'my-test.thank-you.businessPlaceholder': {
    de: 'In welcher Branche bist Du aktuell tätig?',
    en: 'Please select the industry in which you are active',
    ru: 'В какой отрасли Вы работаете на данный момент?',
    nl: 'Welk land werk je het meest',
    fr: 'Dans quel secteur travailles-tu actuellement?',
    es: '¿En qué sector trabajas?',
    it: 'In che settore lavori attualmente?',
    ka: 'გთხოვთ აირჩიოთ სექტორი რომელშიც მუშაობთ?'
  },
  'my-test.thank-you.workLabel': {
    de: 'Unternehmen',
    en: 'Company name',
    ru: 'Компания',
    nl: 'Bedrijfsnaam',
    fr: 'Entreprise',
    es: 'Empresa',
    it: 'Azienda',
    ka: 'კომპანიის სახელწოდება'
  },
  'my-test.thank-you.workPlaceholder': {
    de: 'In welchem Unternehmen arbeitest Du derzeit?',
    en: 'What company do you currently work for?',
    ru: 'В какой компании Вы работаете на данный момент?',
    nl: 'Welk bedrijf werk je momenteel',
    fr: 'Dans quelle entreprise travailles-tu actuellement?',
    es: '¿En qué empresa trabajas?',
    it: 'Presso quale azienda lavori attualmente?',
    ka: 'რომელ კომპანიაში ხართ დასაქმებული ამჟამად?'
  },
  'my-test.thank-you.mobileLabel': {
    de: 'Mobilfunknummer',
    en: 'Mobile number',
    ru: 'Номер мобильного телефона',
    nl: 'Mobiele nummer',
    fr: 'Numéro de téléphone portable',
    es: 'Teléfono móvil',
    it: 'Numero di cellulare',
    ka: 'მობილური ტელეფონის ნომერი'
  },
  'my-test.thank-you.mobilePlaceholder': {
    de: 'Deine Mobilfunknummer',
    en: 'What is your mobile number?',
    ru: 'Ваш номер мобильного телефона?',
    nl: 'What is je nummer?',
    fr: 'Ton numéro de téléphone portable',
    es: 'Tu teléfono móvil',
    it: 'Il tuo numero di cellulare',
    ka: 'მობილური ტელეფონის ნომერი'
  },
  'my-test.thank-you.endText': {
    de: 'Dein Persönlichkeitsprofil wird jetzt erstellt. Vielen Dank für Dein Vertrauen.',
    en: 'Your personality profile will be created now. Thank you very much for your confidence.',
    ru: 'Ваш личностный профиль создан. Спасибо за Ваше доверие.',
    nl: 'Je persoonlijkheidsprofiel wordt nu aangemaakt. Bedankt voor je vertrouwen!',
    fr: 'Ton profil va maintenant être créé. Merci pour ta confiance!',
    es: 'Tu perfil personal ha sido creado. Muchas gracias por tu confianza.',
    it: 'Ora verrà creato il tuo profilo della personalità. Grazie per la fiducia!',
    ka: 'თქვენი პერსონალური პროფილი ახლა შეიქმნება. მადლობას გიხდით ნდობისთვის'
  },
  'my-test.thank-you.send': {
    de: 'Absenden',
    en: 'Submit',
    ru: 'Отправить',
    nl: 'Verzend',
    fr: 'Transmettre',
    es: 'Enviar',
    it: 'Invia',
    ka: 'გაგზავნა'
  },
  'test.variation.14': {
    de: 'ID37/14',
    en: 'ID37/14',
    ru: 'ID37/14',
    nl: 'ID37/14'
  },
  'test.variation.15': {
    de: 'ID37/15',
    en: 'ID37/15',
    ru: 'ID37/15',
    nl: 'ID37/15'
  },
  'test.variation.16': {
    de: 'ID37/16',
    en: 'ID37/16',
    ru: 'ID37/16',
    nl: 'ID37/16'
  },
  'test.variation.unspecified': {
    de: 'Kunde wählt',
    en: 'Customer chooses',
    ru: 'Клиент выбирает',
    nl: 'Customer chooses'
  },

  // Toolbox
  'toolbox.title': {
    de: 'ID37 Toolbox',
    en: 'ID37 Toolbox',
    ru: 'Меню инструментов'
  },
  'toolbox.import': {
    de: 'ID37 Analyse Import',
    en: 'ID37 Analysis Import',
    ru: 'Импорт анализа ID37'
  },
  'toolbox.compare': {
    de: 'Profilvergleich',
    en: 'Compare Profiles',
    ru: 'Сравнить профили'
  },
  'toolbox.teamanalysis': {
    de: 'Teamanalyse',
    en: 'Team analysis',
    ru: 'аналитика группы'
  },
  'toolbox.testReset': {
    de: 'Meinen ID37 Test zurücksetzen',
    en: 'Reset my ID37 test'
  },
  'toolbox.chatbot-documents': {
    de: 'Chatbot Dokumente Import/Export',
    en: 'Chatbot Documents Import/Export'
  },

  // Master -> My customers
  'master.my-customers.heading': {
    de: 'Meine Kunden',
    en: 'My clients',
    ru: 'Мои клиенты'
  },
  'master.my-customers.addClient': {
    de: 'Kunden hinzufügen',
    en: 'Add client',
    ru: 'Добавить клиента'
  },
  'master.my-customers.addClients': {
    de: 'Kunden hinzufügen',
    en: 'Add clients',
    ru: 'Добавить клиентов'
  },
  'master.my-customers.sendInvitations': {
    de: 'Einladungen versenden',
    en: 'Send invitations',
    ru: 'Отправить приглашение'
  },
  'master.my-customers.emailAlreadyInUse': {
    de: 'Diese E-Mail wird bereits verwendet.',
    en: 'This email is already in use.',
    ru: 'Данный E-Mail уже используется.'
  },
  'master.my-customers.notAValidEmail': {
    de: 'Dies ist keine gültige E-Mail Adresse.',
    en: 'This is not a valid email address.',
    ru: 'Этот E-Mail не действителен.'
  },
  'master.my-customers.emailMustNotBeUsedMultipleTimes': {
    de: 'E-Mail Adressen dürfen nicht mehrmals vorkommen.',
    en: 'E-mail addresses may not occur more than once.',
    ru: 'E-Mail не может быть использован повторно.'
  },
  'master.my-customers.close': {
    de: 'Schließen',
    en: 'Close',
    ru: 'Закрыть'
  },
  'master.my-customers.existingClients': {
    de: 'Existierende Kunden',
    en: 'Existing clients',
    ru: 'Существующие клиенты'
  },
  'master.my-customers.searchPlaceholder': {
    de: 'Suchen...',
    en: 'Search...',
    ru: 'Поиск',
    ka: 'ძიება'
  },
  'master.my-customers.hello': {
    de: 'Hallo',
    en: 'Hello',
    ru: 'Здравствуйте'
  },
  'master.my-customers.noCustomersYetStartNow': {
    de: 'Du hast noch keinen Kunden angelegt. Hier kannst du damit starten.',
    en: 'You have not created a customer yet. Here you can start with it.',
    ru: 'Вы еще не создали клиентов. Здесь вы можете их создать.'
  },
  'master.my-customers.addClientsNow': {
    de: 'Jetzt Kunden anlegen',
    en: 'Create customers now',
    ru: 'Создать клиента'
  },
  'master.my-customers.name': {
    de: 'Name',
    en: 'Name',
    ru: 'Имя'
  },
  'master.my-customers.email': {
    de: 'E-Mail',
    en: 'Email',
    ru: 'Электронная почта'
  },
  'master.my-customers.firstName': {
    de: 'Vorname',
    en: 'First name',
    ru: 'Имя'
  },
  'master.my-customers.lastName': {
    de: 'Nachname',
    en: 'Last name',
    ru: 'Фамилия'
  },
  'master.my-customers.group': {
    de: 'Gruppe',
    en: 'Group',
    ru: 'Группа'
  },
  'master.my-customers.variation': {
    de: 'Produkt',
    en: 'Product',
    ru: 'Продукт'
  },
  'master.my-customers.date': {
    de: 'Datum',
    en: 'Date',
    ru: 'Дата'
  },
  'master.my-customers.noCustomersYet': {
    de: 'Du hast noch keinen Kunden.',
    en: 'You do not have a client yet.',
    ru: 'У Вас пока еще нету клиентов'
  },
  'master.my-customers.page': {
    de: 'Seite',
    en: 'Page',
    ru: 'Страница'
  },
  'master.my-customers.of': {
    de: 'von',
    en: 'of',
    ru: 'из'
  },
  'master.my-customers.action': {
    de: 'Aktion',
    en: 'Action',
    ru: 'Действие'
  },
  'master.my-customers.action.changeProduct': {
    de: 'Produkt ändern',
    en: 'Change product',
    ru: 'Сменить продукт'
  },
  'master.my-customers.action.newTestInvite': {
    de: 'neu einladen',
    en: 'Invite again',
    ru: 'Пригласить снова'
  },
  'master.my-customers.action.newInvite': {
    de: 'neu einladen',
    en: 'Invite again',
    ru: 'Пригласить снова'
  },
  'master.my-customers.action.inprogress': {
    de: 'angefangen',
    en: 'in progress',
    ru: 'В процессе'
  },
  'master.my-customers.action.grant': {
    de: 'freigeben',
    en: 'grant',
    ru: 'Предоставить доступ'
  },
  'master.my-customers.action.done': {
    de: 'erledigt',
    en: 'done',
    ru: 'Завершено'
  },
  'master.my-customers.action.comparisonselect': {
    de: 'Auswählen',
    en: 'Select',
    ru: 'Выбрать'
  },
  'master.my-customers.action.removeuser': {
    de: 'Entfernen',
    en: 'Remove',
    ru: 'Удалить'
  },
  'master.my-customers.action.selfcompare': {
    de: 'Mit eigenem Profil vergleichen',
    en: 'Compare with own profile',
    ru: 'Сравнить с собственным профилем'
  },
  'master.my-customers.action.compareaction': {
    de: 'Vergleichen',
    en: 'Compare',
    ru: 'Сравнить'
  },
  'master.my-customers.deleteClient.areYouSure': {
    de: 'Möchtest Du diesen Kunden wirklich endgültig löschen?',
    en: 'Are you sure you want to permanently delete this client?',
    ru: 'Вы уверены, что хотите навсегда удалить этого клиента?'
  },
  'master.my-customers.deleteClient.explanation': {
    de: 'Diese Entscheidung kann nicht rückgängig gemacht werden!',
    en: 'Your decision is final!',
    ru: 'Вы не сможете отменить своего решения!'
  },
  'master.my-customers.deleteClient.accept': {
    de: 'Ja, löschen',
    en: 'Yes, delete',
    ru: 'Да, удалить'
  },
  'master.my-customers.deleteClient.abort': {
    de: 'Nein, nicht löschen',
    en: 'No, do not delete',
    ru: 'Нет, не удалять'
  },
  'master.my-customers.changeGroupModal.title': {
    de: 'Gruppe ändern',
    en: 'Change group'
  },
  'master.my-customers.changeGroupModal.description': {
    de: 'Hier kannst Du den Gruppennamen ändern.',
    en: 'You can change the group name here.'
  },
  'master.my-customers.changeGroupModal.label': {
    de: 'Neuer Gruppenname',
    en: 'New Groupname'
  },
  'master.my-customers.changeGroupModal.labelall': {
    de: 'Den Gruppennamen bei allen Mitgliedern dieser Gruppe ändern.',
    en: 'Change the group name for all members of this group.'
  },
  'master.my-customers.changeGroupModal.button.change': {
    de: 'Speichern',
    en: 'Save'
  },
  'master.my-customers.changeGroupModal.button.abort': {
    de: 'Abbrechen',
    en: 'Cancel'
  },
  'master.my-customers.customerChoose': {
    de: 'Kunde wählt',
    en: 'Customer chooses'
  },
  'master.my-customers.changeProduct': {
    de: 'Produkt wählen',
    en: 'Choose product'
  },
  'master.my-customers.changeProductModal.button.change': {
    de: 'Speichern',
    en: 'Save'
  },

  // Master -> Teamanalysis
  'master.teamanalysis.action.removeuser': {
    de: 'Entfernen',
    en: 'Remove',
    ru: 'Удалить'
  },
  'master.teamanalysis.action.download': {
    de: 'Teamanalyse herunterladen',
    en: 'Download team analysis',
    ru: 'Download team analysis'
  },

  // Master -> Support
  'master.support.analysisSupport': {
    de: 'ID37 Analyse Service',
    en: 'ID37 Analysis Service',
    ru: 'Аналитика ID37'
  },
  'master.support.info': {
    de: 'Zur Vorbereitung von ID37 Analysegesprächen stehen Dir Experten mit mehr als 15-jähriger Erfahrung in der motivbasierten ' +
      'Persönlichkeitsanalyse bereit. Es gibt zwei Möglichkeiten deren Analyse Service in Anspruch zu nehmen:',
    en: 'For the preparation of ID37 interviews, experts with more than 15 years of experience in motivational personality analysis ' +
      'are at your disposal. There are two ways to use their analysis service:',
    ru: 'Для подготовки интервью ID37 для Вас работают эксперты с более чем 15-летним опытом работы в ' +
      'мотивационной диагностике. У Вас есть два варианта, чтобы получить их консультацию'
  },
  'master.support.option1': {
    de: 'Kostenfreier Service via Live-Webinar zu festgelegten Zeiten.',
    en: 'Free service via live webinar at scheduled times.',
    ru: 'Бесплатная консультация через вебинар в прямом эфире по расписанию. '
  },
  'master.support.option2': {
    de: 'Exklusiver individueller Service, nach Terminvereinbarung.<br>Kostenpflichtig: 45,- EUR (zzgl. UsSt.) / 30 Min.',
    en: 'Exclusive individual service, by appointment. <br> Fee: 45, - EUR (plus VAT) / 30 Min.',
    ru: 'Эксклюзивная и индивидуальная консультация по записи. <br> Цена: 45, - EUR (без учета налогов) / 30 мин.'
  },
  'master.support.option1Btn': {
    de: 'Zum kostenpflichtigen Service',
    en: 'Paid service',
    ru: 'Платные услуги'
  },
  'master.support.option2Btn': {
    de: 'Zum kostenfreien Service',
    en: 'Free service',
    ru: 'Бесплатные услуги'
  },
  'admin.billingDetails.download': {
    de: 'Rechnungsdaten herunterladen',
    en: 'Download billing details'
  },
  'admin.billingDetails.download-partner': {
    de: 'Partner Rechnungsdaten herunterladen',
    en: 'Download Partner billing details'
  },
  'admin.billingDetails.billings': {
    de: 'Rechnungsdaten',
    en: 'Billing Details'
  },
  'admin.billingDetails.from': {
    de: 'von',
    en: 'from'
  },
  'admin.billingDetails.to': {
    de: 'bis',
    en: 'to'
  },

  // MyAccount page
  'my-account.saveChanges': {
    de: 'Änderungen speichern',
    en: 'Save changes',
    ru: 'Сохранить изменения'
  },
  'my-account.saveSuccessful': {
    de: 'Deine Änderungen wurden gespeichert.',
    en: 'Your changes have been saved.',
    ru: 'Ваши изменения были сохранены.'
  },
  'my-account.saveUnsuccessful': {
    de: 'Deine Änderungen konnten nicht gespeichert werden. Bitte überprüfe Deine Eingaben.',
    en: 'Your changes could not be saved. Please check your input.',
    ru: 'Ваши изменения не могут быть сохранены. Пожалуйста, проверьте свой вклад.'
  },
  'my-account.myAccount': {
    de: 'Mein Account',
    en: 'My account',
    ru: 'Моя учетная запись'
  },
  'my-account.masterOverview': {
    de: 'Übersicht',
    en: 'Summary',
    ru: 'Обзор'
  },
  'my-account.testPrice': {
    de: 'Testpreis (Pay per Use)',
    en: 'Test price (Pay per Use)',
    ru: 'Тестовая цена (оплата за использование)'
  },
  'my-account.amountOfFinishedTests': {
    de: 'Anzahl abgeschlossener Tests (Status: "erledigt")',
    en: 'Amount of finished tests (status: "done")',
    ru: 'Количество завершеннх тестов(статус: "завершен")'
  },
  'my-account.amountOfClients': {
    de: 'Anzahl eingeladener Clients',
    en: 'Number of invited clients',
    ru: 'Количество приглашенных клиентов'
  },
  'my-account.billingAddress': {
    de: 'Rechnungsadresse',
    en: 'Billing address',
    ru: 'Платёжный адрес'
  },
  'my-account.calculating': {
    de: 'BERECHNE...',
    en: 'CALCULATING...',
    ru: 'РАСЧЁТ...'
  },
  'my-account.personal': {
    de: 'Persönliche Angaben',
    en: 'Personal data',
    ru: 'Личная информация'
  },
  'my-account.jobAndEducation': {
    de: 'Beruf und Ausbildung',
    en: 'Profession and education',
    ru: 'Профессия и образование'
  },
  'my-account.firstName': {
    de: 'Vorname',
    en: 'First name',
    ru: 'Имя'
  },
  'my-account.lastName': {
    de: 'Nachname',
    en: 'Last name',
    ru: 'Фамилия'
  },
  'my-account.email': {
    de: 'E-Mail',
    en: 'Email',
    ru: 'Электронная почта'
  },
  'my-account.dateOfBirth': {
    de: 'Geburtsdatum',
    en: 'Date of birth',
    ru: 'День рождения'
  },
  'my-account.dateOfBirthPlaceholder': {
    de: 'Wann wurdest du geboren?',
    en: 'When were you born?',
    ru: 'Когда Вы родились?'
  },
  'my-account.gender': {
    de: 'Geschlecht',
    en: 'Gender',
    ru: 'Пол'
  },
  'my-account.genderPlaceholder': {
    de: 'Welches Geschlecht hast Du?',
    en: 'What is your gender?',
    ru: 'Какой у Вас пол?'
  },
  'my-account.residence': {
    de: 'Land',
    en: 'Country',
    ru: 'Страна'
  },
  'my-account.residencePlaceholder': {
    de: 'In welchem Land lebst Du derzeit?',
    en: 'What country do you currently live in?',
    ru: 'В какой стране Вы сейчас проживаете?'
  },
  'my-account.origin': {
    de: 'Nationalität',
    en: 'Nationality',
    ru: 'Национальность'
  },
  'my-account.originPlaceholder': {
    de: 'Welche ist Deine Nationalität?',
    en: 'What\'s your nationality?',
    ru: 'Ваша национальность?'
  },
  'my-account.city': {
    de: 'Ort',
    en: 'Current location',
    ru: 'Место жительства'
  },
  'my-account.cityPlaceholder': {
    de: 'Wo lebst Du derzeit?',
    en: 'Where do you currently live?',
    ru: 'Где Вы сейчас проживаете?'
  },
  'my-account.mobilePhoneNumber': {
    de: 'Mobilfunknumnmer',
    en: 'Mobile number',
    ru: 'Номер мобильного телефона'
  },
  'my-account.mobilePhoneNumberPlaceholder': {
    de: 'Deine Mobilfunknummer',
    en: 'What is your mobile number?',
    ru: 'Ваш номер мобильного телефона?'
  },
  'my-account.academicDegree': {
    de: 'Höchster Schulabschluss',
    en: 'Highest level of academic attainment',
    ru: 'Образование'
  },
  'my-account.academicDegreePlaceholder': {
    de: 'Welchen Schulabschluss hast Du?',
    en: 'What\'s your high school diploma?',
    ru: 'Какое образование Вы получили?'
  },
  'my-account.academicTitle': {
    de: 'Akademischer Titel',
    en: 'Academic title',
    ru: 'Ученая степень'
  },
  'my-account.industry': {
    de: 'Branche',
    en: 'Industry',
    ru: 'Отрасль'
  },
  'my-account.industryPlaceholder': {
    de: 'In welcher Branche bist Du aktuell tätig?',
    en: 'Please select the industry in which you are active',
    ru: 'В какой отрасли Вы работаете на данный момент?'
  },
  'my-account.company': {
    de: 'Unternehmen',
    en: 'Company name',
    ru: 'Компания'
  },
  'my-account.companyPlaceholder': {
    de: 'In welchem Unternehmen arbeitest Du derzeit?',
    en: 'What company do you currently work for?',
    ru: 'В какой компании Вы работаете на данный момент?'
  },
  'my-account.profession': {
    de: 'Beruf',
    en: 'Profession',
    ru: 'Профессия'
  },
  'my-account.professionPlaceholder': {
    de: 'In welchem Beruf bist Du aktuell tätig?',
    en: 'What is your current profession?',
    ru: 'Кем Вы работаете на данный момент?'
  },
  'my-account.yourMaster': {
    de: 'Dein ID37 Master',
    en: 'Your ID37 Master',
    ru: 'Ваш Мастер ID37'
  },
  'my-account.noMasterInfoText': {
    de: 'Dir ist derzeit noch kein ID37 Master zugewiesen. Finde hier einen Master:',
    en: 'You currently have no ID37 Master assigned. Find a Master here:',
    ru: 'У Вас нет назначенного Мастера ID37. Найти Мастера здесь:'
  },
  'my-account.findMasterLinkName': {
    de: 'Master finden',
    en: 'Find master',
    ru: 'Найти Мастера'
  },
  'my-account.masterId': {
    de: 'ID',
    en: 'ID',
    ru: 'ID'
  },
  'my-account.masterName': {
    de: 'Name',
    en: 'Name',
    ru: 'Имя'
  },
  'my-account.masterEmail': {
    de: 'E-Mail',
    en: 'Email',
    ru: 'Электронная почта'
  },
  'my-account.removeAssociatedMaster': {
    de: 'Verbindung zu Deinem Master trennen',
    en: 'Disconnect from your Master',
    ru: 'Отказаться от своего Мастера'
  },
  'my-account.removeAssociatedMasterAreYouSure': {
    de: 'Möchtest Du die Verbindung zu Deinen Master wirklich aufheben?',
    en: 'Do you really want to disconnect from your Master?',
    ru: 'Вы действительно хотите отказаться от своего Мастера?'
  },
  'my-account.removeAssociatedMasterExplanation': {
    de: 'Diese Entscheidung kann durch Dich nicht rückgängig gemacht werden!',
    en: 'This decision can not be reversed!',
    ru: 'Вы не сможете отменить своего решения. '
  },
  'my-account.removeAssociatedMasterAccept': {
    de: 'Ja, ich möchte mich von meinem Master trennen',
    en: 'Yes, I would like to disconnect from my Master',
    ru: 'Да, я хочу отказаться от своего Мастера'
  },
  'my-account.removeAssociatedMasterAbort': {
    de: 'Nein, lieber doch nicht',
    en: 'No, better not',
    ru: 'Нет, лучше оставить всё, как есть'
  },
  'my-account.deleteMyAccount': {
    de: 'Meinen Account löschen',
    en: 'Delete my account',
    ru: 'Удалить мою учётную запись'
  },
  'my-account.deleteMyAccountAreYouSure': {
    de: 'Möchtest Du Deinen Account wirklich löschen?',
    en: 'Do you really want to delete your account?',
    ru: 'Вы действительно хотите удалить Вашу учетную запись?'
  },
  'my-account.chatbotDeletionWarning': {
    de: 'Hinweis: Du hast noch ein aktives KI-Chatbot Abo. ' +
      'Bei Löschung des Kontos wird das Abo automatisch gekündigt.' +
      ' Du hast keinen Anspruch auf Rückerstattung eines möglichen Restbetrags.',
    en: 'Note: You still have an active AI chatbot subscription. ' +
      'Deleting your account will automatically cancel this subscription. ' +
      'You are not entitled to a refund of any remaining balance.',
  },
  'my-account.deleteMyAccountExplanation': {
    de: 'Diese Entscheidung kann nicht rückgängig gemacht werden!',
    en: 'Your decision is final!',
    ru: 'Вы не сможете отменить своего решения!'
  },
  'my-account.deleteMyAccountConfirmationInfoA': {
    de: 'Bitte bestätige, dass Du Deinen Account löschen möchtest, indem Du ',
    en: 'Please confirm that you want to delete your account by entering ',
    ru: 'Пожалуйста, подтвердите удаление аккаунта нажатием кнопки „Enter“'
  },
  'my-account.deleteMyAccountInputToConfirm': {
    de: 'account löschen',
    en: 'delete account',
    ru: 'Удалить аккаунт'
  },
  'my-account.deleteMyAccountConfirmationInfoB': {
    de: ' eingibst.',
    en: '.',
    ru: '.'
  },
  'my-account.deleteMyAccountWaitingForConfirmation': {
    de: 'Warte auf Bestätigung...',
    en: 'Waiting for confirmation...',
    ru: 'Ожидание подтверждения...'
  },
  'my-account.deleteMyAccountAccept': {
    de: 'Ja, ich möchte meinen Account unwiderruflich löschen',
    en: 'Yes, I would like to delete my account irrevocably',
    ru: 'Да, я хочу удалить свой аккаунт безвозвратно'
  },
  'my-account.deleteMyAccountAbort': {
    de: 'Nein, lieber doch nicht',
    en: 'No, better not',
    ru: 'Нет, лучше оставить всё, как есть'
  },
  'my-account.criticalDataIsToBeChanged.title': {
    de: 'Bist du sicher?',
    en: 'Are you sure?',
    ru: 'Are you sure?'
  },
  'my-account.criticalDataIsToBeChanged.body': {
    de: 'Dies wird folgende Änderungen speichern:',
    en: 'This will save the following changes:',
    ru: 'This will save the following changes:'
  },
  'my-account.criticalDataIsToBeChanged.applyChanges': {
    de: 'Ja, Änderungen speichern',
    en: 'Yes, save changes',
    ru: 'Yes, save changes'
  },
  'my-account.criticalDataIsToBeChanged.abort': {
    de: 'Abbrechen',
    en: 'Cancel',
    ru: 'Отменить'
  },
  'my-account.optIns': {
    de: 'Opt-In',
    en: 'Opt-In'
  },
  'my-account.optInAd': {
    de: 'Opt-In',
    en: 'Opt-In'
  },
  'my-account.emailSettings': {
    de: 'E-Mail Benachrichtigungen',
    en: 'Email notifications'
  },
  'my-account.advertising': {
    de: 'Werbungen bekommen',
    en: 'Get advertising messages'
  },
  // Email settings
  'emailSettings.informAboutUnreadChatMessages.label': {
    de: 'Ungelesene Chatnachrichten',
    en: 'Unread Chat messages'
  },
  'emailSettings.informAboutUnreadChatMessages.enabledText': {
    de: 'Du wirst über ungelesene Chatnachrichten per E-Mail erinnert.',
    en: 'You will be reminded of unread chat messages by email.'
  },
  'emailSettings.informAboutUnreadChatMessages.disabledText': {
    de: 'Du wirst nicht über ungelesene Chat-Nachrichten informiert.',
    en: 'You will not be informed of unread chat messages.'
  },
  'emailSettings.productInformation.label': {
    de: 'Aktuelle Produktinfos',
    en: 'Current product information'
  },
  'emailSettings.productInformation.enabledText': {
    de: 'Du erhältst Infos über neue ID37 Services & Features per E-Mail.',
    en: 'You receive information about new ID37 services and features via email.'
  },
  'emailSettings.productInformation.disabledText': {
    de: 'Du erhältst keine Infos über neue ID37 Services & Features per E-Mail.',
    en: 'You don`t receive information about new ID37 services and features by email.'
  },

  // Profile pictures
  'profilePictureManager.selectProfilePicture': {
    de: 'Bild auswählen',
    en: 'Select image'
  },
  'profilePictureManager.saveProfilePicture': {
    de: 'Speichern',
    en: 'Save'
  },
  'profilePictureManager.clearProfilePictureSelection': {
    de: 'Zurücksetzen',
    en: 'Clear selection'
  },
  'profilePictureManager.deleteProfilePicture': {
    de: 'Bild löschen',
    en: 'Delete image'
  },
  'profilePictureManager.fileToLarge': {
    de: 'Das Bild ist zu groß. Bitte wähle eine Datei aus, die maximal 1MB groß ist.',
    en: 'The picture is too big. Please select a file with a maximum size of 1MB.'
  },
  'profilePictureManager.fileTypeNotSupported': {
    de: 'Der Dateityp wird nicht unterstützt. Bitte wähle eine JPEG oder PNG Datei aus.',
    en: 'The file type is not supported. Please select a JPEG or PNG file.'
  },
  'profilePictureManager.couldNotLoad': {
    de: 'Die Datei konnte nicht geladen werden.',
    en: 'The file could not be loaded.'
  },

  // Master -> Toolbox -> Import
  'import.title': {
    de: 'ID37 Analysen erzeugen (Importfunktion)',
    en: 'Create ID37 Analysis (Import)',
    ru: 'Создать анализ ID37(Импорт)'
  },
  'import.success': {
    de: 'Erfolgreich importiert',
    en: 'Your import was successful',
    ru: 'Импорт был завершен успешно'
  },
  'import.errorInRow': {
    de: 'Fehler in Zeile',
    en: 'Error in row',
    ru: 'Ошибка в строке'
  },
  'import.download': {
    de: 'Lade die Import-Vorlage (Excel Datei) herunter',
    en: 'Download the import template (Excel file)',
    ru: 'Загрузить шаблон импорта (файл Excel)'
  },
  'import.luxxHeadline': {
    de: 'LUXXprofile Analysen in ID37 Analysen umwandeln',
    en: 'Convert LUXXprofile analyses to ID37 analyses',
    ru: 'Преобразовать результаты LUXXprofile в ID37'
  },
  'import.luxxText1': {
    de: 'LUXXprofile Persönlichkeitsanalysen lassen sich in ID37 Analysen umwandeln, indem Profilwerte ' +
      'von LUXX in die Motiv-Spalten eingetragen werden.',
    en: 'LUXXprofile analyses are convertible to ID37 analyses by inserting LUXXprofile values into the motive columns.',
    ru: 'Результаты LUXXprofile могут быть преобразованы в результаты ID37 с использованием значений профиля.'
  },
  'import.luxxText2': {
    de: 'Wichtig ist die Spalte „einladen“:',
    en: 'The „invite“ column is important:',
    ru: 'Столбец „Пригласить“ очень важен:'
  },
  'import.luxxText3': {
    de: 'Y (= yes) Der Kunde erhält an die von Dir angegebene E-Mailadresse eine Einladung, ' +
      'um einen ID37 Account anzulegen. Legt er diesen an, erhält er seine ID37 ' +
      'Analyse. Hierfür musst Du das Profil freigeben (Aktion "freigeben").',
    en: 'Y (= yes) The client gets an invitation to the email account you entered to create his/her ID37 account. ' +
      'Once created the client is able to access his/her ID37 Analysis. Therefore, you need to grant access to him/her (Action "grant").',
    ru: 'Y (= да) Клиент получит приглашение на электронную почту, которую Вы ввели, чтобы создать его учетную запись ID37. ' +
      'После создания клиент может получить доступ к своему профилю ID37. Поэтому Вам необходимо предоставить клиенту доступ ' +
      '(действие «Предоставить доступ»).'
  },
  'import.luxxText4': {
    de: 'N (= no) Der Kunde wird nicht darüber informiert, dass sein LUXXprofile umgewandelt wird.',
    en: 'N (= no) The client will not be informed about the LUXXprofile import.',
    ru: 'N (= нет) Клиент не будет оповещён о том, что его LUXXprofile будет преобразован. '
  },
  'import.luxxText5': {
    de: 'In beiden Fällen bestätigst Du, dass Du mit den verwendeten E-Mailadressen arbeiten darfst.',
    en: 'In both cases you confirm that you have the permission to use these email addresses.',
    ru: 'В обоих случаях Вы подтверждаете, что Вам разрешено работать с используемыми адресами электронной почты.'
  },
  'import.id37Headline': {
    de: 'ID37 Showcase-Profile erstellen',
    en: 'Create ID37 Showcase Profiles',
    ru: 'Создать Showcase-Profile ID37»'
  },
  'import.id37Text1': {
    de: 'Trage die gewünschten Profilwerte in die Motiv-Spalten ein.',
    en: 'Enter the showcase values for the motives into the columns.',
    ru: 'Введите нужные значения профиля в столбцы мотивов.'
  },
  'import.id37Text2': {
    de: 'Trage bei E-Mail eine Mailadresse ein, die folgender Systematik folgt:',
    en: 'In column email enter an email address with the following pattern:',
    ru: 'В поле электронной почты введите адрес электронной почты по следующей схеме:'
  },
  'import.id37Text3': {
    de: 'einVorname.einNachname@id37.me',
    en: 'firstName.lastName@id37.me',
    ru: 'imya.familiya@id37.me'
  },
  'import.id37Text4': {
    de: 'Sollte beim Hochladen der ausgefüllten Vorlage eine Fehlermeldung ' +
      'hinsichtlich E-Mailadresse erscheinen, so wähle einen Phantasienamen.',
    en: 'Should this result in an error concerning the email address try to use a fantasy name.',
    ru: 'Если это приведет к ошибке, связанной с адресом электронной почты, попробуйте использовать выдуманное имя.'
  },
  'import.id37Text5': {
    de: 'Eine E-Mail Adresse kann nur einmal verwendet werden.',
    en: ' Each email address can only be used once.',
    ru: ' Адрес электронной почты может быть использован только один раз. '
  },
  'import.id37Text6': {
    de: 'Nach Auswahl eines Typs zeigt Dir die Vorlage an, welche Zellen mit Werten befüllt werden müssen.',
    en: 'After selecting a type, the template will show you which cells need to be filled with values.',
    ru: 'После выбора типа шаблон укажет Вам, какие ячейки необходимо заполнить значениями.'
  },
  'import.id37Text7': {
    de: 'Lade Deine ausgefüllte Vorlage hoch und folge weiteren Anweisungen.',
    en: 'Upload your filled out template and follow further instructions.',
    ru: 'Загрузите заполненный шаблон и следуйте дальнейшим инструкциям.'
  },
  'import.btnSelectFile': {
    de: 'Datei für Import auswählen',
    en: 'Select file for import',
    ru: 'Выберите файл для импорта'
  },
  'import.btnDoUpload': {
    de: 'Upload!',
    en: 'Upload!',
    ru: 'Загрузить'
  },
  'import.confirmation.title': {
    de: 'Nutzungsbestätigung',
    en: 'Confirmation of terms',
    ru: 'Подтверждение условий'
  },
  'import.confirmation.text1': {
    de: 'Ich bestätige, dass ich die Einwilligung aller Empfänger für die Nutzung ihrer E-Mailadresse habe.',
    en: 'I confirm that all recipients consented to the use of their email address.',
    ru: 'Я подтверждаю, что у меня есть согласие всех получателей на использование их адресов электронной почты.'
  },
  'import.confirmation.text2': {
    de: 'id37.me Adressen sind davon ausgenommen.',
    en: 'id37.me addresses are exempt.',
    ru: 'Адреса с id37.me являются исключением.'
  },
  'import.confirmation.confirm': {
    de: 'Bestätigen',
    en: 'Confirm',
    ru: 'Подтвердить'
  },
  'import.confirmation.acceptBtnUnarmed': {
    de: 'Upload',
    en: 'Upload',
    ru: 'Загрузить'
  },
  'import.confirmation.acceptBtnArmed': {
    de: 'Upload',
    en: 'Upload',
    ru: 'Загрузить'
  },
  'import.confirmation.abortBtn': {
    de: 'Abbrechen',
    en: 'Cancel',
    ru: 'Отменить'
  },

  // Report
  'report.close': {
    de: 'Analyse schließen',
    en: 'Close analysis',
    ru: 'Закрыть профиль'
  },
  'report.content': {
    de: 'Inhalt',
    en: 'Content',
    ru: 'Содержание'
  },
  'report.formOfAddress': {
    de: {
      '': 'Hallo',
      diverse: 'Hallo',
      female: 'Liebe',
      male: 'Lieber'
    },
    en: {
      '': 'Dear',
      diverse: 'Dear',
      female: 'Dear',
      male: 'Dear'
    },
    ru: {
      '': 'Уважаемый',
      diverse: 'Уважаемый',
      female: 'Уважаемая',
      male: 'Уважаемый'
    },
    nl: {
      '': 'Dear',
      diverse: 'Dear',
      female: 'Dear',
      male: 'Dear'
    }
  },
  'report.id37Master': {
    de: 'ID37 Master',
    en: 'ID37 Master',
    ru: 'Мастер ID37'
  },
  'report.nav.clientInfo': {
    de: 'Client Information',
    en: 'Client information',
    ru: 'Информация о клиентах'
  },
  'report.nav.intro': {
    de: 'Deine ID37 Analyse',
    en: 'Your ID37 analysis',
    ru: 'Ваш профиль ID37'
  },
  'report.nav.findMaster': {
    de: 'ID37 Master finden',
    en: 'Find an ID37 Master',
    ru: 'Найти Мастера ID37'
  },
  'report.nav.book': {
    de: 'Zum ID37 Buch',
    en: 'To the ID37 book',
    ru: 'К книге ID37'
  },
  'report.nav.contact': {
    de: 'Kontakt',
    en: 'Contact',
    ru: 'Контакт'
  },
  'report.nav.print': {
    de: 'ID37 Analyse drucken',
    en: 'Print the ID37 analysis',
    ru: 'Распечатать профиль ID37'
  },
  'report.intro.toScienceLink': {
    de: 'Wie ist Dein Ergebnis einzuordnen?',
    en: 'How to interpret your result?',
    nl: 'Het resultaat interpreteren?',
    ru: 'Как интерпретировать Ваш результат?'
  },
  'report.profile-application.toMotivesDescriptionLink': {
    de: 'Vorstellung der einzelnen Motive und ihrer Wirkung',
    en: 'Presentation of individual motives and their effect',
    nl: 'Betekenis en invloed van de motieven',
    ru: 'Представление отдельных мотивов и их влияние'
  },

  // Client info
  'report.clientInfo.analysisFor': {
    de: 'ID37 Persönlichkeitsanalyse für',
    en: 'ID37 personality analysis for',
    ru: 'Диагностика личности ID37 для',
    ka: 'პიროვნების ანალიზი'
  },
  'report.clientInfo.analysisForAddition': {
    de: ' ',
    en: ' ',
    ru: ' ',
    ka: 'სთვის'
  },
  'report.clientInfo.email': {
    de: 'E-Mail',
    en: 'Email',
    ru: 'Электронная почта',
    ka: 'ელ. ფოსტა'
  },
  'report.clientInfo.gender': {
    de: 'Geschlecht',
    en: 'Gender',
    ru: 'Пол',
    ka: 'გენდერი'
  },
  'report.clientInfo.academicDegree': {
    de: 'Höchster Schulabschluss',
    en: 'Highest level of academic attainment',
    ru: 'Образование',
    ka: 'აკადემიური მოსწრების უმაღლესი დონე'
  },
  'report.clientInfo.origin': {
    de: 'Nationalität',
    en: 'Nationality',
    ru: 'Национальность',
    ka: 'ეროვნება'
  },
  'report.clientInfo.dateOfBirth': {
    de: 'Geburtstag',
    en: 'Date of birth',
    ru: 'День рождения',
    ka: 'დაბადების თარიღი'
  },
  'report.clientInfo.residence': {
    de: 'Land',
    en: 'Country',
    ru: 'Страна',
    ka: 'ქვეყანა'
  },
  'report.clientInfo.city': {
    de: 'Ort',
    en: 'Current location',
    ru: 'Место жительства',
    ka: 'ამჟამინდელი ადგილმდებარეობა'
  },
  'report.clientInfo.industry': {
    de: 'Branche',
    en: 'Industry',
    ru: 'Отрасль',
    ka: 'სექტორი'
  },
  'report.clientInfo.company': {
    de: 'Unternehmen',
    en: 'Company name',
    ru: 'Компания',
    ka: 'კომპანიის დასახელება'
  },
  'report.clientInfo.profession': {
    de: 'Beruf',
    en: 'Profession',
    ru: 'Профессия',
    ka: 'პროფესია'
  },
  'report.clientInfo.mobilePhoneNumber': {
    de: 'Mobilfunknummer',
    en: 'Mobile number',
    ru: 'Номер мобильного телефона',
    ka: 'მობილური ტელ. ნომერი'
  },
  'report.print-document.firefoxPrintWarning': {
    de: 'Du verwendest den Firefox Browser. Beim Drucken und bei interaktiven Darstellungen mit Firefox kann es zu ' +
      'Problemen kommen. Unsere Anwendung ' +
      'ist auf den Google Chrome Browser optimiert. Wir empfehlen Dir für die Erstellung von PDFs und den Ausdruck ' +
      'Google Chrome zu verwenden. Hier kannst Du Dir kostenfrei Chrome herunterladen: ',
    en: 'You are using the Firefox browser. Errors may occur when printing or viewing interactive items with ' +
      'Firefox. Our application ' +
      'is optimized for the use of Chrome. We recommend to use Chrome when creating PDFs and printing. You can ' +
      'download a free version of Chrome here:',
    ru: 'Вы используете браузер Firefox. при печати или просмотре интерактивных элементов в Firefox могут ' +
      'возникать ошибки. Наше приложение ' +
      'оптимизировано для использования Chrome. Мы рекомендуем использовать Chrome при создании PDF-файлов и печати. ' +
      'Вы можете скачать бесплатную версию Chrome здесь:'
  },

  /* MOTIVES */
  'motive.neu': {
    de: 'Neugier',
    en: 'Curiosity',
    ru: 'Любознательность',
    nl: 'Nieuwsgierigheid',
    fr: 'Curiosité',
    es: 'Curiosidad',
    it: 'Curiosità',
    ka: 'ცნობისმოყვარეობა'
  },
  'motive.team.neu': {
    de: 'Neugier',
    en: 'Curiosity',
    ru: 'Любо- знательность',
    nl: 'Nieuws gierigheid',
    fr: 'Curiosité',
    es: 'Curiosidad',
    it: 'Curiosità',
    ka: 'ცნობისმოყვარეობა'
  },
  'motive.neu.abbreviation': {
    de: 'NEU',
    en: 'CUR',
    nl: 'NIE'
  },
  'motive.neu.meaning': {
    de: 'Streben nach Wissen, Erkenntnis und intellektueller Herausforderung.',
    en: 'Desire for knowledge, insight and intellectual challenges.',
    ru: 'Стремление к знаниям и интеллектуальным задачам.',
    nl: 'Verlangen naar kennis, inzicht en intellectuele uitdaging.'
  },
  'motive.neu.low': {
    de: 'Wissen und Erkenntnis spielen eine untergeordnete Rolle in Deinem Leben. Du meidest die Beschäftigung mit ' +
      'intensiven intellektuellen Anstrengungen.',
    en: 'Knowledge and insight play a minor role in your life. You avoid engaging in taxing intellectual activities.',
    ru: 'Знания и познание играют второстепенную роль в Вашей жизни. Вы стремитесь избегать задач, которые требуют ' +
      'интенсивных умственных усилий.',
    nl: 'Kennis en inzicht spelen slechts minimaal een rol in je leven. Je vermijdt activiteiten die intellectueel ' +
      'belastend zijn.'
  },
  'motive.neu.medium': {
    de: 'Wissen und Erkenntnisgewinn spielen keine signifikante Rolle in Deinem Leben.',
    en: 'Knowledge and insight do not play an important role in your life.',
    ru: 'Знания и познание не играют значимой роли в Вашей жизни.',
    nl: 'Kennis en inzicht spelen geen belangrijke rol in je leven.'
  },
  'motive.neu.high': {
    de: 'Wissen und Erkenntnisgewinn spielen eine wichtige Rolle in Deinem Leben. Du strebst nach intellektueller ' +
      'Tätigkeit und Herausforderung.',
    en: 'Knowledge and insight play an important role in your life. You desire intellectual activity and challenges.',
    ru: 'Знания и познание играют значимую роль в Вашей жизни. Вы стремитесь к знаниям и интеллектуальным задачам.',
    nl: 'Kennis en inzicht spelen een belangrijke rol in je leven. Je verlangt naar intellectuele activiteiten en ' +
      'uitdagingen.'
  },
  'motive.san': {
    de: 'Soziale Anerkennung',
    en: 'Social acceptance',
    ru: 'Общественное признание',
    nl: 'Sociale Erkenning',
    fr: 'Reconnaissance sociale',
    es: 'Reconocimiento social',
    it: 'Riconoscimento sociale',
    ka: 'სოციალური აღიარება'
  },
  'motive.team.san': {
    de: 'Soziale Anerkennung',
    en: 'Social acceptance',
    ru: 'Общественное признание',
    nl: 'Sociale Erkenning',
    fr: 'Reconnais- sance' + String.fromCharCode(160) + 'sociale',
    es: 'Reconocimien- to' + String.fromCharCode(160) + 'social',
    it: 'Riconoscimen- to' + String.fromCharCode(160) + 'sociale',
    ka: 'სოციალური აღიარება'
  },
  'motive.san.abbreviation': {
    de: 'SAN',
    en: 'SAC',
    nl: 'SER'
  },
  'motive.san.meaning': {
    de: 'Streben nach Bestätigung und Anerkennung durch andere',
    en: 'Desire for affirmation and acceptance from others.',
    ru: 'Стремление к признанию и почитанию.',
    nl: 'Verlangen naar bevestiging en acceptatie door anderen.'
  },
  'motive.san.low': {
    de: 'Bestätigung und Anerkennung durch andere sind Dir nicht wichtig im Leben. Dein Verhalten richtet sich nicht ' +
      'primär danach, was andere von Dir halten oder wie sie Dich beurteilen.',
    en: 'Being affirmed and accepted by others is not important to you.  Your behavior is not geared toward how ' +
      'others judge or see you.',
    ru: 'Признание и почитание других людей не играет большой роли в Вашей жизни. Ваше поведение в первую очередь не ' +
      'зависит от мнения других.',
    nl: 'Bevestiging krijgen en geaccepteerd worden door anderen is niet belangrijk voor je. Jouw gedrag wordt niet ' +
      'gestuurd door hoe anderen over je oordelen of hoe ze je zien.'
  },
  'motive.san.medium': {
    de: 'Bestätigung und Anerkennung durch andere spielen keine zentrale Rolle in Deinem Leben.',
    en: 'Being affirmed and accepted by others does not play a significant role in your life.',
    ru: 'Признание и почитание другими людьми не играет центральной роли в Вашей жизни.',
    nl: 'Bevestiging en acceptatie door anderen spelen geen significante rol In je leven.'
  },
  'motive.san.high': {
    de: 'Anerkennung durch andere ist Dir sehr wichtig im Leben. Dein Verhalten zielt darauf ab, Bestätigung zu ' +
      'erhalten und Kritik, Ablehnung oder Zurückweisung zu vermeiden.',
    en: 'Being accepted by others is important to you. Your behavior is geared toward receiving affirmation from ' +
      'others and avoiding criticism or rejection.',
    ru: 'Признание играет важную роль в Вашей жизни. Ваше поведение нацелено на получение похвалы и избегание ' +
      'критики или непризнания.',
    nl: 'Geaccepteerd worden door anderen is belangrijk voor je. Jouw gedrag is erop gericht om bevestiging te ' +
      'krijgen en kritiek of afwijzing te vermijden.'
  },
  'motive.ein': {
    de: 'Einfluss',
    en: 'Influence',
    ru: 'Влияние',
    nl: 'Invloed',
    fr: 'Influence',
    es: 'Influencia',
    it: 'Influenza',
    ka: 'გავლენა'
  },
  'motive.team.ein': {
    de: 'Einfluss',
    en: 'Influence',
    ru: 'Влияние',
    nl: 'Invloed',
    fr: 'Influence',
    es: 'Influencia',
    it: 'Influenza',
    ka: 'გავლენა'
  },
  'motive.ein.abbreviation': {
    de: 'EIN',
    en: 'INF',
    nl: 'INV'
  },
  'motive.ein.meaning': {
    de: 'Streben nach Kontrolle und Einfluss auf Personen und Vorgänge.',
    en: 'Desire for control and influence over people and processes.',
    ru: 'Стремление к контролю и влиянию на людей и события.',
    nl: 'Verlangen naar controle en invloed op mensen en processen.'
  },
  'motive.ein.low': {
    de: 'Einfluss auszuüben ist Dir nicht wichtig im Leben. Du legst keinen Wert darauf, Menschen und Vorgänge zu ' +
      'beeinflussen oder Entscheidungen für andere zu treffen.',
    en: 'Exerting influence is not important to you. You do not attach any importance to influencing people and ' +
      'processes or making decisions for others.',
    ru: 'Влияние не играет важной роли в Вашей жизни. Вам не особо важно влиять на людей, события и принятия решений.',
    nl: 'Invloed uitoefenen is niet belangrijk voor je. Je hecht er geen waarde aan om mensen en processen te ' +
      'beïnvloeden of voor anderen besluiten te nemen.'
  },
  'motive.ein.medium': {
    de: 'Kontrolle und Einfluss auszuüben spielt keine signifikante Rolle in Deinem Leben.',
    en: 'Exerting control and influence does not play a significant role in your life.',
    ru: 'Влияние и контроль не играют очень важной роли в Вашей жизни.',
    nl: 'Controle en invloed uitoefenen spelen geen belangrijke rol in je leven.'
  },
  'motive.ein.high': {
    de: 'Einfluss auszuüben ist Dir wichtig im Leben. Du strebst danach, Dein Lebensumfeld zu kontrollieren, andere ' +
      'zu überzeugen, Situationen zu lenken oder Verantwortung zu übernehmen.',
    en: 'Exerting influence is important to you. You make an effort to control your environment, convince others, ' +
      'manage situations or take responsibility for things.',
    ru: 'Влияние играет важную роль в Вашей жизни. Вы стремитесь контролировать своё окружение, убеждать других, ' +
      'руководить ситуацией и брать на себя ответственность.',
    nl: 'Invloed uitoefenen is belangrijk voor je. Je doet moeite om je omgeving onder controle te houden, anderen ' +
      'te overtuigen, situaties te beheersen of verantwoordelijkheid voor dingen te nemen.'
  },
  'motive.sta': {
    de: 'Status',
    en: 'Status',
    ru: 'Статус',
    nl: 'Status',
    fr: 'Statut social',
    es: 'Estatus social',
    it: 'Status sociale',
    ka: 'სტატუსი'
  },
  'motive.team.sta': {
    de: 'Status',
    en: 'Status',
    ru: 'Статус',
    nl: 'Status',
    fr: 'Statut social',
    es: 'Estatus social',
    it: 'Status sociale',
    ka: 'სტატუსი'
  },
  'motive.sta.abbreviation': {
    de: 'STA',
    en: 'STA',
    nl: 'STA'
  },
  'motive.sta.meaning': {
    de: 'Streben nach Ansehen und einer hervorgehobenen Stellung in der Gesellschaft.',
    en: 'Desire for prestige and a prominent position in society.',
    ru: 'Стремление к престижу и видному положению в обществе.',
    nl: 'Verlangen naar aanzien en maatschappelijke sociale status.'
  },
  'motive.sta.low': {
    de: 'Ansehen und gesellschaftliche Stellung spielen eine untergeordnete Rolle in Deinem Leben. Du legst keinen ' +
      'Wert darauf, Dich von anderen abzuheben. Privilegien für einige wenige lehnst Du ab.',
    en: 'Prestige and social standing play only a minor role in your life. You do not attach importance to being ' +
      'able to distinguish yourself from others. You dislike it when privileges are afforded to only a handful of people.',
    ru: 'Престиж и положение в обществе играют второстепенную роль в Вашей жизни. Вы не придаете большого смысла ' +
      'отличию от других людей. Вы отрицаете привилегии.',
    nl: 'Aanzien en sociale status spelen slechts minimaal rol in je leven. Je hecht er geen waarde aan je te ' +
      'kunnen onderscheiden van anderen. Je ergert je eraan als alleen een kleine groep mensen privileges worden ' +
      'toegekend.'
  },
  'motive.sta.medium': {
    de: 'Ansehen und eine elitäre Stellung in der Gesellschaft spielen keine signifikante Rolle in Deinem Leben.',
    en: 'Prestige and having an elite status are not important to you.',
    ru: 'Престиж и элитное положение в обществе не играют значимой роли в Вашей жизни.',
    nl: 'Aanzien en het hebben van een elitaire status zijn niet belangrijk voor je.'
  },
  'motive.sta.high': {
    de: 'Dein Ruf und Deine gesellschaftliche Stellung spielen eine besondere Rolle in Deinem Leben. Du legst großen ' +
      'Wert darauf, Dich von anderen abzuheben und dass dies wahrgenommen wird.',
    en: 'Your reputation and social standing are particularly important to you. You attach great importance to being ' +
      'able to distinguish yourself from others and having this be publicly acknowledged.',
    ru: 'Престиж и положение в обществе играют особую роль в Вашей жизни. Вам очень важно занять высокое положение в ' +
      'обществе и демонстрировать его значимость.',
    nl: 'Je reputatie en sociale status zijn erg belangrijk voor je. Je hecht er veel waarde aan om je te kunnen ' +
      'onderscheiden van anderen en vindt het belangrijk dat dit publiekelijk wordt erkend.'
  },
  'motive.bes': {
    de: 'Besitzen',
    en: 'Retention',
    ru: 'Владение имуществом',
    nl: 'Bezitten',
    fr: 'Posséder',
    es: 'Retención',
    it: 'Proprietà personale',
    ka: 'ფლობა'
  },
  'motive.team.bes': {
    de: 'Besitzen',
    en: 'Retention',
    ru: 'Владение имуществом',
    nl: 'Bezitten',
    fr: 'Posséder',
    es: 'Retención',
    it: 'Proprietà personale',
    ka: 'ფლობა'
  },
  'motive.bes.abbreviation': {
    de: 'BES',
    en: 'RET',
    nl: 'BEZ'
  },
  'motive.bes.meaning': {
    de: 'Bestreben, Besitz und Vorräte anzulegen und zu erhalten.',
    en: 'Efforts to acquire and preserve possessions and other resources.',
    ru: 'Стремление откладывать и сохранять про запас.',
    nl: 'Inzet om eigendommen en andere middelen in bezit te krijgen en te onderhouden.'
  },
  'motive.bes.low': {
    de: 'Materieller Besitz spielt eine untergeordnete Rolle in Deinem Leben. Du neigst dazu, Vorräte zu verbrauchen ' +
      'und Geld auszugeben. Besitz anzuhäufen ist Dir nicht wichtig.',
    en: 'Material possession plays only a minor role in your life. You tend to consume your resources, and spend ' +
      'money. Accumulating possessions is not important to you.',
    ru: 'Mатериальная собственность играет второстепенную роль в Вашей жизни. Вы склонны использовать свои запасы и ' +
      'тратить деньги. Вам не так важно что-то накапливать.',
    nl: 'Materieel eigendom spelt slechts minimaal rol in je leven. Jij zal vooral middelen consumeren en geld ' +
      'uitgeven. Het is voor jou niet belangrijk om bezittingen te sparen en te vermeerderen.'
  },
  'motive.bes.medium': {
    de: 'Vorräte und materieller Besitz spielen keine signifikante Rolle in Deinem Leben.',
    en: 'Material possessions and other resources do not play a significant role in your life.',
    ru: 'Mатериальная собственность не играет значимой роли в Вашей жизни.',
    nl: 'Materieel eigendom en andere middelen spelen geen significante rol in je leven.'
  },
  'motive.bes.high': {
    de: 'Materieller Besitz spielt eine wichtige Rolle in Deinem Leben. Es ist Dir wichtig, Vermögen anzusammeln, ' +
      'anzusparen oder aufzubauen. Du willst Verschwendung vermeiden.',
    en: 'Material possession plays an important role in your life. It is important to you to collect, save or ' +
      'accumulate assets. You want to avoid waste.',
    ru: 'Mатериальная собственность играет важную роль в Вашей жизни. Для Вас крайне важно накапливать, хранить и ' +
      'приумножать своё имущество. Вы стараетесь избегать лишних трат.',
    nl: 'Materieel eigendom speelt een belangrijke rol in je leven. Je vindt het belangrijk om middelen te ' +
      'verzamelen, sparen of vermeerderen. Je wilt verspilling vermijden.'
  },
  'motive.aut': {
    de: 'Autonomie',
    en: 'Autonomy',
    ru: 'Автономность',
    nl: 'Autonomie',
    fr: 'Autonomie',
    es: 'Autonomía',
    it: 'Autonomia',
    ka: 'ავტონომია'
  },
  'motive.team.aut': {
    de: 'Autonomie',
    en: 'Autonomy',
    ru: 'Автономность',
    nl: 'Autonomie',
    fr: 'Autonomie',
    es: 'Autonomía',
    it: 'Autonomia',
    ka: 'ავტონომია'
  },
  'motive.aut.abbreviation': {
    de: 'AUT',
    en: 'AUT',
    nl: 'AUT'
  },
  'motive.aut.meaning': {
    de: 'Streben nach Unabhängigkeit von den Erwartungen und dem Einfluss anderer',
    en: 'Desire for independence from the expectations and influence of others.',
    ru: 'Стремление к независимости от ожиданий и влияния другиlх.',
    nl: 'Verlangen naar onafhankelijkheid van de verwachtingen en invloed van anderen.'
  },
  'motive.aut.low': {
    de: 'Unabhängigkeit spielt eine untergeordnete Rolle in Deinem Leben. Du legst wenig Wert auf Eigenständigkeit, ' +
      'akzeptierst Einflüsse anderer und bist offen für emotionale Nähe.',
    en: 'Independence plays a minor role in your life. You do not attach considerable importance to autonomy, are ' +
      'accepting of the influence of others and are open to establishing emotional ties.',
    ru: 'Независимость играет второстепенную роль в Вашей жизни. Самостоятельность не является для Вас важным ' +
      'критерием, Вы принимаете влияние других и открыты для эмоциональной близости.',
    nl: 'Onafhankelijkheid spelt slechts minimaal rol in je leven. Je hecht geen grote waarde aan autonomie, je ' +
      'accepteert invloed van anderen en staat ervoor open om met anderen een emotionele band op te bouwen.'
  },
  'motive.aut.medium': {
    de: 'Unabhängigkeit spielt keine signifikante Rolle in Deinem Leben.',
    en: 'Independence does not play a significant role in your life.',
    ru: 'Независимость не играет очень важную роль в Вашей жизни.',
    nl: 'Onafhankelijkheid spelt geen belangrijke rol in je leven.'
  },
  'motive.aut.high': {
    de: 'Unabhängigkeit ist Dir sehr wichtig im Leben. Du bevorzugst es, Dein Leben eigenständig zu gestalten und ' +
      'an Deinen eigenen Maßstäben zu orientieren. Du verlässt Dich lieber auf Dich statt auf andere.',
    en: 'Independence is very important to you. You prefer to live your life independently and follow your own ' +
      'standards. You rely on yourself rather than others.',
    ru: 'Независимость играет важную роль в Вашей жизни. Вы предпочитаете самостоятельно проектировать свою жизнь ' +
      'и иметь свои ориентиры. Вы предпочитаете полагаться на себя, а не на других.',
    nl: 'Onafhankelijkheid is erg belangrijk voor je. Je leeft je leven het liefst onafhankelijk en volgt je eigen ' +
      'normen. Je vertrouwt op jezelf in plaats van op anderen.'
  },
  'motive.soz': {
    de: 'Sozialkontakte',
    en: 'Social participation',
    ru: 'Социальные контакты',
    nl: 'Sociale Contacten',
    fr: 'Contacts sociaux',
    es: 'Contacto social',
    it: 'Relazioni sociali',
    ka: 'სოციალური კონტაქტები'
  },
  'motive.team.soz': {
    de: 'Sozialkontakte',
    en: 'Social participation',
    ru: 'Социальные контакты',
    nl: 'Sociale Contacten',
    fr: 'Contacts sociaux',
    es: 'Contacto social',
    it: 'Relazioni sociali',
    ka: 'სოციალური კონტაქტები'
  },
  'motive.soz.abbreviation': {
    de: 'SOZ',
    en: 'SPA',
    nl: 'SCO'
  },
  'motive.soz.meaning': {
    de: 'Wunsch nach sozialen Interaktionen und Kontakt zu anderen Menschen.',
    en: 'Desire for social interaction and contact with other people.',
    ru: 'Желание общения и интерес к другим людям.',
    nl: 'Verlangen naar sociale interactie en contact met andere mensen.'
  },
  'motive.soz.low': {
    de: 'Soziale Kontakte spielen in Deinem Leben eine untergeordnete Rolle. Du verspürst nur selten das Bedürfnis, ' +
      'Zeit mit anderen zu verbringen. Partys und Networking Events vermeidest Du häufig.',
    en: 'Social participation plays only a minor role in your life. You rarely feel the need to spend time with ' +
      'others. You often avoid parties and networking events.',
    ru: 'В Вашей жизни социальные контакты не играют большой роли. Вы редко испытываете необходимость проводить ' +
      'время с другими людьми. Вы часто избегаете вечеринки и встречи.',
    nl: 'Sociale participatie speelt slechts minimaal rol in je leven. Je hebt zelden de aandrang om tijd met ' +
      'anderen door te gaan brengen. Je vermijdt vaak feesten en netwerkevenementen.'
  },
  'motive.soz.medium': {
    de: 'Soziale Kontakte und Geselligkeit spielen keine signifikante Rolle in Deinem Leben.',
    en: 'Social contacts and the company of others do not play a significant role in your life.',
    ru: 'Общение и интерес к другим людям не играют значимой роли в Вашей жизни.',
    nl: 'Sociale contacten en het gezelschap van anderen spelen geen significante rol in je leven.'
  },
  'motive.soz.high': {
    de: 'Die Gesellschaft von und mit anderen ist Dir wichtig im Leben. Du strebst danach, Zeit mit anderen Menschen ' +
      'zu verbringen. Soziale Kontakte empfindest Du als eine Bereicherung für Dein Leben.',
    en: 'The company of others is important to you in your life. You make an effort to spend time with other people. ' +
      'You experience social contacts as an enrichment of your life.',
    ru: 'Вам очень важно общение и интерес к другим людям. Вы стремитесь проводить время с другими людьми. ' +
      'Вы воспринимаете общение как обогащение своей собственной жизни.',
    nl: 'Het gezelschap van anderen in je leven is belangrijk voor je. Je doet moeite om tijd met andere mensen door ' +
      'te brengen. Je ervaart sociale contacten als een verrijking van je leven.'
  },
  'motive.pri': {
    de: 'Prinzipien',
    en: 'Principles',
    ru: 'Лояльность',
    nl: 'Principes',
    fr: 'Principes',
    es: 'Principios',
    it: 'Norme sociali',
    ka: 'პრინციპები'
  },
  'motive.team.pri': {
    de: 'Prinzipien',
    en: 'Principles',
    ru: 'Лояльность',
    nl: 'Principes',
    fr: 'Principes',
    es: 'Principios',
    it: 'Norme sociali',
    ka: 'პრინციპები'
  },
  'motive.pri.abbreviation': {
    de: 'PRI',
    en: 'PRI',
    nl: 'PRI'
  },
  'motive.pri.meaning': {
    de: 'Streben nach Konformität mit sozialen Normen einer gesellschaftlichen Gruppe oder der Gesellschaft.',
    en: 'Desire for conformity with the social norms of a particular social group or society as a whole.',
    ru: 'Стремление соответствовать социальным нормам, которые применяются к конкретным социальным группам или ' +
      'обществу в целом.',
    nl: 'Verlangen om te conformeren aan de sociale norm van een specifieke sociale groep of van de maatschappij ' +
      'als geheel.'
  },
  'motive.pri.low': {
    de: 'Gesellschaftliche Erwartungen und Normen spielen für Dich eine untergeordnete Rolle. Du orientierst Dich ' +
      'nicht an Regeln, insbesondere nicht, wenn sie Dich einschränken.',
    en: 'Social expectations and norms play a minor role for you. Following rules isn’t particularly important to ' +
      'you, especially when they restrict you.',
    ru: 'Общественные ожидания играют второстепенную роль в Вашей жизни. Вы практически не руководствуетесь ' +
      'правилами, когда они ограничивают Вас.',
    nl: 'Sociale verwachtingen en normen spelen slechts minimaal rol in je leven. Het is niet belangrijk voor je om ' +
      'regels te volgen, zeker wanneer ze je zouden beperken.'
  },
  'motive.pri.medium': {
    de: 'Konformität mit sozialen Normen und Regeln spielen keine signifikante Rolle in Deinem Leben.',
    en: 'Conformity with social norms and rules does not play a significant role in your life.',
    ru: 'Стремление соответствовать социальным нормам и правилам не играет значимой роли в Вашей жизни.',
    nl: 'Je conformeren aan sociale normen en regels speelt geen significante rol in je leven.'
  },
  'motive.pri.high': {
    de: 'Du misst Regeln, Prinzipien oder Gesetzen einen hohen Wert bei. Du bist bestrebt, sie zu befolgen, auch ' +
      'wenn dies für Dich persönliche Einschränkung bedeutet.',
    en: 'You attribute great value to rules, principles and laws. You do your best to follow them, even if this ' +
      'means personal restrictions for you.',
    ru: 'Вы придаете правилам и принципам высокую ценность. Вы стремитесь соблюдать эти правила и принципы и не ' +
      'отступать от них, даже если это сопряжено с самоограничениями.',
    nl: 'Je hecht veel waarde aan regels, principes en wetten. Je doet je best om je hieraan te houden, ook als dit ' +
      'je persoonlijk beperkingen oplevert.'
  },
  'motive.sen': {
    de: 'Soziales Engagement',
    en: 'Social engagement',
    ru: 'Общественная деятельность',
    nl: 'Sociale Betrokkenheid',
    fr: 'Engagement social',
    es: 'Compromiso social',
    it: 'Impegno sociale',
    ka: 'სოციალური სამართლიანობა'
  },
  'motive.team.sen': {
    de: 'Soziales Engagement',
    en: 'Social engagement',
    ru: 'Общественная деятельность',
    nl: 'Sociale Betrokkenheid',
    fr: 'Engagement social',
    es: 'Compromiso social',
    it: 'Impegno sociale',
    ka: 'სოციალური სამართლიანობა'
  },
  'motive.sen.abbreviation': {
    de: 'SEN',
    en: 'SEN',
    nl: 'SBE'
  },
  'motive.sen.meaning': {
    de: 'Engagement für benachteiligte und notleidende Menschen und für eine gerechtere Gesellschaft.',
    en: 'Commitment to those people who are suffering or disadvantaged, as well as to a more just society.',
    ru: 'Активная деятельность, направленная на обездоленных и нуждающихся людей и деятельность для создания более ' +
      'справедливого общества.',
    nl: 'Verlangen naar bevestiging en acceptatie door anderen.'
  },
  'motive.sen.low': {
    de: 'Soziale Gerechtigkeit spielt eine untergeordnete Rolle in Deinem Leben. Du siehst soziales Engagement nicht ' +
      'als Deine persönliche Aufgabe an.',
    en: 'Issues of social justice play only a minor role in your life. You do not see social engagement as being ' +
      'your personal obligation.',
    ru: 'Cоциальная справедливость играет второстепенную роль в Вашей жизни. Вы не рассматриваете общественную ' +
      'деятельность как свою собственную задачу.',
    nl: 'Bevestiging krijgen en geaccepteerd worden door anderen is niet belangrijk voor je. Jouw gedrag wordt niet ' +
      'gestuurd door hoe anderen over je oordelen of hoe ze je zien.'
  },
  'motive.sen.medium': {
    de: 'Soziale Gerechtigkeit spielt keine signifikante Rolle in Deinem Leben.',
    en: 'Issues of social justice do not play a particularly important role in your life.',
    ru: 'Cоциальная справедливость не играет значимой роли в Вашей жизни.',
    nl: 'Bevestiging en acceptatie door anderen spelen geen significante rol In je leven.'
  },
  'motive.sen.high': {
    de: 'Soziale Gerechtigkeit spielt eine besondere Rolle in Deinem Leben. Du sorgst Dich um das Wohl anderer ' +
      'Menschen und setzt Dich häufig aktiv dafür ein, Not und Benachteiligung zu lindern.',
    en: 'Issues of social justice play a particularly important role in your life. You are concerned about the ' +
      'welfare of other people, and often campaign actively to alleviate hardship and disadvantage.',
    ru: 'Cоциальная справедливость играет особую роль в Вашей жизни. Вы заботитесь о благополучии других людей и ' +
      'часто активно помогаете нуждающимся и обездоленным.',
    nl: 'Geaccepteerd worden door anderen is belangrijk voor je. Jouw gedrag is erop gericht om bevestiging te ' +
      'krijgen en kritiek of afwijzing te vermijden.'
  },
  'motive.str': {
    de: 'Struktur',
    en: 'Structure',
    ru: 'Структурированность',
    nl: 'Structuur',
    fr: 'Structure',
    es: 'Estructura',
    it: 'Ordine',
    ka: 'სტრუქტურა'
  },
  'motive.team.str': {
    de: 'Struktur',
    en: 'Structure',
    ru: 'Структу- рированность',
    nl: 'Structuur',
    fr: 'Structure',
    es: 'Estructura',
    it: 'Ordine',
    ka: 'სტრუქტურა'
  },
  'motive.str.abbreviation': {
    de: 'STR',
    en: 'STR',
    nl: 'STR'
  },
  'motive.str.meaning': {
    de: 'Bestreben, sich die Umwelt in einfacher und widerspruchsfreier Weise zu strukturieren.',
    en: 'Desire to structure one’s surroundings in simple and consistent terms.',
    ru: 'Стремление структурировать окружающую среду простым и непротиворечивым образом.',
    nl: 'Verlangen naar structuur in je omgeving in de zin van eenvoud en consistentie.'
  },
  'motive.str.low': {
    de: 'Ordnung und Sauberkeit spielen eine untergeordnete Rolle in Deinem Leben. Strukturen und exakte Pläne engen ' +
      'Dich ein.',
    en: 'Order and cleanliness play only a minor role in your life. Structures and exact plans feel to you like ' +
      'constraints.',
    ru: 'Чистота и порядок играют второстепенную роль в Вашей жизни. Структурированность и жесткие планы ' +
      'ограничивают меня.',
    nl: 'Orde en opgeruimdheid spelen slechts minimaal rol in je leven. Structuren en gedetaileerde plannen voelen ' +
      'voor jou beperkend.'
  },
  'motive.str.medium': {
    de: 'Struktur, Ordnung und Sauberkeit spielen keine signifikante Rolle in Deinem Leben.',
    en: 'Structure, order and cleanliness do not play a significant role in your life.',
    ru: 'Чистота, структурированость и порядок играют важную роль в Вашей жизни.',
    nl: 'Structuur, orde en opgeruimdheid spelen geen significante rol in je leven.'
  },
  'motive.str.high': {
    de: 'Struktur, Ordnung und Sauberkeit spielen eine wichtige Rolle in Deinem Leben. Du genießt es, Deinen Alltag ' +
      'zu ordnen – sei es durch Planung, strukturierte Tätigkeiten oder Routinen.',
    en: 'Structure, order and cleanliness play an important role in your life. You enjoy putting your daily life ' +
      'into order, whether through plans, structured activities or routines.',
    ru: 'Чистота и порядок играют важную роль в Вашей жизни. Вам очень нравится упорядочивать Вашу повседневную ' +
      'жизнь, будь то планирование, рутина и ритуалы.',
    nl: 'Structuur, orde en opgeruimdheid spelen een belangrijke rol in je leven. Je vindt het prettig om je ' +
      'dagelijks leven op orde te hebben, aan de hand van plannen, gestructureerde activiteiten of routines.'
  },
  'motive.sic': {
    de: 'Sicherheit',
    en: 'Safety',
    ru: 'Безопасность',
    nl: 'Veiligheid',
    fr: 'Sécurité',
    es: 'Seguridad',
    it: 'Sicurezza',
    ka: 'უსაფრთხოება'
  },
  'motive.team.sic': {
    de: 'Sicherheit',
    en: 'Safety',
    ru: 'Безопасность',
    nl: 'Veiligheid',
    fr: 'Sécurité',
    es: 'Seguridad',
    it: 'Sicurezza',
    ka: 'უსაფრთხოება'
  },
  'motive.sic.abbreviation': {
    de: 'SIC',
    en: 'SAF',
    nl: 'VEI'
  },
  'motive.sic.meaning': {
    de: 'Wunsch nach einem ruhigen und sicheren Leben.',
    en: 'Desire for a quiet and secure life.',
    ru: 'Желание спокойной и безопасной жизни.',
    nl: 'Verlangen naar een rustig en veilig leven.'
  },
  'motive.sic.low': {
    de: 'Sicherheit spielt eine untergeordnete Rolle in Deinem Leben. Du neigst dazu, Risiken einzugehen und stellst ' +
      'Dich gerne Situationen, die potenzielle Gefahren mit sich bringen.',
    en: 'Safety plays only a minor role in your life. You tend to take risks and like to take on situations that may ' +
      'entail hazards.',
    ru: 'Безопасность играет второстепенную роль в Вашей жизни. Вы склонны рисковать и не уклоняться от ситуаций, ' +
      'которые несут в себе потенциальную опасность.',
    nl: 'Veiligheid speelt slechts minimaal rol in je leven. Je neemt graag risico’s en zoekt situaties op die ' +
      'gevaarlijk kunnen zijn.'
  },
  'motive.sic.medium': {
    de: 'Sicherheit spielt keine signifikante Rolle in Deinem Leben.',
    en: 'Safety does not play a significant role in your life.',
    ru: 'Безопасность не играет значимой роли в Вашей жизни.',
    nl: 'Veiligheid speelt geen significante rol in je leven.'
  },
  'motive.sic.high': {
    de: 'Sicherheit spielt eine besondere Rolle in Deinem Leben. Dein Verhalten ist geprägt davon, potenzielle ' +
      'Gefahren zu vermeiden. Du neigst in unbekannten Situationen dazu, sehr vorsichtig zu sein.',
    en: 'Safety plays a particularly important role in your life. Your behavior is guided by the desire to avoid ' +
      'potential hazards. You tend to be very cautious in situations that are new for you.',
    ru: 'Безопасность играет особую роль в Вашей жизни. Вы стараетесь избегать потенциально опасных ситуаций. В ' +
      'неизвестных ситуациях Вы стараетесь быть очень осторожными.',
    nl: 'Veiligheid speelt een erg belangrijke rol in je leven. Je gedrag wordt gestuurd door het verlangen om ' +
      'mogelijke risico’s te vermijden. Je bent vaak erg voorzichtig in nieuwe situaties.'
  },
  'motive.rev': {
    de: 'Revanche',
    en: 'Revenge',
    ru: 'Реванш',
    nl: 'Revanche',
    fr: 'Vengeance',
    es: 'Revancha',
    it: 'Vendetta',
    ka: 'შურისძიება'
  },
  'motive.team.rev': {
    de: 'Revanche',
    en: 'Revenge',
    ru: 'Реванш',
    nl: 'Revanche',
    fr: 'Vengeance',
    es: 'Revancha',
    it: 'Vendetta',
    ka: 'შურისძიება'
  },
  'motive.rev.abbreviation': {
    de: 'REV',
    en: 'REV',
    nl: 'REV'
  },
  'motive.rev.meaning': {
    de: 'Streben nach Vergeltung von erlebtem Unrecht.',
    en: 'Desire for retribution for any injustice experienced.',
    ru: 'Стремление расплатиться за пережитую несправедливость.',
    nl: 'Verlangen naar vergelding voor ervaren onrechtvaardigheid.'
  },
  'motive.rev.low': {
    de: 'Du neigst nicht dazu, es anderen heimzuzahlen, wenn Du das Gefühl hast, von jemandem ungerecht behandelt ' +
      'worden zu sein. Du kannst in der Regel über Kränkungen hinwegsehen und verzeihen.',
    en: 'You tend not to get back at somebody when you feel they’ve treated you unfairly. You are generally able to ' +
      'ignore it when somebody offends you and can forgive them.',
    ru: 'Вы не склонны к мести, даже если с Вами обращаются несправедливо, обижают Вас или обманывают. Вам обычно ' +
      'удается закрывать глаза на оскорбления или обиду и прощать за совершенное.',
    nl: 'Je zal niet snel iemand terugpakken als je het gevoel hebt dat ze je oneerlijk hebben behandeld. Over het ' +
      'algemeen kan je het negeren en vergeven als iemand je beledigt.'
  },
  'motive.rev.medium': {
    de: 'Erlebtes Unrecht zu vergelten spielt keine signifikante Rolle in Deinem Leben.',
    en: 'Retribution for injustices you’ve been subject to does not play a significant role in your life.',
    ru: 'Расплата за пережитую несправедливость не играет значительной роли в Вашей жизни.',
    nl: 'Vergelding voor onrecht dat jou is aangedaan speelt geen belangrijke rol in je leven.'
  },
  'motive.rev.high': {
    de: 'Du neigst dazu, es anderen heimzuzahlen, wenn Du das Gefühl hast, von jemandem betrogen, beleidigt oder ' +
      'hintergangen worden zu sein. Erlebtes Unrecht möchtest Du vergelten.',
    en: 'You tend to get back at those who have betrayed, insulted or deceived you. You want retribution for ' +
      'injustices you’ve experienced.',
    ru: 'Вы склонны к мести, если с Вами обращаются несправедливо, обижают Вас или обманывают. Вы хотите отомстить ' +
      'за пережитую несправедливость.',
    nl: 'Je pakt mensen vaak terug als je het gevoel hebt dat ze je hebben verraden, beledigd of misleid. Je wilt ' +
      'vergelding voor onrecht dat jou is aangedaan.'
  },
  'motive.bew': {
    de: 'Bewegung',
    en: 'Physical exercise',
    ru: 'Физическая активность',
    nl: 'Beweging',
    fr: 'Activité physique',
    es: 'Actividad física',
    it: 'Attività fisica',
    ka: 'მოძრაობა'
  },
  'motive.team.bew': {
    de: 'Bewegung',
    en: 'Physical exercise',
    ru: 'Физическая активность',
    nl: 'Beweging',
    fr: 'Activité physique',
    es: 'Actividad física',
    it: 'Attività fisica',
    ka: 'მოძრაობა'
  },
  'motive.bew.abbreviation': {
    de: 'BEW',
    en: 'PHY',
    nl: 'BEW'
  },
  'motive.bew.meaning': {
    de: 'Streben nach Bewegung und körperliche Aktivität.',
    en: 'Desire for movement and physical activity.',
    ru: 'Желание двигаться и проявлять физическую активность.',
    nl: 'Verlangen naar beweging en fysieke inspanning.'
  },
  'motive.bew.low': {
    de: 'Körperliche Bewegung spielt eine untergeordnete Rolle in Deinem Leben. Dir sind andere Dinge wichtiger als ' +
      'Sport zu treiben. Körperlich anstrengende Tätigkeiten vermeidest Du eher.',
    en: 'Physical activity plays a minor role in your life. There are other things more important to you than ' +
      'sports. You are likely to avoid physically demanding activities.',
    ru: 'Двигательная активность играет второстепенную роль в Вашей жизни. Для Вас есть более важные вещи, чем ' +
      'занятия спортом и физическая активность. Вы стараетесь избегать тяжелых физических нагрузок.',
    nl: 'Fysieke inspanning speelt slechts minimaal rol in je leven. Je vindt andere zaken belangrijker dan ' +
      'sporten. Je zal waarschijnlijk activiteiten die fysieke inspanning vragen vermijden.'
  },
  'motive.bew.medium': {
    de: 'Körperliche Bewegung spielt keine signifikante Rolle in Deinem Leben.',
    en: 'Physical activity does not play a significant role in your life.',
    ru: 'Двигательная активность не играет значимой роли в Вашей жизни.',
    nl: 'Fysieke inspanning speelt geen significante rol in je leven.'
  },
  'motive.bew.high': {
    de: 'Körperliche Bewegung spielt eine besondere Rolle in Deinem Leben. Dich körperlich fit zu halten ist eines ' +
      'Deiner wichtigsten Lebensziele.',
    en: 'Physical activity plays a significant role in your life. Staying physically fit is one of the most ' +
      'important goals in your life.',
    ru: 'Двигательная активность играет особую роль в Вашей жизни. Поддерживать себя в хорошей физической форме для ' +
      'Вас является одной из самых важных целей в жизни.',
    nl: 'Fysieke inspanning speelt een zeer belangrijke rol in je leven. Fysiek fit blijven is een van de ' +
      'belangrijkste doelen in je leven.'
  },
  'motive.ess': {
    de: 'Essensgenuss',
    en: 'Food enjoyment',
    ru: 'Удовольствие от еды',
    nl: 'Eetgenot',
    fr: 'Plaisir alimentaire',
    es: 'Placer por comer',
    it: 'Piacere culinario',
    ka: 'გურმანი'
  },
  'motive.team.ess': {
    de: 'Essensgenuss',
    en: 'Food enjoyment',
    ru: 'Удовольствие от' + String.fromCharCode(160) + 'еды',
    nl: 'Eetgenot',
    fr: 'Plaisir alimentaire',
    es: 'Placer por' + String.fromCharCode(160) + 'comer',
    it: 'Piacere culinario',
    ka: 'გურმანი'
  },
  'motive.ess.abbreviation': {
    de: 'ESS',
    en: 'FEN',
    nl: 'EET'
  },
  'motive.ess.meaning': {
    de: 'Streben nach genussvollen Erfahrungen bei der Nahrungsaufnahme.',
    en: 'Desire for enjoyable experiences in association with food intake.',
    ru: 'Стремление к приятным ощущениям от приема пищи.',
    nl: 'Verlangen naar prettige ervaringen die te maken hebben met eten.'
  },
  'motive.ess.low': {
    de: 'Essen spielt eine untergeordnete Rolle in Deinem Leben. Über die Befriedigung des Hungergefühls hinaus hat ' +
      'das Thema Essen wenig Bedeutung für Dich. Alleine nimmst Du Dir wenig Zeit zum Essen.',
    en: 'Food plays only a minor role in your life. Beyond satisfying hunger, food has little significance for you. ' +
      'On your own, you don’t take much time to eat.',
    ru: 'Еда играет второстепенную роль в Вашей жизни. Пища не имеет для Вас большого значения и служит для ' +
      'удовлетворения их голода. В одиночку Вы тратите немного времени на принятие пищи.',
    nl: 'Eten speelt slechts minimaal rol in je leven. Behalve het stillen van je honger, is eten van weinig van ' +
      'betekenis voor je. Als je alleen bent, besteed je weinig tijd aan eten.'
  },
  'motive.ess.medium': {
    de: 'Essen spielt keine signifikante Rolle in Deinem Leben.',
    en: 'Food does not play a significant role in your life.',
    ru: 'Еда не играет значимой роли в Вашей жизни.',
    nl: 'Eten speelt geen significante rol in je leven.'
  },
  'motive.ess.high': {
    de: 'Essen spielt eine besondere Rolle in Deinem Leben – weit über die Befriedigung physiologischer ' +
      'Grundbedürfnisse hinaus. Du genießt es jederzeit, Dich mit dem Thema Essen zu beschäftigen.',
    en: 'Food plays a significant role in your life – far beyond the satisfaction of basic physiological needs. ' +
      'You always enjoy dealing with the subject of food.',
    ru: 'Еда играет особую роль в Вашей жизни, которая выходит далеко за рамки удовлетворения основных ' +
      'физиологических потребностей. Вы всегда любите вкусно поесть и интересуетесь кулинарией.',
    nl: 'Eten speelt een belangrijke rol in je leven – veel belangrijker dan alleen de bevrediging van je ' +
      'basisbehoeften. Je geniet er altijd van als je bezig bent met het onderwerp ‘eten’.'
  },
  'motive.fam': {
    de: 'Familie',
    en: 'Family',
    ru: 'Семья',
    nl: 'Familie',
    fr: 'Famille',
    es: 'Familia',
    it: 'Famiglia',
    ka: 'ოჯახი'
  },
  'motive.team.fam': {
    de: 'Familie',
    en: 'Family',
    ru: 'Семья',
    nl: 'Familie',
    fr: 'Famille',
    es: 'Familia',
    it: 'Famiglia',
    ka: 'ოჯახი'
  },
  'motive.fam.abbreviation': {
    de: 'FAM',
    en: 'FAM',
    nl: 'FAM'
  },
  'motive.fam.meaning': {
    de: 'Wunsch nach Fürsorge für die Familie.',
    en: 'Desire to cultivate a caring and tight-knit familial network.',
    ru: 'Желание заботиться о семье.',
    nl: 'Verlangen naar een zorgzame en hechte relatie met familieleden.'
  },
  'motive.fam.low': {
    de: 'Die Familie spielt eine untergeordnete Rolle in Deinem Leben. Das Familienleben hat nicht die höchste ' +
      'Bedeutung für Deine Lebenszufriedenheit.',
    en: 'Family plays only a minor role in your life. Family life is not the most important factor in terms of ' +
      'leading a fulfilled life.',
    ru: 'Семья играет второстепенную роль в Вашей жизни. Семейная жизнь не имеет особого значения в ' +
      'удовлетворённости Вашей жизнью.',
    nl: 'Familie speelt slechts minimaal rol in je leven. Je gezin en/of familie is niet een bepalende factor voor ' +
      'een volwaardig leven.'
  },
  'motive.fam.medium': {
    de: 'Die Familie spielt keine signifikante Rolle in Deinem Leben.',
    en: 'Family does not play a significant role in your life.',
    ru: 'Семья не играет важной роли в Вашей жизни.',
    nl: 'Familie speelt geen significante rol in je leven.'
  },
  'motive.fam.high': {
    de: 'Die Familie ist ein wichtiger Bezugspunkt in Deinem Leben. Ein harmonisches Familienleben mit enger ' +
      'Verbundenheit ist von hoher Bedeutung für Deine Lebenszufriedenheit.',
    en: 'Family is an important point of reference in your life. A harmonious family life, with close family ties, ' +
      'is very important for you in terms of leading a fulfilled life.',
    ru: 'Семья играет важную роль в Вашей жизни. Гармоничная семейная жизнь, сопровождаемая чувством семейной связи, ' +
      'имеет для Вас первостепенное значение.',
    nl: 'Familie is voor jou een belangrijk referentiepunt in je leven. Een harmonieuze gezinssituatie, met hechte ' +
      'familiebanden, is erg belangrijk voor je om een volwaardig leven te leiden.'
  },
  'motive.sin': {
    de: 'Sinnlichkeit',
    en: 'Eros',
    ru: 'Чувственность',
    nl: 'Zinnelijkheid',
    fr: 'Sensualité',
    es: 'Sensualidad',
    it: 'Vita sessuale',
    ka: 'სენსუალურობა'
  },
  'motive.team.sin': {
    de: 'Sinnlichkeit',
    en: 'Eros',
    ru: 'Чувственность',
    nl: 'Zinnelijkheid',
    fr: 'Sensualité',
    es: 'Sensualidad',
    it: 'Vita sessuale',
    ka: 'სენსუალურობა'
  },
  'motive.sin.abbreviation': {
    de: 'SIN',
    en: 'ERO',
    nl: 'ZIN'
  },
  'motive.sin.meaning': {
    de: 'Streben nach sinnlichen, erotischen Erfahrungen und einem aktiven, erfüllten Sexualleben.',
    en: 'Desire for sensual, erotic experiences and an active, fulfilled sex life.',
    ru: 'Стремление к чувственным и сексуальным переживаниям и активной, полноценной сексуальной жизни',
    nl: 'Verlangen naar sensuele, erotische ervaringen en een actief en bevredigend seksleven.'
  },
  'motive.sin.low': {
    de: 'Erotik und Leidenschaft spielen eine untergeordnete Rolle in Deinem Leben. Ein erfülltes Sexualleben ist ' +
      'für Dein Lebensglück nicht entscheidend. Dein Verlangen nach Erotik ist eher schwach ausgeprägt.',
    en: 'Eroticism and passion play only a minor role in your life. A fulfilling sex life is not integral to your ' +
      'happiness in life. Your desire for eroticism is rather weak.',
    ru: 'Сексуальность и страсть играют второстепенную роль в Вашей жизни. Реализованная интимная жизнь не имеет ' +
      'решающего значения для Вашего счастья. Ваше стремление к сексуальной близости обычно довольно слабое.',
    nl: 'Erotiek en passie spelen slechts minimaal rol in je leven. Een bevredigend seksleven is niet bepalend voor ' +
      'je geluk. Je verlangen naar erotiek is vrij zwak.'
  },
  'motive.sin.medium': {
    de: 'Erotik, Sinnlichkeit und Leidenschaft spielen keine signifikante Rolle in Deinem Leben.',
    en: 'Eroticism, sensuality and passion do not play a significant role in your life.',
    ru: 'Сексуальность, чувственность и страсть не играют значимой роли в Вашей жизни.',
    nl: 'Erotiek, sensualiteit en passie spelen geen significante rol in je leven.'
  },
  'motive.sin.high': {
    de: 'Erotik, Sinnlichkeit und Leidenschaft spielen eine wichtige Rolle in Deinem Leben. Du genießt es, Deine ' +
      'Sexualität ausleben zu können und suchst Gelegenheiten dafür regelmäßig.',
    en: 'Eroticism, sensuality and passion play a particularly important role in your life. You enjoy being sexually ' +
      'active and regularly seek out opportunities to have erotic experiences.',
    ru: 'Сексуальность, чувственность и страсть играют важную роль в Вашей жизни. Вы любите быть сексуально ' +
      'активными и стремитесь найти ситуации, в которых Вы можете проявить свою сексуальность.',
    nl: 'Erotiek, sensualiteit en passie spelen een zeer belangrijke rol in je leven. Je bent graag seksueel actief ' +
      'en gaat op zoek naar kansen voor erotische ervaringen.'
  },

  /* KEY GRAPHIC */
  'graphic.footer.low': {
    de: 'NIEDRIG',
    en: 'LOW',
    ru: 'НИЗКИЙ',
    nl: 'LOW'
  },
  'graphic.footer.mod': {
    de: 'MITTEL',
    en: 'MODERATE',
    ru: 'СРЕДНИЙ',
    nl: 'MODERATE'
  },
  'graphic.footer.high': {
    de: 'HOCH',
    en: 'HIGH',
    ru: 'ВЫСОКИЙ',
    nl: 'HIGH'
  },
  'graphic.printbutton': {
    de: 'Grafik drucken',
    en: 'Print Graphic',
    ru: 'Распечатать график',
    nl: 'Afbeelding printen'
  },

  /* ORDER */
  /* RU translations are commented out because not everything is translated. */
  'order.PRODUCT_SELECTION': {
    de: 'Produkt auswählen',
    en: 'Choose product',
    ru: 'Выберите продукт'
  },
  'order.ADDRESS_INPUT': {
    de: 'Rechnungsstellung',
    en: 'Billing Information',
    ru: 'Платёжный адрес'
  },
  'order.PAYMENT_METHOD_SELECTION': {
    de: 'Zahlungsart wählen',
    en: 'Choose payment method',
    ru: 'выберите способ оплаты'
  },
  'order.OVERVIEW': {
    de: 'Warenkorb',
    en: 'Shopping cart',
    ru: 'Корзина'
  },
  'order.PURCHASE': {
    de: 'Kauf durchführen',
    en: 'Make your purchase',
    ru: 'Совершить покупку'
  },
  'addressForm.title': {
    de: 'Rechnungsadresse',
    en: 'Invoice address',
    ru: 'платежный адрес'
  },
  'addressForm.firstName': {
    de: 'Vorname*',
    en: 'First name*',
    ru: 'Имя*'
  },
  'addressForm.optional': {
    de: 'Optional',
    en: 'if applicable',
    ru: 'По желанию'
  },
  'addressForm.lastName': {
    de: 'Nachname*',
    en: 'Last name*',
    ru: 'Фамилия*'
  },
  'addressForm.company': {
    de: 'Unternehmen',
    en: 'Company name',
    ru: 'Компания'
  },
  'addressForm.companyPlaceholder': {
    de: 'Für welches Unternehmen bist Du tätig? (Optional)',
    en: 'Which company are you working for? (if applicable)',
    ru: 'В какой компании вы работаете? (По желанию)'
  },
  'addressForm.vatIdentification': {
    de: 'Umsatzsteuer-Identifikationsnummer',
    en: 'VAT',
    ru: 'Идентификационный номер налогоплательщика'
  },
  'addressForm.country': {
    de: 'Land*',
    en: 'Country*',
    ru: 'Страна*'
  },
  'addressForm.state': {
    de: 'Bundesland',
    en: 'State',
    ru: 'федеральное государство'
  },
  'addressForm.zipCode': {
    de: 'Postleitzahl*',
    en: 'Zip code*',
    ru: 'почтовый индекс*'
  },
  'addressForm.city': {
    de: 'Ort*',
    en: 'Current location*',
    ru: 'Место жительства*'
  },
  'addressForm.street': {
    de: 'Straße*',
    en: 'Street*',
    ru: 'улица*'
  },
  'addressForm.houseNr': {
    de: 'Hausnummer*',
    en: 'House number*',
    ru: 'номер дома*'
  },
  'addressForm.required': {
    de: '*Pflichtfeld',
    en: '*Required field',
    ru: '*Обязательное поле',
    nl: '*Verplicht veld',
    ka: '*სავალდებულო ველი'
  },
  'addressForm.errors.required': {
    de: 'Dieses Feld darf nicht leer sein.',
    en: 'Required field.',
    ru: 'Это поле не должно быть пустым.'
  },
  'addressForm.errors.whitespace': {
    de: 'Dieses Feld darf nicht nur Leerzeichen enthalten.',
    en: 'This field must not only contain spaces.',
    ru: 'Это поле не должно быть пустым.'
  },
  'order.greet': {
    de: 'Hallo',
    en: 'Hello',
    ru: 'Здравствуйте'
  },
  'order.text1': {
    de: 'Bitte gib Deine Rechnungsadresse hier ein.',
    en: 'Please enter your billing address here. ',
    ru: 'Пожалуйста напишите Ваш платежный адрес.'
  },
  'order.text2': {
    de: 'Solltest Du einen Rabattcode erhalten haben, kannst Du ihn im nächsten Schritt einlösen.',
    en: 'In case you have received a discount code you can enter it during the next step.',
    ru: 'Если у Вас есть код для скидки, Вы можете ввести его ниже.'
  },
  'order.ongoingText1': {
    de: 'Laufende Bestellung gefunden',
    en: 'Current order found',
    ru: 'Был найден Ваш заказ'
  },
  'order.ongoingText2': {
    de: 'Du hast die Rechnungsadresse für diese Bestellung bereits eingegeben. ' +
      'Du kannst sie hier überprüfen und nach Belieben ändern.',
    en: 'You already entered the billing address for this order. You can check and change it here.',
    ru: 'Вы ввели свой платежный адрес. Проверьте его еще раз и при необходимости измените.'
  },
  'order.back': {
    de: 'Zurück',
    en: 'Back',
    ru: 'Назад'
  },
  'order.continue': {
    de: 'Weiter',
    en: 'Next',
    ru: 'Продолжить'
  },
  'order.position.displayName': {
    de: 'Produkt',
    en: 'Product',
    ru: 'Продукт'
  },
  'order.position.amount': {
    de: 'Anzahl',
    en: 'Amount',
    ru: 'Количество'
  },
  'order.position.netPrice': {
    de: 'Netto',
    en: 'Net',
    ru: 'нетто'
  },
  'order.position.discount': {
    de: 'Rabatt',
    en: 'Discount',
    ru: 'скидка'
  },
  'order.position.vat': {
    de: 'Steuer',
    en: 'VAT',
    ru: 'налог'
  },
  'order.position.finalPrice': {
    de: 'Brutto',
    en: 'Gross',
    ru: 'брутто'
  },
  'order.overview.cart': {
    de: 'Warenkorb',
    en: 'Shopping cart',
    ru: 'Корзина'
  },
  'order.overview.soldBy': {
    de: 'Verkauf erfolgt durch:',
    en: 'Sold by:',
    ru: 'Продавец:'
  },
  'order.overview.companyInfo1': {
    de: 'ID37 Company GmbH',
    en: 'ID37 Company GmbH',
    ru: 'ID37 Company GmbH'
  },
  'order.overview.companyInfo2': {
    de: 'Kollwitzstraße 40',
    en: 'Kollwitzstraße 40',
    ru: 'Kollwitzstraße 40'
  },
  'order.overview.companyInfo3': {
    de: '10405 Berlin',
    en: '10405 Berlin',
    ru: '10405 Berlin'
  },
  'order.overview.companyInfo4': {
    de: 'Deutschland',
    en: 'Germany',
    ru: 'Germany'
  },
  'order.overview.agb': {
    de: 'AGB',
    en: 'Terms and conditions',
    ru: 'Общие условия продажи'
  },
  'order.overview.agbLink': {
    de: 'https://www.id37.io/agb',
    en: 'https://www.id37.io/en/en-agbs',
    ru: 'https://www.id37.io/en/en-agbs'
  },
  'order.overview.privacyPolicy': {
    de: 'Datenschutz',
    en: 'Privacy policy',
    ru: 'Положение о конфедициальности'
  },
  'order.overview.privacyPolicyLink': {
    de: 'https://www.id37.io/datenschutz',
    en: 'https://www.id37.io/en/privacy',
    ru: 'https://www.id37.io/en/privacy'
  },
  'order.overview.imprint': {
    de: 'Impressum',
    en: 'Imprint',
    ru: 'Контакты'
  },
  'order.overview.imprintLink': {
    de: 'https://www.id37.io/impressum',
    en: 'https://www.id37.io/en/imprint',
    ru: 'https://www.id37.io/en/imprint'
  },
  'order.overview.digitalProductInfo': {
    de: 'Ich stimme ausdrücklich zu, dass vor Ablauf der Widerrufsfrist mit der Ausführung des Vertrags begonnen wird. ' +
      'Mir ist bekannt, dass mit Beginn der Ausführung mein Widerrufsrecht erlischt.',
    en: 'I expressly agree that the execution of the contract may commence before the expiry ' +
      'of the withdrawal period. I am aware that my right of withdrawal expires at the ' +
      'beginning of the execution of the contract.',
    ru: 'Давая согласие, я принимаю договор, который дает право на использование дижитального продукта сразу после покупки и исключает ' +
      'право отказа.'
  },
  'order.overview.accept': {
    de: 'Mit dem Absenden Deiner Bestellung erklärst Du Dich mit den ID37 <a href="https://www.id37.io/agb">AGB</a> und der ' +
      '<a href="https://www.id37.io/datenschutz">Datenschutzerklärung</a> von ID37 einverstanden. ' +
      'In den AGB findest Du Informationen zu Deinem Widerrufsrecht.' +
      ' <a href="https://web.tresorit.com/l#2dNqJxN6CnbsPrgmHcXcCw">Hier</a> findest Du das Muster eines Widerrufsformulars.',
    en: 'By submitting your order, you agree with the ID37 <a href="https://www.id37.io/en/en-agbs">Terms and Conditions</a> and the ' +
      '<a href="https://www.id37.io/en/privacy">privacy policy</a> of ID37. ' +
      'In the terms and conditions you will find information about your right of withdrawal.' +
      ' <a href="https://web.tresorit.com/l#2dNqJxN6CnbsPrgmHcXcCw">Here</a> you will find the pattern of a cancellation form.',
    ru: 'Нажимая на кнопку Заказать тест ID37 <a href="https://www.id37.io/agb">AGB</a> и ' +
      '<a href="https://www.id37.io/datenschutz">Договор о конфедициальности</a> от ID37 Вы даете свое согласие. ' +
      'Здесь Вы найдете всю информацию об Общих условиях продажи.' +
      ' <a href="https://web.tresorit.com/l#2dNqJxN6CnbsPrgmHcXcCw">Здесь</a> Здесь Вы найдете условия отказа от покупки.'
  },
  'order.overview.back': {
    de: 'Zurück',
    en: 'Back',
    ru: 'Назад'
  },
  'order.overview.continue': {
    de: 'Kaufen',
    en: 'Buy',
    ru: 'купить'
  },
  'order.overview.completeOrder': {
    de: 'Bestellung abschließen',
    en: 'Complete your purchase',
    ru: 'Принять заказ'
  },
  'order.overview.discountCode': {
    de: 'Rabattcode',
    en: 'Discount code',
    ru: 'Скидка'
  },
  'order.overview.discountCode.apply': {
    de: 'Anwenden',
    en: 'Apply',
    ru: 'Применить'
  },
  'order.overview.discountCode.activeInfoA1': {
    de: 'Du hast den Rabattcode ',
    en: 'You successfully applied discount code ',
    ru: 'Вы успешно ввели код '
  },
  'order.overview.discountCode.activeInfoA2': {
    de: ' erfolgreich angewendet.',
    en: '.',
    ru: '.'
  },
  'order.overview.discountCode.activeInfoB1': {
    de: 'Deine Bestellung ist um ',
    en: 'Your order is discounted by ',
    ru: 'Ваша скидка учтена '
  },
  'order.overview.discountCode.activeInfoB2': {
    de: ' rabattiert.',
    en: '.',
    ru: '.'
  },
  'order.overview.coachCode.label.A': {
    de: 'Gib den Code Deines WW Coachs ein*',
    en: 'Enter the code of your WW Coach*'
  },
  'order.overview.coachCode.label.B': {
    de: 'Kein WW Coach? Nutze diesen Code: HappyWW',
    en: 'Not a WW Coach? Use this code: HappyWW'
  },
  'order.overview.coachCode.activeInfoA1': {
    de: 'Du hast den Code ',
    en: 'You successfully applied discount code '
  },
  'order.overview.coachCode.activeInfoA2': {
    de: ' angewendet.',
    en: '.'
  },
  'order.overview.coachCode.required': {
    de: '*Pflichtfeld',
    en: '*Required field',
    ru: '*Обязательное поле',
    nl: '*Verplicht veld'
  },
  'order.overview.discountCode.comes.from.category': {
    de: 'Der Code kommt aus der Kategorie: ',
    en: 'The Discountcode comes from this Category: ',
    ru: 'Ваша скидка из категории: '
  },
  'order.overview.discountCode.applicationSuccessful': {
    de: 'Der Code wurde erfolgreich angewendet.',
    en: 'The discount code was applied successfully.',
    ru: 'Ваша скидка была использована.'
  },
  'order.overview.discountCode.applicationUnsuccessful': {
    de: 'Der Code konnte nicht angewendet werden.',
    en: 'The discount code could not be applied.',
    ru: 'Вашу скидку использовать нельзя.'
  },
  'order.cart.summary': {
    de: 'Zusammenfassung',
    en: 'Summary',
    ru: 'Резюме'
  },
  'order.cart.netSum': {
    de: 'Summe - Netto',
    en: 'Total - net',
    ru: 'Сумма - Нетто'
  },
  'order.cart.savings': {
    de: 'Summe - Rabatt',
    en: 'Total - discount',
    ru: 'Сумма - Скидка'
  },
  'order.cart.vatLosses': {
    de: 'Summe - Steuern',
    en: 'Total - taxes',
    ru: 'Сумма - Налог'
  },
  'order.cart.grossSum': {
    de: 'Summe - Brutto',
    en: 'Total - gross',
    ru: 'Сумма - Брутто'
  },
  'order.processing.info': {
    de: 'Deine Bestellung wird überprüft ...',
    en: 'Your order will be checked ...',
    ru: 'Мы проверяем Ваш заказ.'
  },
  'order.paymentProviderChooser.header': {
    de: 'Wähle Deine Zahlungsart',
    en: 'Choose your payment method',
    ru: 'Выберете способ оплаты'
  },
  'order.paymentProviderChooser.info': {
    de: 'Du kannst zwischen folgenden Karten wählen, um Deine Bestellung fortzuführen. ' +
      'Solltest Du keine dieser Karten besitzen, wende Dich bitte an <a href="help@id37.io">help@id37.io</a>.',
    en: 'You may choose one of the following cards in order to continue the order process. ' +
      'In case your card is not available, kindly contact us at <a href="help@id37.io">help@id37.io</a>.',
    ru: 'Вы можете выбрать следующие способы опалты. ' +
      'Если у Вас нет подходящей карты, то нажмите здесь <a href="help@id37.io">help@id37.io</a>.'
  },
  'order.paymentProviderChooser.creditCard': {
    de: 'Kreditkarte',
    en: 'Credit card',
    ru: 'Кредитная карта'
  },
  'order.paymentProviderChooser.mastercard': {
    de: 'Mastercard',
    en: 'Mastercard',
    ru: 'Mastercard'
  },
  'order.paymentProviderChooser.amex': {
    de: 'American Express',
    en: 'American Express',
    ru: 'American Express'
  },
  'order.paymentProviderChooser.visa': {
    de: 'VISA',
    en: 'VISA',
    ru: 'VISA'
  },
  'order.paymentProviderChooser.sofort': {
    de: 'Sofortüberweisung',
    en: 'Sofortüberweisung',
    ru: 'Sofortüberweisung'
  },
  'order.paymentProviderChooser.paypal': {
    de: 'PayPal',
    en: 'PayPal',
    ru: 'PayPal'
  },
  'order.paymentProviderChooser.applepay': {
    de: 'Apple Pay',
    en: 'Apple Pay',
    ru: 'Apple Pay'
  },
  'order.buy': {
    de: 'Jetzt kaufen',
    en: 'Buy now',
    ru: 'Купить'
  },
  'order.completed.heading': {
    de: 'Vielen Dank für Deinen Einkauf!',
    en: 'Thank you for your purchase!',
    ru: 'Спасибо за покупку!'
  },
  'order.completed.text1': {
    de: 'Wir haben Dir soeben Deine Kaufbestätigung inklusive Rechnung per E-Mail zugesandt.',
    en: 'We just sent a purchase confirmation including an invoice to your email address.',
    ru: 'На Вашу почту пришло подтверждение и счет о покупке.'
  },
  'order.completed.text2': {
    de: 'Du findest Deine Bestellung mit der Rechnung auch unter folgender Nummer in Deinem ID37 Account:',
    en: 'You may also find your order and invoice under the following reference number in your ID37 account:',
    ru: 'Вы найдете свою покупку и счет в Вашем аккаунте ID37 с номером:'
  },
  'order.completed.text3': {
    de: 'Klicke auf den Button, um Deine ID37 Persönlichkeitsanalyse durchzuführen.',
    en: 'Click on this button in order to start your ID37 personality assessment.',
    ru: 'Нажмите на кнопку, чтобы провести диагностику ID37.'
  },
  'order.completed.text4': {
    de: 'Klicke auf diesen Button, um ein Gespräch mit Jay, deinem neuen digitalen Assistenten, zu starten.',
    en: 'Press this button to initiate a conversation with Jay, your new digital assistant.'
  },
  'order.completed.goToTest': {
    de: 'Persönlichkeitsanalyse erstellen',
    en: 'Get your ID37',
    ru: 'Начните тест'
  },
  'order.completed.goToChatbotJay': {
    de: 'Chat starten mit Jay',
    en: 'Start Chat with Jay'
  },
  'order.notCompleted.heading': {
    de: 'Diese Bestellung ist noch nicht abgeschlossen.',
    en: 'This order has not yet been completed.',
    ru: 'Дополните данные Вашего заказа'
  },
  'order.notCompleted.action.chargesCanceled.text1': {
    de: 'Dein Bezahlvorgang wurde abgebrochen.',
    en: 'Your payment process has been canceled.',
    ru: 'Оплата не прошла.'
  },
  'order.notCompleted.statusAfterProcessingIsInitialized.text1': {
    de: 'Du kannst dort weitermachen, wo du mit deiner Bestellung aufgehört hast.',
    en: 'You can continue where you left off with your order.',
    ru: 'Продолжите Вашу покупку и заполните необходимые поля.'
  },
  'order.notCompleted.statusAfterProcessingIsInitialized.continueOrdering': {
    de: 'Jetzt Bestellung fortführen',
    en: 'Continue ordering now',
    ru: 'Продолжить оформление заказа'
  },
  'orders.orders': {
    de: 'Bestellungen & Rechnungen',
    en: 'Orders & Invoices',
    ru: 'заказы'
  },
  'orders.products.id37Test': {
    de: 'ID37 Test',
    en: 'ID37 Test',
    ru: 'ID37 тест'
  },
  'orders.products.id37Terminated': {
    de: 'ID37 Chatbot Jay',
    en: 'ID37 Chatbot Jay',
    ru: 'ID37 Чатбот Джей'
  },
  'orders.products.id37TestWithCoaching': {
    de: 'ID37 Test mit Coaching',
    en: 'ID37 Test with Coaching',
    ru: 'ID37 Тест с коучингом'
  },
  'orders.products.id37Chatbot': {
    de: 'KI Coach Jay',
    en: 'KI Coach Jay',
    ru: 'ID37 Чатбот Джей'
  },
  'orders.products.unknownProduct': {
    de: 'Unbekanntes Produkt',
    en: 'Unknown Product',
    ru: 'Неизвестный продукт'
  },
  'orders.products.unfinishedOrder': {
    de: 'Unfertige Bestellung',
    en: 'Unfinished Order',
    ru: 'Незавершённый заказ'
  },
  'orders.overview': {
    de: 'Übersicht',
    en: 'Summary',
    ru: 'Обзор'
  },
  'orders.noOrdersYet': {
    de: 'Du hast noch keine Bestellungen getätigt.',
    en: 'You have not made any orders yet.',
    ru: 'Вы еще не сделали никаких заказов.'
  },
  'orders.id': {
    de: 'Bestell-Nr.',
    en: 'Order No.',
    ru: 'Номер заказа'
  },
  'orders.status': {
    de: 'Status',
    en: 'Status',
    ru: 'Статус'
  },
  'orders.invoices.no': {
    de: 'RN ',
    en: 'IN '
  },
  'orders.invoices.initialized': {
    de: 'Gestartet',
    en: 'Initialized'
  },
  'orders.invoices.canceled': {
    de: 'Abgebrochen',
    en: 'Canceled'
  },
  'orders.invoices.completed': {
    de: 'Abgeschlossen',
    en: 'Completed'
  },
  'orders.invoices.unsubscribed': {
    de: 'Abo gekündigt',
    en: 'Unsubscribed'
  },
  'orders.invoices.subscription_terminated': {
    de: 'Abo inaktiv',
    en: 'Subscription terminated'
  },
  'orders.invoices.error': {
    de: 'Fehler',
    en: 'Error'
  },
  'orders.date': {
    de: 'Datum',
    en: 'Date',
    ru: 'дата'
  },
  'orders.product': {
    de: 'Produkt',
    en: 'Product',
    ru: 'Продукт'
  },
  'orders.invoices': {
    de: 'Rechnungen',
    en: 'Invoices',
    ru: 'счета'
  },
  'orders.amount': {
    de: 'Betrag',
    en: 'Amount'
  },
  'orders.invoices.header': {
    de: 'Rechnungen',
    en: 'Invoices',
  },
  'orders.cancel-subscription-action': {
    de: 'Abo kündigen',
    en: 'Cancel Subscription',
    ru: 'Отменить подписку'
  },
  'orders.cancelSubscription': {
    de: 'Kündigung',
    en: 'Termination',
    ru: 'Увольнение'
  },
  'orders.unfinishedOrder': {
    de: 'Unfertige Bestellung',
    en: 'Unfinished Order',
    ru: 'незавершённый заказ'
  },
  'orders.download-invoice-action': {
    de: 'Herunterladen',
    en: 'Download',
    ru: 'скачать'
  },
  'orders.process-action': {
    de: 'Überprüfen',
    en: 'Check',
    ru: 'проверка'
  },
  'orders.subscriptions.cancelChatbotHeadline': {
    de: 'KI-Coach Jay',
    en: 'AI coach Jay'
  },
  'orders.subscriptions.cancelChatbotTitel': {
    de: 'Chatbot Abo wirklich beenden?',
    en: 'Really want to cancel your chatbot subscription? ',
  },
  'orders.subscriptions.cancelTextA': {
    de: 'Möchtest Du das ID37 Chatbot Abo wirklich zum ',
    en: 'Do you really want to cancel your ID37 chatbot subscription by ',
  },
  'orders.subscriptions.cancelTextB': {
    de: ' kündigen und auf die Dialoge mit Jay verzichten? Solltest Du kündigen, senden wir Dir die Bestätigung in Kürze per E-Mail zu.',
    en: ' and no longer engage with Jay? If you cancel your subscription, we’ll send you a confirmation of cancellation by e-mail.'
  },
  'orders.subscriptions.cancelChatbotSubscription': {
    de: 'Kündigen',
    en: 'Cancel subscription',
  },
  'orders.subscriptions.closeModal': {
    de: 'Abbrechen',
    en: 'Keep subscription',
  },
  'orders.testing.header': {
    de: 'KI-Chatbot 14 Tage lang kostenlos testen und kennenlernen',
    en: 'Test and get to know the AI chatbot free of charge for 14 days'
  },
  'orders.testing.information': {
    de: 'Alle ID37 Anwender:innen erhalten KI-Coach Jay 14 Tage lang gratis. Die Probephase beginnt, ' +
      'sobald der KI-Chatbot aktiviert wird und endet automatisch. ' +
      'Die Anwender:innen müssen nichts tun. Nach dem Ablauf der Testphase kann das Abo abgeschlossen werden.',
    en: 'All ID37 users receive AI Coach Jay free of charge for 14 days. The trial period starts as soon as the ' +
      'AI chatbot is activated and ends automatically. ' +
      'Users do not have to do anything. At the end of the trial period, the subscription can be concluded.'
  },
  'orders.testing.getToKnowUsNow': {
    de: 'Jetzt kennenlernen',
    en: 'Get to know us now'
  },
  'orders.subscriptions.subscriptions': {
    de: 'Abos',
    en: 'Subscriptions'
  },
  'orders.subscriptions.personalChatbot': {
    de: 'Persönlicher KI-Coach Jay',
    en: 'Personal AI coach Jay '
  },
  'orders.subscriptions.personalChatbotText1': {
    de: 'Der KI-Chatbot hilft ID37 Anwender:innen, sofort Antworten auf zwischenmenschliche Fragen zu finden, ' +
      'ihr Verhalten zu ändern oder ihr Wissen über ID37 und Motivationspsychologie zu vertiefen. ' +
      'Die Dialoge passen sich stets der individuellen Persönlichkeit der Nutzer:innen an.',
    en: 'The AI chatbot helps ID37 users find answers to interpersonal questions, change their behavior ' +
      'or deepen their knowledge of ID37 and motivational psychology – whenever they want. Discussions with Jay ' +
      'are always tailored to a user’s individual personality.'
  },
  'orders.subscriptions.personalChatbotText2': {
    de: 'KI-Coach Jay verbessert das persönliche ID37 Erlebnis für Anwender:innen und Profis.',
    en: 'AI coach Jay helps users and professionals get the most out of the ID37 personality model. '

  },
  'orders.subscriptions.personalChatbotText3': {
    de: 'Er kann genutzt werden, sobald das ID37 Persönlichkeitsprofil vorliegt.',
    en: 'It can be used as soon as an ID37 personality profile is available. '
  },

  'orders.subscriptions.personalChatbotHeadline': {
    de: 'Zentrale Features',
    en: 'Key features'
  },

  'orders.subscriptions.personalChatbotFeature1': {
    de: 'Personalisierte Interaktion',
    en: 'Personalized interaction'
  },

  'orders.subscriptions.personalChatbotFeature2': {
    de: 'Wissenschaftliche Grundlage',
    en: 'Evidence-based '
  },

  'orders.subscriptions.personalChatbotFeature3': {
    de: 'Immer verfügbar',
    en: 'Always available'
  },

  'orders.subscriptions.personalChatbotFeature4': {
    de: 'Mehrsprachig',
    en: 'Multilingual'
  },

  'orders.subscriptions.personalChatbotFeature5': {
    de: 'Permanente Weiterentwicklung',
    en: 'Continuous improvement'
  },

  'orders.subscriptions.personalChatbotFeature6': {
    de: 'Keine Nutzungsbeschränkung, was die Anzahl der Dialoge betrifft',
    en: 'No usage limits on the number of dialogues'
  },
  'orders.subscriptions.personalChatbotQuitHeadline': {
    de: 'Jederzeit kündbar',
    en: 'Cancelable anytime'
  },
  'orders.subscriptions.personalChatbotQuitText1': {
    de: 'Das Abo ist monatlich kündbar.',
    en: 'The subscription can be canceled on one-month’s notice.'
  },

  'orders.subscriptions.personalChatbotQuitText2': {
    de: '*Der Preis pro Monat beträgt 5,95 € (inkl. USt.) und verlängert sich nach einem Monat ' +
      'automatisch um einen weiteren Monat. Die Bezahlung erfolgt bequem per Kreditkarte oder PayPal.',
    en: '*The price per month is €5.95 (incl. VAT) and is automatically renewed for another month after' +
      ' one month. Payment is conveniently made by credit card or PayPal.  '
  },
  'orders.subscriptions.personalChatbotQuitText3': {
    de: 'Die Nutzung des KI-Coachs erfolgt auf Basis unserer ',
    en: 'The use of the AI coach is based on our '
  },
  'orders.subscriptions.personalChatbotQuitText4': {
    de: 'für die Nutzung von Online-Dienste von ID37. Informationen darüber,' +
        ' wie wir im Rahmen der Nutzung des KI-Coachs Jay deine personenbezogenen Daten verarbeiten, findest du in der ',
    en: ' for the use of online services from ID37. Information on how we process your personal data when using AI ' +
      'coach Jay can be found in the '
  },
  'orders.subscriptions.linkText1': {
    de: 'Allgemeinen Geschäftsbedingungen',
    en: 'General Terms and Conditions'
  },
  'orders.subscriptions.linkText2': {
    de: 'Datenschutzerklärung für KI-Coach Jay.',
    en: 'Privacy Policy for AI Coach Jay.'
  },
  'orders.subscriptions.link1': {
    de: 'https://www.id37.io/agb',
    en: 'https://www.id37.io/en/en-agbs'
  },
  'orders.subscriptions.link2': {
    de: 'https://www.id37.io/datenschutz-ki-coach',
    en: 'https://www.id37.io/en/privacy-ai-coach'
  },
  'orders.subscriptions.personalChatbotButtonClosed': {
    de: 'Mehr Informationen',
    en: 'More Information'
  },
  'orders.subscriptions.personalChatbotButtonOpened': {
    de: 'Weniger Informationen',
    en: 'Less Information'
  },
  'orders.subscriptions.personalChatbotBuyButton': {
    de: 'Jetzt kaufen',
    en: 'Buy now'
  },
  'orders.subscriptions.personalChatbotBuyButtonAcquisition': {
    de: 'Pro-Account',
    en: 'Pro-Account'
  },
  'orders.subscriptions.personalChatbotCancelSubscriptionButton': {
    de: 'Kündigen',
    en: 'Cancel subscription'
  },
  'orders.subscriptions.subscriptionStatus.notSubscribed': {
    de: 'Monatsabo',
    en: 'Monthly subscription'
  },
  'orders.subscriptions.subscriptionStatus.subscribed': {
    de: 'Jay Abo aktiv',
    en: 'Subscription active'
  },
  'orders.subscriptions.subscriptionStatus.terminated': {
    de: 'Jay Abo gekündigt',
    en: 'Subscription canceled'
  },

  'orders.subscriptions.subscriptionInformationText.subscribed': {
    de: 'Verlängerung: ',
    en: 'Renewal: '
  },

  'orders.subscriptions.subscriptionInformationText.notSubscribed': {
    de: '5€ / Monat*',
    en: '€5 / month*'
  },

  'orders.subscriptions.subscriptionInformationText.terminated': {
    de: 'Nutzbar bis: ',
    en: 'Valid until: '
  },

  'orders.subscriptions.date': {
    de: '.',
    en: '/'
  },
  'orders.error.errorHeadline': {
    de: 'Ein Fehler ist aufgetreten',
    en: 'An error has occurred'
  },
  'orders.error.errorTextDefault': {
    de: 'Bitte kontaktiere unseren Administrator für Unterstützung unter: ' +
      '<a href = "mailto:jay@id37.io">jay@id37.io</a>.',
    en: 'Please contact our administrator for assistance at: ' + '<a href="mailto:jay@id37.io">jay@id37.io</a>'
  },
  'orders.error.errorTextCreditCard': {
    de: 'Deine Kreditkarte ist abgelaufen. Bitte aktualisiere Deine Zahlungsdaten um fortzufahren.',
    en: 'Your credit card has expired. Please update your payment details to continue.'
  },
  'orders.error.errorTextChatbot': {
    de: 'Es gibt ein Problem mit Deinem Chatbot-Zugang. Bitte kontaktiere unseren Administrator für Unterstützung unter: ' +
      '<a href = "mailto:jay@id37.io">jay@id37.io</a>. ',
    en: 'There is an issue with your chatbot access. Please contact our administrator for assistance at: ' +
      '<a href = "mailto:jay@id37.io">jay@id37.io</a>. '
  },

  'invited-by-partner.broughtToYouBy': {
    de: 'Brought to you by',
    en: 'Brought to you by'
  },

  /* NOTIFICATIONS */
  'notifications.noNotifications': {
    de: 'Du hast noch keine Benachrichtigungen.',
    en: 'You don\'t have any notifications yet.'
  },
  'notification.ACCOUNT_CREATED.title': {
    de: 'Herzlich willkommen bei ID37',
    en: 'Welcome to ID37'
  },
  'notification.ACCOUNT_CREATED.message.invitedBy': {
    de: ' hat Dich zu ID37 eingeladen.',
    en: ' invited you to ID37.'
  },
  'notification.ACCOUNT_CREATED.message.partA': {
    de: 'Schön, dass Du dabei bist. '
      + 'Wir wünschen Dir viele neue Erkenntnisse über Deine Persönlichkeit und ihre Wirkung auf Dein '
      + 'Denken, Fühlen, und Verhalten.',
    en: 'Hi! And welcome to ID37, where we hope you will enjoy finding new insights into your personality and its '
      + 'effect on how you think, feel and act.'
  },
  'notification.TEST_INVITATION.title': {
    de: 'Erstelle Dein Persönlichkeitsprofil',
    en: 'Create your personality profile'
  },
  'notification.TEST_INVITATION.message.partA': {
    de: 'Du bist eingeladen, Dein ID37 Persönlichkeitsprofil durchzuführen. Hier geht’s zum ',
    en: 'You are invited to create your ID37 personality profile. Click here to access the '
  },
  'notification.TEST_INVITATION.message.questionnaire': {
    de: 'ID37 Fragebogen',
    en: 'ID37 questionnaire'
  },
  'notification.TEST_INVITATION.message.partB': {
    en: '.'
  },
  'notification.TEST_REVOKED.title': {
    de: 'Zugriff zum ID37 Test wurde entzogen',
    en: 'Access to the ID37 test has been revoked'
  },
  'notification.TEST_REVOKED.message.partA': {
    de: 'Dein ',
    en: 'Your '
  },
  'notification.TEST_REVOKED.message.roleMaster': {
    de: 'Master',
    en: 'Master'
  },
  'notification.TEST_REVOKED.message.rolePartner': {
    de: 'Partner',
    en: 'partner'
  },
  'notification.TEST_REVOKED.message.partB': {
    de: ' hat Dir den Testzugriff entzogen.',
    en: ' has revoked your access to the test.'
  },
  'notification.TEST_FINISHED.title': {
    de: 'Neues Persönlichkeitsprofil erstellt',
    en: 'New personality profile created'
  },
  'notification.TEST_FINISHED.message.partA': {
    de: 'Das Persönlichkeitsprofil von ',
    en: 'The personality profile for '
  },
  'notification.TEST_FINISHED.message.partB': {
    de: ' wurde erstellt und ist in Deinem ID37 Account hinterlegt. Jetzt das ',
    en: ' has been created and is stored in your ID37 account. Take a look at the '
  },
  'notification.TEST_FINISHED.message.report': {
    de: 'Profil',
    en: 'profile'
  },
  'notification.TEST_FINISHED.message.partC': {
    de: ' ansehen.',
    en: ' now.'
  },
  'notification.TEST_ACCESS_GRANTED.title': {
    de: 'Schau Dir Dein Persönlichkeitsprofil an',
    en: 'Take a look at your personality profile'
  },
  'notification.TEST_ACCESS_GRANTED.message.partA': {
    de: 'Dein ID37 Persönlichkeitsprofil wurde freigeschaltet. Klicke hier, um zu Deiner ',
    en: 'Access to your ID37 personality profile has been activated. Click here to review your '
  },
  'notification.TEST_ACCESS_GRANTED.message.report': {
    de: 'ID37 Analyse',
    en: 'ID37 analysis'
  },
  'notification.TEST_ACCESS_GRANTED.message.partB': {
    de: ' zu gelangen.',
    en: '.'
  },
  'notification.FRIENDSHIP_INVITATION.title': {
    de: 'Du hast eine neue Kontaktanfrage',
    en: 'You have a new contact request'
  },
  'notification.FRIENDSHIP_INVITATION.message.partA': {
    de: ' möchte mit Dir in Kontakt treten und *ACCUSATIVE.N* Profil mit Dir teilen.',
    en: ' would like to get in touch with you.'
  },
  'notification.FRIENDSHIP_ACCEPTED.title': {
    de: 'Deine Kontaktanfrage wurde bestätigt',
    en: 'Your contact request has been accepted'
  },
  'notification.FRIENDSHIP_ACCEPTED.message.partA': {
    de: ' hat deine Kontaktanfrage bestätigt.',
    en: ' has accepted your request to get in touch.'
  },
  'notification.SYSTEM_TEST.title': {
    de: 'Systemtest',
    en: 'System test'
  },
  'notification.SYSTEM_TEST.message': {
    de: 'Zu Testzwecken erstellt.',
    en: 'Created for testing purposes.'
  },
  'notification.TEST_EXPIRING_MASTER.title': {
    de: 'Ein Kunde hat die Einladung zu ID37 noch nicht angenommen',
    en: 'A client has not yet accepted your invitation to start the ID37 personality assessment'
  },
  'notification.TEST_EXPIRING_MASTER.message.partA': {
    de: 'Bitte ',
    en: 'Ask '
  },
  'notification.TEST_EXPIRING_MASTER.message.partB': {
    de: ', die Einladung anzunehmen und sich zu registrieren. Das erspart Dir den Aufwand einer erneuten Einladung. '
      + 'Der Einladungslink verliert in <b>7 Tagen</b> seine Gültigkeit.',
    en: ' to accept the invitation and register. This will save you the hassle of having to repeat the invitation. '
      + 'This invitation link will expire in <b>7 days</b>'
  },
  'notification.TEST_EXPIRING_CLIENT.title': {
    de: 'Du hast Deinen Test noch nicht begonnen',
    en: 'You haven’t started your test yet'
  },
  'notification.TEST_EXPIRING_CLIENT.message.partA': {
    de: 'Hallo ',
    en: 'Hello '
  },
  'notification.TEST_EXPIRING_CLIENT.message.partB': {
    de: ', Du hast Deinen Test noch nicht begonnen, er verliert in <b>7 Tagen</b> seine Gültigkeit.',
    en: ', you haven’t started your test yet, this invitation will expire in <b>7 days</b>.'
  },
  'notification.TEST_EXPIRED_OF_CLIENT.title': {
    de: 'Eine Einladung hat ihre Gültigkeit verloren',
    en: 'An ID37 invitation has expired'
  },
  'notification.TEST_EXPIRED_OF_CLIENT.message.partA': {
    de: ' hat sich noch nicht bei ID37 registriert. Der Link ist nicht mehr gültig. '
      + 'Setze Dich mit der Person in Verbindung und lade sie erneut ein. Hier ',
    en: ' has not yet registered with ID37. The link has expired. Please contact the client and re-invite them. Invite '
  },
  'notification.TEST_EXPIRED_OF_CLIENT.message.partB': {
    de: 'einladen.',
    en: 'here.'
  },
  'notification.SUPERLIKE_SENT.title': {
    de: 'Du hast eine Reaktion erhalten: ',
    en: 'You’ve got a response: '
  },
  'notification.SUPERLIKE_SENT.message': {
    de: 'hat auf Dein Profil reagiert.',
    en: 'has responded to your profile.'
  },
  'notification.I_APPRECIATE_YOU_A_LOT.message': {
    de: 'Ich schätze Dich sehr',
    en: 'I really appreciate you'
  },
  'notification.INTERESTING_PERSONALITY.message': {
    de: 'Interessante Persönlichkeit!',
    en: 'What an interesting personality!'
  },
  'notification.WE_HAVE_A_LOT_OF_POTENTIAL.message': {
    de: 'Wir haben viel Potential',
    en: 'We have a lot of potential'
  },
  'notification.LETS_TALK.message': {
    de: 'Lass uns reden',
    en: 'Let’s talk'
  },
  'notification.TESTSHARE_CHANGED.title': {
    de: 'Anzahl geteilter Motive geändert',
    en: 'The number of shared motives has been changed'
  },
  'notification.TESTSHARE_CHANGED.messageA': {
    de: 'hat die Anzahl der Motive in Eurem ',
    en: 'has changed the number of motives shared in your '
  },
  'notification.TESTSHARE_CHANGED.messageB': {
    de: 'Profilvergleich',
    en: 'profile comparison'
  },
  'notification.TESTSHARE_CHANGED.messageC': {
    de: ' geändert. Schreibe eine Chat-Nachricht, wenn Du nachfragen möchtest.',
    en: '. Send *DATIVE* a chat message should you have any questions.'
  },

  /* OPT-INS */
  'opt-in-management.NETWORKING.labelEnabled': {
    de: '<b>Zustimmung zur ID37 Suche</b>',
    en: '<b>Opt in to the ID37 search feature</b>'
  },
  'opt-in-management.NETWORKING.labelDisabled': {
    de: '<b>Keine Zustimmung zur ID37 Suche</b>',
    en: '<b>Opt out of the ID37 search feature</b>'
  },
  'opt-in-management.ADVERTISING.labelEnabled': {
    de: '<b>Werbungen bekommen</b>',
    en: '<b>Receive advertising</b>'
  },
  'opt-in-management.ADVERTISING.labelDisabled': {
    de: '<b>Keine Werbungen bekommen</b>',
    en: '<b>Deny advertising</b>'
  },
  'opt-in-management.NETWORKING.enabledText': {
    de: 'Du wirst von Nutzern, die ihr ID37 Persönlichkeitsprofil erstellt haben, gefunden. So können Dich ' +
      'Deine Arbeitskollegen, Businesspartner, Freunde und Lebenspartner  kontaktieren und ihr Profil mit Dir ' +
      'teilen. <b>Dein Persönlichkeitsprofil ist nicht automatisch sichtbar - Du entscheidest im Einzelfall.</b> ' +
      'Nur wenn Du der ID37 Suche zustimmst, holst Du das Optimum aus den Beziehungen in Deinem Netzwerk heraus.',
    en: 'Opting in allows users who’ve created their ID37 personality profile to find you. Your colleagues at work, '
      + 'business partners, friends and life partners can contact you and share their profile with you. '
      + '<b>Your personality profile is not automatically viewable – you decide who gets to see it on a case-by-case basis.</b>'
      + ' Opting in to the ID37 search feature lets you get the most out of the relationships in your network.'
  },
  'opt-in-management.NETWORKING.disabledText': {
    de: 'Du wirst von Nutzern, die ihr ID37 Persönlichkeitsprofil erstellt haben, <b>nicht</b> gefunden. So ' +
      'können Dich Deine Arbeitskollegen, Businesspartner, Freunde und Lebenspartner nicht kontaktieren, um ihr ' +
      'Profil mit Dir zu teilen. <b>Stimme der ID37 Suche zu, um das Optimum aus den Beziehungen in Deinem Netzwerk ' +
      'herauszuholen.</b>',
    en: 'Opting out means users who’ve created their ID37 personality profile won’t be able to find you. Your colleagues '
      + 'at work, business partners, friends and life partners cannot contact you and share their profile with you. '
      + '<b>By opting in to the ID37 search feature, you can get the most out of the relationships in your network. </b> '
  },
  'opt-in.NETWORKING.heading': {
    de: 'Netzwerken',
    en: 'Networking'
  },
  'opt-in.ADVERTISING.heading': {
    de: 'Neuigkeiten',
    en: 'News'
  },
  'opt-in.NETWORKING.message': {
    de: 'Es gibt eine neue Funktion, mit der Du Profilvergleiche mit Deinen Kontakten anstellen kannst,'
      + ' wenn beide dies wollen. Bitte stimme der Funktion „Mein Netzwerk” einmalig zu.\n'
      + 'In „Mein Account” kannst du diese Zustimmung jederzeit wieder ändern.',
    en: 'There\'s a new feature that allows you to compare your profile with those of your contacts who’ve agreed '
      + 'to sharing theirs. Please give your one-time opt-in to the “My Network” feature.\n'
      + 'You can change your status at any time under “My Account”.'

  },
  'opt-in.NETWORKING.decline': {
    de: 'Ablehnen',
    en: 'Deny'
  },
  'opt-in.NETWORKING.accept': {
    de: 'Annehmen',
    en: 'Accept'
  },
  'opt-in.ADVERTISING.decline': {
    de: 'Ablehnen',
    en: 'Deny'
  },
  'opt-in.ADVERTISING.accept': {
    de: 'Annehmen',
    en: 'Accept'
  },

  /* FRIENDS */
  'friends.myContacts': {
    de: 'Meine Kontakte',
    en: 'My Contacts'
  },
  'friends.welcome.headline': {
    de: 'Willkommen beim ID37 Netzwerk',
    en: 'Welcome to the ID37 network'
  },
  'friends.welcome.text': {
    de: 'Möchtest Du andere besser verstehen? ' +
      'Teile Dein ID37 Persönlichkeitsprofil mit Personen aus Deinem privaten oder beruflichen Umfeld. ' +
      'Das gibt Euch die Gelegenheit, einander besser zu verstehen.',
    en: 'Do you want to improve your ability to understand others? Share your ID37 personality profile with people from '
      + 'your private or professional worlds. This will give both you and them the opportunity to understand each other better.'
  },
  'friends.welcome.button': {
    de: 'Ja. Gerne!',
    en: 'Yes. I’d love to!'
  },
  'friends.introText2.headline': {
    de: 'Finde Deine ID37 Kontakte',
    en: 'Find your ID37 contacts'
  },
  'friends.introText2': {
    de: 'Du weißt nicht, wer aus Deinem Umfeld die ID37 Persönlichkeitsanalyse durchgeführt hat? '
      + 'Finde Deine Freunde, Bekannten oder Kollegen, die bereits ein ID37 Profil haben, '
      + 'einfach über die Suchfunktion.',
    en: 'You don\'t know who in your circle has done the ID37 personality assessment? Find friends, acquaintances or '
      + 'colleagues who already have an ID37 profile simply by using the search function.'
  },

  /* FRIEND SEARCH */
  'friendSearch.title': {
    de: 'Kontakte finden',
    en: 'Find contacts'
  },
  'friendSearch.searchInputPlaceholder': {
    de: 'Suchen ...',
    en: 'Search ...'
  },
  'friendSearch.networkingDisabledWarningA': {
    de: 'Derzeit kannst Du nur Personen Deiner bestehenden Kontaktliste finden. '
      + 'Erteile die Einwilligung für die Suche, um alle Personen auf der Plattform angezeigt zu bekommen. '
      + 'Hier gelangst Du in Deinen ',
    en: 'You are currently able to find only those in your existing contact list. To be able to search across everyone '
      + 'on the platform, simply opt in to the search feature. Click here to access your '
  },
  'friendSearch.networkingDisabledWarningAccount': {
    de: 'ID37 Account',
    en: 'ID37 account'
  },
  'friendSearch.networkingDisabledWarningB': {
    de: ', um die Freigabe zu erteilen.',
    en: ', and opt in.'
  },
  'friendSearch.searchResult': {
    de: 'Suchergebnisse',
    en: 'Search queries'
  },
  'friendSearch.emptyResult': {
    de: 'Deine Suche ergab leider keine Ergebnisse. <br>'
      + 'Voraussichtlich hat die Person, die Du suchst, noch kein ID37 Persönlichkeitsprofil erstellt. '
      + 'Empfehle ID37 weiter, damit ihr Eure Profile vergleichen könnt!',
    en: 'Sorry, but there are no results for your search. <br> The person you are looking for has presumably not yet '
      + 'created an ID37 personality profile. Recommend ID37 to others so you can compare your profiles!'
  },
  'friendSearch.button.abort': {
    de: 'Beenden',
    en: 'Exit'
  },

  /* FRIEND LIST */
  'friendList.receivedRequests': {
    de: 'Erhaltene Anfragen',
    en: 'Requests received'
  },
  'friendList.sentRequests': {
    de: 'Gesendete Anfragen',
    en: 'Sent requests'
  },
  'friendList.inProgress': {
    de: 'Bestätigte Kontakte, Test oder Motivauswahl offen',
    en: 'Confirmed contacts, test or motive selection pending'
  },

  /* FRIEND TILE */
  'friendTile.deleteFriendship': {
    de: 'Verbindung trennen',
    en: 'Delete connection'
  },
  'friendTile.resendRequest': {
    de: 'Anfrage erneut senden',
    en: 'Resend request'
  },
  'friendTile.deleteRequest': {
    de: 'Anfrage löschen',
    en: 'Delete request'
  },
  'friendTile.updateTestShare': {
    de: 'Motivauswahl treffen',
    en: 'Share motives'
  },
  'friendTile.text.notApproved': {
    de: 'hat noch nicht bestätigt',
    en: 'has not yet confirmed'
  },
  'friendTile.action.requestAgain': {
    de: 'Nochmal anfragen',
    en: 'Request again'
  },
  'friendTile.action.acceptRequest': {
    de: 'Anfrage annehmen',
    en: 'Accept request'
  },
  'friendTile.action.sendRequest': {
    de: 'Profilvergleich anfragen',
    en: 'Request profile comparison'
  },
  'friendTile.different': {
    de: 'verschieden',
    en: 'different'
  },
  'friendTile.state.noTest': {
    de: 'Test durchführen',
    en: 'Buy test'
  },
  'friendTile.state.unfinishedTest': {
    de: 'Test abschließen',
    en: 'Finish test'
  },
  'friendTile.state.grantTest': {
    de: 'Test noch nicht freigegeben',
    en: 'Test not granted yet'
  },
  'friendTile.state.motiveSelection': {
    de: 'Motivauswahl treffen',
    en: 'Choose your motives'
  },
  'friendTile.state.inProgress': {
    de: 'Profil in Arbeit',
    en: 'Profile in progress'
  },
  'friendTile.recommend': {
    de: 'ID37 empfehlen',
    en: 'Recommend ID37'
  },

  /* FRIENDSHIP DELETE MODAL */
  'friendship.deleteModal.titleA': {
    de: 'Bist Du dir sicher, dass Du ',
    en: 'Are you sure you want to delete '
  },
  'friendship.deleteModal.titleB': {
    de: ' aus Deinen Kontakten entfernen möchtest?',
    en: ' from your list of contacts?'
  },
  'friendship.deleteModal.info': {
    de: 'Ihr könnt Euch später erneut verbinden.',
    en: 'You can reconnect again later.'
  },
  'friendship.deleteModal.accept': {
    de: 'Ja',
    en: 'Yes'
  },
  'friendship.deleteModal.decline': {
    de: 'Nein',
    en: 'No'
  },
  /* TestShare MODAL */
  'testShare.request.title': {
    de: 'Profilvergleich anfragen',
    en: 'Send request'
  },
  'testShare.accept.title': {
    de: 'Anfrage annehmen',
    en: 'Accept request'
  },
  'testShare.textA': {
    de: 'Welche ID37 Motive möchtest Du mit ',
    en: 'Which ID37 motives do you want to share with '
  },
  'testShare.textB': {
    de: ' teilen?',
    en: '?'
  },
  'testShare.16': {
    de: 'alle 16 Motive',
    en: 'all 16 motives'
  },
  'testShare.15': {
    de: 'alle, ohne SINNLICHKEIT',
    en: 'all, excluding the motive EROS'
  },
  'testShare.14': {
    de: 'alle, ohne SINNLICHKEIT und FAMILIE',
    en: 'all, excluding the motives EROS and FAMILY'
  },
  'testShare.shareNow': {
    de: 'Jetzt teilen',
    en: 'Share now'
  },
  /* USER INVITE */
  'userInviteOption.title': {
    de: 'ID37 empfehlen',
    en: 'Recommend ID37'
  },
  'userInviteOption.text': {
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    de: 'Du möchtest jemandem empfehlen, die ID37 Persönlichkeitsanalyse durchzuführen?'
      + ' Du kannst die ID37 Analyse 3x vergünstigt empfehlen.<br>Der Empfänger erhält dann einen '
      + prices.friends_and_family_discount_in_percentage + '% Rabatt.',
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    en: 'Would you like to recommend the ID37 personality assessment to someone you know? Your recommendation can come '
      + 'with a discount for up to 3 individuals.<br>Each recipient receives a '
      + prices.friends_and_family_discount_in_percentage + '% discount.'
  },
  'userInviteOption.leftButton.title': {
    de: 'Family & Friends',
    en: 'Family & Friends'
  },
  'userInviteOption.leftButton.tax.text': {
    de: 'inkl. USt.',
    en: 'incl. VAT'
  },
  'userInviteOption.leftButton.usage.text': {
    de: 'Noch *USAGE*x verfügbar',
    en: 'Still *USAGE*x discount access'
  },
  'userInviteOption.rightButton.title': {
    de: 'Regulärer Preis',
    en: 'Regular price'
  },
  'userInviteOption.rightButton.tax.text': {
    de: 'inkl. USt.',
    en: 'incl. VAT'
  },
  'userInviteOption.rightButton.usage.text': {
    de: 'Unbegrenzt verfügbar',
    en: 'Unlimited access'
  },
  'userInvite.title': {
    de: 'Gute Wahl!',
    en: 'Good choice!'
  },
  'userInvite.text': {
    de: 'Der Empfänger erhält eine E-Mail mit einem Link, über den er sich für ID37 registrieren, ' +
      'den Test kaufen und durchführen kann.',
    en: 'The recipient will receive an email with a link that allows them to register for ID37, and then purchase '
      + 'and take the test.'
  },
  'userInvite.email': {
    de: 'E-Mail',
    en: 'Email',
    ru: 'Электронная почта'
  },
  'userInvite.firstName': {
    de: 'Vorname',
    en: 'First name',
    ru: 'Имя'
  },
  'userInvite.lastName': {
    de: 'Nachname',
    en: 'Last name',
    ru: 'Фамилия'
  },
  'userInvite.notAValidEmail': {
    de: 'Dies ist keine gültige E-Mail Adresse.',
    en: 'This is not a valid email address.',
    ru: 'Этот E-Mail не действителен.'
  },
  'userInvite.terms.headline': {
    de: 'Nutzungshinweis',
    en: 'Terms of use'
  },
  'userInvite.terms.text': {
    de: 'Ich bestätige, dass ID37 die E-Mailadresse des Empfängers nutzen darf. Selbstverständlich kann dies vom ' +
      'Empfänger jederzeit widerrufen werden.',
    en: 'I confirm that ID37 can use the recipient\'s email address. '
      + 'The recipient can revoke this consent at any point in time.'
  },
  'userInvite.terms.error': {
    de: 'Du musst den Bedingungen zustimmen.',
    en: 'You must agree to the terms to proceed.'
  },
  'userInvite.sendInvitation': {
    de: 'Empfehlung senden',
    en: 'Send invitation',
    ru: 'Отправить приглашение'
  },
  'userInvite.thanks': {
    de: 'Danke!',
    en: 'Thanks!'
  },
  'userInvite.thanks.text': {
    de: 'Deine Empfehlung wurde erfolgreich versendet an:',
    en: 'Your invitation was successfully sent to:'
  },
  'userInvite.alreadyOnPlatform.A': {
    de: 'Ein User mit dieser E-Mail ist bereits registriert. '
      + 'Wenn die Person dem Netzwerken zugestimmt hat, findest Du sie über die Funktion ',
    en: 'A user with this email already exists. Try to find the user by searching your '
  },
  'userInvite.alreadyOnPlatform.B': {
    de: '"Mein Netzwerk"',
    en: 'network'
  },
  'userInvite.alreadyOnPlatform.C': {
    en: '.'
  },

  /* CHAT */
  'chat.backToDashboard': {
    de: 'Zurück zum Dashboard',
    en: 'Return to dashboard'
  },
  'chat.chats': {
    de: 'Chats',
    en: 'Chats'
  },
  'chat.yourContacts': {
    de: 'Deine Kontakte',
    en: 'Your contacts'
  },
  'chat.inputPlaceholder': {
    de: 'Hier reinschreiben ...',
    en: 'Write here ...'
  },
  'chat.inputDisclaimer1': {
    de: 'Manchmal kann Jay Fehler machen. Bitte überprüfe wichtige Informationen.',
    en: 'Jay can make mistakes. Please check important information.'
  },
  'chat.inputDisclaimer2': {
    de: 'Die Nutzung von Jay basiert auf den <a href="https://www.id37.io/agb" target="_blank">ID37 AGBs</a>' +
      ' für die Nutzung von Online-Dienste und ' +
      '<a href="https://www.id37.io/datenschutz-ki-coach" target="_blank">der Datenschutzerklärung für KI-Coach Jay</a>.',
    en: 'The use of Jay is based on the <a href="https://www.id37.io/en/en-agbs" target="_blank">ID37 Terms and Conditions</a>' +
      ' for the Use of Online Services and the ' +
    '<a href="https://www.id37.io/en/privacy-ai-coach" target="_blank">Privacy Policy for AI coach Jay</a>.'
  },
  'chat.findContacts': {
    de: 'Kontakte finden',
    en: 'Find contacts'
  },
  'chat.pleaseSelectAContact': {
    de: 'Personen, mit denen Du vernetzt bist, werden hier angezeigt und Du kannst Dich mit Ihnen austauschen. '
      + 'Klicke auf die Person und starte die Kommunikation.',
    en: 'People you are connected with will be displayed here. This is where you can interact with them. '
      + 'Just click on the person and get things going!'
  },
  'chat.sendFirstMessage': {
    de: 'Beginne eine Unterhaltung, indem Du Deine erste Nachricht abschickst.',
    en: 'Start a conversation by sending your first message.'
  },
  'chat.cancelEdit': {
    de: 'Abbrechen',
    en: 'Cancel'
  },
  'chat.saveEdit': {
    de: 'Änderungen speichern',
    en: 'Save changes'
  },
  'chat.edited': {
    de: 'Bearbeitet',
    en: 'Edited'
  },
  'chat.readState.read': {
    de: 'gelesen',
    en: 'read'
  },
  'chat.readState.unread': {
    de: 'ungelesen',
    en: 'not read'
  },
  'chat.sendWithEnter': {
    de: 'Senden mit Enter',
    en: 'Hit Enter to send'
  },
  /* SELECT */
  'select.defaultPlaceholder': {
    de: 'Bitte auswählen...',
    en: 'Please select...'
  },
  'select.defaultSearchPlaceholder': {
    de: 'Suchen...',
    en: 'Search...',
    ka: 'ძიება...'
  },
  'select.defaultNoSearchResults': {
    de: 'Keine Optionen gefunden.',
    en: 'No options found.'
  },
  'select.defaultSelected': {
    de: 'gewählt',
    en: 'selected',
    fr: 'Ta réponse',
    es: 'Tu respuesta',
    it: 'La tua risposta',
    ka: 'აირჩიეთ'
  },

  /* GENDER */
  // POSSESSIVE PRONOUN CASES
  'possessivePronoun.nominative.m/n.c': {
    de: {
      diverse: 'Sein',
      female: 'Ihr',
      male: 'Sein',
      '': 'Sein'
    },
    en: {
      diverse: 'His',
      female: 'Her',
      male: 'His',
      '': 'His'
    }
  },
  'possessivePronoun.nominative.m/n': {
    de: {
      diverse: 'sein',
      female: 'ihr',
      male: 'sein',
      '': 'sein'
    },
    en: {
      diverse: 'his',
      female: 'her',
      male: 'his',
      '': 'his'
    }
  },
  'possessivePronoun.genitive.f/pl': {
    de: {
      diverse: 'seiner',
      female: 'ihrer',
      male: 'seiner',
      '': 'seiner'
    },
    en: {
      diverse: 'his',
      female: 'her',
      male: 'his',
      '': 'his'
    }
  },
  'possessivePronoun.dative.f': {
    de: {
      diverse: 'seiner',
      female: 'ihrer',
      male: 'seiner',
      '': 'seiner'
    },
    en: {
      diverse: 'his',
      female: 'her',
      male: 'his',
      '': 'his'
    }
  },
  'possessivePronoun.dative.n': {
    de: {
      diverse: 'seinem',
      female: 'ihrem',
      male: 'seinem',
      '': 'seinem'
    },
    en: {
      diverse: 'his',
      female: 'her',
      male: 'his',
      '': 'his'
    }
  },
  'possessivePronoun.accusative.m': {
    de: {
      diverse: 'seinen',
      female: 'ihren',
      male: 'seinen',
      '': 'seinen'
    },
    en: {
      diverse: 'his',
      female: 'her',
      male: 'his',
      '': 'his'
    }
  },
  'possessivePronoun.accusative.f/pl': {
    de: {
      diverse: 'seine',
      female: 'ihre',
      male: 'seine',
      '': 'seine'
    },
    en: {
      diverse: 'his',
      female: 'her',
      male: 'his',
      '': 'his'
    }
  },
  'possessivePronoun.accusative.n.c': {
    de: {
      diverse: 'Sein',
      female: 'Ihr',
      male: 'Sein',
      '': 'Sein'
    },
    en: {
      diverse: 'His',
      female: 'Her',
      male: 'His',
      '': 'His'
    }
  },
  'possessivePronoun.accusative.n': {
    de: {
      diverse: 'sein',
      female: 'ihr',
      male: 'sein',
      '': 'sein'
    },
    en: {
      diverse: 'his',
      female: 'her',
      male: 'his',
      '': 'his'
    }
  },
  // PERSONAL PRONOUN CASES
  'personalPronoun.nominative.c': {
    de: {
      diverse: 'Er',
      female: 'Sie',
      male: 'Er',
      '': 'Er'
    },
    en: {
      diverse: 'He',
      female: 'She',
      male: 'He',
      '': 'He'
    }
  },
  'personalPronoun.nominative': {
    de: {
      diverse: 'er',
      female: 'sie',
      male: 'er',
      '': 'er'
    },
    en: {
      diverse: 'he',
      female: 'she',
      male: 'he',
      '': 'he'
    }
  },
  'personalPronoun.dative.c': {
    de: {
      diverse: 'Ihm',
      female: 'Ihr',
      male: 'Ihm',
      '': 'Ihm'
    },
    en: {
      diverse: 'Him',
      female: 'Her',
      male: 'Him',
      '': 'Him'
    }
  },
  'personalPronoun.dative': {
    de: {
      diverse: 'ihm',
      female: 'ihr',
      male: 'ihm',
      '': 'ihm'
    },
    en: {
      diverse: 'him',
      female: 'her',
      male: 'him',
      '': 'him'
    }
  },
  'personalPronoun.accusative': {
    de: {
      diverse: 'ihn',
      female: 'sie',
      male: 'ihn',
      '': 'ihn'
    },
    en: {
      diverse: 'him',
      female: 'her',
      male: 'him',
      '': 'him'
    }
  },
  /* FRIENDS COMPARE SITE */
  'friendCompareSite.headline': {
    de: '*NAME* besser verstehen',
    en: 'Understand *NAME* better'
  },
  'friendCompareSite.intro.text1': {
    de: 'Jeder Mensch ist einzigartig. Hier erfährst Du, was *NAME* einzigartig macht. Du erhältst einen Einblick in '
      + '*ACCUSATIVE.F* Wirklichkeit. Das ist die Basis, um das Miteinander wertschätzend zu gestalten und zu '
      + 'entwickeln.',
    en: 'Every person is unique. This is where you find out what it is that makes *NAME* special. You’ll gain insight '
      + 'into *ACCUSATIVE.F* reality. This is the basis of fostering a relationship founded on mutual appreciation.'
  },
  'friendCompareSite.intro.text2': {
    de: 'Denn gute Beziehungen – im Leben oder in der Zusammenarbeit – sind von zentraler Bedeutung für unser '
      + 'Wohlbefinden. Sie wirken sich auf alle Lebensbereiche positiv aus.',
    en: 'After all, good relationships – in both our private and professional lives – are essential to our well-being. '
      + 'They have a positive effect on all aspects of life.'
  },
  'friendCompareSite.intro.text3': {
    de: 'Im Gegensatz zum Verhalten sind uns die Motive unserer Mitmenschen, also die Antreiber, die zum Verhalten '
      + 'führen, meist nicht bekannt. Das ändert sich jetzt.',
    en: 'Unlike behavior, we’re usually unaware of the motives – that is, the underlying drivers of behavior – that '
      + 'influence how others around us act. We can change that.'
  },
  'friendCompareSite.different': {
    de: 'verschieden',
    en: 'different'
  },

  /* DISTANCE GRAPHIC */
  'distanceGraphicPage.question.headline': {
    de: 'Fragst Du Dich bei *FIRSTNAME* manchmal ...',
    en: 'Regarding *FIRSTNAME*, do you ever ask yourself ...'
  },
  'distanceGraphicPage.question.neu.high': {
    de: 'Wie kann *NOMINATIVE* nur so viel Zeit aufbringen, um sich zu vertiefen?',
    en: 'How can *NOMINATIVE* spend that much time engaged in intellectual activities?'
  },
  'distanceGraphicPage.question.neu.low': {
    de: 'Wie kann *NOMINATIVE* sich mit Halbwissen zufriedengeben?',
    en: 'How can *NOMINATIVE* be satisfied with incomplete knowledge?'
  },
  'distanceGraphicPage.question.san.high': {
    de: 'Wieso will *NOMINATIVE* es immer allen rechtmachen?',
    en: 'Why does *NOMINATIVE* always want to please everybody?'
  },
  'distanceGraphicPage.question.san.low': {
    de: 'Woher kommt diese Selbstsicherheit?',
    en: 'Where does that self-confidence come from?'
  },
  'distanceGraphicPage.question.ein.high': {
    de: 'Woher kommt dieser Wille zur Einflussnahme?',
    en: 'Where does that desire to exercise influence come from?'
  },
  'distanceGraphicPage.question.ein.low': {
    de: 'Woher kommt die Gelassenheit, Dinge einfach laufen zu lassen?',
    en: 'Where does that sense of equanimity, the ability to let things take their course, come from?'
  },
  'distanceGraphicPage.question.sta.high': {
    de: 'Wie kann *NOMINATIVE* sich nur mit Äußerlichkeiten begnügen?',
    en: 'How can *NOMINATIVE* be satisfied with appearances alone?'
  },
  'distanceGraphicPage.question.sta.low': {
    de: 'Wieso möchte *NOMINATIVE* nicht auffallen?',
    en: 'Why doesn’t *NOMINATIVE* want to stand out?'
  },
  'distanceGraphicPage.question.bes.high': {
    de: 'Wie kann *NOMINATIVE* Dinge so ewig behalten?',
    en: 'How can *NOMINATIVE* hold on to things forever?'
  },
  'distanceGraphicPage.question.bes.low': {
    de: 'Wie kann *NOMINATIVE* Dinge so einfach wegschmeißen – auch wenn sie vielleicht noch funktionieren?',
    en: 'How can *NOMINATIVE* just toss things out – even if they still work?'
  },
  'distanceGraphicPage.question.aut.high': {
    de: 'Warum lässt *NOMINATIVE* sich nicht helfen?',
    en: 'Why is *NOMINATIVE* so reticent to accept help?'
  },
  'distanceGraphicPage.question.aut.low': {
    de: 'Wie kann *NOMINATIVE* so eng mit Leuten sein?',
    en: 'How can *NOMINATIVE* be so close to others?'
  },
  'distanceGraphicPage.question.soz.high': {
    de: 'Wie schafft *NOMINATIVE* es, ständig „on tour“ zu sein?',
    en: 'How does *NOMINATIVE* manage to cultivate so many contacts?'
  },
  'distanceGraphicPage.question.soz.low': {
    de: 'Wieso zieht *NOMINATIVE* sich ständig zurück?',
    en: 'Why does *NOMINATIVE* keep pulling back or withdrawing?'
  },
  'distanceGraphicPage.question.pri.high': {
    de: 'Wie kann *NOMINATIVE* sich nur so pedantisch an Regeln halten?',
    en: 'How can *NOMINATIVE* be so pedantic about following the rules?'
  },
  'distanceGraphicPage.question.pri.low': {
    de: 'Wieso kann *NOMINATIVE* so sprunghaft sein – was heute gilt ist morgen vergessen?',
    en: 'How can *NOMINATIVE* be so erratic – and simply forget what mattered just a day ago?'
  },
  'distanceGraphicPage.question.sen.high': {
    de: 'Woher kommt dieses Engagement für andere?',
    en: 'Where does this commitment to others come from?'
  },
  'distanceGraphicPage.question.sen.low': {
    de: 'Woher kommt die Gleichgültigkeit gegenüber dem Leid in der Welt?',
    en: 'Where does this indifference to global suffering come from?'
  },
  'distanceGraphicPage.question.str.high': {
    de: 'Wieso schafft *NOMINATIVE* gerne Ordnung?',
    en: 'Why does *NOMINATIVE* like to create order?'
  },
  'distanceGraphicPage.question.str.low': {
    de: 'Wie kann *NOMINATIVE* im Chaos *ACCUSATIVE.N* Leben meistern?',
    en: 'How can *NOMINATIVE* cope with such chaos in *ACCUSATIVE.N* life?'
  },
  'distanceGraphicPage.question.sic.high': {
    de: 'Wie kann *NOMINATIVE* jemals Chancen ergreifen, wenn *NOMINATIVE* immer nur Risiken sieht?',
    en: 'How can *NOMINATIVE* take advantage of an opportunity when *NOMINATIVE* only ever sees risks?'
  },
  'distanceGraphicPage.question.sic.low': {
    de: 'Woher kommt die Gelassenheit, Risiken einzugehen?',
    en: 'Where does this relaxed ability to take risks come from?'
  },
  'distanceGraphicPage.question.rev.high': {
    de: 'Warum muss *NOMINATIVE* immer alles vergelten?',
    en: 'Why does *NOMINATIVE* always feel the need to seek retribution?'
  },
  'distanceGraphicPage.question.rev.low': {
    de: 'Wie kann *NOMINATIVE* Unrecht auf sich sitzen lassen?',
    en: 'How can *NOMINATIVE* simply ignore injustice?'
  },
  'distanceGraphicPage.question.bew.high': {
    de: 'Wie kann *NOMINATIVE* sich nur so auspowern?',
    en: 'How can *NOMINATIVE* exhaust themselves like that?'
  },
  'distanceGraphicPage.question.bew.low': {
    de: 'Wie kann *NOMINATIVE* nur so wenig Sport machen?',
    en: 'How can *NOMINATIVE* exercise so little?'
  },
  'distanceGraphicPage.question.ess.high': {
    de: 'Wie kann *NOMINATIVE* Essen so wichtig nehmen?',
    en: 'How can *NOMINATIVE* take food so seriously?'
  },
  'distanceGraphicPage.question.ess.low': {
    de: 'Wie schafft *NOMINATIVE* es, Essen ganz und gar zu vergessen?',
    en: 'How does *NOMINATIVE* simply forget to eat?'
  },
  'distanceGraphicPage.question.fam.high': {
    de: 'Wie kann *NOMINATIVE* nur so aufblühen, wenn es um die Familie geht?',
    en: 'How can *NOMINATIVE* gush like that when it comes to family matters?'
  },
  'distanceGraphicPage.question.fam.low': {
    de: 'Wie kann *NOMINATIVE* nur so wenig Zeit mit seiner Familie verbringen?',
    en: 'How can *NOMINATIVE* spend so little time with members of the family?'
  },
  'distanceGraphicPage.question.sin.high': {
    de: 'Wie kann sich bei *DATIVE* nur ständig alles um Sex drehen?',
    en: 'How can sex be so important to *DATIVE*?'
  },
  'distanceGraphicPage.question.sin.low': {
    de: 'Wieso ist *DATIVE* Erotik nicht wichtig?',
    en: 'Why doesn’t *NOMINATIVE* feel eroticism is important?'
  },
  'distanceGraphicPage.intro.text_1': {
    de: 'Wenn Du Dir diese oder ähnliche Fragen stellst, dann hast Du Dir bereits Gedanken über *NAME* gemacht. Wieso '
      + 'seid Ihr manchmal so verschieden? Hier erfährst Du, was wirklich dahintersteckt – welche Motive und Antreiber '
      + '*ACCUSATIVE.F* Emotionen auslösen und *ACCUSATIVE.N* Verhalten leiten.'
      + '<br><br>'
      + 'Die Unterschiedlichkeit von uns Menschen liegt an unserer Einzigartigkeit. Und das ist etwas Großartiges. '
      + 'Wir unterscheiden uns, weil wir unterschiedlichen Aspekte im Leben unterschiedliche Bedeutung beimessen. Und '
      + 'wir sind uns ähnlich, weil wir manchen Aspekten eine ähnlich hohe oder geringe Bedeutung beimessen.'
      + '<br><br>'
      + 'Deine Unterschiede und Gemeinsamkeiten mit *NAME* haben wir anhand der Motivausprägungen '
      + 'Eurer ID37 Persönlichkeitsprofile berechnet: Wir bezeichnen den Abstand aller Motive zueinander als '
      + 'Diversity-Index. Je höher dieser Index ist, desto weiter sind Eure Motive voneinander entfernt. <br>'
      + 'Euer Diversity-Index beträgt <strong>*DISTANCE*%</strong>.'
      + '<br><br>'
      + 'Nachfolgend findest Du das gesamte Spektrum: In welchen Motiven seid Ihr sehr unterschiedlich ausgeprägt (',
    en: 'If you’ve asked yourself these or similar questions, then you’ve already begun thinking about *NAME*. Why are '
      + 'you sometimes so different from each other? Here’s where you can learn something about what’s actually going '
      + 'on – about the motives and drivers that trigger *ACCUSATIVE.F* emotions and guide *ACCUSATIVE.N* behavior.'
      + '<br><br>'
      + 'The ways in which we differ are what make us unique as human beings. And that’s a great thing. We differ from '
      + 'each other because we attach different levels of importance to different aspects of life. And we are in '
      + 'certain ways similar because we attach a similar degree of importance to other aspects.'
      + '<br><br>'
      + 'Drawing on your ID37 personality profile and your motive expressions, we’ve calculated the ways in which you '
      + 'differ from and are similar to *NAME*: The combined distance between all motives is your Diversity Index '
      + 'score. The higher the figure, the greater the gap between your motive expressions. <br>'
      + 'Your Diversity Index score is <strong>*DISTANCE*%</strong>. '
      + '<br><br>'
      + 'The full spectrum is as follows: Where do you differ strongly in terms of your motive expressions ('
  },
  'distanceGraphicPage.intro.distantLink': {
    de: 'fern',
    en: 'far apart'
  },
  'distanceGraphicPage.intro.text_2': {
    de: ')? Auf welchen Motiven seid Ihr sehr ähnlich (',
    en: ')? Which motives show a strong similarity ('
  },
  'distanceGraphicPage.intro.closeLink': {
    de: 'nah',
    en: 'close'
  },
  'distanceGraphicPage.intro.text_3': {
    de: ')? Und auf welchen Motiven liegt Ihr gar nicht so weit auseinander (',
    en: ')? And which motives show only a moderate difference ('
  },
  'distanceGraphicPage.intro.moderateLink': {
    de: 'moderat',
    en: 'somewhat close'
  },
  'distanceGraphicPage.intro.text_4': {
    de: ')?',
    en: ')?'
  },
  'distanceGraphicPage.DISTANT.headline': {
    de: 'Warum ist *NAME* anders?',
    en: 'Why is *NAME* different?'
  },
  'distanceGraphicPage.DISTANT.text': {
    de: '*NAME* nimmt die Welt aus *DATIVE.F* Perspektive wahr - wie Du selbst natürlich auch. Die Motive, denen '
      + 'Ihr sehr unterschiedliche Bedeutung im Leben zuweist, sind zentrale Anhaltspunkte für die erfolgreiche '
      + 'Gestaltung Eurer Beziehung: Sie helfen dabei, die Ursachen für irritierendes Verhalten zu erkennen und zu '
      + 'verstehen. Sie ermöglichen es Euch, zu identifizieren, wo Ihr Euch ergänzen könnt und bieten Euch die '
      + 'Gelegenheit, fremde Welten zu entdecken und neue Perspektiven einzunehmen.',
    en: '*NAME* sees the world from *DATIVE.F* perspective – just as you do. The motives to which you ascribe very '
      + 'different levels of importance are central to the success of your relationship. Taking a closer look at them '
      + 'can help you identify and understand the drivers of what may seem like confusing or irritating behavior in '
      + 'others. This allows you to identify the areas in which you can complement each other and opens up avenues for '
      + 'you to explore unfamiliar territory as you look at things from a different perspective.'
  },
  'distanceGraphicPage.CLOSE.headline': {
    de: 'Was verbindet Dich mit *NAME*?',
    en: 'In which ways are you similar to *NAME*?'
  },
  'distanceGraphicPage.CLOSE.text': {
    de: 'Motive, die Du mit *NAME* sehr ähnlich ausgeprägt hast, sind spannend. Sie weisen darauf hin, dass Ihr '
      + 'diesen eine ähnliche Bedeutung zuweist. Diesbezüglich habt Ihr weniger Konfliktpotenzial.',
    en: 'Those motives for which you and *NAME* have similar expressions are interesting to explore. This suggests '
      + 'that you assign similar importance to them. These are the areas in which there is less potential for conflict '
      + 'between the two of you.'
  },
  'distanceGraphicPage.MODERATE.headline': {
    de: 'Wo gibt es geringfügige Unterschiede zu *NAME*?',
    en: 'Where do you show only minor differences from *NAME*?'
  },
  'distanceGraphicPage.MODERATE.text': {
    de: 'Es gibt Motive, bei denen unterscheidest Du Dich von *NAME* – allerdings nicht sehr stark. Es könnte sein, '
      + 'dass Ihr emotional anders empfindet oder recht ähnlich. Hier lohnt es sich, besonders genau hinzusehen. Auch '
      + 'hier lassen sich spannende Ansätze entdecken, um Eure Beziehung besser zu gestalten. Findet es heraus.',
    en: 'There are motives in which you differ from *NAME* – but not by much. It’s possible that you experience '
      + 'similar or different emotions with these motives, so it’s worth examining them a bit more closely. There '
      + 'are many compelling things to discover here that can help improve your relationship. Let’s take a closer look.'
  },
  'distanceGraphicPage.ALL.headline': {
    de: 'Wie „tickt“ *NAME* im Vergleich zu Dir?',
    en: 'How does *NAME* tick, compared to you?'
  },
  'distanceGraphicPage.ALL.text': {
    de: 'Hier kannst Du Dir einen Eindruck davon verschaffen, wo Du mit *NAME* bei jedem einzelnen Motiv stehst. Es '
      + 'ist wichtig, das Gesamtprofil vor Augen zu haben, denn bei der Betrachtung der individuellen Persönlichkeit '
      + 'spielen alle Ausprägungen eine Rolle. Mit etwas Übung findest Du hier Eure Handlungsanleitung für den Umgang '
      + 'miteinander.',
    en: 'Here you can get an idea of the extent to which you differ from *NAME* on each motive. When considering '
      + 'these results, it’s important to keep a person’s overall profile in mind because each expression of a motive '
      + 'plays a role in how we perceive an individual’s personality. Here are some exercises that will guide you in '
      + 'your interaction with each other.'
  },
  'distanceGraphicPage.button.distant': {
    de: 'Fern',
    en: 'Far apart'
  },
  'distanceGraphicPage.button.close': {
    de: 'Nah',
    en: 'Close'
  },
  'distanceGraphicPage.button.moderate': {
    de: 'Moderat',
    en: 'Somewhat close'
  },
  'distanceGraphicPage.button.all': {
    de: 'Gesamt',
    en: 'Overall'
  },
  'distanceGraphicPage.motive': {
    de: 'diesem Motiv ',
    en: 'This is the motive '
  },
  'distanceGraphicPage.motives': {
    de: 'diesen Motiven ',
    en: 'These are the motives '
  },
  'distanceGraphicPage.empty.DISTANT': {
    de: 'Es gibt Motive, da unterscheiden sich zwei Personen sehr stark voneinander. <br>'
      + 'Bei Dir und *NAME* ist dies jedoch nicht der Fall.',
    en: 'Two people can be very different in terms of their expression of certain motives. <br>'
      + 'However, this is not the case with you and *NAME*.'

  },
  'distanceGraphicPage.empty.MODERATE': {
    de: 'Es gibt Motive, da unterscheiden sich zwei Personen - allerdings nicht sehr stark. <br>'
      + 'Bei dir und *NAME* ist dies jedoch nicht der Fall.',
    en: 'Two people can show only moderate differences in terms of the expression of certain motives. <br>'
      + 'However, this is not the case with you and *NAME*.'

  },
  'distanceGraphicPage.empty.CLOSE': {
    de: 'Es gibt Motive, da sind sich zwei Personen sehr ähnlich.<br> '
      + 'Bei dir und *NAME* ist dies jedoch nicht der Fall.',
    en: 'Two people can be very similar in terms of their expression of certain motives.<br>'
      + 'However, this is not the case with you and *NAME*.'

  },
  /* DISTANT FILTER */
  'distantFilter.motive.headline': {
    de: 'Auf *MOTIVES* unterscheidest Du Dich stark von *NAME*',
    en: '*MOTIVES* where you and *NAME* differ significantly'
  },
  'distantFilter.motiveText.headline': {
    de: ' - Euer Abstand: ',
    en: ' - Your point difference: '
  },
  'distantFilter.neu.high': {
    de: 'Wissen und Erkenntnisgewinn spielen eine wichtige Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). '
      + '*NOMINATIVE.C* strebt nach intellektueller Tätigkeit und Herausforderung. Anders als Dich '
      + '(Dein Profilwert: *SCORE*), kann es *ACCUSATIVE* beispielsweise befriedigen, *ACCUSATIVE.M* Wissensdurst in '
      + 'unterschiedlichsten Themenfeldern zu stillen, egal wofür das Wissen nützlich ist. Anders als Dich kann '
      + 'Halbwissen *ACCUSATIVE* frustrieren.',
    en: 'Knowledge and gaining knowledge play an important role in *NAME*’s life (profile score: *SCORE_FR*). '
      + '*NOMINATIVE.C* likes to be intellectually engaged and challenged. Unlike you (your profile score: *SCORE*), '
      + '*ACCUSATIVE.M* thirst for knowledge can be satisfied by *ACCUSATIVE.M* search for knowledge in a broad range of '
      + 'topics, regardless of its relevance. Unlike you, incomplete knowledge can frustrate *ACCUSATIVE*.'
  },
  'distantFilter.neu.low': {
    de: 'Wissen und Erkenntnisgewinn spielen eine untergeordnete Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). '
      + '*NOMINATIVE.C* meidet die Beschäftigung mit intensiven intellektuellen Anstrengungen. Anders als Dir (Dein '
      + 'Profilwert: *SCORE*), macht es *DATIVE* Spaß, das Wissen direkt umzusetzen und Lösungen schnell anzugehen. '
      + 'Anders als bei Dir kommen Langeweile und Frust bei Details auf, die aus *DATIVE.F* Sicht nicht nützlich sind.',
    en: 'Knowledge and gaining knowledge play a minor role in *NAME*’s life (profile score: *SCORE_FR*). '
      + '*NOMINATIVE.C* avoids engaging in taxing intellectual activities. Unlike you (your profile score: *SCORE*), '
      + 'for *DATIVE* it’s fun to apply knowledge directly and quickly develop solutions. Unlike you, boredom and '
      + 'frustration come with having to deal with details which, from *DATIVE.F* perspective, are not useful.'
  },
  'distantFilter.neu.medium-high': {
    de: 'Wissen und Erkenntnisgewinn sind *NAME* (Profilwert: *SCORE_FR*) weniger wichtig als Dir. ' +
      'Anders als bei Dir. Mit einem Profilwert von *SCORE* spielt Erkenntnisgewinn eine wichtige Rolle in ' +
      'Deinem Leben. Du strebst nach intellektueller Tätigkeit und Herausforderung. Es kann Dich beispielsweise ' +
      'befriedigen, Deinen Wissensdurst zu stillen, egal wofür das Wissen nützlich ist. ' +
      'Halbwissen kann Dich frustrieren. *NAME* erlebt in Bezug auf Erkenntnisgewinn keine extremen Emotionen.',
    en: 'Knowledge and gaining knowledge are less important to *NAME* (profile score: *SCORE_FR*) than they are to '
      + 'you. This is where you differ. Your profile score of *SCORE* shows that gaining knowledge plays an important '
      + 'role in your life. You like to be intellectually engaged and challenged. For example, you may satisfy your '
      + 'thirst for knowledge by seeking it out, regardless of its relevance. Incomplete knowledge can frustrate you. '
      + '*NAME* does not experience extreme emotions with respect to gaining knowledge.'
  },
  'distantFilter.neu.medium-low': {
    de: 'Wissen und Erkenntnisgewinn  sind *NAME* (Profilwert: *SCORE_FR*) wichtiger als Dir. ' +
      'Mit einem Profilwert von *SCORE* spielt Erkenntnisgewinn in Deinem Leben eine untergeordnete Rolle. ' +
      'Du meidest die Beschäftigung mit intensiven intellektuellen Anstrengungen. Dir macht es Spaß, das Wissen ' +
      'direkt umzusetzen und Lösungen schnell anzugehen. Langeweile und Frust kommen bei Details auf, die aus Deiner ' +
      'Sicht nicht nützlich sind. *NAME* erlebt in Bezug auf Erkenntnisgewinn keine extremen Emotionen.',
    en: 'Knowledge and gaining knowledge are more important to *NAME* (profile score: *SCORE_FR*) than they are to '
      + 'you. Your profile score of *SCORE* shows that gaining knowledge plays a minor role in your life. You avoid '
      + 'engaging in taxing intellectual activities. You enjoy applying knowledge directly and quickly developing '
      + 'solutions. Boredom and frustration come with having to deal with details which, from your perspective, are '
      + 'irrelevant. *NAME* does not experience extreme emotions with respect to gaining knowledge.'
  },
  'distantFilter.san.high': {
    de: 'Anerkennung durch andere ist für *NAME* sehr wichtig im Leben (Profilwert: *SCORE_FR*). Anders als bei Dir ' +
      '(Dein Profilwert: *SCORE*), zielt *ACCUSATIVE.N* Verhalten zielt darauf ab, Bestätigung zu erhalten und ' +
      'Kritik, Ablehnung oder Zurückweisung zu vermeiden. Es macht *DATIVE* in der Regel Freude, es allen ' +
      'rechtmachen zu können. *NOMINATIVE.C* hat in der Regel Selbstzweifel, wenn Kritik geübt wird.',
    en: 'Being accepted by others is very important to *NAME* (profile score: *SCORE_FR*). Unlike you '
      + '(your profile score: *SCORE*), *ACCUSATIVE.N* behavior is geared toward receiving affirmation from others and '
      + 'avoiding criticism or rejection. As a rule, *NOMINATIVE* likes being able to please everybody. *NOMINATIVE.C* '
      + 'usually experiences feelings of self-doubt when criticized.'
  },
  'distantFilter.san.low': {
    de: 'Bestätigung und Anerkennung durch andere sind *NAME* nicht wichtig im Leben (Profilwert: *SCORE_FR*). ' +
      '*NOMINATIVE.N.C* Verhalten richtet sich nicht primär danach, was andere von *DATIVE* halten oder beurteilen. ' +
      'Anders als Du (Dein Profilwert: *SCORE*), freut *NOMINATIVE* sich beispielsweise über herausfordernde ' +
      'Aufgaben und hat in der Regel keine Angst zu versagen. Misserfolge irritieren *ACCUSATIVE* gegebenenfalls, ' +
      'verursachen aber anders als bei Dir keine Selbstzweifel.',
    en: 'Affirmation and acceptance by others is not important to *NAME* (profile score: *SCORE_FR*). '
      + '*NOMINATIVE.N.C* behavior is not geared toward how others judge or see *DATIVE*. Unlike you '
      + '(your profile score: *SCORE*), *NOMINATIVE* enjoys challenging tasks and is usually not afraid of failure. '
      + 'Failures may bother *ACCUSATIVE*, but unlike you, they don\'t provoke self-doubt.'
  },
  'distantFilter.san.medium-high': {
    de: 'Bestätigung und Anerkennung durch andere haben für *NAME* (Profilwert: *SCORE_FR*) weniger Bedeutung ' +
      'als für Dich. Mit einem Profilwert von *SCORE* ist Dir Anerkennung sehr wichtig im Leben. Dein Verhalten ' +
      'zielt darauf ab, Bestätigung zu erhalten und Kritik, Ablehnung oder Zurückweisung zu vermeiden. ' +
      'Dir macht es in der Regel Freude, wenn Du es allen rechtmachen kannst. Du hast beispielsweise Selbstzweifel, ' +
      'wenn Kritik geübt wird. *NAME* braucht in bestimmten Situationen auch Anerkennung, in anderen jedoch nicht.',
    en: 'Affirmation and acceptance by others are less important to *NAME* (profile score: *SCORE_FR*) than they are '
      + 'to you. Your profile score of *SCORE* shows that acceptance is very important to you in life. Your behavior '
      + 'is geared toward receiving affirmation from others and avoiding criticism or rejection. You usually enjoy '
      + 'being able to please everybody. You may, for example, experience self-doubt when criticized. *NAME* also '
      + 'needs to feel accepted in certain situations, but not in others.'
  },
  'distantFilter.san.medium-low': {
    de: 'Bestätigung und Anerkennung durch andere haben für *NAME* (Profilwert: *SCORE_FR*) mehr Bedeutung im Leben ' +
      'als für Dich. Mit einem Profilwert von *SCORE* ist dir Anerkennung nicht wichtig. Du freust dich ' +
      'beispielsweise über herausfordernde Aufgaben und hast in der Regel keine Angst zu versagen. Misserfolge ' +
      'irritieren Dich gegebenenfalls, verursachen aber keine Selbstzweifel. ' +
      '*NAME* braucht in bestimmten Situationen auch Anerkennung, in anderen jedoch nicht.',
    en: 'Affirmation and acceptance by others are more important to *NAME* (profile score: *SCORE_FR*) than they are '
      + 'to you. Your profile score of *SCORE* shows that acceptance is not important to you. For example, you enjoy '
      + 'challenging tasks and are usually not afraid of failure. Failures may bother you, but they don’t provoke '
      + 'self-doubt. *NAME* also needs to feel accepted in certain situations, but not in others.'
  },
  'distantFilter.ein.high': {
    de: 'Einfluss und Kontrolle auszuüben spielt im Leben von *NAME* eine große Rolle (Profilwert: *SCORE_FR*). ' +
      'Anders als Du (Dein Profilwert: *SCORE*), strebt *NOMINATIVE* danach, andere zu überzeugen, Situationen zu ' +
      'lenken oder Verantwortung zu übernehmen. *NOMINATIVE.C* ist in der Regel zufrieden, wenn *NOMINATIVE* alles ' +
      'im Griff hat. Anders als Du fühlt *NOMINATIVE* sich hilflos, wenn *NOMINATIVE* die Kontrolle über einen ' +
      'längeren Zeitraum verliert.',
    en: 'Being able to exercise influence and control plays an important role in *NAME*’s life '
      + '(profile score: *SCORE_FR*). Unlike you (your profile score: *SCORE*), *NOMINATIVE* strives to persuade '
      + 'others, manage situations or take responsibility for things. *NOMINATIVE.C* usually feels satisfied when '
      + '*NOMINATIVE* has everything under control. Unlike you, *NOMINATIVE* feels helpless when *NOMINATIVE* does not '
      + 'have everything under control for an extended period of time.'
  },
  'distantFilter.ein.low': {
    de: 'Einfluss auszuüben ist *NAME* nicht wichtig im Leben (Profilwert: *SCORE_FR*). Anders als ' +
      'Du (Dein Profilwert: *SCORE*), legt *NOMINATIVE* keinen Wert darauf, Menschen und Vorgänge zu beeinflussen ' +
      'oder Entscheidungen für andere zu treffen. *NOMINATIVE.C* freut sich in der Regel, anderen freie Hand zu ' +
      'lassen und empfindet es anders als Du als Belastung, wenn *NOMINATIVE* alles alleine entscheiden muss.',
    en: 'Exerting influence is not important to *NAME* (profile score: *SCORE_FR*). Unlike you '
      + '(your profile score: *SCORE*), *NOMINATIVE* does not attach any importance to influencing people and '
      + 'processes or making decisions for others. *NOMINATIVE.C* is usually happy to give other people free rein '
      + 'and, unlike you, finds it burdensome when *NOMINATIVE* has to decide everything alone.'
  },
  'distantFilter.ein.medium-high': {
    de: 'Kontrolle und Einfluss spielen im Leben von *NAME* (Profilwert: *SCORE_FR*) eine geringere Rolle als bei ' +
      'Dir. Mit einem Profilwert von *SCORE* ist es Dir sehr wichtig, Einfluss auszuüben. Du strebst danach, Dein ' +
      'Lebensumfeld zu kontrollieren, andere zu überzeugen, Situationen zu ' +
      'lenken oder Verantwortung zu übernehmen. Du bist in der Regel zufrieden, wenn Du alles im Griff hast. Du ' +
      'fühlst dich hilflos, wenn Du die Kontrolle über einen längeren Zeitraum verlierst. ' +
      '*NAME* möchte manchmal Einfluss ausüben, manchmal jedoch nicht.',
    en: 'Being able to exercise influence and control plays a less important role in *NAME*’s life '
      + '(profile score: *SCORE_FR*) than it does in yours. Your profile score of *SCORE* shows that being able to '
      + 'exert influence is very important to you. You make an effort to control your environment, persuade others, '
      + 'manage situations or take responsibility for things. As a rule, you are satisfied when you have everything '
      + 'under control. You feel helpless when you do not have everything under control over a longer period of time. '
      + 'At times, *NAME* would like to exert influence, other times not.'
  },
  'distantFilter.ein.medium-low': {
    de: 'Kontrolle und Einfluss spielen im Leben von *NAME* (Profilwert: *SCORE_FR*) eine größere Rolle als bei Dir. ' +
      'Mit einem Profilwert von *SCORE* ist es Dir nicht wichtig, Einfluss auszuüben. Du legst keinen Wert darauf, ' +
      'Menschen und Vorgänge zu beeinflussen oder Entscheidungen für andere zu treffen. Du freust sich in der Regel, ' +
      'anderen freie Hand zu lassen und empfindest es meist als Belastung, wenn Du alles alleine entscheiden muss. ' +
      '*NAME* möchte manchmal Einfluss ausüben, manchmal jedoch nicht.',
    en: 'Being able to exercise influence and control plays a more important role in *NAME*’s life '
      + '(profile score: *SCORE_FR*) than it does in yours. Your profile score of *SCORE* shows that being able to '
      + 'exert influence is not very important to you. You do not attach any importance to influencing people and '
      + 'processes or making decisions for others. As a rule, you are happy to give other people free rein and '
      + 'generally find it burdensome when you have to decide everything alone. At times, *NAME* wants to exert '
      + 'influence, other times not.'
  },
  'distantFilter.sta.high': {
    de: 'Für *NAME* spielen *NOMINATIVE.M* Ruf und die gesellschaftliche Stellung eine besondere Rolle im Leben ' +
      '(Profilwert: *SCORE_FR*). Anders als Du (Dein Profilwert: *SCORE*), legt *NOMINATIVE* großen Wert darauf, ' +
      'sich von anderen abzuheben und dass dies wahrgenommen wird. *NOMINATIVE.C* ist, anders als Du, glücklich ' +
      'über Vorrechte und Privilegien – insbesondere, wenn sie *DATIVE* zugesprochen werden. Anders als Dich ' +
      'ärgert *ACCUSATIVE* Gleichbehandlung.',
    en: 'For *NAME*, *NOMINATIVE.M* reputation and social standing play an important role in life (profile score: *SCORE_FR*). '
      + 'Unlike you (your profile score: *SCORE*), *NOMINATIVE* attaches great importance to standing out and having '
      + 'this be acknowledged. *NOMINATIVE.C*, unlike you, is happy to benefit from prerogatives and privileges – '
      + 'especially when they are granted to *DATIVE*. Unlike you, *NOMINATIVE* is angered by equality oriented treatment.'
  },
  'distantFilter.sta.low': {
    de: 'Für *NAME* spielen Ansehen und gesellschaftliche Stellung eine untergeordnete Rolle im Leben ' +
      '(Profilwert: *SCORE_FR*). Anders als Du (Dein Profilwert: *SCORE*), legt *NOMINATIVE* keinen Wert darauf, ' +
      'sich von anderen abzuheben und lehnt Privilegien ab. *NOMINATIVE.C* ist zufrieden, wenn alle auf Augenhöhe ' +
      'sind. Anders als Du lehnt *NOMINATIVE* es in der Regel ab, bevorzugt behandelt zu werden.',
    en: 'For *NAME*, *NOMINATIVE.M* prestige and social standing play a minor role in life '
      + '(profile score: *SCORE_FR*). Unlike you (your profile score: *SCORE*), *NOMINATIVE* attaches no importance to '
      + 'standing out and having this be acknowledged. *NOMINATIVE.C* is satisfied when everyone is at eye level. '
      + 'Unlike you, *NOMINATIVE* usually dislikes being given preferential treatment.'
  },
  'distantFilter.sta.medium-high': {
    de: 'Ansehen und eine elitäre Stellung in der Gesellschaft spielen für *NAME* (Profilwert: *SCORE_FR*) eine ' +
      'geringere Rolle als für Dich. Mit einem Profilwert von *SCORE* sind Dir Dein Ruf und Deine ' +
      'gesellschaftliche Stellung wichtig. Du legst großen Wert darauf, Dich von anderen abzuheben und dass dies ' +
      'wahrgenommen wird. Du bist glücklich über Vorrechte und Privilegien – insbesondere, wenn sie Dir ' +
      'zugesprochen werden. Es ärgert Dich in der Regel, wenn Du wie alle anderen behandelt wirst. Für *NAME* sind ' +
      'Ansehen und eine besondere Stellung in manchen Situationen wichtig, in anderen jedoch nicht.',
    en: 'Prestige and prominence in society play a less important role for *NAME* (profile score: *SCORE_FR*) than '
      + 'they do for you. Your profile score of *SCORE* shows that your reputation and social standing are important '
      + 'to you. You attach great importance to being able to distinguish yourself from others and having this be '
      + 'publicly acknowledged. You are happy to benefit from prerogatives and privileges – especially when they are '
      + 'granted to you specifically. You generally dislike being treated like everyone else. For *NAME*, having '
      + 'prestige and social standing are important in some situations, but not in others.'
  },
  'distantFilter.sta.medium-low': {
    de: 'Ansehen und eine elitäre Stellung in der Gesellschaft spielen für *NAME* (Profilwert: *SCORE_FR*) eine ' +
      'größere Rolle als für Dich. Mit einem Profilwert von *SCORE* sind Dir Dein Ansehen und Deine ' +
      'gesellschaftliche Stellung nicht wichtig. Du legst keinen Wert darauf, Dich von ' +
      'anderen abzuheben. Privilegien für einige wenige lehnst Du ab. Du bist zufrieden, wenn alle auf Augenhöhe ' +
      'sind. Du lehnst es in der Rege ab bevorzugt behandelt zu werden. Für *NAME* sind Ansehen und eine besondere ' +
      'Stellung in manchen Situationen wichtig, in anderen jedoch nicht.',
    en: 'Prestige and prominence in society are more important to *NAME* (profile score: *SCORE_FR*) than they are to '
      + 'you. Your profile score of *SCORE* shows that having prestige and social standing are not important to you. '
      + 'You do not attach importance to being able to distinguish yourself from others. You dislike it when '
      + 'privileges are afforded to only a handful of people. You are satisfied with everyone being at eye level. As '
      + 'a rule, you dislike being given preferential treatment. For *NAME*, having prestige and social standing are '
      + 'important in some situations, but not in others.'
  },
  'distantFilter.bes.high': {
    de: 'Materieller Besitz spielt eine wichtige Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). Anders als Dir ' +
      '(Dein Profilwert: *SCORE*), ist es *DATIVE* wichtig, Vorräte anzusammeln, anzusparen oder aufzubauen und ' +
      'Verschwendung zu vermeiden. *NOMINATIVE.C* ist beispielsweise zufrieden, wenn *NOMINATIVE* alle Besitztümer ' +
      'um sich weiß. *NOMINATIVE.C* kann sich anders als Du meist nur schwer von Dingen trennen.',
    en: 'Material possessions play an important role in *NAME*’s life (profile score: *SCORE_FR*). '
      + 'Unlike you (your profile score: *SCORE*), it is important to *DATIVE* to collect, save, or accumulate assets '
      + 'and avoid waste. For example, *NOMINATIVE* is satisfied when surrounded by all *NOMINATIVE.M* possessions. '
      + 'Unlike you, *NOMINATIVE* generally has a hard time parting with things.'
  },
  'distantFilter.bes.low': {
    de: 'Materieller Besitz spielt eine untergeordnete Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). Anders ' +
      'als Du (Dein Profilwert: *SCORE*), neigt *NOMINATIVE* dazu, Vorräte zu verbrauchen und Geld auszugeben. Es ' +
      'ist *DATIVE* nicht wichtig, Besitz anzuhäufen. Es macht *ACCUSATIVE* in der Regel glücklich, sich von ' +
      'überflüssigem Ballast zu trennen. Anders als Dich kann es *ACCUSATIVE* beispielsweise irritieren, wenn ' +
      'alles auf „Heller und Pfennig“ abgerechnet wird.',
    en: 'Material possessions play a minor role in *NAME*’s life (profile score: *SCORE_FR*). Unlike you '
      + '(your profile score: *SCORE*), *NOMINATIVE* tends to consume resources and spend money. Accumulating '
      + 'possessions is not important to *DATIVE*. It usually makes *ACCUSATIVE* happy to part with excess things. '
      + 'Unlike you, for example, it may bother *ACCUSATIVE* to penny-pinch.'
  },
  'distantFilter.bes.medium-high': {
    de: 'Vorräte und materieller Besitz sind für *NAME* (Profilwert: *SCORE_FR*) weniger wichtig als für Dich. Mit ' +
      'einem Profilwert von *SCORE* spielt materieller Besitz eine wichtige Rolle in Deinem Leben. Es ist Dir ' +
      'wichtig, Vermögen anzusammeln, anzusparen oder aufzubauen. Du willst Verschwendung vermeiden. Du bist ' +
      'beispielsweise zufrieden, wenn Du alle Besitztümer um Dich weißt. Meist kannst Du Dich nur schwer von Dingen ' +
      'trennen. Für *NAME* sind Vorräte und materieller Besitz manchmal wichtig, manchmal jedoch nicht.',
    en: 'Resources and material possessions are less important to *NAME* (profile score: *SCORE_FR*) than they are to '
      + 'you. Your profile score of *SCORE* shows that material possessions play an important role in your life. It is '
      + 'important to you to collect, save or accumulate assets. You want to avoid waste. For example, you are '
      + 'satisfied when you are surrounded by your possessions. You generally find it hard to part with things. For '
      + '*NAME*, resources and material possessions are sometimes important, other times not.'
  },
  'distantFilter.bes.medium-low': {
    de: 'Vorräte und materieller Besitz sind für *NAME* (Profilwert: *SCORE_FR*) wichtiger als für Dich. Mit einem ' +
      'Profilwert von *SCORE* spielt materieller Besitz eine untergeordnete Rolle in Deinem Leben. Du neigst dazu, ' +
      'Vorräte zu verbrauchen und Geld auszugeben. Besitz anzuhäufen ist Dir nicht wichtig. Es macht Dich in der ' +
      'Regel glücklich, Dich von überflüssigem Ballast zu trennen. Es kann Dich irritieren, wenn alles auf ' +
      '„Heller und Pfennig“ abgerechnet wird. Für *NAME* sind Vorräte und materieller Besitz manchmal wichtig, ' +
      'manchmal jedoch nicht.',
    en: 'Resources and material possessions are more important to *NAME* (profile score: *SCORE_FR*) than they are to '
      + 'you. Your profile score of *SCORE* shows that material possessions play a minor role in your life. You tend '
      + 'to consume your resources and spend money. Accumulating possessions is not important to you. It usually makes '
      + 'you happy to part with excess things. Penny-pinching can bother you. For *NAME*, resources and material '
      + 'possessions are sometimes important, other times not.'
  },
  'distantFilter.aut.high': {
    de: 'Unabhängigkeit ist für *NAME* sehr wichtig im Leben (Profilwert: *SCORE_FR*). Anders als Du ' +
      '(Dein Profilwert: *SCORE*), bevorzugt *NOMINATIVE* es, *ACCUSATIVE.N* Leben eigenständig zu gestalten und ' +
      'an den eigenen Maßstäben zu orientieren. *NOMINATIVE.C* verlässt sich lieber auf sich statt auf andere. Es ' +
      'bedeutet beispielsweise Freiheit für *ACCUSATIVE*, sein eigenes Ding machen zu können. *NOMINATIVE.C* ' +
      'empfindet die Nähe zu anderen meist als einengend – anders als Du.',
    en: 'Independence is very important in life for *NAME* (profile score: *SCORE_FR*). Unlike you '
      + '(your profile score: *SCORE*), *NOMINATIVE* prefers to live *ACCUSATIVE.N* life independently, according to '
      + 'their own standards. *NOMINATIVE.C* prefers self-reliance. For example, being able to act independently can '
      + 'imply freedom to *ACCUSATIVE*. Unlike you, *NOMINATIVE* usually feels boxed in by emotional ties to others.'
  },
  'distantFilter.aut.low': {
    de: 'Unabhängigkeit spielt für *NAME* eine untergeordnete Rolle im Leben (Profilwert: *SCORE_FR*). Anders als ' +
      'Du (Dein Profilwert: *SCORE*), legt *NOMINATIVE* wenig Wert auf Eigenständigkeit, akzeptiert Einflüsse ' +
      'anderer und ist offen für emotionale Nähe. *NOMINATIVE.C* fühlt sich in der Regel geborgen, wenn sich andere ' +
      'um *ACCUSATIVE* kümmern und fürchtet anders als Du Ausgrenzung.',
    en: 'Independence plays a minor role in *NAME*’s life (profile score: *SCORE_FR*). Unlike you '
      + '(your profile score: *SCORE*), *NOMINATIVE* attaches little importance to autonomy, accepts the influence '
      + 'of others and is open to establishing emotional ties. *NOMINATIVE.C* usually feels secure when others take '
      + 'care of *ACCUSATIVE* and, unlike you, fears being excluded.'
  },
  'distantFilter.aut.medium-high': {
    de: 'Unabhängigkeit ist *NAME* (Profilwert: *SCORE_FR*) weniger wichtig als Dir. Mit einem Profilwert von ' +
      '*SCORE* ist Dir Unabhängigkeit sehr wichtig im Leben. Du bevorzugst es, Dein Leben eigenständig zu gestalten ' +
      'und an Deinen eigenen Maßstäben zu orientieren. Du verlässt Dich lieber auf Dich statt auf andere. Für Dich ' +
      'ist es Freiheit, wenn Du Dein eigenes Ding machen kannst. Du empfindest die Nähe zu anderen meist als ' +
      'einengend. Es gibt Situationen, in denen *NAME* Unabhängigkeit auch wichtig ist, manchmal aber auch nicht.',
    en: 'Independence is less important to *NAME* (profile score: *SCORE_FR*) than it is to you. Your profile score of '
      + '*SCORE* shows that independence is very important to you in life. You prefer to live your life independently '
      + 'and to follow your own standards. You rely on yourself rather than others. You experience being able to do '
      + 'your own thing as a form of freedom. You often feel boxed in by emotional ties to others. *NAME* also finds '
      + 'independence in some situations important, less so in others.'
  },
  'distantFilter.aut.medium-low': {
    de: 'Unabhängigkeit ist *NAME* (Profilwert: *SCORE_FR*) wichtiger als Dir. Mit einem Profilwert von *SCORE* ' +
      'spielt Unabhängigkeit eine untergeordnete Rolle in Deinem Leben. Du legst wenig Wert auf Eigenständigkeit, ' +
      'akzeptierst Einflüsse anderer und bist offen für emotionale Nähe. Du fühlst Dich in der Regel geborgen, ' +
      'wenn sich andere um Dich kümmern und fürchtest Ausgrenzung. ' +
      'Es gibt Situationen, in denen *NAME* Unabhängigkeit auch wichtig ist, manchmal aber auch nicht.',
    en: 'Independence is more important to *NAME* (profile score: *SCORE_FR*) than it is to you. Your profile score of '
      + '*SCORE* shows that independence plays a minor role in your life. You do not attach considerable importance to '
      + 'autonomy, are accepting of the influence of others and are open to establishing emotional ties. You generally '
      + 'feel secure when others care for you and fear being excluded. *NAME* also finds independence in some '
      + 'situations important, less so in others.'
  },
  'distantFilter.soz.high': {
    de: 'Die Gesellschaft von und mit anderen ist *NAME* wichtig im Leben (Profilwert: *SCORE_FR*). Anders als Du ' +
      '(Dein Profilwert: *SCORE*), strebt *NOMINATIVE* danach, Zeit mit anderen Menschen zu verbringen. Soziale ' +
      'Kontakte empfindet *NOMINATIVE* als eine Bereicherung für *ACCUSATIVE.N* Leben. Es ist *DATIVE* wichtig, ' +
      'Spaß mit anderen zu erleben. *NOMINATIVE.C* will nicht gerne alleine sein.',
    en: 'For *NAME*, being with others is an important part of life (profile score: *SCORE_FR*). Unlike you '
      + '(your profile score: *SCORE*), *NOMINATIVE* makes an effort to spend time with other people. *NOMINATIVE.C* '
      + 'finds it rewarding and enriching to engage in social participation. It is important to *DATIVE* to have fun '
      + 'with other people. *NOMINATIVE.C* doesn\'t like to be alone.'
  },
  'distantFilter.soz.low': {
    de: 'Soziale Kontakte spielen im Leben von *NAME* eine untergeordnete Rolle (Profilwert: *SCORE_FR*). Anders als ' +
      'Du (Dein Profilwert: *SCORE*), verspürt *NOMINATIVE* nur selten das Bedürfnis, Zeit mit anderen zu ' +
      'verbringen. Partys und Networking Events vermeidet *NOMINATIVE* häufig. *NOMINATIVE.C* freut sich über die ' +
      'Zeit mit sich alleine und findet es anders als Du meist anstrengend unter Menschen zu sein.',
    en: 'Social participation plays a minor role in *NAME*’s life (profile score: *SCORE_FR*). Unlike you '
      + '(your profile score: *SCORE*), *NOMINATIVE* rarely feels the need to spend time with others. *NOMINATIVE.C* '
      + 'often avoids parties and networking events. *NOMINATIVE.C* enjoys being alone and, unlike you, generally '
      + 'finds it taxing to be around other people.'
  },
  'distantFilter.soz.medium-high': {
    de: 'Soziale Kontakte und Geselligkeit spielen für *NAME* (Profilwert: *SCORE_FR*) eine geringere Rolle als für ' +
      'Dich. Mit einem Profilwert von *SCORE* ist Dir die Gesellschaft von und mit anderen wichtig im Leben. Du ' +
      'strebst danach, Zeit mit anderen Menschen zu verbringen. Soziale Kontakte empfindest Du als eine ' +
      'Bereicherung für Dein Leben. Es ist Dir wichtig, Spaß mit anderen zu erleben. Vereinsamung fürchtest Du.' +
      '*NAME* verspürt manchmal das Bedürfnis, Zeit mit anderen zu verbringen, manchmal jedoch nicht. *NOMINATIVE.C* ' +
      'ist diesbezüglich nicht extrem ausgeprägt.',
    en: 'Social participation and the company of others play a less important role in life for *NAME* '
      + '(profile score: *SCORE_FR*) than they do for you. Your profile score of *SCORE* shows that being with other '
      + 'people is important to you. You make an effort to spend time with other people. You regard social '
      + 'participation as an enrichment to your life. It is important to you to have fun with other people. You fear '
      + 'loneliness. At times, *NAME* feels the need to spend time with others, at other times not. *NOMINATIVE.C* '
      + 'does not have a particularly strong expression of this motive.'
  },
  'distantFilter.soz.medium-low': {
    de: 'Soziale Kontakte und Geselligkeit spielen für *NAME* (Profilwert: *SCORE_FR*) eine größere Rolle als für ' +
      'Dich. Mit einem Profilwert von *SCORE* spielen soziale Kontakte in Deinem Leben eine untergeordnete Rolle. Du ' +
      'verspürst nur selten das Bedürfnis, Zeit mit anderen zu verbringen. Partys und Networking Events vermeidest ' +
      'Du häufig. Du freust Dich über die Zeit mit Dir alleine und findest es meist anstrengend unter Menschen zu ' +
      'sein. *NAME* verspürt manchmal das Bedürfnis, Zeit mit anderen zu verbringen, manchmal jedoch nicht. ' +
      '*NOMINATIVE.C* ist diesbezüglich nicht extrem ausgeprägt.',
    en: 'Social participation and the company of others play are more important to *NAME* (profile score: *SCORE_FR*) '
      + 'than they are to you. Your profile score of *SCORE* shows that social participation plays a minor role in '
      + 'your life. You rarely feel the need to spend time with others. You often avoid parties and networking events. '
      + 'You enjoy being alone and usually find it taxing to be around other people. At times, *NAME* feels the need '
      + 'to spend time with others, at other times not. *NOMINATIVE.C* does not have a particularly strong expression '
      + 'of this motive.'
  },
  'distantFilter.pri.high': {
    de: '*NAME* misst Regeln, Prinzipien oder Gesetzen einen hohen Wert bei (Profilwert: *SCORE_FR*). Anders als Du ' +
      '(Dein Profilwert: *SCORE*), ist *NOMINATIVE* bestrebt, diese zu befolgen, auch wenn es für *ACCUSATIVE* ' +
      'persönliche Einschränkungen bedeutet. *NOMINATIVE.C* ist stolz auf die eigene Loyalität und ist anders als Du ' +
      'beschämt, wenn *NOMINATIVE* mal nicht ganz die Wahrheit gesagt hat.',
    en: '*NAME* attaches considerable importance to rules, principles and laws (profile score: *SCORE_FR*). Unlike you '
      + '(your profile score: *SCORE*), *NOMINATIVE* tries to abide by them, even if this behavior involves personal '
      + 'disadvantages or restrictions for *ACCUSATIVE*. *NOMINATIVE.C* takes pride in demonstrating loyalty and, '
      + 'unlike you, feels a sense of shame when *NOMINATIVE* isn’t fully honest.'
  },
  'distantFilter.pri.low': {
    de: 'Gesellschaftliche Erwartungen und Normen spielen für *NAME* eine untergeordnete Rolle ' +
      '(Profilwert: *SCORE_FR*). Anders als Du (Dein Profilwert: *SCORE*), orientiert *NOMINATIVE* sich nicht an ' +
      'Regeln - insbesondere nicht, wenn sie *ACCUSATIVE* einschränken. *NOMINATIVE.C* empfindet es als Freiheit, ' +
      'eine eigene Interpretation von Regeln leben zu können und ist anders als Du frustriert, wenn *NOMINATIVE* ' +
      'diese Flexibilität über eine längere Zeit nicht leben kann.',
    en: 'For *NAME*, social expectations and norms play a minor role in life (profile score: *SCORE_FR*). Unlike you '
      + '(your profile score: *SCORE*), *NOMINATIVE* doesn’t necessarily follow the rules – especially when they place '
      + 'constraints on *ACCUSATIVE*. *NOMINATIVE.C* experiences a sense of freedom with being able to live life '
      + 'according to their interpretation of the rules and, unlike you, is frustrated if *NOMINATIVE* is restrained '
      + 'from exercising this flexibility for an extended period of time. '
  },
  'distantFilter.pri.medium-high': {
    de: 'Konformität mit sozialen Normen und Regeln ist für *NAME* (Profilwert: *SCORE_FR*) weniger wichtig als für ' +
      'Dich. Mit einem Profilwert von *SCORE* misst Du Regeln, Prinzipien oder Gesetzen einen hohen Wert bei. Du ' +
      'bist bestrebt, sie zu befolgen, auch wenn dies für Dich persönliche Einschränkungen bedeutet. Du bist stolz ' +
      'auf Deine Loyalität und ist beispielsweise beschämt, wenn Du mal nicht ganz die Wahrheit gesagt hast. ' +
      'Für *NAME* sind soziale Normen in manchen Situationen von Bedeutung, in manchen aber auch nicht.',
    en: 'Conforming to social norms and rules is less important to *NAME* (profile score: *SCORE_FR*) than it is to '
      + 'you. Your profile score of *SCORE* shows that you attach considerable importance to rules, principles and '
      + 'laws. You do your best to follow them, even if this behavior involves disadvantages or restrictions for you '
      + 'personally. You pride yourself on your loyalty and experience shame, for example, when you aren’t fully '
      + 'honest. For *NAME*, social norms are in some situations relevant, and in others not.'
  },
  'distantFilter.pri.medium-low': {
    de: 'Konformität mit sozialen Normen und Regeln ist für *NAME* (Profilwert: *SCORE_FR*) wichtiger als für ' +
      'Dich. Mit einem Profilwert von *SCORE* spielen gesellschaftliche Erwartungen für Dich eine untergeordnete ' +
      'Rolle. Du orientierst Dich nicht an Regeln, insbesondere nicht, wenn sie Dich einschränken. Du empfindest es ' +
      'als Freiheit, Deine eigene Interpretation von Regeln leben zu können. Du bist beispielsweise frustriert, wenn ' +
      'Du diese Flexibilität längere Zeit nicht leben kannst. ' +
      'Für *NAME* sind soziale Normen in manchen Situationen von Bedeutung, in manchen aber auch nicht.',
    en: 'Conforming to social norms and rules is more important to *NAME* (profile score: *SCORE_FR*) than it is to '
      + 'you. Your profile score of *SCORE* shows that social norms play a minor role for you. Following the rules '
      + 'isn’t particularly important to you, especially when they constrain you. You experience a sense of freedom by '
      + 'being able to interpret rules more flexibly. You are, for example, frustrated by not being able to exercise '
      + 'this flexibility for an extended period of time. For *NAME*, social norms are in some situations relevant, '
      + 'and in others not.'
  },
  'distantFilter.sen.high': {
    de: 'Soziale Gerechtigkeit spielt eine besondere Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). Anders als ' +
      'Du (Dein Profilwert: *SCORE*), sorgt *NOMINATIVE* sich um das Wohl anderer Menschen und setzt sich häufig ' +
      'aktiv dafür ein, Not und Benachteiligung zu lindern. *NOMINATIVE.C* empfindet tiefe Zufriedenheit geholfen zu ' +
      'haben und ist anders als Du verzweifelt, wenn *NOMINATIVE* nicht helfen kann.',
    en: 'Issues of social justice play an important role in *NAME*’s life (profile score: *SCORE_FR*). Unlike you '
      + '(your profile score: *SCORE*), *NOMINATIVE* is concerned about the welfare of others and often campaigns '
      + 'actively to alleviate hardship and disadvantage. *NOMINATIVE.C* experiences a deep sense of satisfaction at '
      + 'having been able to help others and, unlike you, feels despair when *NOMINATIVE* can’t do anything to help.'
  },
  'distantFilter.sen.low': {
    de: 'Soziale Gerechtigkeit spielt eine untergeordnete Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). Anders ' +
      'als Du (Dein Profilwert: *SCORE*), sieht *NOMINATIVE* soziales Engagement nicht als *ACCUSATIVE.F* ' +
      'persönliche Aufgabe an. *NOMINATIVE.C* ist zufrieden über den eigenen Realismus und hat anders als Du meist ' +
      'wenig Verständnis dafür, dass Menschen sich nicht selbst helfen.',
    en: 'Issues of social justice play a minor role in *NAME*’s life (profile score: *SCORE_FR*). Unlike you '
      + '(your profile score: *SCORE*), *NOMINATIVE* does not see social engagement as being *ACCUSATIVE.F* personal '
      + 'obligation. *NOMINATIVE.C* is satisfied with this sense of realism and, unlike you, generally has little '
      + 'compassion for those who don’t help themselves.'
  },
  'distantFilter.sen.medium-high': {
    de: 'Soziale Gerechtigkeit ist für *NAME* (Profilwert: *SCORE_FR*) weniger wichtig als für Dich. Mit einem ' +
      'Profilwert von *SCORE* spielt soziale Gerechtigkeit eine besondere Rolle in Deinem Leben. Du sorgst Dich um ' +
      'das Wohl anderer Menschen und setzt Dich häufig aktiv dafür ein, Not und Benachteiligung zu lindern. Du ' +
      'empfindest eine tiefe Zufriedenheit, geholfen zu haben und bist verzweifelt, wenn Du nicht ' +
      'helfen kannst. Für *NAME* ist soziale Ungerechtigkeit manchmal von Bedeutung, manchmal aber auch nicht.',
    en: 'Social justice is less important to *NAME* (profile score: *SCORE_FR*) than it is to you. Your profile score '
      + 'of *SCORE* shows that issues of social justice play an important role in your life. You are concerned about '
      + 'the welfare of other people, and often campaign actively to alleviate hardship and disadvantage. You '
      + 'experience a deep sense of satisfaction at having been able to help others and feel despair when you can’t do '
      + 'anything to help. For *NAME*, social injustice is sometimes important, sometimes not.'
  },
  'distantFilter.sen.medium-low': {
    de: 'Soziale Gerechtigkeit ist für *NAME* (Profilwert: *SCORE_FR*) wichtiger als für Dich. Mit einem Profilwert ' +
      'von *SCORE* spielt soziale Gerechtigkeit eine untergeordnete Rolle in Deinem Leben. Du siehst soziales ' +
      'Engagement nicht als Deine persönliche Aufgabe an. Du bist zufrieden über Deinen Realismus und hat meist ' +
      'wenig Verständnis dafür, dass Menschen sich nicht selbst helfen. ' +
      'Für *NAME* ist soziale Ungerechtigkeit manchmal von Bedeutung, manchmal aber auch nicht.',
    en: 'Social justice is more important to *NAME* (profile score: *SCORE_FR*) than it is to you. Your profile score '
      + 'of *SCORE* shows that issues of social justice play a minor role in your life. You do not see social '
      + 'engagement as a personal obligation. You are satisfied with your sense of realism and generally have little '
      + 'compassion for those who don’t help themselves. For *NAME*, social injustice is sometimes important, '
      + 'sometimes not.'
  },
  'distantFilter.str.high': {
    de: 'Struktur, Ordnung und Sauberkeit spielen eine wichtige Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). ' +
      'Anders als Du (Dein Profilwert: *SCORE*), genießt *NOMINATIVE* es, den Alltag zu ordnen – sei es durch ' +
      'Planung, strukturierte Tätigkeiten oder Routinen. *NOMINATIVE.C* ist beispielsweise glücklich über einen ' +
      'funktionierenden Plan; kann aber anders als Du die Orientierung verlieren, wenn von einem Plan abgewichen ' +
      'wird.',
    en: 'Structure, order and cleanliness play an important role in *NAME*’s life (profile score: *SCORE_FR*). Unlike '
      + 'you (your profile score: *SCORE*), *NOMINATIVE* enjoys bringing order to daily life, whether through plans, '
      + 'structured activities or routines. *NOMINATIVE.C*, for example, enjoys having a plan that works; but, unlike '
      + 'you, can get thrown off track by deviations from the plan.'
  },
  'distantFilter.str.low': {
    de: 'Ordnung und Sauberkeit spielen eine untergeordnete Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). ' +
      'Anders als Du (Dein Profilwert: *SCORE*), engen *ACCUSATIVE* Strukturen und exakte Pläne ein. *NOMINATIVE.C* ' +
      'ist glücklich, einen Tag ohne Planung zu verbringen und fühlt sich anders als Du in Strukturen gefangen.',
    en: 'Structure, order and cleanliness play a minor role in *NAME*’s life (profile score: *SCORE_FR*). Unlike you '
      + '(your profile score: *SCORE*), *ACCUSATIVE* feels constrained by structures and strict plans. *NOMINATIVE.C* '
      + 'enjoys spending a day without any plans and, unlike you, feels boxed in by structures.'
  },
  'distantFilter.str.medium-high': {
    de: 'Struktur, Ordnung und Sauberkeit sind für *NAME* (Profilwert: *SCORE_FR*) weniger wichtig als für Dich. Mit ' +
      'einem Profilwert von *SCORE* spielen Struktur, Ordnung und Sauberkeit eine wichtige Rolle in Deinem Leben. ' +
      'Du genießt es, Deinen Alltag zu ordnen – sei es durch Planung, strukturierte Tätigkeiten oder Routinen. Du ' +
      'bist glücklich über funktionierende Pläne und kannst zum Beispiel die Orientierung verlieren, wenn Du von ' +
      'einem Plan abweichen musst. *NAME* legt manchmal Wert auf Ordnung und manchmal nicht.',
    en: 'Structure, order and cleanliness are less important to *NAME* (profile score: *SCORE_FR*) than they are to '
      + 'you. Your profile score of *SCORE* shows that structure, order and cleanliness play an important role in your '
      + 'life. You enjoy bringing order to your daily life, whether through plans, structured activities or routines. '
      + 'You enjoy having plans that work, and you may get thrown off track if you have to deviate from a plan. *NAME* '
      + 'sometimes attaches importance to order, sometimes not.'
  },
  'distantFilter.str.medium-low': {
    de: 'Struktur, Ordnung und Sauberkeit sind für *NAME* (Profilwert: *SCORE_FR*) wichtiger als für Dich. Mit einem ' +
      'Profilwert von *SCORE* spielen Ordnung und Sauberkeit eine untergeordnete Rolle in Deinem Leben. Strukturen ' +
      'und exakte Pläne engen Dich ein. Du bist glücklich, einen Tag ohne Planung zu verbringen und fühlst Dich in ' +
      'zu viel Struktur gefangen. *NAME* legt manchmal Wert auf Ordnung und manchmal nicht.',
    en: 'Structure, order and cleanliness are more important to *NAME* (profile score: *SCORE_FR*) than they are to '
      + 'you. Your profile score of *SCORE* shows that order and cleanliness play a minor role in your life. You feel '
      + 'constrained by structures and strict plans. You\'re happy to spend a day without planning and feel boxed in '
      + 'by too much structure. *NAME* sometimes attaches importance to order, sometimes not.'
  },
  'distantFilter.sic.high': {
    de: 'Sicherheit spielt eine besondere Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). Anders als Du ' +
      '(Dein Profilwert: *SCORE*), ist *ACCUSATIVE.N* Verhalten geprägt davon, potenzielle Gefahren zu vermeiden. ' +
      '*NOMINATIVE.C* neigt in unbekannten Situationen dazu, sehr vorsichtig zu sein und ist in der Regel zufrieden, ' +
      'wenn das Leben in vorhersehbaren Bahnen verläuft. *NOMINATIVE.C* sorgt sich anders als Du um die Zukunft oder ' +
      'bei grundlegenden Veränderungen.',
    en: 'Safety plays an important role in *NAME*’s life (profile score: *SCORE_FR*). Unlike you '
      + '(your profile score: *SCORE*), *ACCUSATIVE.N* behavior is guided by the desire to avoiding potential hazards. '
      + '*NOMINATIVE.C* tends to be very cautious in unfamiliar situations, and is usually content with predictability '
      + 'in life. *NOMINATIVE.C* feels differently than you do about the future or major changes.'
  },
  'distantFilter.sic.low': {
    de: 'Sicherheit spielt eine untergeordnete Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). Anders als Du ' +
      '(Dein Profilwert: *SCORE*), neigt *NOMINATIVE* dazu, Risiken einzugehen und stellt sich gerne Situationen, ' +
      'die potenzielle Gefahren mit sich bringen. *NOMINATIVE.C* verspürt beispielsweise einen Kick, wenn ' +
      '*NOMINATIVE* nicht weiß, wie eine Situation ausgehen wird. *NOMINATIVE.C* hat anders als Du eher Sorge, dass ' +
      'das Leben keine Herausforderungen mehr bereithält.',
    en: 'Safety plays a minor role in *NAME*’s life (profile score: *SCORE_FR*). Unlike you '
      + '(your profile score: *SCORE*), *NOMINATIVE* tends to take risks and enjoys facing situations that involve '
      + 'potential risks. For example, *NOMINATIVE* experiences a thrill when *NOMINATIVE* doesn\'t know how things '
      + 'will turn out. Unlike you, *NOMINATIVE* is more concerned about life no longer holding challenges.'
  },
  'distantFilter.sic.medium-high': {
    de: 'Sicherheit ist für *NAME* (Profilwert: *SCORE_FR*) weniger wichtig als für Dich. Mit einem Profilwert von ' +
      '*SCORE* spielt Sicherheit eine besondere Rolle in Deinem Leben. Dein Verhalten ist geprägt davon, potenzielle ' +
      'Gefahren zu vermeiden. Du neigst in unbekannten Situationen dazu, sehr vorsichtig zu sein. Du bist in der ' +
      'Regel zufrieden, wenn das Leben in vorhersehbaren Bahnen verläuft. Du sorgst Dich um die Zukunft oder bei ' +
      'grundlegenden Veränderungen. *NAME* braucht manchmal das Gefühl von Sicherheit und manchmal nicht.',
    en: 'Safety is less important to *NAME* (profile score: *SCORE_FR*) than it is to you. Your profile score of '
      + '*SCORE* shows that safety plays an important role in your life. Your behavior is guided by the desire to '
      + 'avoid potential hazards. You tend to be very cautious in situations that are new for you. You\'re usually '
      + 'satisfied when things in your life are predictable. For you, thinking about the future or major changes in '
      + 'life instills concern. *NAME* sometimes needs to feel safe, sometimes not.'
  },
  'distantFilter.sic.medium-low': {
    de: 'Sicherheit ist für *NAME* (Profilwert: *SCORE_FR*) wichtiger als für Dich. Mit einem Profilwert von *SCORE* ' +
      'spielt Sicherheit eine untergeordnete Rolle in Deinem Leben. Du neigst dazu, Risiken einzugehen und stellst ' +
      'Dich gerne Situationen, die potenzielle Gefahren mit sich bringen. Du verspürst beispielsweise einen Kick, ' +
      'wenn Du nicht weißt, wie eine Situation ausgehen wird. Du hast Sorge, dass das Leben keine Herausforderungen ' +
      'mehr bereithält. *NAME* braucht manchmal das Gefühl von Sicherheit und manchmal nicht.',
    en: 'Safety is more important to *NAME* (profile score: *SCORE_FR*) than it is to you. Your profile score of '
      + '*SCORE* shows that safety plays a minor role in your life. You tend to take risks and like to take on '
      + 'situations that may entail hazards. You get a thrill out of not knowing how things will turn out. You tend to '
      + 'worry that life won\'t hold any more challenges for you. *NAME* sometimes needs to feel safe, sometimes not.'
  },
  'distantFilter.rev.high': {
    de: '*NAME* neigt dazu, es anderen heimzuzahlen, wenn *NOMINATIVE* das Gefühl hat, von jemandem betrogen, ' +
      'beleidigt oder hintergangen worden zu sein (Profilwert: *SCORE_FR*). Anders als Du ' +
      '(Dein Profilwert: *SCORE*), möchte *NOMINATIVE* erlebtes Unrecht vergelten. *NOMINATIVE.C* erlebt Genugtuung, ' +
      'es den anderen gezeigt zu haben. Es ärgert *ACCUSATIVE* beispielsweise anders als Dich, wenn der andere ' +
      'ruhig bleibt und sich nicht streiten will.',
    en: '*NAME* tends to seek retribution if *NOMINATIVE* feels betrayed, insulted or deceived '
      + '(profile score: *SCORE_FR*). Unlike you (your profile score: *SCORE*), *NOMINATIVE* seeks retribution for past '
      + 'wrongs. *NOMINATIVE.C* feels gratified by being able to express rage toward the person one feels is '
      + 'responsible. Unlike you, for *ACCUSATIVE*, it’s annoying when somebody remains calm and eschews argument.'
  },
  'distantFilter.rev.low': {
    de: '*NAME* neigt nicht dazu, es anderen heimzuzahlen, wenn *NOMINATIVE* das Gefühl hat, von jemandem ungerecht ' +
      'behandelt worden zu sein (Profilwert: *SCORE_FR*). Anders als Du (Dein Profilwert: *SCORE*), kann ' +
      '*NOMINATIVE* in der Regel über Kränkungen hinwegsehen und verzeihen. *NOMINATIVE.C* ist in der Regel ' +
      'zufrieden, wenn es einen Konsens gibt und fühlt sich, anders als Du, unwohl, wenn sich ein Streit anbahnt.',
    en: '*NAME* tends not to seek retribution if *NOMINATIVE* feels unfairly treated (profile score: *SCORE_FR*). '
      + 'Unlike you (your profile score: *SCORE*), *NOMINATIVE* can usually look past slights committed by others and '
      + 'forgive them. *NOMINATIVE.C* is usually satisfied when consensus is reached and, unlike you, is uncomfortable '
      + 'with a brewing argument.'
  },
  'distantFilter.rev.medium-high': {
    de: 'Erlebtes Unrecht zu vergelten spielt im Leben von *NAME* (Profilwert: *SCORE_FR*) eine geringere Rolle als ' +
      'bei Dir. Mit einem Profilwert von *SCORE* neigst Du dazu, es anderen heimzuzahlen, wenn Du das Gefühl hast, ' +
      'von jemandem betrogen, beleidigt oder hintergangen worden zu sein. Erlebtes Unrecht möchtest Du vergelten. Du ' +
      'erlebst Genugtuung, wenn Du es anderen gezeigt hast. Es ärgert Dich beispielsweise, wenn der andere ruhig ' +
      'bleibt und sich nicht streiten will. *NAME* möchte erlebtes Unrecht manchmal vergelten und manchmal nicht. ' +
      '*NOMINATIVE.C* hat diesbezüglich keine extreme Ausprägung.',
    en: 'Seeking retribution for past wrongs plays a less important role in *NAME*’s life (profile score: *SCORE_FR*) '
      + 'than it does in yours. Your profile score of *SCORE* shows that you tend to get back at those whom you feel '
      + 'have betrayed, insulted or deceived you. You want retribution for past wrongs. You feel gratified by being '
      + 'able to express rage toward the person one feels is responsible. You are, for example, annoyed when somebody '
      + 'remains calm and eschews argument. *NAME* sometimes seeks retribution for past wrongs, sometimes not. '
      + '*NOMINATIVE.C* shows no strong expression in this respect.'
  },
  'distantFilter.rev.medium-low': {
    de: 'Erlebtes Unrecht zu vergelten spielt im Leben von *NAME* (Profilwert: *SCORE_FR*) eine größere Rolle als ' +
      'bei Dir. Mit einem Profilwert von *SCORE* neigst Du nicht dazu, es anderen heimzuzahlen, wenn Du das Gefühl ' +
      'hast, von jemandem ungerecht behandelt worden zu sein. Du kannst in der Regel über Kränkungen hinwegsehen und ' +
      'verzeihen. In der Regel bist Du zufrieden, wenn es einen Konsens gibt und fühlst Dich unwohl, wenn sich ein ' +
      'Streit anbahnt. *NAME* möchte erlebtes Unrecht manchmal vergelten und manchmal nicht. ' +
      '*NOMINATIVE.C* hat diesbezüglich keine extreme Ausprägung.',
    en: 'Seeking retribution for past wrongs plays a more important role in *NAME*’s life (profile score: *SCORE_FR*) '
      + 'than it does in yours. Your profile score of *SCORE* shows that you do not tend to get back at those whom you '
      + 'feel have treated you unfairly. You are generally able to ignore it when somebody offends you and can forgive '
      + 'them. You are usually satisfied when consensus is reached and are uncomfortable with a brewing argument. '
      + '*NAME* sometimes seeks retribution for past wrongs, sometimes not. *NOMINATIVE.C* shows no strong expression '
      + 'in this respect.'
  },
  'distantFilter.bew.high': {
    de: 'Körperliche Bewegung spielt eine besondere Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). Anders ' +
      'als Du (Dein Profilwert: *SCORE*), ist es eines *GENITIVE.F/PL* wichtigsten Lebensziele, sich körperlich fit ' +
      'zu halten. Es ist *DATIVE* wichtig, den Körper fit zu halten und zu spüren. Es frustriert *ACCUSATIVE* ' +
      'beispielsweise, wenn *NOMINATIVE* über längere Zeit keinen Sport machen kann.',
    en: 'Physical exercise plays an important role in *NAME*’s life (profile score: *SCORE_FR*). Unlike you '
      + '(your profile score: *SCORE*), staying physically fit is one of *GENITIVE.F/PL* most important goals in life. '
      + 'It is important to *DATIVE* to keep one’s body fit. For *ACCUSATIVE*, it is frustrating when *NOMINATIVE* '
      + 'can\'t exercise for an extended period of time.'
  },
  'distantFilter.bew.low': {
    de: 'Körperliche Bewegung spielt eine untergeordnete Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). Anders '
      + 'als Du (Dein Profilwert: *SCORE*), sind *DATIVE* andere Dinge wichtiger als Sport zu treiben. Körperlich '
      + 'anstrengende Tätigkeiten vermeidet *NOMINATIVE* eher. Anders als Du, kann *NOMINATIVE* es beispielsweise '
      + 'genießen, zu relaxen. *NOMINATIVE.C* ist frustriert, wenn zu viel unnötige Bewegung gefordert wird.',
    en: 'Physical exercise plays a minor role in *NAME*’s life (profile score: *SCORE_FR*). Unlike you '
      + '(your profile score: *SCORE*), other things are more important to *DATIVE* than playing sports. *NOMINATIVE.C* '
      + 'is likely to avoid engaging in physically strenuous activities. Unlike you, *NOMINATIVE* can, for example, '
      + 'relax and enjoy doing so. *NOMINATIVE.C* is frustrated by situations involving too much unnecessary '
      + 'physical activity.'
  },
  'distantFilter.bew.medium-high': {
    de: 'Körperliche Bewegung ist für *NAME* (Profilwert: *SCORE_FR*) weniger wichtig als für Dich. Mit einem ' +
      'Profilwert von *SCORE* spielt körperliche Bewegung eine besondere Rolle in Deinem Leben. Dich körperlich fit ' +
      'zu halten ist eines Deiner wichtigsten Lebensziele. Du möchtest Deinen Körper fit halten und ihn spüren. Es ' +
      'frustriert Dich beispielsweise, wenn Du über längere Zeit keinen Sport machen kann. *NAME* hat diesbezüglich ' +
      'keine extreme Ausprägung - manchmal braucht *NOMINATIVE* Bewegung und manchmal nicht.',
    en: 'Physical exercise is less important to *NAME* (profile score: *SCORE_FR*) than it is to you. Your profile '
      + 'score of *SCORE* shows that physical activity plays an important role in your life. Staying physically fit is '
      + 'one of your most important goals in life. You want to keep your body fit. You are frustrated when you can’t '
      + 'exercise for an extended period of time. *NAME* has no strong expression in this respect – sometimes '
      + '*NOMINATIVE* needs exercise, sometimes not.'
  },
  'distantFilter.bew.medium-low': {
    de: 'Körperliche Bewegung ist für *NAME* (Profilwert: *SCORE_FR*) wichtiger als für Dich. Mit einem Profilwert ' +
      'von *SCORE* spielt körperliche Bewegung eine untergeordnete Rolle in Deinem Leben. Dir sind andere Dinge ' +
      'wichtiger als Sport zu treiben. Körperlich anstrengende Tätigkeiten vermeidest Du eher. Du kannst es ' +
      'beispielsweise genießen, zu relaxen. Du bist frustriert, wenn zu viel unnötige Bewegung von Dir gefordert ' +
      'wird. ' +
      '*NAME* hat diesbezüglich keine extreme Ausprägung - manchmal braucht *NOMINATIVE* Bewegung und manchmal nicht.',
    en: 'Physical exercise is more important to *NAME* (profile score: *SCORE_FR*) than it is to you. Your profile '
      + 'score of *SCORE* shows that physical exercise plays a minor role in your life. There are other things more '
      + 'important to you than doing sports. You are likely to avoid engaging in physically strenuous activities. You '
      + 'can, for example, relax and enjoy doing so. You get frustrated by situations involving too much unnecessary '
      + 'physical activity. *NAME* shows no strong expression in this respect – *NOMINATIVE* sometimes needs exercise, '
      + 'sometimes not.'
  },
  'distantFilter.ess.high': {
    de: 'Essen spielt eine besondere Rolle im Leben von *NAME* – weit über die Befriedigung physiologischer ' +
      'Grundbedürfnisse hinaus (Profilwert: *SCORE_FR*). Anders als Du (Dein Profilwert: *SCORE*), genießt ' +
      '*NOMINATIVE* es jederzeit, sich mit dem Thema Essen zu beschäftigen und ist teilweise geradezu euphorisch ' +
      'darüber. Anders als Du, ist *NOMINATIVE* frustriert, wenn er beispielsweise vor lauter Arbeit nicht zum ' +
      'Essen kommt.',
    en: 'Food plays an important role in *NAME*’s life – far beyond the satisfaction of basic physiological needs '
      + '(profile score: *SCORE_FR*). Unlike you (your profile score: *SCORE*), *NOMINATIVE* cultivates an active '
      + 'interest in the topic of food and is, at times, downright euphoric about it. Unlike you, *NOMINATIVE* is '
      + 'frustrated when, for example, a heavy workload gets in the way of enjoying a meal.'
  },
  'distantFilter.ess.low': {
    de: 'Essen spielt eine untergeordnete Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). Anders als Du ' +
      '(Dein Profilwert: *SCORE*), hat über die Befriedigung des Hungergefühls hinaus das Thema Essen in der Regel ' +
      'wenig Bedeutung für *ACCUSATIVE*. Allein nimmt *NOMINATIVE* sich wenig Zeit zum Essen. Anders als Du, ' +
      'freut *NOMINATIVE* sich beispielsweise darüber, wenn das Essen nebenbei erledigt werden kann und fühlt sich ' +
      'unwohl, wenn die Situation so ist, dass *NOMINATIVE* essen muss – auch ohne Hungergefühl.',
    en: 'Food plays a minor role in *NAME*’s life (profile score: *SCORE_FR*). Unlike you '
      + '(your profile score: *SCORE*), for *ACCUSATIVE*, food generally has little significance beyond satisfying '
      + 'hunger. *NOMINATIVE.C* doesn’t take much time to eat. Unlike you, *NOMINATIVE* prefers to eat while doing '
      + 'something else, and feels uncomfortable in situations in which *NOMINATIVE* has to eat – even without '
      + 'feeling hungry.'
  },
  'distantFilter.ess.medium-high': {
    de: 'Essensgenuss ist für *NAME* (Profilwert: *SCORE_FR*) weniger bedeutend als für Dich. Mit einem Profilwert ' +
      'von *SCORE* spielt Essen eine besondere Rolle in Deinem Leben – weit über die Befriedigung physiologischer ' +
      'Grundbedürfnisse hinaus. Du genießt es jederzeit, Dich mit dem Thema Essen zu beschäftigen und bist teilweise ' +
      'geradezu euphorisch darüber. Es frustriert Dich, wenn Du beispielsweise vor lauter Arbeit nicht zum Essen ' +
      'kommst. ' +
      '*NAME* hat diesbezüglich keine besonderen Vorlieben - manchmal ist das Thema Essen wichtig, manchmal nicht.',
    en: 'Enjoying food is less important to *NAME* (profile score: *SCORE_FR*) than it is to you. Your profile score '
      + 'of *SCORE* shows that food plays a significant role in your life – far beyond the satisfaction of basic '
      + 'physiological needs. You cultivate an active interest in the topic of food and are, at times, downright '
      + 'euphoric about it. You are frustrated when, for example, a busy work schedule keeps you from eating. *NAME* '
      + 'has no particular preferences in this regard – sometimes the topic of food is important, sometimes not.'
  },
  'distantFilter.ess.medium-low': {
    de: 'Essensgenuss ist für *NAME* (Profilwert: *SCORE_FR*) wichtiger als für Dich. Mit einem Profilwert von ' +
      '*SCORE* spielt Essen eine untergeordnete Rolle in Deinem Leben. Über die Befriedigung des Hungergefühls ' +
      'hinaus hat das Thema Essen wenig Bedeutung für Dich. Alleine nimmst Du Dir wenig Zeit zum Essen. Es freut ' +
      'Dich eher, wenn das Essen nebenbei erledigt werden kann. Du fühlst Dich unwohl, wenn die Situation so ist, ' +
      'dass Du essen musst – auch ohne Hungergefühl.' +
      '*NAME* hat diesbezüglich keine besonderen Vorlieben - manchmal ist das Thema Essen wichtig, manchmal nicht.',
    en: 'Enjoying food more important to *NAME* (profile score: *SCORE_FR*) than it is to you. Your profile score of '
      + '*SCORE* shows that food plays a minor role in your life. Beyond satisfying hunger, food has little '
      + 'significance for you. On your own, you don’t take much time to eat. You generally prefer to eat while doing '
      + 'something else. You feel uncomfortable in situations in which you have to eat – even without feeling hungry. '
      + '*NAME* has no particular preferences in this regard – sometimes the topic of food is important, sometimes not.'
  },
  'distantFilter.fam.high': {
    de: 'Die Familie ist ein wichtiger Bezugspunkt im Leben von *NAME* (Profilwert: *SCORE_FR*). Anders als für ' +
      'Dich (Dein Profilwert: *SCORE*), ist ein harmonisches Familienleben mit enger Verbundenheit von hoher ' +
      'Bedeutung für *ACCUSATIVE.F* Lebenszufriedenheit. Für *ACCUSATIVE* können beispielsweise Familientreffen ' +
      'große Vorfreude auslösen. Anders als Du, will *NOMINATIVE* viel Zeit mit der Familie verbringen.',
    en: 'Family plays an important role in *NAME*’s life (profile score: *SCORE_FR*). Unlike you '
      + '(your profile score: *SCORE*), a harmonious family life, characterized by close family ties, is very '
      + 'important for *ACCUSATIVE.F* satisfaction with life. Family gatherings, for example, can elicit a strong '
      + 'sense of anticipation in *ACCUSATIVE*. Unlike you, *NOMINATIVE* wants to spend a lot of time with family.'
  },
  'distantFilter.fam.low': {
    de: 'Die Familie spielt eine untergeordnete Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). Anders als für ' +
      'Dich (Dein Profilwert: *SCORE*), hat das Familienleben nicht die höchste Bedeutung für *ACCUSATIVE.F* ' +
      'Lebenszufriedenheit. *NOMINATIVE.C* ist zufrieden, wenn *NOMINATIVE* keine familiären Verpflichtungen hat und ' +
      'ist anders als Du über so etwas wie regelmäßige Familientreffen genervt.',
    en: 'Family plays a minor role in *NAME*’s life (profile score: *SCORE_FR*). Unlike you '
      + '(your profile score: *SCORE*), family life is not the most important thing in terms of *ACCUSATIVE.F* '
      + 'satisfaction with life. *NOMINATIVE.C* is satisfied when *NOMINATIVE* has no familial obligations and, '
      + 'unlike you, is annoyed by things like regular family gatherings.'
  },
  'distantFilter.fam.medium-high': {
    de: 'Die Familie hat für *NAME* (Profilwert: *SCORE_FR*) eine geringere Bedeutung als für Dich. Mit einem ' +
      'Profilwert von *SCORE* ist Familie ein wichtiger Bezugspunkt in Deinem Leben. Ein harmonisches Familienleben ' +
      'mit enger Verbundenheit ist für Deine Lebenszufriedenheit sehr wichtig. Familientreffen können beispielsweise ' +
      'große Vorfreude bei Dir auslösen. Du gerätst leicht in Sorge, dass einem geliebten Menschen etwas zustoßen ' +
      'könnte. Für *NAME* ist Familie manchmal wichtig und manchmal nicht - *NOMINATIVE* hat diesbezüglich keine ' +
      'extreme Ausprägung.',
    en: 'Family is less important to *NAME* (profile score: *SCORE_FR*) than it is to you. Your profile score of '
      + '*SCORE* shows that family plays an important role in  your life. A harmonious family life, characterized by '
      + 'close family ties, is very important to your sense of satisfaction with life. Family gatherings, for example, '
      + 'can elicit a strong sense of anticipation in you. You are prone to worry that something might happen to a '
      + 'loved one. For *NAME*, family is sometimes important, sometimes not  – *NOMINATIVE* shows no strong '
      + 'expression in this respect.'
  },
  'distantFilter.fam.medium-low': {
    de: 'Die Familie hat für *NAME* (Profilwert: *SCORE_FR*) eine höhere Bedeutung als für Dich. Mit einem ' +
      'Profilwert von *SCORE* spielt die Familie eine untergeordnete Rolle in Deinem Leben. Das Familienleben ist ' +
      'für Deine Lebenszufriedenheit nicht sehr wichtig. Du bist zufrieden, wenn Du keine familiären Verpflichtungen ' +
      'hast und bist beispielsweise von regelmäßigen Familientreffen genervt. Für *NAME* ist Familie manchmal ' +
      'wichtig und manchmal nicht - *NOMINATIVE* hat diesbezüglich keine extreme Ausprägung.',
    en: 'Family is more important to *NAME* (profile score: *SCORE_FR*) than it is to you. Your profile score of '
      + '*SCORE* shows that family plays a minor role in your life. Family life is not very important to your sense '
      + 'of satisfaction with life. You are satisfied if you have no familial obligations and are, for example, '
      + 'annoyed by regular family gatherings. For *NAME*, family is sometimes important, sometimes not  – '
      + '*NOMINATIVE* shows no strong expression in this respect.'
  },
  'distantFilter.sin.high': {
    de: 'Erotik, Sinnlichkeit und Leidenschaft spielen eine wichtige Rolle im Leben von *NAME* ' +
      '(Profilwert: *SCORE_FR*). Anders als Du (Dein Profilwert: *SCORE*), genießt *NOMINATIVE* es, *ACCUSATIVE.N* ' +
      'Sexualität ausleben zu können und sucht regelmäßig Gelegenheiten dafür. Anders als Du, empfindet ' +
      '*NOMINATIVE* Genuss an ungeplanten erotischen Erlebnissen und ist frustriert über unerfüllte Lust.',
    en: 'Eroticism, sensuality and passion play an important role in *NAME*’s life (profile score: *SCORE_FR*). '
      + 'Unlike you (your profile score: *SCORE*), *NOMINATIVE* enjoys being sexually active and seeks out '
      + 'opportunities to live out *ACCUSATIVE.N* sexuality. Unlike you, *NOMINATIVE* takes pleasure in unplanned '
      + 'erotic experiences and is frustrated by unfulfilled desire.'
  },
  'distantFilter.sin.low': {
    de: 'Erotik und Leidenschaft spielen eine untergeordnete Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). ' +
      'Anders als für Dich (Dein Profilwert: *SCORE*), ist ein erfülltes Sexualleben für *ACCUSATIVE.N* Lebensglück ' +
      'nicht entscheidend. *NOMINATIVE.N.C* Verlangen nach Erotik ist eher schwach ausgeprägt. *NOMINATIVE.C* ' +
      'empfindet Erleichterung, wenn *NOMINATIVE* nicht zu häufig sexuell aktiv sein muss und ist genervt, ' +
      'über Sex sprechen zu müssen.',
    en: 'Eroticism, sensuality and passion play a minor role in *NAME*’s life (profile score: *SCORE_FR*). Unlike you '
      + '(your profile score: *SCORE*), a fulfilled sex life is not integral to *ACCUSATIVE.N* happiness in life. '
      + '*NOMINATIVE.N.C* desire for eroticism is rather weak. *NOMINATIVE.C* is rather relieved when *NOMINATIVE* '
      + 'doesn\'t have to be sexually active too often, and is annoyed by having to talk about sex.'
  },
  'distantFilter.sin.medium-high': {
    de: 'Erotik, Sinnlichkeit und Leidenschaft sind *NAME* (Profilwert: *SCORE_FR*) weniger wichtig als Dir. Mit ' +
      'einem Profilwert von *SCORE* spielen Erotik, Sinnlichkeit und Leidenschaft eine wichtige Rolle in Deinem ' +
      'Leben. Du genießt es, Deine Sexualität ausleben zu können und suchst regelmäßig Gelegenheiten dafür. Du ' +
      'empfindest Genuss an ungeplanten erotischen Erlebnissen und bist frustriert über unerfüllte Lust.' +
      '*NAME* hat diesbezüglich keine extreme Ausprägung - manchmal braucht *NOMINATIVE* Sexualität, manchmal nicht.',
    en: 'Eroticism, sensuality and passion are less important to *NAME* (profile score: *SCORE_FR*) than they are to '
      + 'you. Your profile score of *SCORE* shows that eroticism, sensuality and passion play an important role in '
      + 'your life. You enjoy being sexually active and regularly seek out opportunities to have erotic experiences. '
      + 'You take pleasure in unplanned erotic experiences and are frustrated by unfulfilled desire. *NAME* shows no '
      + 'strong expression in this respect – *NOMINATIVE* sometimes needs sex, sometimes not.'
  },
  'distantFilter.sin.medium-low': {
    de: 'Erotik, Sinnlichkeit und Leidenschaft sind *NAME* (Profilwert: *SCORE_FR*) wichtiger als Dir. Mit einem ' +
      'Profilwert von *SCORE* spielen Erotik und Leidenschaft eine untergeordnete Rolle in Deinem Leben. Ein ' +
      'erfülltes Sexualleben ist für Dein Lebensglück nicht entscheidend. Dein Verlangen nach Erotik ist eher ' +
      'schwach ausgeprägt. Du bist erleichtert, wenn Du nicht zu häufig sexuell aktiv sein musst und bist genervt, ' +
      'über Sex sprechen zu müssen. ' +
      '*NAME* hat diesbezüglich keine extreme Ausprägung - manchmal braucht *NOMINATIVE* Sexualität, manchmal nicht.',
    en: 'Eroticism, sensuality and passion are more important to *NAME* (profile score: *SCORE_FR*) than they are to '
      + 'you. Your profile score of *SCORE* shows that eroticism and passion play a minor role in your life. A '
      + 'fulfilling sex life is not integral to your happiness in life. Your desire for eroticism is rather weak. You '
      + 'are relieved when you don\'t have to be sexually active too often, and are annoyed by having to talk about '
      + 'sex. *NAME* shows no strong expression in this respect – *NOMINATIVE* sometimes needs sex, sometimes not.'
  },
  /* SUPERLIKE */
  'superLike.wow': {
    de: 'Oh wow!',
    en: 'Oh wow!'
  },
  'superLike.text': {
    de: 'Zeige *FIRSTNAME* was Du denkst',
    en: 'Show *FIRSTNAME* what you think'
  },
  'superLike.button': {
    de: 'Reagiere',
    en: 'Respond'
  },
  'superLike.send.button': {
    de: 'Nachricht senden',
    en: 'Send a message'
  },
  'superLike.headline': {
    de: 'Zeige Deine Reaktion',
    en: 'Show your reaction'
  },
  'superLike.intro': {
    de: 'Was denkst Du?',
    en: 'What do you think?'
  },
  'superLike.APPRECIATE': {
    de: 'Ich schätze Dich sehr',
    en: 'I value you a lot'
  },
  'superLike.INTERESTING': {
    de: 'Interessante Persönlichkeit!',
    en: 'What an interesting personality!'
  },
  'superLike.POTENTIAL': {
    de: 'Wir haben viel Potential',
    en: 'We have a lot of potentinal'
  },
  'superLike.TALK': {
    de: 'Lass uns reden',
    en: 'Let’s talk'
  },
  'superLike.placeholder': {
    de: 'Deine persönliche Nachricht',
    en: 'Your personal message'
  },
  'superLike.report.headline': {
    de: 'Schau Dir die ID37 Analyse von *FIRSTNAME* im Original an',
    en: 'Take a look at *FIRSTNAME*’s ID37 assessment'
  },
  'superLike.report.text_1': {
    de: 'Die vollständige ID37 Persönlichkeitsanalyse von *NAME* kannst Du ',
    en: 'You can view *NAME*’s full ID37 personality assessment '
  },
  'superLike.report.link': {
    de: 'hier',
    en: 'here'
  },
  'superLike.report.text_2': {
    de: ' ansehen. Auch die ',
    en: '. You can also explore the '
  },
  'superLike.report.graphic': {
    de: 'Grafik',
    en: 'figure'
  },
  'superLike.report.text_3': {
    de: ' steht Dir in der klassischen Darstellung zur Verfügung.',
    en: ' illustrating their motives and expressions.'
  },
  'superLike.differences.tip.headline': {
    de: 'Tipp - Das kannst Du tun',
    en: 'Here\'s what you can do'
  },
  'superLike.differences.tip1_1': {
    de: 'Die Beschreibung der Motive ist allgemein formuliert. Um *NAME* besser zu verstehen, hilft es, ' +
      '*ACCUSATIVE.N* Verhalten zu beobachten und dieses in den Zusammenhang mit *DATIVE.N* ',
    en: 'The description is phrased in general terms. To better understand *NAME*, it helps to observe *ACCUSATIVE.N* '
      + 'behavior and relate it to *DATIVE.N* '
  },
  'superLike.differences.tip1.keyGraphic': {
    de: 'ID37 Motivprofil',
    en: 'ID37 motives profile'
  },
  'superLike.differences.tip1_2': {
    de: ' zu bringen. Berücksichtige, dass Motive sich verstärken oder abschwächen können. Da dies etwas Übung ' +
      'erfordert, können  Notizen hilfreich sein.<br><br>',
    en: '. Don’t forget that a person’s expression of motives can strengthen or weaken. As this requires some' +
      'practice, taking notes may prove helpful.<br><br>'
  },
  'superLike.differences.tip2': {
    de: 'Denke auch daran, dass Dein eigenes Motivprofil Deine Wahrnehmung beeinflussen kann. Warum stört Dich ein ' +
      'Verhalten, warum findest Du es unangemessen? Ist es das überhaupt? Oder ist es nur aus Deiner Wirklichkeit ' +
      'heraus irritierend? Vielleicht wird Dein Verhalten selbst als seltsam betrachtet. Der erste Schritt ist ' +
      'immer die Selbstreflexion.<br><br>',
    en: 'And remember that your own motive profile can influence your perception. Why does a particular behavior '
      + 'bother you, why do you find it inappropriate? Is it, in fact, inappropriate? Or does it just conflict with '
      + 'your reality? Perhaps your behavior is itself considered strange. '
      + 'The first step always involves self-reflection.<br><br>'
  },
  'superLike.differences.tip3_A': {
    de: 'Dein ID37 Master ',
    en: 'Your ID37 Master '
  },
  'superLike.differences.tip3_B1': {
    de: 'Ein ',
    en: 'An '
  },
  'superLike.differences.tip3_B2': {
    de: 'ID37 Experte',
    en: 'ID37 Expert'
  },
  'superLike.differences.tip3': {
    de: ' kann Dich und *NAME* dabei unterstützen, Eure Motivprofile zu interpretieren und auf Eure Situation und '
      + 'Ziele einzugehen.<br><br>',
    en: ' can help you and *NAME* interpret your motive profiles and explore your situation and goals more closely.'
      + '<br><br>'
  },
  'superLike.differences.good': {
    de: '<strong>Gut zu wissen</strong><br>' +
      'Normal ist, dass wir es als selbstverständlich hinnehmen, wenn der andere etwas tut, das uns gefällt. Erst ' +
      'wenn der andere etwas sagt oder tut, das uns missfällt, reagieren wir – und dann meist negativ, nörgeln rum ' +
      'oder werden ironisch. Die Folge ist, dass die Beziehung auf Dauer zunehmend negativer empfunden wird und ' +
      'gegenseitige Wertschätzung ausbleibt. Um diesen Prozess zu unterbinden gilt es zu handeln. Du bist bereits ' +
      'mittendrinnen!<br><br>',
    en: '<strong>Good to know</strong><br>'
      + 'It\'s normal for us to take it for granted when somebody does something we like. Only when they say or do '
      + 'something we disapprove of do we react – and then usually in a negative way by nagging or expressing irony. '
      + 'Over time, this results in an increasingly negative view of a relationship that lacks mutual appreciation. '
      + 'You need to do something to stop this process from continuing. And you\'ve already begun!<br><br>'

  },
  'superLike.differences.attitude': {
    de: '<strong>Haltung</strong><br>'
      + 'Nicht die Unterschiede zwischen Menschen führen zu Problemen, sondern die Art, wie wir damit umgehen.',
    en: '<strong>Attitude</strong><br> '
      + 'It\'s not the differences between people that lead to problems, but the ways in which we deal with these '
      + 'differences.'

  },
  /* AFTER SUPERLIKE (gray box) */
  'distantFilter.impulses.headline': {
    de: 'Impulse',
    en: 'Things to consider'
  },
  'distantFilter.impulses.text1': {
    de: 'Das Verhalten ist der sichtbare Teil der Persönlichkeit – der Grund des „typischen“ Verhaltens und der ' +
      'Emotionen lässt sich zu einem großen Anteil in ihren Motiven finden.',
    en: 'Behavior is the visible manifestation of personality – the roots of "typical" behaviors and emotions can be '
      + 'found to a large extent in a person\'s motives.'
  },
  'distantFilter.impulses.listItem.title': {
    de: 'Überlege für Dich:',
    en: 'For you to consider:'
  },
  'distantFilter.impulses.listItem1': {
    de: 'Hast Du neue Aspekte über *NAME* kennengelernt?',
    en: 'Did you learn anything new about *NAME*?'
  },
  'distantFilter.impulses.listItem2': {
    de: 'Wo hattest Du Aha-Momente?',
    en: 'Where did you have “a-ha” moments?'
  },
  'distantFilter.impulses.listItem3': {
    de: 'Welche Situationen fallen Dir ein, in denen Ihr große Unstimmigkeiten hattet?',
    en: 'In which contexts did you find yourselves in strong disagreement with each other?'
  },
  'distantFilter.impulses.listItem4': {
    de: 'Was genau hat Dich gestört?',
    en: 'What exactly bothered you?'
  },
  'distantFilter.impulses.listItem5': {
    de: 'Wie hast Du reagiert?',
    en: 'How did you react?'
  },
  'distantFilter.impulses.listItem6_1': {
    de: 'Kannst Du Dir mit dem Wissen ',
    en: 'Given what you know about how '
  },
  'distantFilter.impulses.listItem6_2': {
    de: 'Eure Motivunterschiede',
    en: 'your motives differ'
  },
  'distantFilter.impulses.listItem6_3': {
    de: ' erklären, warum es passiert ist?',
    en: ' can you explain why it happened?'
  },
  'distantFilter.impulses.listItem7': {
    de: 'Wie würdest Du mit Deinem heutigen Wissen damit umgehen?',
    en: 'How would you handle things, given what you now know?'
  },
  'distantFilter.impulses.text2_1': {
    de: 'Sicherlich sind Dir beim Nachdenken auch viele gute Seiten von *NAME* aufgefallen. Das ist gut so! Mit den ' +
      'Augen auf Eure positiven Erlebnisse wirst Du mit mehr Enthusiasmus an Eurer Beziehung arbeiten. Nachfolgend ' +
      'findest Du ein paar Reflexionen und Übungen dazu.<br><br>' +
      'Schon jetzt findest Du viele Ansatzpunkte für ein transparentes und respektvolles Gespräch mit *NAME*. Du ' +
      'kannst vorab auch einen Blick auf die ',
    en: 'Surely, upon reflection, you’ve noticed many admirable things about *NAME*. That\'s a good thing! By drawing '
      + 'attention to your positive experiences, you can work more enthusiastically on your relationship. Below, '
      + 'you\'ll find a few suggestions of things to reflect on and some exercises for guidance.<br><br>'
      + 'These are just a few of the many places to start in holding an open, respectful conversation with *NAME*. '
      + 'You can also start by taking a look in advance at the '
  },
  'distantFilter.impulses.dialogue.link': {
    de: '„Gelingende Gesprächsführung“',
    en: '“Successful Conversation”'
  },
  'distantFilter.impulses.text2_2': {
    de: 'werfen.',
    en: 'section.'
  },
  'distantFilter.exercise.headline': {
    de: 'Übungen',
    en: 'Exercises'
  },
  'distantFilter.exercise.invest.headline': {
    de: '„In die Beziehung einzahlen“',
    en: '“Investing in the relationship”'
  },
  'distantFilter.exercise.invest.text1': {
    de: 'Um Eure Beziehung aktiv zu gestalten, kannst Du Dich mit Worten und Taten einbringen. Zum Beispiel, ' +
      'indem Du *NAME* Wertschätzung für das entgegenbringst, was Dir fremd ist.<br><br>' +
      'Nimm Dir Zeit, um zu überlegen:',
    en: 'Actively influencing your relationship involves using words and actions. This means, for example, showing '
      + '*NAME* your appreciation for something that is foreign to you. <br><br> Take time to think about this:'
  },
  'distantFilter.exercise.invest.listItem1': {
    de: 'Mit welchen Worten, Taten oder Routinen kannst Du *NAME* Wertschätzung entgegenbringen?',
    en: 'What words, actions, or routines can you use to demonsrate appreciation to *NAME*?'
  },
  'distantFilter.exercise.invest.listItem2': {
    de: 'Erinnere Dich dabei an die positiven Seiten von *NAME*, dann wird Dir diese Übung leichter fallen.',
    en: 'Remind yourself of *NAME*\'s positive sides while doing this, and you’ll find this exercise easier.'
  },
  'distantFilter.exercise.invest.listItem3': {
    de: 'Was bedeutet das für Dich vor dem Hintergrund Deines eigenen Motivprofils – große Anstrengung oder viel ' +
      'Rückenwind?',
    en: 'What does this mean for you in the context of your own motives profile – does this involve a major effort on '
      + 'your part or does this feel natural to you?'
  },
  'distantFilter.exercise.invest.text2': {
    de: 'Probiere es aus und beobachte, was passiert. Werden Deine Bemühungen registriert? Deine Bemühungen sind ' +
      'keine Selbstverständlichkeit. Sie werden helfen, den Umgang miteinander freudvoller zu gestalten und Eure ' +
      'Zufriedenheit im Miteinander wird wachsen.',
    en: 'Try it out and see what happens. Are your efforts acknowledged? Doing this sort of thing doesn\'t always come '
      + 'easily. But it will help make your interactions with each other more pleasant, and both of you will derive '
      + 'greater satisfaction from your time together.'
  },
  'distantFilter.exercise.listen.headline': {
    de: '„Gut zuhören“',
    en: '“Be a good listener”'
  },
  'distantFilter.exercise.listen.text1': {
    de: 'Gutes Zuhören ist genauso wichtig wie Aktivität. Versuche in Gesprächen aufmerksam zuzuhören.',
    en: 'Listening well is just as important as taking action. Try to be a careful listener when conversing with others.'
  },
  'distantFilter.exercise.listen.listItem1': {
    de: 'Zeige, dass Du aufmerksam bist.',
    en: 'Show that you\'re paying attention.'
  },
  'distantFilter.exercise.listen.listItem2': {
    de: 'Versuche genau zu erfassen, was *NAME* meint.',
    en: 'Try to understand exactly what *NAME* means.'
  },
  'distantFilter.exercise.listen.listItem3': {
    de: 'Zeige, dass Du verstanden hast, was *NAME* gesagt hat, z.B. durch wiederholen des Verstandenen mit ' +
      'Deinen Worten.',
    en: 'Show that you understand what *NAME* is saying. You can do this, for example, by summarizing in your '
      + 'own words what’s just been said.'
  },
  'distantFilter.exercise.listen.listItem4': {
    de: 'Formuliere Deine Vermutungen und Ideen als offene Frage. Das hilft dabei, vorgefasste Meinungen und das ' +
      'Aufdrücken der eigenen Wirklichkeit zu vermeiden. Z.B. „Hast Du Dich dabei unsicher gefühlt?“ statt ' +
      '„Das liegt an Deiner Unsicherheit.“',
    en: 'Formulate your thoughts and ideas about what you believe is going on as an open question. This helps keep you '
      + 'from projecting your own preconceived notions or reality onto the situation. You can say, for example, '
      + '“Did that make you feel insecure?” instead of “That’s because you’re insecure.”'
  },
  'distantFilter.exercise.listen.listItem5': {
    de: 'Reagiere positiv, wenn sich *NAME* offen geäußert hat. Sei dann auch selbst offen!',
    en: 'Respond positively when *NAME* speaks openly. And remain open yourself!'
  },
  'distantFilter.exercise.listen.text2': {
    de: 'Einfach mal ausprobieren: Gib *NAME* mehr Redeanteil als Dir. Möchtest Du etwas einwerfen? Überdenke, was ' +
      'Du gehört hast, bevor Du Deine Meinung zum Besten gibst. Mit der Kenntnis der unterschiedlichen ' +
      'Motivausprägungen von *NAME* kennst Du die Lebensprioritäten von *DATIVE* ziemlich genau. Vor allem auch ' +
      'die, die Dir eher fremd sind und umgekehrt. Durch genaues Zuhören, kannst Du Deine Erkenntnisse verfeinern ' +
      'und Dein Einfühlungsvermögen verbessern.',
    en: 'Just try it out: Let *NAME* have the floor longer than you. If you want to contribute something, think about '
      + 'what you’ve heard before offering your opinion. Now that you are familiar with the various expressions of '
      + '*NAME*\'s motives, you pretty much know what *DATIVE* priorities in life are. You’re aware in particular of '
      + 'those that are very different from yours, as well as those motives in which you are similar. Listening '
      + 'carefully helps you deepen your knowledge and improve your empathy.'
  },
  'distantFilter.exercise.dialogue.headline': {
    de: '„Gelingende Gesprächsführung“',
    en: '“Successful Conversation”'
  },
  'distantFilter.exercise.dialogue.text': {
    de: 'Voraussetzung für ein gutes Verständnis ist, dass Du und *NAME* bereit seid, offen über Euch selbst zu sprechen '
      + 'und versucht, dem anderen seine Empfindungen und Überlegungen eindeutig mitzuteilen. Eindeutig heißt dabei, '
      + 'dass der andere so wenig wie möglich interpretieren oder erraten muss bei dem, was Du sagst.<br><br>'
      + 'Kommunikation ist Interaktion: Da Ihr Euch im Gespräch gegenseitig beeinflusst, liefert Ihr Euch '
      + 'wechselseitig Reiz und Reaktion. Ihr steuert Euer Gespräch gegenseitig. Daher kommt es nicht nur darauf an, '
      + 'wie jemand spricht, sondern auch darauf, wie der andere darauf eingeht.<br><br>'
      + 'Es gibt jede Menge Ratgeber auf diesem Gebiet, in die Ihr Euch einlesen könnt. Hier gibt es sechs wichtige '
      + 'Aspekte einer gelingenden Gesprächsführung:',
    en: 'A prerequisite to understanding each other well is that you and *NAME* both demonstrate a willingness to talk '
      + 'openly about yourselves and to communicate clearly your feelings and thoughts. Communicating clearly in this '
      + 'context means that the other person doesn’t have to do much interpreting or guessing about what it is you are '
      + 'saying. <br><br>'
      + 'Communication is interaction: When people are engaged in a conversation, they influence each other through '
      + 'cues and reactions. You both steer the conversation. This is not just a matter of how someone speaks; '
      + 'it’s also about how the other person responds. <br><br>'
      + 'There are plenty of guides or support materials published on this topic that you can read up on. Here are six '
      + 'important aspects of a successful conversation:'

  },
  'distantFilter.exercise.dialogue.listItem1': {
    de: '<strong>Ich-Botschaften</strong>: Von den eigenen Gedanken und Gefühlen sprechen. Verzicht auf „man“ oder „wir“.',
    en: '<strong>I-driven statements</strong>: Express your own thoughts and feelings. Avoid the use of “one” or “we.”'
  },
  'distantFilter.exercise.dialogue.listItem2': {
    de: 'Von <strong>konkreten Situationen und Anlässen</strong> sprechen. Keine Verallgemeinerungen wie „immer“, „nie“, „typisch“',
    en: 'Talk about <strong>specific situations or events</strong> . Avoid generalizations such as “always,” “never,” “typically.”'
  },
  'distantFilter.exercise.dialogue.listItem3': {
    de: 'Auf ein <strong>konkretes Verhalten</strong> verweisen. Dem anderen keine negativen Eigenschaften ' +
      'zuschreiben. Jede Persönlichkeit ist, wie sie ist – ganz wertfrei. Vielleicht passen aus ' +
      'Deiner Sicht Verhalten und Situation nicht zusammen.',
    en: 'Refer to a <strong>specific behavior</strong> . Do not ascribe negative traits to the other person. Every '
      + 'personality is neither good nor bad, it simply is. Perhaps from your point of view, a particular type of '
      + 'behavior is not appropriate to the situation.'
  },
  'distantFilter.exercise.dialogue.listItem4': {
    de: 'Im <strong>hier und jetzt</strong> bleiben. Rückgriffe in die Vergangenheit lenken ab. Vermeidung von „alten Hüten“',
    en: 'Remain in <strong>the here and now</strong>. Bringing up the past only distracts. Let bygones be bygones.'
  },
  'distantFilter.exercise.dialogue.listItem5': {
    de: '<strong>Reaktion nicht vorwegnehmen.</strong> Unterstellungen nehmen dem anderen die Möglichkeit offen ' +
      'zu reagieren. Vermeidung von Vorwegnahmen wie: „… aber das machst Du ja sowieso nicht mit!“',
    en: '<strong>Don’t pre-empt a person’s response.</strong> When you insinuate, you deprive the other person of the '
      + 'opportunity to react openly. Avoid pre-emptive statements like “... but you wouldn’t go along with that anyway!”'
  },
  'distantFilter.exercise.dialogue.listItem6': {
    de: '<strong>Sich selbst öffnen.</strong> Formuliere direkt, was in Dir vorgeht. Das hilft dabei, Anklagen ' +
      'und Vorwürfe zu vermeiden. Eine Orientierung an den 4 Basisemotionen Freude, Angst, Trauer, Wut, hilft ' +
      'dabei, z.B. „Ich freue mich …“ „Ich bin zufrieden …“, Ich fürchte …“, „Ich bin traurig …“, ' +
      '„Ich bin wütend …“.',
    en: '<strong>Open yourself up.</strong> Express in words what’s going on inside of you. This will help you steer '
      + 'clear of leveling criticisms and accusations. It helps to start by drawing on the four basic emotions of joy, '
      + 'fear, sadness and anger – for example, “I’m happy ...,” “I’m satisfied ...,” “I’m afraid ...,” “I’m sad ...,” “I’m angry ...”.'
  },
  /* CLOSE FILTER */
  'closeFilter.motive': {
    de: 'diesem Motiv',
    en: 'this motive'
  },
  'closeFilter.motives': {
    de: 'diesen Motiven',
    en: 'these motives'
  },
  'closeFilter.motive.headline': {
    de: 'Auf *MOTIVES* hast Du mit *NAME* eine ähnliche Ausprägung',
    en: 'You and *NAME* have a similar expression of *MOTIVES*'
  },
  'closeFilter.motiveText.headline': {
    de: ' - Euer Abstand: ',
    en: ' - Your point difference: '
  },
  'closeFilter.neu.high-medium': {
    de: 'Wissen und Erkenntnisgewinn spielen eine wichtige Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). ' +
      '*NOMINATIVE.C* strebt nach intellektueller Tätigkeit und Herausforderung. ' +
      'Dir ist Erkenntsnisgewinn weniger wichtig (Dein Profilwert: *SCORE*).',
    en: 'Knowledge and gaining knowledge play an important role in *NAME*’s life (profile score: *SCORE_FR*). '
      + '*NOMINATIVE.C* likes to be intellectually engaged and challenged. Gaining knowledge is less important to you '
      + '(your profile score: *SCORE*).'
  },
  'closeFilter.neu.high-high': {
    de: 'Wissen und Erkenntnisgewinn spielen eine wichtige Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). ' +
      'Genauso wie bei Dir (Dein Profilwert: *SCORE*). Ihr strebt beide nach intellektueller Tätigkeit und ' +
      'Herausforderung und seid an vielen Themenfeldern interessiert.',
    en: 'Knowledge and gaining knowledge play an important role in *NAME*’s life (profile score: *SCORE_FR*). Just '
      + 'like you (your profile score: *SCORE*). Both of you seek to be intellectually engaged and challenged, and you '
      + 'both have a wide range of interests.'
  },
  'closeFilter.neu.medium-high': {
    de: 'Wissen und Erkenntnisgewinn sind *NAME* (Profilwert: *SCORE_FR*) weniger wichtig als Dir. Mit einem ' +
      'Profilwert von *SCORE* spielt Erkenntnisgewinn für Dich eine wichtige Rolle. Du strebst nach intellektueller ' +
      'Tätigkeit und Herausforderung. Da Euer Profilpunkte nahe beieinander liegen, seid Ihr Euch recht ähnlich.',
    en: 'Knowledge and gaining knowledge are less important to *NAME* (profile score: *SCORE_FR*) than they are to you. '
      + 'Your profile score of *SCORE* shows that gaining knowledge is important to you. You like to be intellectually '
      + 'engaged and challenged. The distance between your profile points here is marginal, which shows that you are '
      + 'quite similar in this regard.'
  },
  'closeFilter.neu.medium-medium': {
    de: 'Wissen und Erkenntnisgewinn spielen keine signifikante Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). ' +
      'Genauso wie bei Dir (Profilwert: *SCORE*).',
    en: 'Knowledge and gaining knowledge do not play an important role in *NAME*’s life (profile score: *SCORE_FR*). '
      + 'Just like you (your profile score: *SCORE*).'
  },
  'closeFilter.neu.medium-low': {
    de: 'Wissen und Erkenntnisgewinn sind *NAME* (Profilwert: *SCORE_FR*) wichtiger als Dir. Mit einem Profilwert ' +
      'von *SCORE* spielt Erkenntnisgewinn in Deinem Leben nur eine untergeordnete Rolle. Du meidest die ' +
      'Beschäftigung mit intensiven intellektuellen Anstrengungen. ' +
      'Da Euer Profilpunkte nahe beieinander liegen, seid Ihr Euch recht ähnlich.',
    en: 'Knowledge and gaining knowledge are more important to *NAME* (profile score: *SCORE_FR*) than they are to you. '
      + 'Your profile score of *SCORE* shows that gaining knowledge plays a minor role in your life. '
      + 'You avoid engaging in taxing intellectual activities. The distance between your profile points here is '
      + 'marginal, which shows that you are quite similar in this regard.'
  },
  'closeFilter.neu.low-low': {
    de: 'Wissen und Erkenntnisgewinn spielen eine untergeordnete Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). ' +
      'Genauso wie bei Dir (Profilwert: *SCORE*). Ihr meidet die Beschäftigung mit intensiven intellektuellen ' +
      'Anstrengungen.',
    en: 'Knowledge and gaining knowledge play a minor role in *NAME*’s life (profile score: *SCORE_FR*). '
      + 'Just like you (your profile score: *SCORE*). You both avoid engaging in taxing intellectual activities.'
  },
  'closeFilter.neu.low-medium': {
    de: 'Wissen und Erkenntnisgewinn spielen eine untergeordnete Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). ' +
      '*NOMINATIVE.C* meidet die Beschäftigung mit intensiven intellektuellen Anstrengungen. ' +
      'Dir ist Erkenntnisgewinn wichtiger (Dein Profilwert: *SCORE*).',
    en: 'Knowledge and gaining knowledge play a minor role in *NAME*’s life (profile score: *SCORE_FR*). '
      + '*NOMINATIVE.C* avoids engaging in taxing intellectual activities. Gaining knowledge is more important to you '
      + '(your profile score: *SCORE*).'
  },
  'closeFilter.san.high-medium': {
    de: 'Anerkennung durch andere ist für *NAME* sehr wichtig im Leben (Profilwert: *SCORE_FR*). *NOMINATIVE.N.C* ' +
      'Verhalten zielt darauf ab, Bestätigung zu erhalten und Kritik, Ablehnung oder Zurückweisung zu vermeiden. ' +
      'In Deinem Leben spielt Anerkennung eine geringere Rolle (Dein Profilwert: *SCORE*).',
    en: 'Being accepted by others is very important to *NAME* (profile score: *SCORE_FR*). *NOMINATIVE.N.C* behavior '
      + 'is geared toward receiving affirmation from others and avoiding criticism or rejection. Acceptance plays a '
      + 'less important role in your life (your profile score: *SCORE*).'
  },
  'closeFilter.san.high-high': {
    de: 'Anerkennung durch andere ist für *NAME* sehr wichtig im Leben (Profilwert: *SCORE_FR*). Genauso wie für ' +
      'Dich (Dein Profilwert: *SCORE*). Euer Verhalten zielt darauf ab, Bestätigung zu erhalten und Kritik, ' +
      'Ablehnung oder Zurückweisung zu vermeiden. Kommt Kritik, so kann dies Selbstzweifel verursachen.',
    en: 'Being accepted by others is very important to *NAME* (profile score: *SCORE_FR*). Just like you '
      + '(your profile score: *SCORE*). For both of you, your behavior is geared toward receiving affirmation from '
      + 'others and avoiding criticism or rejection. When faced with criticism, self-doubt may arise.'
  },
  'closeFilter.san.medium-high': {
    de: 'Bestätigung und Anerkennung durch andere haben für *NAME* (Profilwert: *SCORE_FR*) weniger Bedeutung als ' +
      'für Dich. Mit einem Profilwert von *SCORE* ist Dir Anerkennung sehr wichtig. Dein Verhalten zielt darauf ab, ' +
      'Bestätigung zu erhalten und Kritik, Ablehnung oder Zurückweisung zu vermeiden. ' +
      'Da Eure Profilpunkte nahe beieinanderliegen, verhaltet Ihr Euch recht ähnlich.',
    en: 'Affirmation and acceptance by others are less important to *NAME* (profile score: *SCORE_FR*) than they are to you. '
      + 'Your profile score of *SCORE* shows that acceptance is very important to you. Your behavior is geared toward '
      + 'receiving affirmation from others and avoiding criticism or rejection. The distance between your profile '
      + 'points here is marginal, which shows that your behaviors are similar in this regard.'
  },
  'closeFilter.san.medium-medium': {
    de: 'Bestätigung und Anerkennung durch andere spielen keine zentrale Rolle im Leben von *NAME* ' +
      '(Profilwert: *SCORE_FR*). Genauso wie bei Dir (Profilwert: *SCORE*).',
    en: 'Affirmation and acceptance by others do not play significant roles in *NAME*’s life (profile score: *SCORE_FR*). '
      + 'Just like you (your profile score: *SCORE*).'
  },
  'closeFilter.san.medium-low': {
    de: 'Bestätigung und Anerkennung durch andere haben für *NAME* (Profilwert: *SCORE_FR*) mehr Bedeutung im Leben ' +
      'als für Dich. Mit einem Profilwert von *SCORE* ist Dir Anerkennung nicht wichtig. Du freust dich vielmehr ' +
      'über herausfordernde Aufgaben und hast in der Regel keine Angst zu versagen. ' +
      'Da Eure Profilpunkte nahe beieinanderliegen, empfindet Ihr recht ähnlich.',
    en: 'Affirmation and acceptance by others are more important to *NAME* (profile score: *SCORE_FR*) than they are to you. '
      + 'Your profile score of *SCORE* shows that acceptance is not important to you. You are more likely to enjoy '
      + 'challenging tasks and are usually not afraid of failure. The distance between your profile points here is '
      + 'marginal, which shows that you are similar in this regard.'
  },
  'closeFilter.san.low-low': {
    de: 'Bestätigung und Anerkennung durch andere sind *NAME* nicht wichtig im Leben (Profilwert: *SCORE_FR*). ' +
      'Genauso wie bei Dir (Profilwert: *SCORE*). Euer Verhalten richtet sich nicht primär danach, was andere von ' +
      'Euch halten oder wie ihr beurteilt werdet.',
    en: 'Affirmation and acceptance by others is not important to *NAME* (profile score: *SCORE_FR*). Just like you '
      + '(your profile score: *SCORE*). In both cases, your behavior is not geared toward how others judge or see you.'
  },
  'closeFilter.san.low-medium': {
    de: 'Bestätigung und Anerkennung durch andere sind *NAME* nicht wichtig im Leben (Profilwert: *SCORE_FR*). ' +
      '*NOMINATIVE.C* meidet die Beschäftigung mit intensiven intellektuellen Anstrengungen. ' +
      'In Deinem Leben spielt Anerkennung eine größere Rolle (Dein Profilwert: *SCORE*).',
    en: 'Affirmation and acceptance by others is not important to *NAME* (profile score: *SCORE_FR*). *NOMINATIVE.C* '
      + 'avoids engaging in taxing intellectual activities. Acceptance plays a more important role in your life '
      + '(your profile score: *SCORE*).'
  },
  'closeFilter.ein.high-medium': {
    de: 'Einfluss und Kontrolle auszuüben spielt im Leben von *NAME* eine große Rolle (Profilwert: *SCORE_FR*). ' +
      '*NOMINATIVE.C* strebt danach, *ACCUSATIVE.N* Lebensumfeld zu kontrollieren, andere zu überzeugen, ' +
      'Situationen zu lenken oder Verantwortung zu übernehmen. ' +
      'Dir ist es weniger wichtig, Einfluss und Kontrolle auszuüben (Dein Profilwert: *SCORE*).',
    en: 'Being able to exercise influence and control plays an important role in *NAME*’s life (profile score: *SCORE_FR*). '
      + '*NOMINATIVE.C* makes an effort to control *ACCUSATIVE.N* environment, persuade others, manage situations or '
      + 'take responsibility for things. Exercising influence and control is less important to you (your profile score: *SCORE*).'
  },
  'closeFilter.ein.high-high': {
    de: 'Einfluss und Kontrolle auszuüben spielt im Leben von *NAME* eine große Rolle (Profilwert: *SCORE_FR*). ' +
      'Genauso wie bei Dir (Dein Profilwert: *SCORE*). Ihr strebt danach, andere zu überzeugen, Situationen zu ' +
      'lenken oder Verantwortung zu übernehmen und seid in der Regel zufrieden, wenn ihr  alles im Griff habt.',
    en: 'Being able to exercise influence and control plays an important role in *NAME*’s life (profile score: *SCORE_FR*). '
      + 'Just like you (your profile score: *SCORE*). You both make an effort to persuade others, manage situations or '
      + 'take responsibility for things, and are usually satisfied when you have everything under control.'
  },
  'closeFilter.ein.medium-high': {
    de: 'Kontrolle und Einfluss spielen im Leben von *NAME* (Profilwert: *SCORE_FR*) eine geringere Rolle als bei ' +
      'Dir. Mit einem Profilwert von *SCORE* ist es Dir wichtig, Einfluss auszuüben. Du strebst danach, Dein ' +
      'Lebensumfeld zu kontrollieren, andere zu überzeugen, Situationen zu lenken oder Verantwortung zu übernehmen. ' +
      'Da Eure Profilpunkte nahe beieinanderliegen, verhaltet Ihr Euch recht ähnlich.',
    en: 'Being able to exercise influence and control plays a less important role in *NAME*’s life '
      + '(profile score: *SCORE_FR*) than it does in yours. Your profile score of *SCORE* shows that being able to '
      + 'exert influence is important to you. You make an effort to control your environment, persuade others, '
      + 'manage situations, or take responsibility for things. The distance between your profile points here is '
      + 'marginal, which shows that your behaviors are similar in this regard.'
  },
  'closeFilter.ein.medium-medium': {
    de: 'Kontrolle und Einfluss auszuüben spielt keine signifikante Rolle im Leben von *NAME* ' +
      '(Profilwert: *SCORE_FR*). Genauso wie bei Dir (Profilwert: *SCORE*).',
    en: 'Being able to exercise influence and control do not play an important role in *NAME*’s life '
      + '(profile score: *SCORE_FR*). Just like you (your profile score: *SCORE*).'
  },
  'closeFilter.ein.medium-low': {
    de: 'Kontrolle und Einfluss spielen im Leben von *NAME* (Profilwert: *SCORE_FR*) eine größere Rolle als bei Dir. ' +
      'Mit einem Profilwert von *SCORE* ist es Dir nicht wichtig, Einfluss auszuüben. ' +
      'Du legst keinen Wert darauf, Menschen und Vorgänge zu beeinflussen oder Entscheidungen für andere zu treffen. ' +
      'Da Eure Profilpunkte nahe beieinanderliegen, verhaltet Ihr Euch recht ähnlich.',
    en: 'Being able to exercise influence and control plays a more important role in *NAME*’s life '
      + '(profile score: *SCORE_FR*) than it does in yours. Your profile score of *SCORE* shows that being able to '
      + 'exert influence is not very important to you. You do not attach any importance to influencing people and '
      + 'processes or making decisions for others. The distance between your profile points here is marginal, which '
      + 'shows that your behaviors are similar in this regard.'
  },
  'closeFilter.ein.low-low': {
    de: 'Einfluss auszuüben ist *NAME* nicht wichtig im Leben (Profilwert: *SCORE_FR*). Genauso wie bei Dir ' +
      '(Profilwert: *SCORE*). Ihr legt keinen Wert darauf, Menschen und Vorgänge zu beeinflussen oder Entscheidungen ' +
      'für andere zu treffen.',
    en: 'Exerting influence is not important to *NAME* (profile score: *SCORE_FR*). Just like you '
      + '(your profile score: *SCORE*). You both do not attach any importance to influencing people and processes or '
      + 'making decisions for others.'
  },
  'closeFilter.ein.low-medium': {
    de: 'Einfluss auszuüben ist *NAME* nicht wichtig im Leben (Profilwert: *SCORE_FR*). *NOMINATIVE.C* legt keinen ' +
      'Wert darauf, Menschen und Vorgänge zu beeinflussen oder Entscheidungen für andere zu treffen. ' +
      'Dir ist es wichtiger, Einfluss und Kontrolle auszuüben (Dein Profilwert: *SCORE*).',
    en: 'Exerting influence is not important to *NAME* (profile score: *SCORE_FR*). *NOMINATIVE.C* does not attach any '
      + 'importance to influencing people and processes or making decisions for others. Exercising influence and '
      + 'control is more important to you (your profile score: *SCORE*).'
  },
  'closeFilter.sta.high-medium': {
    de: 'Für *NAME* spielen *NOMINATIVE.M* Ruf und die gesellschaftliche Stellung eine besondere Rolle im Leben ' +
      '(Profilwert: *SCORE_FR*). *NOMINATIVE.C* legt großen Wert darauf, sich von anderen abzuheben und dass dies ' +
      'wahrgenommen wird. Dir sind Ansehen und eine elitäre Stellung weniger wichtig (Dein Profilwert: *SCORE*).',
    en: 'For *NAME*, *NOMINATIVE.M* reputation and social standing play an important role in life '
      + '(profile score: *SCORE_FR*). *NOMINATIVE.C* attaches great importance to being distinguished from others and '
      + 'having this be publicly acknowledged. You are less invested in prestige and prominence (your profile score: *SCORE*).'
  },
  'closeFilter.sta.high-high': {
    de: 'Für *NAME* spielen *NOMINATIVE.M* Ruf und die gesellschaftliche Stellung eine besondere Rolle im Leben ' +
      '(Profilwert: *SCORE_FR*). Genauso wie bei Dir (Dein Profilwert: *SCORE*). Ihr legt großen Wert darauf, ' +
      'Euch von anderen abzuheben und dass dies wahrgenommen wird. Ihr seid glücklich über Vorrechte und ' +
      'Privilegien – insbesondere, wenn sie Euch zugesprochen werden.',
    en: 'For *NAME*, *NOMINATIVE.M* reputation and social standing play an important role in life (profile score: *SCORE_FR*). '
      + 'Just like you (your profile score: *SCORE*). You both attach great importance to being distinguished from '
      + 'others and having this be publicly acknowledged. Both of you are happy to benefit from prerogatives and '
      + 'privileges – especially when they are granted to you as individuals.'
  },
  'closeFilter.sta.medium-high': {
    de: 'Ansehen und eine elitäre Stellung in der Gesellschaft spielen für *NAME* (Profilwert: *SCORE_FR*) eine ' +
      'geringere Rolle als für Dich. Mit einem Profilwert von *SCORE* sind Dir Dein Ruf und Deine gesellschaftliche ' +
      'Stellung wichtig. Du legst großen Wert darauf, Dich von anderen abzuheben und dass dies wahrgenommen wird. ' +
      'Da Eure Profilpunkte nahe beieinanderliegen, empfindet Ihr recht ähnlich.',
    en: 'Prestige and prominence in society play a less important role for *NAME* (profile score: *SCORE_FR*) than '
      + 'they do for you. Your profile score of *SCORE* shows that your reputation and social standing are important '
      + 'to you. You attach great importance to being able to distinguish yourself from others and having this be '
      + 'publicly acknowledged. The distance between your profile points here is marginal, '
      + 'which shows that you are similar in this regard.'
  },
  'closeFilter.sta.medium-medium': {
    de: 'Ansehen und eine elitäre Stellung in der Gesellschaft spielen keine signifikante Rolle im Leben von *NAME* ' +
      '(Profilwert: *SCORE_FR*). Genauso wie bei Dir (Profilwert: *SCORE*).',
    en: 'Prestige and prominence in society do not play an important role in *NAME*’s life (profile score: *SCORE_FR*). '
      + 'Just like you (your profile score: *SCORE*).'
  },
  'closeFilter.sta.medium-low': {
    de: 'Ansehen und eine elitäre Stellung in der Gesellschaft spielen für *NAME* (Profilwert: *SCORE_FR*) eine ' +
      'größere Rolle als für Dich. Mit einem Profilwert von *SCORE* spielen Ansehen und gesellschaftliche Stellung ' +
      'in Deinem Leben eine untergeordnete Rolle. Du legst keinen Wert darauf, Dich von anderen abzuheben. ' +
      'Da Eure Profilpunkte nahe beieinanderliegen, empfindet Ihr recht ähnlich.',
    en: 'Prestige and prominence in society are more important to *NAME* (profile score: *SCORE_FR*) than they are to you. '
      + 'Your profile score of *SCORE* shows that prestige and social standing play only a minor role in your life. '
      + 'You do not attach importance to being able to distinguish yourself from others. The distance between your '
      + 'profile points here is marginal, which shows that you are similar in this regard.'
  },
  'closeFilter.sta.low-low': {
    de: 'Für *NAME* spielen Ansehen und gesellschaftliche Stellung eine untergeordnete Rolle im Leben ' +
      '(Profilwert: *SCORE_FR*). Genauso wie bei Dir (Dein Profilwert: *SCORE*). Ihr legt keinen Wert darauf, Euch ' +
      'von anderen abzuheben und lehnt Privilegien ab.',
    en: 'For *NAME*, prestige and social standing play a minor role in life (profile score: *SCORE_FR*). '
      + 'Just like you (your profile score: *SCORE*). Both of you place no value on distinguishing yourselves from '
      + 'others and eschew being granted privileges.'
  },
  'closeFilter.sta.low-medium': {
    de: 'Für *NAME* spielen Ansehen und gesellschaftliche Stellung eine untergeordnete Rolle im Leben ' +
      '(Profilwert: *SCORE_FR*). *NOMINATIVE.C* legt keinen Wert darauf, sich von anderen abzuheben und lehnt ' +
      'Privilegien ab. Dir sind Ansehen und eine elitäre Stellung wichtiger (Dein Profilwert: *SCORE*).',
    en: 'For *NAME*, prestige and social standing play a minor role in life (profile score: *SCORE_FR*). '
      + '*NOMINATIVE.C* places no value on being distinguished from others and eschews being granted privileges. '
      + 'You are more invested in prestige and prominence (your profile score: *SCORE*).'
  },
  'closeFilter.bes.high-medium': {
    de: 'Materieller Besitz spielt eine wichtige Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). Es ist ' +
      '*DATIVE* wichtig, Dinge und Geld anzusammeln, anzusparen oder aufzubauen und Verschwendung zu vermeiden. ' +
      'Eigentum und materieller Besitz sind Dir weniger wichtig (Dein Profilwert: *SCORE*).',
    en: 'Material possessions play an important role in *NAME*’s life (profile score: *SCORE_FR*). It is important to '
      + '*DATIVE* to accumulate belongings, save or amass money, and avoid waste. Property and material possessions '
      + 'are less important to you (profile score: *SCORE*).'
  },
  'closeFilter.bes.high-high': {
    de: 'Materieller Besitz spielt eine wichtige Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). Genauso wie bei ' +
      'Dir (Dein Profilwert: *SCORE*). Euch ist es wichtig, Dinge und Geld anzusammeln, anzusparen oder aufzubauen ' +
      'und Verschwendung zu vermeiden. Ihr seid beispielsweise zufrieden, wenn ihr alle Besitztümer um Euch wisst. ',
    en: 'Material possessions play an important role in *NAME*’s life (profile score: *SCORE_FR*). Just like you '
      + '(your profile score: *SCORE*). Both of you find it important to accumulate belongings, save or amass money, '
      + 'and avoid waste. Both of you feel content, for example, when you are surrounded by your belongings. '
  },
  'closeFilter.bes.medium-high': {
    de: 'Eigentum und materieller Besitz sind für *NAME* (Profilwert: *SCORE_FR*) weniger wichtig als für Dich. ' +
      'Mit einem Profilwert von *SCORE* spielt materieller Besitz für Dich eine wichtige Rolle im Leben. ' +
      'Es bedeutet Dir etwas, Ding und Geld anzusammeln, anzusparen oder aufzubauen. Du willst Verschwendung ' +
      'vermeiden. Da Eure Profilpunkte nahe beieinanderliegen, seid Ihr Euch recht ähnlich.',
    en: 'Property and material possessions are less important to *NAME* (profile score: *SCORE_FR*) than they are to '
      + 'you. Your profile score of *SCORE* shows that material possessions play an important role in your life. You '
      + 'feel compelled to accumulate belongings, and save or amass money. You want to avoid waste. The distance '
      + 'between your profile points here is marginal, which shows that you are quite similar in this regard.'
  },
  'closeFilter.bes.medium-medium': {
    de: 'Eigentum und materieller Besitz spielen keine signifikante Rolle im Leben von *NAME* ' +
      '(Profilwert: *SCORE_FR*). Genauso wie bei Dir (Dein Profilwert: *SCORE*).',
    en: 'Property and material possessions do not play a significant role *NAME*’s life (profile score: *SCORE_FR*). '
      + 'Just like you (your profile score: *SCORE*).'
  },
  'closeFilter.bes.medium-low': {
    de: 'Materieller Besitz ist für *NAME* (Profilwert: *SCORE_FR*) wichtiger als für Dich. ' +
      'Mit einem Profilwert von *SCORE* spielt Eigentum nur eine untergeordnete Rolle in Deinem Leben. ' +
      'Du neigst dazu, Vorräte zu verbrauchen und Geld auszugeben. Besitz anzuhäufen ist Dir nicht wichtig. ' +
      'Da Eure Profilpunkte nahe beieinanderliegen, seid Ihr Euch recht ähnlich.',
    en: 'Material possessions are more important to *NAME* (profile score: *SCORE_FR*) than they are to you. Your '
      + 'profile score of *SCORE* shows that material possessions play a minor role in your life. You tend to consume '
      + 'your resources and spend money. Accumulating possessions is not important to you. The distance between your '
      + 'profile points here is marginal, which shows that you are quite similar in this regard.'
  },
  'closeFilter.bes.low-low': {
    de: 'Materieller Besitz spielt eine untergeordnete Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). Genauso ' +
      'wie bei Dir (Dein Profilwert: *SCORE*). Ihr neigt dazu, Vorräte zu verbrauchen und Geld auszugeben.',
    en: 'Material possessions play a minor role in *NAME*’s life (profile score: *SCORE_FR*). Just like you '
      + '(your profile score: *SCORE*). You both tend to consume your resources and spend money.'
  },
  'closeFilter.bes.low-medium': {
    de: 'Materieller Besitz spielt eine untergeordnete Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). ' +
      '*NOMINATIVE.C* neigt dazu, Vorräte zu verbrauchen und Geld auszugeben. Es ist *DATIVE* nicht wichtig, Besitz ' +
      'anzuhäufen. Dir sind Eigentum und materieller Besitz wichtiger (Dein Profilwert: *SCORE*).',
    en: 'Material possessions play a minor role in *NAME*’s life (profile score: *SCORE_FR*). *NOMINATIVE.C* tends to '
      + 'consume resources and spend money. Accumulating possessions is not important to *DATIVE*. Property and '
      + 'material possessions are more important to you (profile score: *SCORE*).'
  },
  'closeFilter.aut.high-medium': {
    de: 'Unabhängigkeit ist für *NAME* sehr wichtig im Leben (Profilwert: *SCORE_FR*). *NOMINATIVE.C* strebt danach, ' +
      '*ACCUSATIVE.N* Leben eigenständig zu gestalten und an den eigenen Maßstäben zu orientieren. ' +
      'Unabhängigkeit ist Dir weniger wichtig (Dein Profilwert: *SCORE*).',
    en: 'Independence is very important in life for *NAME* (profile score: *SCORE_FR*). *NOMINATIVE.C* strives to live '
      + '*ACCUSATIVE.N* life independently, according to their own standards. Independence is less important to you '
      + '(profile score: *SCORE*).'
  },
  'closeFilter.aut.high-high': {
    de: 'Unabhängigkeit ist für *NAME* sehr wichtig im Leben (Profilwert: *SCORE_FR*). Genauso wie bei Dir ' +
      '(Dein Profilwert: *SCORE*). Ihr strebt danach, Euer Leben eigenständig zu gestalten und an den eigenen ' +
      'Maßstäben zu orientieren. Ihr verlasst Euch sich lieber auf Euch selbst statt auf andere. Ihr empfindet ' +
      'Freiheit, Euer eigenes Ding machen zu können.',
    en: 'Independence is very important in life for *NAME* (profile score: *SCORE_FR*). Just like you '
      + '(your profile score: *SCORE*). Both of you strive to live independent lives in accordance with your own standards. '
      + 'You prefer to rely on yourselves rather than on others. You experience being able to do your own thing as a form of freedom.'
  },
  'closeFilter.aut.medium-high': {
    de: 'Unabhängigkeit ist *NAME* (Profilwert: *SCORE_FR*) weniger wichtig als Dir. Mit einem Profilwert von ' +
      '*SCORE* ist Dir Unabhängigkeit sehr wichtig. Du bevorzugst es, Dein Leben eigenständig zu gestalten und an ' +
      'Deinen eigenen Maßstäben zu orientieren. Da Eure Profilpunkte nahe beieinanderliegen, verhaltet Ihr Euch ' +
      'recht ähnlich',
    en: 'Independence is less important to *NAME* (profile score: *SCORE_FR*) than it is to you. Your profile score of '
      + '*SCORE* shows that independence is very important to you. You prefer to live your life independently and to '
      + 'follow your own standards. The distance between your profile points here is marginal, which shows that your '
      + 'behaviors are similar in this regard.'
  },
  'closeFilter.aut.medium-medium': {
    de: 'Unabhängigkeit spielt keine signifikante Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). '
      + 'Genauso wie bei Dir (Profilwert: *SCORE*).',
    en: 'Independence does not play an important role in *NAME*’s life (profile score: *SCORE_FR*). Just like you '
      + '(your profile score: *SCORE*).'
  },
  'closeFilter.aut.medium-low': {
    de: 'Unabhängigkeit ist *NAME* (Profilwert: *SCORE_FR*) wichtiger als Dir. Mit einem Profilwert von *SCORE* ' +
      'spielt Unabhängigkeit in Deinem Leben eine untergeordnete Rolle. Du legst wenig Wert auf Eigenständigkeit, ' +
      'akzeptierst Einflüsse anderer und bist offen für emotionale Nähe. Da Eure Profilpunkte nahe ' +
      'beieinanderliegen, empfindet Ihr recht ähnlich.',
    en: 'Independence is more important to *NAME* (profile score: *SCORE_FR*) than it is to you. Your profile score of '
      + '*SCORE* shows that independence plays a minor role in your life. You do not attach considerable importance to '
      + 'autonomy, are accepting of the influence of others and are open to establishing emotional ties. The distance '
      + 'between your profile points here is marginal, which shows that you are similar in this regard.'
  },
  'closeFilter.aut.low-low': {
    de: 'Unabhängigkeit spielt für *NAME* eine untergeordnete Rolle im Leben (Profilwert: *SCORE_FR*). Genauso wie ' +
      'bei Dir (Profilwert: *SCORE*). Ihr legt wenig Wert auf Eigenständigkeit, akzeptiert Einflüsse anderer und ' +
      'seid offen für emotionale Nähe.',
    en: 'Independence plays a minor role in *NAME*’s life (profile score: *SCORE_FR*). Just like you '
      + '(your profile score: *SCORE*). Both of you do not attach considerable importance to autonomy, are accepting '
      + 'of the influence of others and are open to establishing emotional ties.'
  },
  'closeFilter.aut.low-medium': {
    de: 'Unabhängigkeit spielt für *NAME* eine untergeordnete Rolle im Leben (Profilwert: *SCORE_FR*). ' +
      '*NOMINATIVE.C* legt wenig Wert auf Eigenständigkeit, akzeptiert Einflüsse anderer und ist offen für ' +
      'emotionale Nähe. Dir ist Unabhängigkeit wichtiger (Profilwert: *SCORE*).',
    en: 'Independence plays a minor role in *NAME*’s life (profile score: *SCORE_FR*). *NOMINATIVE.C* does not attach '
      + 'considerable importance to autonomy, is accepting of the influence of others, and is open to establishing '
      + 'emotional ties. Independence is more important to you (profile score: *SCORE*).'
  },
  'closeFilter.soz.high-medium': {
    de: 'Die Gesellschaft von und mit anderen ist *NAME* wichtig im Leben (Profilwert: *SCORE_FR*). *NOMINATIVE.C* ' +
      'strebt danach, Zeit mit anderen Menschen zu verbringen. Soziale Kontakte und Geselligkeit sind Dir weniger ' +
      'wichtig (Dein Profilwert: *SCORE*).',
    en: 'For *NAME*, being with others is an important part of life (profile score: *SCORE_FR*). *NOMINATIVE.C* makes '
      + 'an effort to spend time with other people. Social participation and the company of others are less important '
      + 'to you (your profile score: *SCORE*).'
  },
  'closeFilter.soz.high-high': {
    de: 'Die Gesellschaft von und mit anderen ist *NAME* wichtig im Leben (Profilwert: *SCORE_FR*). Genauso wie bei ' +
      'Dir (Dein Profilwert: *SCORE*). Ihr strebt danach, Zeit mit anderen Menschen zu verbringen. Soziale Kontakte ' +
      'empfindet ihr als eine Bereicherung für Euer Leben und ihr habt gerne Spaß mit anderen.',
    en: 'For *NAME*, being with others is an important part of life (profile score: *SCORE_FR*). Just like you '
      + '(your profile score: *SCORE*). Both of you make an effort to spend time with other people. You both see '
      + 'social participation as enriching your lives, and you enjoy having a good time with others.'
  },
  'closeFilter.soz.medium-high': {
    de: 'Soziale Kontakte und Geselligkeit spielen für *NAME* (Profilwert: *SCORE_FR*) eine geringere Rolle als für ' +
      'Dich. Mit einem Profilwert von *SCORE* ist Dir die Gesellschaft von und mit anderen wichtig im Leben. Du ' +
      'strebst danach, Zeit mit anderen Menschen zu verbringen. Da Eure Profilpunkte nahe beieinanderliegen, ' +
      'empfindet Ihr recht ähnlich.',
    en: 'Social participation and the company of others play a less important role in life for *NAME* '
      + '(profile score: *SCORE_FR*) than they do for you. Your profile score of *SCORE* shows that being with other '
      + 'people is important to you. You make an effort to spend time with other people. The distance between your '
      + 'profile points here is marginal, which shows that you are similar in this regard.'
  },
  'closeFilter.soz.medium-medium': {
    de: 'Soziale Kontakte und Geselligkeit spielen keine signifikante Rolle im Leben von *NAME* ' +
      '(Profilwert: *SCORE_FR*). Genauso wie bei Dir (Profilwert: *SCORE*).',
    en: 'Social participation and the company of others do not play an important role in *NAME*’s life '
      + '(profile score: *SCORE_FR*). Just like you (your profile score: *SCORE*).'
  },
  'closeFilter.soz.medium-low': {
    de: 'Soziale Kontakte und Geselligkeit spielen für *NAME* (Profilwert: *SCORE_FR*) eine größere Rolle als für ' +
      'Dich. Mit einem Profilwert von *SCORE* spielen soziale Kontakte in Deinem Leben eine untergeordnete Rolle. ' +
      'Du verspürst nur selten das Bedürfnis, Zeit mit anderen zu verbringen. Da Eure Profilpunkte nahe ' +
      'beieinanderliegen, empfindet Ihr recht ähnlich.',
    en: 'Social participation and the company of others are more important to *NAME* (profile score: *SCORE_FR*) than '
      + 'they are to you. Your profile score of *SCORE* shows that social participation plays a minor role in your '
      + 'life. You rarely feel the need to spend time with others. The distance between your profile points here is '
      + 'marginal, which shows that you are similar in this regard.'
  },
  'closeFilter.soz.low-low': {
    de: 'Soziale Kontakte spielen im Leben von *NAME* eine untergeordnete Rolle (Profilwert: *SCORE_FR*). Genauso ' +
      'wie bei Dir (Profilwert: *SCORE*). Ihr verspürt nur selten das Bedürfnis, Zeit mit anderen zu verbringen. ' +
      'Partys und Networking Events vermeidet ihr häufig.',
    en: 'Social participation plays a minor role in *NAME*’s life (profile score: *SCORE_FR*). Just like you '
      + '(your profile score: *SCORE*). Both of you rarely feel the need to spend time with others. '
      + 'You both tend to avoid parties and networking events.'
  },
  'closeFilter.soz.low-medium': {
    de: 'Soziale Kontakte spielen im Leben von *NAME* eine untergeordnete Rolle (Profilwert: *SCORE_FR*). ' +
      '*NOMINATIVE.C* verspürt nur selten das Bedürfnis, Zeit mit anderen zu verbringen. Dir sind soziale Kontakte ' +
      'und Geselligkeit wichtiger (Dein Profilwert: *SCORE*).',
    en: 'Social participation plays a minor role in *NAME*’s life (profile score: *SCORE_FR*). *NOMINATIVE.C* rarely '
      + 'feels the need to spend time with others. Social participation and the company of others are more important '
      + 'to you (your profile score: *SCORE*).'
  },
  'closeFilter.pri.high-medium': {
    de: '*NAME* misst Regeln, Prinzipien oder Gesetzen einen hohen Wert bei (Profilwert: *SCORE_FR*). *NOMINATIVE.C* ' +
      'ist bestrebt, diese zu befolgen, auch wenn es für *ACCUSATIVE* persönliche Einschränkungen bedeutet. ' +
      'Konformität mit sozialen Normen und Regeln ist Dir weniger wichtig (Dein Profilwert: *SCORE*).',
    en: '*NAME* attaches considerable importance to rules, principles and laws (profile score: *SCORE_FR*). '
      + '*NOMINATIVE.C* tries to abide by them, even if this behavior involves personal disadvantages or restrictions '
      + 'for *ACCUSATIVE*. Conforming to social norms and rules is less important to you (profile score: *SCORE*).'
  },
  'closeFilter.pri.high-high': {
    de: '*NAME* misst Regeln, Prinzipien oder Gesetzen einen hohen Wert bei (Profilwert: *SCORE_FR*). Genauso wie ' +
      'bei Dir (Dein Profilwert: *SCORE*). Ihr seid bestrebt, soziale Regeln und Normen zu befolgen, auch wenn es ' +
      'Euch persönliche Einschränkungen bedeutet. Ihr seid stolz auf die eigene Loyalität und voraussichtlich ' +
      'beschämt, wenn Ihr mal nicht ganz die Wahrheit gesagt habt.',
    en: '*NAME* attaches considerable importance to rules, principles and laws (profile score: *SCORE_FR*). Just like '
      + 'you (your profile score: *SCORE*). Both of you do your best to abide by social rules and norms, even if this '
      + 'behavior is at a personal disadvantage. You both pride yourselves on your loyalty and are likely to feel '
      + 'shame if not fully honest.'
  },
  'closeFilter.pri.medium-high': {
    de: 'Konformität mit sozialen Normen und Regeln ist für *NAME* (Profilwert: *SCORE_FR*) weniger wichtig als für ' +
      'Dich. Mit einem Profilwert von *SCORE* misst Du Regeln, Prinzipien oder Gesetzen einen hohen Wert bei. Du ' +
      'bist bestrebt, diese zu befolgen, auch wenn dies für Dich persönliche Einschränkungen bedeutet. ' +
      'Da Eure Profilpunkte nahe beieinanderliegen, verhaltet Ihr Euch recht ähnlich.',
    en: 'Conforming to social norms and rules is less important to *NAME* (profile score: *SCORE_FR*) than it is to you. '
      + 'Your profile score of *SCORE* shows that you attach considerable importance to rules, principles and laws. '
      + 'You do your best to follow them, even if this behavior involves disadvantages or restrictions for you personally. '
      + 'The distance between your profile points here is marginal, which shows that your behaviors are similar in this regard.'
  },
  'closeFilter.pri.medium-medium': {
    de: 'Konformität mit sozialen Normen und Regeln spielen keine signifikante Rolle im Leben von *NAME* ' +
      '(Profilwert: *SCORE_FR*). Genauso wie bei Dir (Profilwert: *SCORE*).',
    en: 'Conformity with social norms and rules does not play an important role in *NAME*’s life (profile score: *SCORE_FR*). '
      + 'Just like you (your profile score: *SCORE*).'
  },
  'closeFilter.pri.medium-low': {
    de: 'Konformität mit sozialen Normen und Regeln ist für *NAME* (Profilwert: *SCORE_FR*) wichtiger als für Dich. ' +
      'Mit einem Profilwert von *SCORE* spielen gesellschaftliche Erwartungen und Normen für Dich lediglich eine ' +
      'untergeordnete Rolle. Du orientierst Dich nicht an Regeln, insbesondere nicht, wenn sie Dich einschränken. ' +
      'Da Eure Profilpunkte nahe beieinanderliegen, verhaltet Ihr Euch recht ähnlich.',
    en: 'Conforming to social norms and rules is more important to *NAME* (profile score: *SCORE_FR*) than it is to you. '
      + 'Your profile score of *SCORE* shows that social norms play a minor role for you. Following the rules isn’t '
      + 'particularly important to you, especially when they constrain you. The distance between your profile points '
      + 'here is marginal, which shows that your behaviors are similar in this regard.'
  },
  'closeFilter.pri.low-low': {
    de: 'Gesellschaftliche Erwartungen und Normen spielen für *NAME* eine untergeordnete Rolle ' +
      '(Profilwert: *SCORE_FR*). Genauso wie bei Dir (Profilwert: *SCORE*). Ihr orientiert Euch nicht an Regeln, ' +
      'insbesondere nicht, wenn sie Euch einschränken. Ihr empfindet es als Freiheit, Eure eigene Interpretation von ' +
      'Regeln leben zu können.',
    en: 'For *NAME*, social expectations and norms play a minor role in life (profile score: *SCORE_FR*). Just like '
      + 'you (your profile score: *SCORE*). Following the rules isn’t particularly important to either of you, '
      + 'especially when this involves constraints. You both experience a sense of freedom by being able to interpret '
      + 'rules more flexibly.'
  },
  'closeFilter.pri.low-medium': {
    de: 'Gesellschaftliche Erwartungen und Normen spielen für *NAME* eine untergeordnete Rolle ' +
      '(Profilwert: *SCORE_FR*). *NOMINATIVE.C* orientiert sich nicht an Regeln, insbesondere nicht, wenn sie ' +
      '*ACCUSATIVE* einschränken. ' +
      'Konformität mit sozialen Normen und Regeln ist Dir wichtiger (Dein Profilwert: *SCORE*).',
    en: 'For *NAME*, social expectations and norms play a minor role in life (profile score: *SCORE_FR*). '
      + '*NOMINATIVE.C* doesn’t necessarily follow the rules, especially when they place constraints on *ACCUSATIVE*. '
      + 'Conforming to social norms and rules is more important to you (profile score: *SCORE*).'
  },
  'closeFilter.sen.high-medium': {
    de: 'Soziale Gerechtigkeit spielt eine besondere Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). ' +
      '*NOMINATIVE.C* sorgt sich um das Wohl anderer Menschen und setzt sich häufig aktiv dafür ein, Not und ' +
      'Benachteiligung zu lindern. Dir ist soziale Gerechtigkeit weniger wichtig (Dein Profilwert: *SCORE*).',
    en: 'Issues of social justice play an important role in *NAME*’s life (profile score: *SCORE_FR*). *NOMINATIVE.C* '
      + 'is concerned about the welfare of others and often campaigns actively to alleviate hardship and disadvantage. '
      + 'Social justice is less important to you (profile score: *SCORE*).'
  },
  'closeFilter.sen.high-high': {
    de: 'Soziale Gerechtigkeit spielt eine besondere Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). Genauso ' +
      'wie bei Dir (Dein Profilwert: *SCORE*). Ihr sorgt Euch um das Wohl anderer Menschen und setzt Euch häufig ' +
      'aktiv dafür ein, Not und Benachteiligung zu lindern. Ihr empfindet in der Regel eine tiefe Zufriedenheit ' +
      'anderen geholfen zu haben.',
    en: 'Issues of social justice play an important role in *NAME*’s life (profile score: *SCORE_FR*). Just like you '
      + '(your profile score: *SCORE*). Both of you are concerned about the welfare of other people, and often campaign '
      + 'actively to alleviate hardship and disadvantage. You both often experience a deep sense of satisfaction at '
      + 'having been able to help others.'
  },
  'closeFilter.sen.medium-high': {
    de: 'Soziale Gerechtigkeit ist für *NAME* (Profilwert: *SCORE_FR*) weniger wichtig als für Dich. Mit einem ' +
      'Profilwert von *SCORE* spielt soziale Gerechtigkeit für Dich eine besondere Rolle. Du sorgst Dich um das Wohl ' +
      'anderer Menschen und setzt Dich häufig aktiv dafür ein, Not und Benachteiligung zu lindern. ' +
      'Da Eure Profilpunkte nahe beieinanderliegen, empfindet Ihr recht ähnlich.',
    en: 'Social justice is less important to *NAME* (profile score: *SCORE_FR*) than it is to you. Your profile score '
      + 'of *SCORE* shows that issues of social justice play an important role in your life. You are concerned about '
      + 'the welfare of other people, and often campaign actively to alleviate hardship and disadvantage. The distance '
      + 'between your profile points here is marginal, which shows that you are similar in this regard.'
  },
  'closeFilter.sen.medium-medium': {
    de: 'Soziale Gerechtigkeit spielt keine signifikante Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). Genauso ' +
      'wie bei Dir (Profilwert: *SCORE*).',
    en: 'Issues of social justice do not play an important role in *NAME*’s life (profile score: *SCORE_FR*). '
      + 'Just like you (your profile score: *SCORE*).'
  },
  'closeFilter.sen.medium-low': {
    de: 'Soziale Gerechtigkeit ist für *NAME* (Profilwert: *SCORE_FR*) wichtiger als für Dich. Mit einem Profilwert ' +
      'von *SCORE* spielt soziale Gerechtigkeit lediglich eine untergeordnete Rolle. Du siehst soziales Engagement ' +
      'nicht als Deine persönliche Aufgabe an. ' +
      'Da Eure Profilpunkte nahe beieinanderliegen, empfindet Ihr recht ähnlich.',
    en: 'Social justice is more important to *NAME* (profile score: *SCORE_FR*) than it is to you. Your profile score '
      + 'of *SCORE* shows that issues of social justice play a minor role in your life. You do not see social '
      + 'engagement as a personal obligation. The distance between your profile points here is marginal, which shows '
      + 'that you are similar in this regard.'
  },
  'closeFilter.sen.low-low': {
    de: 'Soziale Gerechtigkeit spielt eine untergeordnete Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). ' +
      'Genauso wie bei Dir (Dein Profilwert: *SCORE*). Ihr seht soziales Engagement nicht als Eure persönliche ' +
      'Aufgabe an.',
    en: 'Issues of social justice play a minor role in *NAME*’s life (profile score: *SCORE_FR*). Just like you '
      + '(your profile score: *SCORE*). Both of do not see social engagement as a personal obligation.'
  },
  'closeFilter.sen.low-medium': {
    de: 'Soziale Gerechtigkeit spielt eine untergeordnete Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). ' +
      '*NOMINATIVE.C* sieht soziales Engagement nicht als *ACCUSATIVE.F* persönliche Aufgabe an. ' +
      'Dir ist soziale Gerechtigkeit wichtiger (Dein Profilwert: *SCORE*).',
    en: 'Issues of social justice play a minor role in *NAME*’s life (profile score: *SCORE_FR*). *NOMINATIVE.C* does '
      + 'not see social engagement as a personal obligation. Social justice is more important to you (profile score: *SCORE*).'
  },
  'closeFilter.str.high-medium': {
    de: 'Struktur, Ordnung und Sauberkeit spielen eine wichtige Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). ' +
      '*NOMINATIVE.C* genießt es, den Alltag zu ordnen, sei es durch Planung, strukturierte Tätigkeiten oder ' +
      'Routinen. Dir sind Struktur, Ordnung und Sauberkeit weniger wichtig (Dein Profilwert: *SCORE*).',
    en: 'Structure, order and cleanliness play an important role in *NAME*’s life (profile score: *SCORE_FR*). '
      + '*NOMINATIVE.C* enjoys bringing order to daily life, whether through plans, structured activities or routines. '
      + 'Structure, order and cleanliness are less important to you (profile score: *SCORE*).'
  },
  'closeFilter.str.high-high': {
    de: 'Struktur, Ordnung und Sauberkeit spielen eine wichtige Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). ' +
      'Genauso wie bei Dir (Dein Profilwert: *SCORE*). Ihr genießt es, den Alltag zu ordnen, sei es durch Planung, ' +
      'strukturierte Tätigkeiten oder Routinen und seid glücklich über einen funktionierenden Plan.',
    en: 'Structure, order and cleanliness play an important role in *NAME*’s life (profile score: *SCORE_FR*). Just '
      + 'like you (your profile score: *SCORE*). Both of you enjoy bringing order to your daily lives, whether through '
      + 'planning, structured activities or routines, and enjoy having a plan that works.'
  },
  'closeFilter.str.medium-high': {
    de: 'Struktur, Ordnung und Sauberkeit sind für *NAME* (Profilwert: *SCORE_FR*) weniger wichtig als für Dich. Mit ' +
      'einem Profilwert von *SCORE* spielen Struktur, Ordnung und Sauberkeit in Deinem Leben eine wichtige Rolle. ' +
      'Du genießt es, Deinen Alltag zu ordnen, sei es durch Planung, strukturierte Tätigkeiten oder Routinen. ' +
      'Da Eure Profilpunkte nahe beieinanderliegen, seid Ihr Euch recht ähnlich.',
    en: 'Structure, order and cleanliness are less important to *NAME* (profile score: *SCORE_FR*) than they are to you. '
      + 'Your profile score of *SCORE* shows that structure, order and cleanliness play an important role in your life. '
      + 'You enjoy bringing order to your daily life, whether through plans, structured activities or routines. The '
      + 'distance between your profile points here is marginal, which shows that you are quite similar in this regard.'
  },
  'closeFilter.str.medium-medium': {
    de: 'Struktur, Ordnung und Sauberkeit spielen keine signifikante Rolle im Leben von *NAME* ' +
      '(Profilwert: *SCORE_FR*). Genauso wie bei Dir (Profilwert: *SCORE*).',
    en: 'Structure, order and cleanliness do not play an important role in *NAME*\'s life (profile score: *SCORE_FR*). '
      + 'Just like you (your profile score: *SCORE*).'
  },
  'closeFilter.str.medium-low': {
    de: 'Struktur, Ordnung und Sauberkeit sind für *NAME* (Profilwert: *SCORE_FR*) wichtiger als für Dich. Mit einem ' +
      'Profilwert von *SCORE* spielen Ordnung und Sauberkeit für Dich lediglich eine untergeordnete Rolle. ' +
      'Strukturen und exakte Pläne engen Dich eher ein. ' +
      'Da Eure Profilpunkte nahe beieinanderliegen, seid Ihr Euch recht ähnlich.',
    en: 'Structure, order and cleanliness are more important to *NAME* (profile score: *SCORE_FR*) than they are to you. '
      + 'Your profile score of *SCORE* shows that order and cleanliness play a minor role in your life. You tend to feel '
      + 'constrained by structures and strict plans. The distance between your profile points here is marginal, which '
      + 'shows that you are quite similar in this regard.'
  },
  'closeFilter.str.low-low': {
    de: 'Ordnung und Sauberkeit spielen eine untergeordnete Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). ' +
      'Genauso wie bei Dir (Profilwert: *SCORE*). Strukturen und exakte Pläne engen Euch ein.',
    en: 'Structure, order and cleanliness play a minor role in *NAME*’s life (profile score: *SCORE_FR*). Just like '
      + 'you (your profile score: *SCORE*). Both of you feel constrained by structures and strict plans.'
  },
  'closeFilter.str.low-medium': {
    de: 'Ordnung und Sauberkeit spielen eine untergeordnete Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). ' +
      'Strukturen und exakte Pläne engen *ACCUSATIVE* ein. ' +
      'Dir sind Struktur, Ordnung und Sauberkeit wichtiger (Dein Profilwert: *SCORE*).',
    en: 'Structure, order and cleanliness play a minor role in *NAME*’s life (profile score: *SCORE_FR*). '
      + '*NOMINATIVE.C* feels constrained by structures and strict plans. Structure, order and cleanliness are more '
      + 'important to you (profile score: *SCORE*).'
  },
  'closeFilter.sic.high-medium': {
    de: 'Sicherheit spielt eine besondere Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). *NOMINATIVE.N.C* ' +
      'Verhalten ist geprägt davon, potenzielle Gefahren zu vermeiden. ' +
      'Sicherheit ist Dir weniger wichtig (Dein Profilwert: *SCORE*).',
    en: 'Safety plays an important role in *NAME*’s life (profile score: *SCORE_FR*). *ACCUSATIVE.N.C* behavior is '
      + 'guided by the desire to avoid potential hazards. Safety is less important to you (profile score: *SCORE*).'
  },
  'closeFilter.sic.high-high': {
    de: 'Sicherheit spielt eine besondere Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). Genauso wie bei Dir ' +
      '(Dein Profilwert: *SCORE*). Euer Verhalten ist geprägt davon, potenzielle Gefahren zu vermeiden. Ihr neigt in ' +
      'unbekannten Situationen dazu, sehr vorsichtig zu sein und seid in der Regel zufrieden, wenn das Leben in ' +
      'vorhersehbaren Bahnen verläuft.',
    en: 'Safety plays an important role in *NAME*’s life (profile score: *SCORE_FR*). Just like you (your profile score: *SCORE*). '
      + 'With both of you, your behavior is guided by the desire to avoid potential hazards. You both tend to be very '
      + 'cautious in unfamiliar situations, and are usually content with predictability in life.'
  },
  'closeFilter.sic.medium-high': {
    de: 'Sicherheit ist für *NAME* (Profilwert: *SCORE_FR*) weniger wichtig. Mit einem Profilwert von *SCORE* spielt ' +
      'Sicherheit in Deinem Leben eine besondere Rolle. Dein Verhalten ist geprägt davon, potenzielle Gefahren zu ' +
      'vermeiden. Da Eure Profilpunkte nahe beieinanderliegen, verhaltet Ihr Euch recht ähnlich.',
    en: 'Safety is less important to *NAME* (profile score: *SCORE_FR*) than it is to you. Your profile score of '
      + '*SCORE* shows that safety plays an important role in your life. Your behavior is guided by the desire to '
      + 'avoid potential hazards. The distance between your profile points here is marginal, which shows that your '
      + 'behaviors are similar in this regard.'
  },
  'closeFilter.sic.medium-medium': {
    de: 'Sicherheit spielt keine signifikante Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). Genauso wie bei ' +
      'Dir (Profilwert: *SCORE*).',
    en: 'Safety does not play an important role in *NAME*’s life (profile score: *SCORE_FR*). '
      + 'Just like you (your profile score: *SCORE*).'
  },
  'closeFilter.sic.medium-low': {
    de: 'Sicherheit ist für *NAME* (Profilwert: *SCORE_FR*) wichtiger als für Dich. Mit einem Profilwert von *SCORE* ' +
      'spielt Sicherheit für Dich lediglich eine untergeordnete Rolle. Du neigst dazu, Risiken einzugehen und ' +
      'stellst Dich gerne Situationen, die potenzielle Gefahren mit sich bringen. Da Eure Profilpunkte nahe ' +
      'beieinanderliegen, verhaltet Ihr Euch recht ähnlich.',
    en: 'Safety is more important to *NAME* (profile score: *SCORE_FR*) than it is to you. Your profile score of '
      + '*SCORE* shows that safety plays a minor role in your life. You tend to take risks and like to take on '
      + 'situations that may entail hazards. The distance between your profile points here is marginal, which shows '
      + 'that your behaviors are similar in this regard.'
  },
  'closeFilter.sic.low-low': {
    de: 'Sicherheit spielt eine untergeordnete Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). Genauso wie bei ' +
      'Dir (Profilwert: *SCORE*). Ihr neigt dazu, Risiken einzugehen und stellt Euch gerne Situationen, die ' +
      'potenzielle Gefahren mit sich bringen.',
    en: 'Safety plays a minor role in *NAME*’s life (profile score: *SCORE_FR*). Just like you (your profile score: *SCORE*). '
      + 'You both tend to take risks and like to take on situations that may entail hazards.'
  },
  'closeFilter.sic.low-medium': {
    de: 'Sicherheit spielt eine untergeordnete Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). *NOMINATIVE.C* ' +
      'neigt dazu, Risiken einzugehen und stellt sich gerne Situationen, die potenzielle Gefahren mit sich bringen. ' +
      'Für Dich ist Sicherheit wichtiger (Dein Profilwert: *SCORE*).',
    en: 'Safety plays a minor role in *NAME*’s life (profile score: *SCORE_FR*). *NOMINATIVE.C* tends to take risks and '
      + 'likes to take on situations that may entail hazards. Safety is less important to you (profile score: *SCORE*).'
  },
  'closeFilter.rev.high-medium': {
    de: '*NAME* neigt dazu, es anderen heimzuzahlen, wenn *NOMINATIVE* das Gefühl hat, von jemandem betrogen, ' +
      'beleidigt oder hintergangen worden zu sein (Profilwert: *SCORE_FR*). *NOMINATIVE.C* möchte erlebtes Unrecht ' +
      'vergelten. Für Dich ist es weniger wichtig, erlebtes Unrecht zu vergelten (Dein Profilwert: *SCORE*).',
    en: '*NAME* tends to seek retribution if *NOMINATIVE* feels betrayed, insulted or deceived (profile score: *SCORE_FR*). '
      + '*NOMINATIVE.C* seeks retribution for past wrongs. You are less invested in seeking retribution for past wrongs '
      + '(your profile score: *SCORE*). '
  },
  'closeFilter.rev.high-high': {
    de: 'Erlebtes Unrecht zu vergelten spielt eine besondere Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). '
      + 'Genauso wie bei Dir (Dein Profilwert: *SCORE*). Ihr möchtet erlebtes Unrecht vergelten und erlebt Genugtuung, '
      + 'es anderen gezeigt zu haben.',
    en: 'Seeking retribution for past wrongs plays an important role in *NAME*’s life (profile score: *SCORE_FR*). '
      + 'Just like you (your profile score: *SCORE*). Both of you seek retribution for past wrongs, and you feel '
      + 'gratified by expressing rage to the person one feels is responsible.'
  },
  'closeFilter.rev.medium-high': {
    de: 'Erlebtes Unrecht zu vergelten spielt im Leben von *NAME* (Profilwert: *SCORE_FR*) eine geringere Rolle als '
      + 'bei Dir. Mit einem Profilwert von *SCORE* neigst Du dazu, es anderen heimzuzahlen, wenn Du das Gefühl hast, '
      + 'von jemandem betrogen, beleidigt oder hintergangen worden zu sein. '
      + 'Da Eure Profilpunkte nahe beieinanderliegen, seid Ihr Euch recht ähnlich.',
    en: 'Seeking retribution for past wrongs plays a less important role in *NAME*’s life (profile score: *SCORE_FR*) '
      + 'than it does in yours. Your profile score of *SCORE* shows that you tend to get back at those whom you feel '
      + 'have betrayed, insulted or deceived you. The distance between your profile points here is marginal, which '
      + 'shows that you are quite similar in this regard.'
  },
  'closeFilter.rev.medium-medium': {
    de: 'Erlebtes Unrecht zu vergelten spielt keine signifikante Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). ' +
      'Genauso wie bei Dir (Profilwert: *SCORE*).',
    en: 'Seeking retribution for past wrongs does not play an important role in *NAME*’s life '
      + '(profile score: *SCORE_FR*). Just like you (your profile score: *SCORE*).'
  },
  'closeFilter.rev.medium-low': {
    de: 'Erlebtes Unrecht zu vergelten spielt im Leben von *NAME* (Profilwert: *SCORE_FR*) eine größere Rolle als ' +
      'bei Dir. Mit einem Profilwert von *SCORE* neigst Du nicht dazu, es anderen heimzuzahlen, ' +
      'wenn Du das Gefühl hast, von jemandem ungerecht behandelt worden zu sein. ' +
      'Da Eure Profilpunkte nahe beieinanderliegen, seih Ihr Euch recht ähnlich.',
    en: 'Seeking retribution for past wrongs plays a more important role in *NAME*’s life (profile score: *SCORE_FR*) '
      + 'than it does in yours. Your profile score of *SCORE* shows that you do not tend to get back at those whom you '
      + 'feel have treated you unfairly. The distance between your profile points here is marginal, which shows that '
      + 'you are quite similar in this regard.'
  },
  'closeFilter.rev.low-low': {
    de: '*NAME* neigt nicht dazu, es anderen heimzuzahlen, wenn *NOMINATIVE* das Gefühl hat, von jemandem ungerecht ' +
      'behandelt worden zu sein (Profilwert: *SCORE_FR*). Genauso wie Du (Profilwert: *SCORE*). Ihr könnt in der ' +
      'Regel über Kränkungen hinwegsehen und verzeihen.',
    en: '*NAME* tends not to seek retribution if *NOMINATIVE* feels betrayed, insulted or deceived '
      + '(profile score: *SCORE_FR*). Just like you (your profile score: *SCORE*). You are generally able to ignore it '
      + 'when somebody offends you and can forgive them.'
  },
  'closeFilter.rev.low-medium': {
    de: '*NAME* neigt nicht dazu, es anderen heimzuzahlen, wenn *NOMINATIVE* das Gefühl hat, von jemandem ungerecht ' +
      'behandelt worden zu sein (Profilwert: *SCORE_FR*). ' +
      'Für Dich ist es wichtiger, erlebtes Unrecht zu vergelten (Dein Profilwert: *SCORE*).',
    en: '*NAME* tends not to seek retribution if *NOMINATIVE* feels betrayed, insulted or deceived (profile score: *SCORE_FR*). '
      + 'You are more invested in seeking retribution for past wrongs (your profile score: *SCORE*).'
  },
  'closeFilter.bew.high-medium': {
    de: 'Körperliche Bewegung spielt eine besondere Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). Es ist eines ' +
      '*GENITIVE.F/PL* wichtigsten Lebensziele, sich körperlich fit zu halten. ' +
      'Dir ist körperliche Bewegung weniger wichtig (Dein Profilwert: *SCORE*).',
    en: 'Physical exercise plays an important role in *NAME*’s life (profile score: *SCORE_FR*). Staying physically '
      + 'fit is one of *GENITIVE.F/PL* most important goals in life. Physical exercise is less important to you '
      + '(your profile score: *SCORE*).'
  },
  'closeFilter.bew.high-high': {
    de: 'Körperliche Bewegung spielt eine besondere Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). Genauso wie ' +
      'bei Dir (Dein Profilwert: *SCORE*). Es ist eines Eurer wichtigsten Lebensziele, Euch körperlich fit zu halten ' +
      'und den Körper zu spüren.',
    en: 'Physical exercise plays an important role in *NAME*’s life (profile score: *SCORE_FR*). '
      + 'Just like you (your profile score: *SCORE*). For both of you, staying physically fit and experiencing what '
      + 'your body is capable of are essential goals in life.'
  },
  'closeFilter.bew.medium-high': {
    de: 'Körperliche Bewegung ist für *NAME* (Profilwert: *SCORE_FR*) weniger wichtig als für Dich. Mit einem ' +
      'Profilwert von *SCORE* spielt körperliche Bewegung eine besondere Rolle in Deinem Leben. Dich körperlich fit ' +
      'zu halten hat eine Bedeutung für Dich. Da Eure Profilpunkte nahe beieinanderliegen, empfindet Ihr ähnlich.',
    en: 'Physical exercise is less important to *NAME* (profile score: *SCORE_FR*) than it is to you. Your profile '
      + 'score of *SCORE* shows that physical exercise plays an important role in your life. Keeping yourself '
      + 'physically fit is a relevant factor in life. The distance between your profile points here is marginal, '
      + 'which shows that you are similar in this regard.'
  },
  'closeFilter.bew.medium-medium': {
    de: 'Körperliche Bewegung spielt keine signifikante Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). ' +
      'Genauso wie bei Dir (Profilwert: *SCORE*).',
    en: 'Physical exercise does not play an important role in *NAME*’s life (profile score: *SCORE_FR*). Just like you '
      + '(your profile score: *SCORE*).'
  },
  'closeFilter.bew.medium-low': {
    de: 'Körperliche Bewegung ist für *NAME* (Profilwert: *SCORE_FR*) wichtiger als für Dich. Mit einem Profilwert ' +
      'von *SCORE* spielt körperliche Bewegung für Dich lediglich eine untergeordnete Rolle im Leben. ' +
      'Da Eure Profilpunkte nahe beieinanderliegen, empfindet Ihr recht ähnlich.',
    en: 'Physical exercise is more important to *NAME* (profile score: *SCORE_FR*) than it is to you. Your profile '
      + 'score of *SCORE* shows that physical exercise plays a minor role in your life. The distance between your '
      + 'profile points here is marginal, which shows that you are similar in this regard.'
  },
  'closeFilter.bew.low-low': {
    de: 'Körperliche Bewegung spielt eine untergeordnete Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). Genauso ' +
      'wie bei Dir (Profilwert: *SCORE*). Euch sind andere Dinge wichtiger als Sport zu treiben. Körperlich ' +
      'anstrengende Tätigkeiten vermeidet ihr eher.',
    en: 'Physical exercise plays a minor role in *NAME*’s life (profile score: *SCORE_FR*). Just like you '
      + '(your profile score: *SCORE*). Both of you find other things more important than doing sports. You are both '
      + 'likely to avoid engaging in physically strenuous activities.'
  },
  'closeFilter.bew.low-medium': {
    de: 'Körperliche Bewegung spielt eine untergeordnete Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). ' +
      '*DATIVE.C* sind andere Dinge wichtiger als Sport zu treiben. Körperlich anstrengende Tätigkeiten vermeidet ' +
      '*NOMINATIVE* eher. Dir ist körperliche Bewegung wichtiger (Dein Profilwert: *SCORE*).',
    en: 'Physical exercise plays a minor role in *NAME*’s life (profile score: *SCORE_FR*). For *DATIVE*, there are '
      + 'more important things than doing sports. *NOMINATIVE.C* is likely to avoid engaging in physically strenuous '
      + 'activities. Physical exercise is more important to you (your profile score: *SCORE*).'
  },
  'closeFilter.ess.high-medium': {
    de: 'Essen spielt im Leben von *NAME* – weit über die Befriedigung physiologischer Grundbedürfnisse hinaus eine ' +
      'besondere Rolle (Profilwert: *SCORE_FR*). *NOMINATIVE.C* genießt es jederzeit, sich mit dem Thema ' +
      'Essen zu beschäftigen und ist teilweise geradezu euphorisch darüber. In Deinem Leben spielt Essen keine ' +
      'signifikante Rolle (Dein Profilwert: *SCORE*).',
    en: 'Food plays an important role in *NAME*’s life – far beyond the satisfaction of basic physiological needs '
      + '(profile score: *SCORE_FR*). *NOMINATIVE.C* cultivates an active interest in the topic of food and is, at '
      + 'times, downright euphoric about it. Food does not play an important role in your life (your profile score: *SCORE*).'
  },
  'closeFilter.ess.high-high': {
    de: 'Essen spielt im Leben von *NAME* – weit über die Befriedigung physiologischer Grundbedürfnisse hinaus eine ' +
      'besondere Rolle (Profilwert: *SCORE_FR*). Genauso wie bei Dir (Dein Profilwert: *SCORE*). Ihr genießt ' +
      'es jederzeit, Euch mit dem Thema Essen zu beschäftigen und seid teilweise geradezu euphorisch darüber.',
    en: 'Food plays an important role in *NAME*’s life – far beyond the satisfaction of basic physiological needs '
      + '(profile score: *SCORE_FR*). Just like you (your profile score: *SCORE*). Both of you cultivate an active '
      + 'interest in the topic of food and are, at times, downright euphoric about it.'
  },
  'closeFilter.ess.medium-high': {
    de: 'Essensgenuss ist für *NAME* (Profilwert: *SCORE_FR*) weniger bedeutend als für Dich. Mit einem Profilwert ' +
      'von *SCORE* spielt Essen für Dich – weit über die Befriedigung physiologischer Grundbedürfnisse hinaus eine ' +
      'besondere Rolle. Du genießt es jederzeit, Dich mit dem Thema Essen zu beschäftigen und bist teilweise ' +
      'geradezu euphorisch darüber. Da Eure Profilpunkte nahe beieinanderliegen, seid Ihr Euch recht ähnlich.',
    en: 'Enjoying food is less important to *NAME* (profile score: *SCORE_FR*) than it is to you. Your profile score '
      + 'of *SCORE* shows that food plays an important role in your life – far beyond the satisfaction of basic '
      + 'physiological needs. You cultivate an active interest in the topic of food and are, at times, downright '
      + 'euphoric about it. The distance between your profile points here is marginal, which shows that you are quite '
      + 'similar in this regard.'
  },
  'closeFilter.ess.medium-medium': {
    de: 'Essen spielt keine signifikante Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). ' +
      'Genauso wie bei Dir (Profilwert: *SCORE*).',
    en: 'Food does not play an important role in *NAME*’s life (profile score: *SCORE_FR*). '
      + 'Just like you (your profile score: *SCORE*).'
  },
  'closeFilter.ess.medium-low': {
    de: 'Essensgenuss ist für *NAME* (Profilwert: *SCORE_FR*) wichtiger als für Dich. Mit einem Profilwert von ' +
      '*SCORE* spielt Essen für Dich nur eine untergeordnete Rolle. ' +
      'Über die Befriedigung des Hungergefühls hinaus hat das Thema Essen wenig Bedeutung für Dich. ' +
      'Da Eure Profilpunkte nahe beieinanderliegen, seid Ihr Euch recht ähnlich.',
    en: 'Enjoying food is more important to *NAME* (profile score: *SCORE_FR*) than it is to you. Your profile score '
      + 'of *SCORE* shows that food plays a minor role in your life. Beyond satisfying hunger, food has little '
      + 'significance for you. The distance between your profile points here is marginal, which shows that you are '
      + 'quite similar in this regard.'
  },
  'closeFilter.ess.low-low': {
    de: 'Essen spielt eine untergeordnete Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). Genauso wie bei Dir ' +
      '(Profilwert: *SCORE*). Für Euch hat über die Befriedigung des Hungergefühls hinaus das Thema Essen in der ' +
      'Regel wenig Bedeutung.',
    en: 'Food plays a minor role in *NAME*’s life (profile score: *SCORE_FR*). Just like you '
      + '(your profile score: *SCORE*). Beyond satisfying hunger, food has little significance for either of you.'
  },
  'closeFilter.ess.low-medium': {
    de: 'Essen spielt eine untergeordnete Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). Für *ACCUSATIVE* hat ' +
      'über die Befriedigung des Hungergefühls hinaus das Thema Essen in der Regel wenig Bedeutung. Allein nimmt ' +
      '*NOMINATIVE* sich beispielsweise wenig Zeit zum Essen. ' +
      'Essensgenuss ist Dir wichtiger (Dein Profilwert: *SCORE*).',
    en: 'Food plays a minor role in *NAME*’s life (profile score: *SCORE_FR*). For *ACCUSATIVE*, food has little '
      + 'significance beyond satisfying hunger. *NOMINATIVE.C* doesn’t take much time to eat. Enjoying food is more '
      + 'important to you (your profile score: *SCORE*).'
  },
  'closeFilter.fam.high-medium': {
    de: 'Die Familie ist ein wichtiger Bezugspunkt im Leben von *NAME* (Profilwert: *SCORE_FR*). Ein harmonisches ' +
      'Familienleben mit enger Verbundenheit ist von hoher Bedeutung für *GENITIVE.F/PL* Lebenszufriedenheit. ' +
      'Dir ist die Familie weniger wichtig (Dein Profilwert: *SCORE*).',
    en: 'Family plays an important role in *NAME*’s life (profile score: *SCORE_FR*). A harmonious family life, '
      + 'characterized by close family ties, is very important to *GENITIVE.F/PL* satisfaction with life. Family is less '
      + 'important to you (profile score: *SCORE*).'
  },
  'closeFilter.fam.high-high': {
    de: 'Die Familie ist ein wichtiger Bezugspunkt im Leben von *NAME* (Profilwert: *SCORE_FR*). Genauso wie bei Dir ' +
      '(Dein Profilwert: *SCORE*). Ein harmonisches Familienleben mit enger Verbundenheit ist von hoher Bedeutung ' +
      'für Eure Lebenszufriedenheit.',
    en: 'Family plays an important role in *NAME*’s life (profile score: *SCORE_FR*). Just like you '
      + '(your profile score: *SCORE*). A harmonious family life, characterized by close family ties, is very '
      + 'important to both of in terms of your sense of satisfaction with life.'
  },
  'closeFilter.fam.medium-high': {
    de: 'Die Familie hat für *NAME* (Profilwert: *SCORE_FR*) eine geringere Bedeutung als für Dich. ' +
      'Mit einem Profilwert von *SCORE* ist Familie für Dich ein wichtiger Bezugspunkt in Deinem Leben. ' +
      'Ein harmonisches Familienleben mit enger Verbundenheit ist von hoher Bedeutung für Deine Lebenszufriedenheit. ' +
      'Da Eure Profilpunkte nahe beieinanderliegen, seid Ihr Euch recht ähnlich.',
    en: 'Family is less important to *NAME* (profile score: *SCORE_FR*) than it is to you. Your profile score of '
      + '*SCORE* shows that family plays an important role in your life. A harmonious family life, characterized by '
      + 'close family ties, is very important to your sense of satisfaction with life. The distance between your '
      + 'profile points here is marginal, which shows that you are quite similar in this regard.'
  },
  'closeFilter.fam.medium-medium': {
    de: 'Die Familie spielt keine signifikante Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). ' +
      'Genauso wie bei Dir (Profilwert: *SCORE*).',
    en: 'Family does not play an important role in *NAME*’s life (profile score: *SCORE_FR*). '
      + 'Just like you (your profile score: *SCORE*).'
  },
  'closeFilter.fam.medium-low': {
    de: 'Die Familie hat für *NAME* (Profilwert: *SCORE_FR*) eine höhere Bedeutung als für Dich. Mit einem ' +
      'Profilwert von *SCORE* spielt die Familie bei Dir nur eine untergeordnete Rolle. Das Familienleben ' +
      'hat nicht die höchste Bedeutung für Deine Lebenszufriedenheit. ' +
      'Da Eure Profilpunkte nahe beieinanderliegen, seid Ihr Euch recht ähnlich.',
    en: 'Family is more important to *NAME* (profile score: *SCORE_FR*) than it is to you. Your profile score of '
      + '*SCORE* shows that family plays a minor role in your life. Family life is not the most important factor to '
      + 'satisfaction with life. The distance between your profile points here is marginal, which shows that you are '
      + 'quite similar in this regard.'
  },
  'closeFilter.fam.low-low': {
    de: 'Die Familie spielt eine untergeordnete Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). Genauso wie bei ' +
      'Dir (Profilwert: *SCORE*). Das Familienleben hat nicht die höchste Bedeutung für Eure Lebenszufriedenheit.',
    en: 'Family plays a minor role in *NAME*’s life (profile score: *SCORE_FR*). Just like you '
      + '(your profile score: *SCORE*). Family life is not the most important factor to satisfaction with life.'
  },
  'closeFilter.fam.low-medium': {
    de: 'Die Familie spielt eine untergeordnete Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). Familienleben ' +
      'hat nicht die höchste Bedeutung für *GENITIVE.F/PL* Lebenszufriedenheit - *NOMINATIVE* ist zufrieden, wenn ' +
      '*NOMINATIVE* keine familiären Verpflichtungen hat. Dir ist Familie wichtiger (Dein Profilwert: *SCORE*).',
    en: 'Family plays a minor role in *NAME*’s life (profile score: *SCORE_FR*). Family life is not the most important '
      + 'factor to *GENITIVE.F/PL* satisfaction with life - *NOMINATIVE* is content when *NOMINATIVE* has no familial '
      + 'obligations. Family is more important to you (your profile score: *SCORE*).'
  },
  'closeFilter.sin.high-medium': {
    de: 'Erotik, Sinnlichkeit und Leidenschaft spielen eine wichtige Rolle im Leben von *NAME* ' +
      '(Profilwert: *SCORE_FR*). *NOMINATIVE.C* genießt es, *ACCUSATIVE.F* Sexualität ausleben zu können ' +
      'und sucht regelmäßig Gelegenheiten dafür. ' +
      'Dir sind Erotik, Sinnlichkeit und Leidenschaft weniger wichtig (Dein Profilwert: *SCORE*).',
    en: 'Eroticism, sensuality and passion play an important role in *NAME*’s life (profile score: *SCORE_FR*). '
      + '*NOMINATIVE.C* enjoys being sexually active and regularly seeks out opportunities to live out *ACCUSATIVE.N* '
      + 'sexuality. Eroticism, sensuality and passion are less important to you (profile score: *SCORE*).'
  },
  'closeFilter.sin.high-high': {
    de: 'Erotik, Sinnlichkeit und Leidenschaft spielen eine wichtige Rolle im Leben von *NAME* ' +
      '(Profilwert: *SCORE_FR*). Genauso wie bei Dir (Dein Profilwert: *SCORE*). Ihr genießt es, Eure Sexualität ' +
      'ausleben zu können und sucht regelmäßig Gelegenheiten dafür.',
    en: 'Eroticism, sensuality and passion play an important role in *NAME*\'s life (profile score: *SCORE_FR*). Just '
      + 'like you (your profile score: *SCORE*). Both of you enjoy being sexually active and regularly seek out '
      + 'opportunities to have erotic experiences.'
  },
  'closeFilter.sin.medium-high': {
    de: 'Erotik, Sinnlichkeit und Leidenschaft sind *NAME* (Profilwert: *SCORE_FR*) weniger wichtig als Dir. ' +
      'Mit einem Profilwert von *SCORE* spielen Erotik, Sinnlichkeit und Leidenschaft für Dich hingegen eine ' +
      'wichtige Rolle. Du genießt es, Deine Sexualität ausleben zu können und suchst regelmäßig Gelegenheiten dafür. ' +
      'Da Eure Profilpunkte nahe beieinanderliegen, seid Ihr Euch recht ähnlich.',
    en: 'Eroticism, sensuality and passion are less important to *NAME* (profile score: *SCORE_FR*) than they are to '
      + 'you. Your profile score of *SCORE* shows that, by contrast, eroticism, sensuality and passion play an '
      + 'important role in your life. You enjoy being sexually active and regularly seek out opportunities to have '
      + 'erotic experiences. The distance between your profile points here is marginal, which shows that you are quite '
      + 'similar in this regard.'
  },
  'closeFilter.sin.medium-medium': {
    de: 'Erotik, Sinnlichkeit und Leidenschaft spielen keine signifikante Rolle im Leben von *NAME* ' +
      '(Profilwert: *SCORE_FR*). Genauso wie bei Dir (Profilwert: *SCORE*).',
    en: 'Eroticism, sensuality and passion do not play an important role in *NAME*’s life (profile score: *SCORE_FR*). '
      + 'Just like you (your profile score: *SCORE*).'
  },
  'closeFilter.sin.medium-low': {
    de: 'Erotik, Sinnlichkeit und Leidenschaft sind *NAME* (Profilwert: *SCORE_FR*) wichtiger als Dir. ' +
      'Mit einem Profilwert von *SCORE* spielen Erotik und Leidenschaft bei Dir sogar nur ' +
      'eine untergeordnete Rolle. Ein erfülltes Sexualleben ist für Dein Lebensglück nicht entscheidend. ' +
      'Da Eure Profilpunkte nahe beieinanderliegen, seid Ihr euch recht ähnlich.',
    en: 'Eroticism, sensuality and passion are more important to *NAME* (profile score: *SCORE_FR*) than they are to '
      + 'you. Your profile score of *SCORE* shows that eroticism and passion play only a minor role in your life. A '
      + 'fulfilling sex life is not integral to your happiness in life. The distance between your profile points here '
      + 'is marginal, which shows that you are quite similar in this regard.'
  },
  'closeFilter.sin.low-low': {
    de: 'Erotik und Leidenschaft spielen eine untergeordnete Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). ' +
      'Genauso wie bei Dir (Profilwert: *SCORE*). Ein erfülltes Sexualleben ist für Euer Lebensglück nicht ' +
      'entscheidend.',
    en: 'Eroticism, sensuality and passion play a minor role in *NAME*’s life (profile score: *SCORE_FR*). '
      + 'Just like you (your profile score: *SCORE*). A fulfilling sex life is not integral to your happiness in life.'
  },
  'closeFilter.sin.low-medium': {
    de: 'Erotik und Leidenschaft spielen eine untergeordnete Rolle im Leben von *NAME* (Profilwert: *SCORE_FR*). ' +
      'Ein erfülltes Sexualleben ist für *ACCUSATIVE.N* Lebensglück nicht entscheidend. ' +
      '*NOMINATIVE.N.C* Wunsch nach Erotik ist eher schwach ausgeprägt. ' +
      'Dir sind Erotik, Sinnlichkeit und Leidenschaft wichtiger (Dein Profilwert: *SCORE*).',
    en: 'Eroticism, sensuality and passion play a minor role in *NAME*’s life (profile score: *SCORE_FR*). A '
      + 'fulfilling sex life is not integral to *ACCUSATIVE.N* happiness in life. *NOMINATIVE.N.C* desire for '
      + 'eroticism is rather weak. Eroticism, sensuality and passion are more important to you (profile score: *SCORE*).'
  },
  'closeFilter.reflexion.headline': {
    de: 'Reflexion',
    en: 'Reflection'
  },
  'closeFilter.reflexion.text': {
    de: 'Motive, auf denen Ihr eine ähnliche Ausprägung habt, erlebt Ihr ähnlich. Ihr versteht Euch dann tendenziell ' +
      'sehr gut. Natürlich kann es inhaltlich zu möglichen Differenzen kommen. Denke doch mal darüber nach – noch ' +
      'viel besser ist es, wenn Du dies mit *NAME* gemeinsam machst. Die nachfolgenden Fragen können Euch beim ' +
      'Reflektieren helfen.',
    en: 'Motives for which you both have a similar expression are those that you experience similarly. You’ll tend to '
      + 'get along very well in these areas. Of course, there are several different ways in which you’ll differ in '
      + 'terms of the details. You might consider doing this together with *NAME*, as this can result in even better '
      + 'outcomes. The following questions may be of help as you reflect together.'
  },
  'closeFilter.reflexion.listItem1.headline': {
    de: 'In Bezug auf Eure Beziehung:',
    en: 'Regading your relationship with each other:'
  },
  'closeFilter.reflexion.listItem1_1': {
    de: 'In welchen Situationen funktionierst Du mit *NAME* super zusammen?',
    en: 'In which situations do you work really well with *NAME*?'
  },
  'closeFilter.reflexion.listItem1_2': {
    de: 'Gibt es Bereiche bei denen es inhaltlich immer wieder zu Unstimmigkeiten kommt? Welche sind das?',
    en: 'Are there any areas where you repeatedly disagree? Which areas are they?'
  },
  'closeFilter.reflexion.listItem1_3': {
    de: 'Schreibt diese so konkret wie möglich auf. Sprecht darüber. Lösungen werden dann umsetzbar, wenn sie ' +
      'spezifische Vereinbarungen über konkrete Verhaltensweisen in bestimmten Situationen enthalten. Was könnte ' +
      'das bei Euch sein?',
    en: 'Write down and describe them, and be as specific as possible. Talk about them with each other Solutions '
      + 'become actionable when they involve making specific agreements on types of behavior in certain situations. '
      + 'What might this mean for the both of you?'
  },
  'closeFilter.reflexion.listItem2.headline': {
    de: 'In Bezug auf andere Personen:',
    en: 'Regarding other people:'
  },
  'closeFilter.reflexion.listItem2_1': {
    de: 'Wie verhaltet Ihr Euch dann gegenüber den anderen?',
    en: 'How do you guys behave toward others?'
  },
  'closeFilter.reflexion.listItem2_2': {
    de: 'Respektiert Ihr in Eurem Team, Eurer Familie, in der Clique diejenigen, denen vermutlich andere Aspekte ' +
      'im Leben wichtig sind?',
    en: 'Do you demonstrate respect for those in your team, your family, or in your circle of friends, '
      + 'for whom other aspects of life are important?'
  },
  'closeFilter.reflexion.listItem2_3': {
    de: 'Was könnt Ihr tun, um dafür ein besseres Augenmerk zu haben?',
    en: 'What can you do to develop a stronger sensitivity for this?'
  },
  'closeFilter.reflexion.listItem2_4': {
    de: 'Lösungen werden dann umsetzbar, wenn sie spezifische Vereinbarungen über konkrete Verhaltensweisen in ' +
      'bestimmten Situationen enthalten. Was könnte das bei Euch sein?',
    en: 'Solutions become actionable when they involve making specific agreements on types of behavior in certain '
      + 'situations. What might this mean for the both of you?'
  },
  'closeFilter.reflexion.attitude': {
    de: '<strong>Haltung</strong><br>' +
      'Geht immer davon aus, dass die anderen gute Absichten haben. Dies ermöglicht es Euch, offener zu sein und die ' +
      'Beziehung zu anderen zu verbessern.',
    en: '<strong>Attitude</strong><br>'
      + 'Always assume that the others have good intentions. This will allow you to be more open and relate better to others.'

  },
  /* MODERATE FILTER */
  'moderateFilter.exercise.headline': {
    de: 'Übung',
    en: 'Exercise'
  },
  'moderateFilter.exercise.experiences.headline': {
    de: '„Unsere Erlebnisse dieser Woche“',
    en: '“Our experiences this week”'
  },
  'moderateFilter.exercise.experiences.text': {
    de: 'Wenn es darum geht, ein besseres Miteinander zu gestalten, lohnt es sich immer die Motive zu betrachten, ' +
      'auf denen Ihr Euch unterscheidet. <br><br>' +
      'Geht Motiv für Motiv durch und erzählt Euch Eure emotional stärksten Momente dieser Woche, die zum ' +
      'jeweiligen Motiv passen.<br><br>' +
      'Bitte beachte: Es geht nicht darum, Ereignisse zu suchen, die Euch und Eure Beziehung betreffen. Es geht ' +
      'darum, Gefühle zu beschreiben und deutlich zu machen, was diese Gefühle jeweils ausgelöst hat.',
    en: 'When it comes to improving how you get along, it’s always worth looking at the motives in which you differ. <br><br>'
      + 'Go through each motive and give an account of your most emotionally salient moments from the last week for each.<br><br>'
      + 'Remember: This is not about looking for events that impact you each individually or your relationship. '
      + 'The point here is to describe your feelings and make clear what it is that triggered those feelings in each case.'

  },
  /* ALL FILTER */
  'allFilter.glance.headline': {
    de: 'Auf einen Blick',
    en: 'At a glance'
  },
  'allFilter.glance.text': {
    de: 'Hier wird das gesamte Spektrum Eurer emotionalen Distanzen sichtbar: ',
    en: 'Here you see the full spectrum of how you differ emotionally: '
  },
  'allFilter.listItemDistant': {
    de: '<a>Darin seid Ihr unterschiedlich</a> ',
    en: '<a>This is where you differ</a> '
  },
  'allFilter.listItemClose': {
    de: '<a>Das verbindet Euch</a> ',
    en: '<a>You are very similar in this regard</a> '
  },
  'allFilter.listItemModerate': {
    de: '<a>Darin seid Ihr unterschiedlich, aber nur gering</a>',
    en: '<a>This is where you differ, but only marginally</a> '
  },
  /* CONSULT MASTER */
  'consultMaster.title': {
    de: 'Weiter wachsen',
    en: 'Grow further'
  },
  'consultMaster.headline': {
    de: 'Beziehungen gestalten und pflegen',
    en: 'Shape and cultivate your relationships'
  },
  'consultMaster.text': {
    de: 'Ihr habt viele Anregungen erhalten, um übereinander und über Eure Beziehung nachzudenken. Tauscht Euch aus, ' +
      'um Eure Beziehung bewusster zu gestalten. Die nachfolgenden 3 Schritte geben Orientierung:',
    en: 'You’ve been given several ideas to think about with regard to yourself, others and your relationship. Now '
      + 'you can exchange your thoughts with each other so that you both have a better understanding of your '
      + 'relationship. The following three steps will help you get started:'
  },
  'consultMaster.listItem1': {
    de: '<strong>ID37 Profile reflektieren</strong><br>' +
      'Steuern kann man nur sich selbst. Es liegt also an jedem von Euch aktiv zu werden:',
    en: '<strong>Reflect on your ID37 profiles</strong><br>'
      + 'You have the power to steer only your own behavior. It\'s up to each of you individually to take action:'

  },
  'consultMaster.listItem1_1': {
    de: 'Mach Dir bewusst, welche Motive wie wirken. Was sorgt bei Dir für Wohlbefinden, was sorgt für Frust? Wie ist es bei *NAME*?',
    en: 'Develop an awareness of which motives function how. What makes you feel good, what makes you feel frustrated? How about *NAME*?'
  },
  'consultMaster.listItem1_2': {
    de: 'Sorge dafür, dass Dein Erleben für *NAME* transparent wird – sprich über Deine Emotionen, Deinen ' +
      'persönlichen Hintergrund, Deine Haltung, Deine Perspektive und Deine Erwartungen.',
    en: 'Make sure your experience of things is transparent to *NAME*, talk about your emotions, personal background, '
      + 'attitude, perspective and expectations.'
  },
  'consultMaster.listItem1_3': {
    de: 'Sieh zu, dass Du auch die Wirklichkeit des anderen erfassen kannst. Sprich es an, wenn Du etwas ' +
      'nicht verstehst.',
    en: 'Do what you can to understand how the other person experiences the world. Address an issue if you don’t understand it.'
  },
  'consultMaster.listItem2': {
    de: '<strong>Erkenntnis gewinnen, Respekt zeigen</strong><br>' +
      'Jeder ist ein Individuum, das in seiner eigenen Wirklichkeit lebt. Es geht nicht darum, einander zu ändern, ' +
      'lediglich an bestimmten Verhaltensweisen in bestimmten Situationen zu arbeiten.<br>' +
      'Wertschätzung ist, sich seine Motive nicht vorzuwerfen. Je größer die Unterschiede, desto mehr sind Respekt ' +
      'und Empathie gefragt. Schärfe Dein Augenmerk auf die guten Seiten von *NAME*. Gib Wertschätzung. Ein Zuviel ' +
      'gibt es nicht.',
    en: '<strong>Gain knowledge, show respect</strong><br>'
      + 'Everyone’s an individual that lives in their own reality. This is not about changing each other, just working '
      + 'on certain behaviors in certain situations.<br> Being able to appreciate another person means not blaming '
      + 'them for their motives. The greater the differences between you, the more respect and empathy are required. '
      + 'Focus more on *NAME*’s good sides. Demonstrate your appreciation. There’s no such thing as too much.'

  },
  'consultMaster.listItem3': {
    de: '<strong>Lösungen entwickeln</strong><br>' +
      'Sammelt Ideen und Anregungen, in welchen Situationen Ihr konkrete Maßnahmen ergreifen könnt, um besser ' +
      'miteinander zurecht zu kommen. Dabei hilft Euch Eure Unterschiedlichkeit. Manche Dinge fallen Dir in ' +
      'bestimmten Situationen einfacher als dem anderen. Und umgekehrt. Nutzt dies! Welche Ressourcen Ihr ' +
      'aktivieren könnt, steht in Eurem Persönlichkeitsprofil.',
    en: '<strong>Develop solutions</strong><br>'
      + 'Collect ideas and suggestions about specific steps you can take to get along better in particular situations. '
      + 'The ways in which you differ will help you do that. Some things are easier for you in certain situations, '
      + 'and some things are easier for the other person in certain situations. Use this to your mutual benefit! '
      + 'The resources you can both draw on are to be found in your individual personality profiles.'

  },
  'consultMaster.attitude': {
    de: '<strong>Haltung</strong><br>' +
      'Der Erfolgsfaktor für ein besseres Miteinander ist, den anderen so zu nehmen, wie er ist.',
    en: '<strong>Attitude</strong><br>'
      + 'A success factor in improving how you get along is accepting the other person as they are.'

  },
  'consultMaster.deep.headline': {
    de: 'Ihr wollt tiefer eintauchen?',
    en: 'You want to dive even deeper?'
  },
  'consultMaster.deep.text': {
    de: 'Die individuelle Lebenssituation, die Art der Beziehung und die spezifische Interaktionsstruktur zweier ' +
      'Menschen ergeben eine Komplexität, die die Beziehungsgestaltung zu einer spannenden und umfassenden Aufgabe ' +
      'macht. Wer Experten hinzuziehen möchte oder mehr über das Zusammenspiel von Motiven, Motivation, Emotion, ' +
      'Verhalten und Lebenszufriedenheit erfahren möchte, findet hier weiterführende Unterstützung: ',
    en: 'Individual circumstances in life, the nature of your relationship with each other and the specific context in '
      + 'which your interaction takes place result in a complexity that makes building your relationship an exciting '
      + 'multifaced task. For those who would like to consult individual experts or learn more about how motives, '
      + 'motivation, emotion, behavior and life satisfaction relate to each other, further information and support is available here: '
  },
  'consultMaster.deep.master': {
    de: '<strong>ID37 Master beraten gerne</strong><br>' +
      'ID37 Master sind Experten in der Persönlichkeitsanalyse. Sie können ihr Wissen und ihre Erfahrung einbringen ' +
      'und Euch dabei helfen ein Verständnis zu entwickeln, was den jeweils anderen ausmacht. Sie sind geschult, ' +
      'wenn es darum geht Motivkonstellationen zu erkennen - eine Motivausprägung kann eine andere verstärken oder ' +
      'abschwächen – und die jeweiligen Lebenssituationen in die Analyse mit einbeziehen. ID37 Master haben ein ' +
      'umfassendes Verständnis darüber, was eine Person ausmacht.<br>' +
      '<a href="https://www.id37.io/master-finden" target="_blank">Zum ID37 Master Finder</a>',
    en: '<strong>ID37 Masters are happy to advise</strong><br>'
      + 'ID37 Masters are experts in personality assessments. Their expertise and experience can help both of you '
      + 'better understand what makes the other person tick. They are trained to recognize motive constellations – how '
      + 'one set of motives can strengthen or weaken another – and are skilled at incorporating specific circumstances '
      + 'in life into an assessment. ID37 Masters have a comprehensive understanding of what makes a person tick.<br>'
      + '<a href="https://www.id37.io/en/master-finder" target="_blank">Go to ID37 Master Finder</a>'

  },
  'consultMaster.deep.book': {
    de: '<strong>Psychologische Grundlage und praktisches Werkzeug</strong><br>' +
      'Möchtest Du tiefer in das Thema Motivation, Verhalten und Selbstkenntnis eintauchen? Das Buch und ebook ' +
      '„Die ID37 Persönlichkeitsanalyse - Bedeutung und Wirkung von Lebensmotiven für effiziente Selbststeuerung“ ' +
      'liefern sowohl psychologische Grundlagen als auch praktische Ansätze dafür.<br>' +
      '<a href="https://www.id37.io/id37-buch" target="_blank">Zum ID37 Buch</a>',
    en: '<strong>The science behind the tool and its practical application</strong><br>'
      + 'Do you want to learn more about motivation, behavior and self-awareness? Published in both digital and print '
      + 'forms, the book “Personality Assessment with ID37 – Motivation and the Ability to Self-Direct” explores the '
      + 'psychological research informing the tool and provides practical exercises for working with it.<br>'
      + '<a href="https://www.id37.io/en/id37-book" target="_blank">Click here to order the ID37 book</a>'

  },
  'consultMaster.deep.videos1': {
    de: '<strong>Videos</strong><br>' +
      'Schau Dir die ',
    en: '<strong>Videos</strong><br>'
      + 'Check out these '
  },
  'consultMaster.deep.videos.link': {
    de: '<a href="https://vimeo.com/showcase/6654620" target="_blank">Videos</a>',
    en: '<a href="https://vimeo.com/showcase/6654620" target="_blank">videos</a>'
  },
  'consultMaster.deep.videos2': {
    de: ' an, um tiefer in die Methode einzutauchen.',
    en: ' to explore the method even further (currently only available in german).'
  },
  'distanceScoreDescriptions.you': {
    de: 'Du',
    en: 'You'
  },

  // Update
  'update.updateAvailable.title': {
    de: 'ID37 Aktualisierung',
    en: 'ID37 Update'
  },
  'update.updateAvailable.body': {
    de: 'Eine neue Version von ID37 ist verfügbar. Bitte aktualisiere die Anwendung.',
    en: 'A new version of ID37 is available. Please update the application.'
  },
  'update.updateAvailable.action': {
    de: 'Jetzt aktualisieren',
    en: 'Update now'
  },
  'update.unrecoverable.title': {
    de: 'Neustart erforderlich',
    en: 'Restart required'
  },
  'update.unrecoverable.body': {
    de: 'ID37 muss zur korrekten Ausführung neu gestartet werden.',
    en: 'ID37 must be restarted for correct execution.'
  },
  'update.unrecoverable.action': {
    de: 'Jetzt aktualisieren',
    en: 'Update now'
  },
  'update.updateSuccessful': {
    de: 'Update erfolgreich!',
    en: 'Update successful!'
  },

  /* Team */
  'team.headline': {
    de: 'Meine Teams',
    en: 'My teams'
  },
  'team.new.team': {
    de: 'Neues Team',
    en: 'New team'
  },
  'team.greetings': {
    de: 'Hallo',
    en: 'Hello'
  },
  'team.printbutton': {
    de: 'Team drucken',
    en: 'Print team'
  },
  'team.introText1': {
    de: 'Stelle mit Kolleginnen und Kollegen, Kundinnen und Kunden, Freundinnen und Freunden, mit der Familie und '
      + 'anderen ID37 Kontakten ein Team zusammen. Los gehts.',
    en: 'Let\'s get started with creating a team that includes your colleagues, clients, friends, family and other '
      + 'contacts in your ID37 network.'
  },
  'team.button': {
    de: 'Teams anlegen',
    en: 'Create teams'
  },
  'team.introText2_1': {
    de: 'Du hast noch keine ID37 Kontakte? Vernetze Dich ',
    en: 'If you don\'t have any ID37 contacts yet, start networking '
  },
  'team.introText2_link_friends': {
    de: 'hier',
    en: 'here'
  },
  'team.introText2_2': {
    de: ' mit Personen, die Du kennst oder ',
    en: ' with people you know and '
  },
  'team.introText2_link_recommend': {
    de: 'empfehle ID37',
    en: 'recommend ID37'
  },
  'team.introText2_3': {
    de: ' weiter.',
    en: '.'
  },

  /* Team Text */
  'team.infoHeader1': {
    de: 'Entdecke Entwicklungschancen mit der ID37 Teamanalyse',
    en: 'Discover development opportunities with the ID37 team analysis'
  },
  'team.infoText1': {
    de: 'Mache die Vielfalt in Deinem Team mit der ID37 Teamanalyse sichtbar und nutze  Transparenz und Offenheit, ' +
      'um gemeinsame Ziele zu erreichen.',
    en: 'Make your team’s diversity more visible with the ID37 team analysis and use transparency and openness '
      + 'to achieve common goals.'
  },
  'team.infoHeader2': {
    de: 'Impulse für Deine ID37 Teamanalyse',
    en: 'Inspiration for your ID37 team analysis'
  },
  'team.collapsibleHeader1': {
    de: 'Fokus auf Gemeinsamkeiten im Team',
    en: 'Focus on commonalities in the team'
  },
  'team.collapsibleText1': {
    de: 'Ein Beispiel: Alle Mitglieder haben auf dem Motiv NEUGIER eine hohe oder mittlere Ausprägung. '
      + 'Niemand hat eine niedrige Ausprägung.',
    en: 'An example: Each member of your team shows a strongly or moderately expressed CURIOSITY motive. '
      + 'Nobody has a weak expression of this motive.'
  },
  'team.collapsibleText1.daily': {
    de: '<strong>Was heißt das im Alltag?</strong><br>'
      + 'Das Team hat auf dem Motiv NEUGIER eine Gemeinsamkeit. '
      + 'Alle wollen ihrem Wissensdurst nachgehen. Halbwissen lehnen sie ab.',
    en: '<strong>What does that mean for day-to-day business?</strong><br>'
      + 'With regard to the CURIOSITY motive, the team has one commonality. '
      + 'Everybody wants to indulge their thirst for knowledge and rejects superficial knowledge.'
  },
  'team.collapsibleText1.effect': {
    de: '<strong>Mögliche Auswirkungen?</strong>',
    en: '<strong>Possible effects?</strong>'
  },
  'team.collapsibleText1.effect.listItem1': {
    de: 'NEUGIER, also das Streben nach Wissen, Erkenntnis und intellektueller Herausforderung, '
      + 'ist ein emotionaler „Klebstoff“ für das Team. Alle können sich vermutlich in Themen vertiefen, '
      + 'dabei ähnlich positive Gefühle entwickeln und dafür eine gemeinsame Sprache finden '
      + '(inhaltliche Meinungsverschiedenheiten wird es trotzdem geben).',
    en: 'CURIOSITY, or the pursuit of knowledge, insight and intellectual challenge, serves as an emotional glue for '
      + 'the team. Everyone on the team is likely to delve deeply into topics, develop similarly positive feelings in '
      + 'doing so and find a common language for the task at hand (though there will nevertheless be differences of '
      + 'opinion with regard to specific issues of content).'
  },
  'team.collapsibleText1.effect.listItem2': {
    de: 'Mögliche „blinden Flecken“: Es kann sein, dass das Team in langen Diskussionen verweilt, '
      + 'Aufgaben nicht zum Abschluss bringt und sich vielleicht sogar als Wissenselite sieht.',
    en: 'Possible blind spots<br>'
      + 'The team may spend considerable time engaging in long discussions, '
      + 'thus failing to complete tasks and might even view itself as a knowledge elite.'
  },
  'team.collapsibleText1.effect.listItem3': {
    de: 'Mögliche Selbstwahrnehmung des Teams: „Wir sind smart, interessiert und geistvoll.“ '
      + 'Es könnte sein, dass das Team andere Teams als wenig intellektuell und „Unwissende“ abstempelt.',
    en: 'The team’s possible self-perception:<br>'
      + '“We are smart, interested in issues and intellectually gifted.”<br>'
      + 'The team may regard other groups as less intellectual and even as ignorant.'
  },
  'team.collapsibleText1.effect.listItem4': {
    de: 'Mögliche Fremdwahrnehmung von anderen: „Dieses Team ist verkopft, kompliziert, oberlehrerhaft“.',
    en: 'Possible external perception by others:<br>'
      + '“This team is overly intellectual, complicated and excessively didactic.”'
  },
  'team.collapsibleText1.measures': {
    de: '<strong>Welche Maßnahmen sind denkbar?</strong><br>'
      + 'Es könnte eine Plattform für Wissensvertiefung initiiert werden (z.B. online Forum), damit ausschweifende '
      + 'inhaltliche Diskussionen nicht in Arbeitsmeetings stattfinden. Es könnte vereinbart werden, dass alle '
      + 'Teammitglieder regelmäßig berichten, wie ihre Umsetzungspläne aussehen und wie ihr Fortschritt ist.',
    en: '<strong>What measures could be taken?</strong><br>'
      + 'A platform that encourages the team to deepen their knowledge could be created (e.g., an online forum). '
      + 'This would allow protracted discussions on specific content issues to be kept out of work meetings.<br>'
      + 'Team members could agree to report regularly on what their implementation plans are, '
      + 'and on how much progress they have made.'
  },
  'team.collapsibleHeader2': {
    de: 'Fokus auf Unterschiede im Team',
    en: 'Focus on differences in the team'
  },
  'team.collapsibleText2': {
    de: 'Ein Beispiel: Alle Mitglieder haben auf dem Motiv PRINZIPIEN eine niedrige oder mittlere Ausprägung. '
      + 'Nur eine Person hat eine hohe Ausprägung auf diesem Motiv',
    en: 'An example: All members – except one – have a weakly or moderately expressed PRINCIPLES motive. '
  },
  'team.collapsibleText2.daily': {
    de: '<strong>Was heißt das im Alltag?</strong><br>'
      + 'Die eine Person mit der hohen Ausprägung auf PRINZIPIEN hat die höchste Sensibilität für die Einhaltung '
      + 'von Normen und Vorschriften im Team – während die anderen Mitglieder Regeln und Prinzipien '
      + 'nicht uneingeschränkt befolgen.',
    en: '<strong>What does that mean for day-to-day business?</strong><br>'
      + 'The person with the strongly expressed PRINCIPLES motive is the most sensitive to compliance with norms and '
      + 'regulations. For their part, the other team members don’t fully follow rules and principles.'
  },
  'team.collapsibleText2.effect': {
    de: '<strong>Mögliche Auswirkungen?</strong><br>'
      + 'Dies birgt die Gefahr von Konflikten, weil die eine Person in einer anderen Wertewelt lebt. '
      + 'Es könnte sein, dass sich diese Person als Außenseiter im Team fühlt und frustriert ist, '
      + 'wenn sie ihr Anliegen nach der Einhaltung von Regeln dauerhaft nicht zur Sprache bringen und umsetzen kann.',
    en: '<strong>Possible effects?</strong><br>'
      + 'There’s a risk of conflict in this scenario, because the one individual lives in a different world of values '
      + 'than the others. This person may feel like an outsider on the team and may be frustrated if she consistently '
      + 'fails to persuade the others to comply with the rules or feels that she can’t even bring up a '
      + 'request to do so.'
  },
  'team.collapsibleText2.measures': {
    de: '<strong>Welche Maßnahmen sind denkbar?</strong><br>'
      + 'Die Bewusstmachung der Außenseiterrolle für diese Person auf dem Motiv PRINZIPIEN ist ein wichtiger Schritt. '
      + 'Das Team kann sich beispielsweise darauf einigen, dass diese Person immer die „Prinzipien-Karte“ spielen '
      + 'kann (symbolisch), wenn sie sich auf diesem Motiv im Team unwohl fühlt und eine „Prinzipiendiskussion“ '
      + 'führen kann.',
    en: '<strong>What measures could be taken?</strong><br>'
      + 'Making the entire team conscious of this person’s outsider role with regard to the PRINCIPLES motive is an '
      + 'important step. For example, the team might agree that this person can (symbolically) play the '
      + '“principles card,” and thus trigger a “principles discussion,” any time she feels uncomfortable '
      + 'in the group on the basis of this motive. '
  },
  'team.collapsibleHeader3': {
    de: 'Fokus auf Lagerbildung',
    en: 'Focus on factionalization'
  },
  'team.collapsibleText3': {
    de: 'Ein Beispiel: Von vier Teammitgliedern haben zwei Mitglieder eine hohe Ausprägung auf dem '
      + 'Motiv SOZIALE ANERKENNUNG und zwei eine niedrige Ausprägung.',
    en: 'An example: In a team of four members, two have strongly expressed SOCIAL ACCEPTANCE motives, '
      + 'and two have only weak expressions of this motive. '
  },
  'team.collapsibleText3.daily': {
    de: '<strong>Was heißt das im Alltag?</strong><br>'
      + 'Für die Personen mit hoher Motivausprägung sind Anerkennung und Bestätigung durch andere wichtig. '
      + 'Die Personen mit niedriger Motivausprägung hingegen richtet ihr Verhalten nicht primär danach aus, '
      + 'was andere von ihnen halten.',
    en: '<strong>What does that mean for day-to-day business?</strong><br>'
      + 'For the people with a strongly expressed motive in this area, acceptance and affirmation from others are '
      + 'important. By contrast, the people with weak motive expressions in this area do not base their behavior '
      + 'primarily on what others think of them. '
  },
  'team.collapsibleText3.effect': {
    de: '<strong>Mögliche Auswirkungen?</strong><br>'
      + 'In einer Stresssituation könnte der Umgangston der Personen mit niedriger Ausprägung gegebenenfalls '
      + 'rau werden, der von den Personen mit hoher Ausprägung als nicht wertschätzend empfunden wird und zu '
      + 'Blockaden führt.',
    en: '<strong>Possible effects?</strong><br>'
      + 'In a stressful situation, the tone of voice used by the people with weakly expressed motives in this area '
      + 'could become rather harsh, which would in turn be perceived by the people with strongly expressed '
      + 'SOCIAL RECOGNITION motives as being disrespectful. This could generate friction between them.'
  },
  'team.collapsibleText3.measures': {
    de: '<strong>Welche Maßnahmen sind denkbar?</strong><br>'
      + 'Es ist hilfreich, wenn sich alle Mitglieder ihre Eigen- und Fremdwahrnehmung bewusstmachen. '
      + 'Für ein besseres gegenseitiges Verständnis kann es helfen, Tandems aus den unterschiedlichen „Lagern“ '
      + 'zusammenzustellen. Sie reflektieren ihre Ausprägung auf dem Motiv, um sich gegenseitigen mehr Wertschätzung '
      + 'entgegenzubringen und empathische Kommunikation zu trainieren.',
    en: '<strong>What measures could be taken?</strong><br>'
      + 'It is helpful when all team members are aware both of their own self-perceptions and of how they are likely '
      + 'to be perceived by others. For better mutual understanding, it can help to put together tandems containing '
      + 'team members from each of the different “camps.” They can then reflect together on their different '
      + 'expressions of the SOCIAL RECOGNITION motive, learning to demonstrate more mutual respect and practicing '
      + 'empathetic communication.'
  },
  'team.collapsibleText3.tip': {
    de: '<strong>Tipp:</strong> Blende die anderen Teammitglieder aus, um Dich auf das Tandem zu konzentrieren. '
      + 'Blende Motive aus, wenn sie Dich ablenken.',
    en: '<strong>Tip:</strong> To make it easier to concentrate on the tandem, hide the other team members in the '
      + 'application. Hide other motives as well, if they are a distraction. '
  },
  'team.collapsibleHeader4': {
    de: 'Fokus auf Vielfalt als Stärke',
    en: 'Focus on diversity as a strength'
  },
  'team.collapsibleText4': {
    de: 'Identifiziere Motive, auf denen die Teammitglieder wie auf einer Perlenschnur verteilt sind: '
      + 'Es gibt sowohl niedrige, moderate und hohe Ausprägungen. Ein Beispiel anhand des Motivs SICHERHEIT.<br>',
    en: 'Identify motives for which the team members’ various expressions are strung out like pearls on a string. '
      + 'In such cases, there will be weak, moderate and strong expressions.<br>'
      + 'We use the SAFETY motive here as an example.'
  },
  'team.collapsibleText4.daily': {
    de: '<strong>Was heißt das im Alltag?</strong><br>'
      + 'Personen mit hoher Motivausprägung haben eine Sensibilität für potenzielle Gefahren. Sie vermeiden es eher, '
      + 'Risiken einzugehen. Bei Personen mit einer niedrigen Motivausprägung liegt der Fokus nicht auf Risiken; '
      + 'sie sehen in unbekannten Situationen eher Chancen. Personen mit einer mittleren Ausprägung benötigen '
      + 'manchmal das Gefühl von Sicherheit und manchmal nicht.',
    en: '<strong>What does that mean for day-to-day business?</strong><br>'
      + 'Individuals with a strongly expressed SAFETY motive are highly sensitive to potential dangers. They tend to '
      + 'avoid taking risks. People in whom this motive is weakly expressed don’t focus on risks; rather, they tend to '
      + 'see unfamiliar situations as opportunities. People with a moderate expression of this motive sometimes need '
      + 'the feeling of safety, and other times do not. '
  },
  'team.collapsibleText4.effect': {
    de: '<strong>Mögliche Auswirkungen?</strong><br>'
      + 'In Bezug auf SICHERHEIT nimmt das Team eine multiple Perspektive ein. Wenn alle Teammitglieder zu Wort '
      + 'kommen, ist die Chance groß, dass das Team eine optimale Lösung oder Ansatz findet: Alle Blickwinkel und '
      + 'Erfahrungen kommen auf den Tisch und werden gemeinsam abgewogen.',
    en: '<strong>Possible effects</strong><br>'
      + 'With regard to SAFETY, the team has a multifaceted perspective. When the team’s members are all able to have '
      + 'their say, there is a good chance that it will discover an optimal solution or approach. All viewpoints and '
      + 'experiences are brought to the table and are considered together.'
  },
  'team.collapsibleText4.measures': {
    de: '<strong>Welche Maßnahmen sind denkbar?</strong><br>'
      + 'Um die Vielfalt zu nutzen, können verschiedene Teamrollen ins Leben gerufen werden – zum Beispiel die des '
      + '„Risikomanagers“. Diese Rolle erhalten Personen mit einer sehr hohen Ausprägung auf dem Motiv SICHERHEIT, '
      + 'denn sie sind sensibel für mögliche Risiken. Die Rolle des „Pioniers“ erhalten Personen, die eine sehr '
      + 'niedrige Ausprägung haben, denn sie haben einen natürlichen Radar für neue Chancen.',
    en: '<strong>What measures could be taken?</strong><br>'
      + 'To take advantage of this diversity, different team roles could be created – for example, that of a '
      + '“risk manager.” This role should be given to people with a very strongly expressed SAFETY motive, as they '
      + 'are sensitive to possible risks. The role of “pioneer” should in turn be given to people in whom this motive '
      + 'is very weakly expressed, as they have a natural radar for new opportunities. '
  },
  'team.collapsibleText4.tip': {
    de: '<strong>Tipp:</strong> Blende die anderen Motive aus, um Dich auf ein Motiv (hier SICHERHEIT) zu '
      + 'konzentrieren. Blende sukzessive weitere Motive ein, wenn Du Dir Wechselwirkungen vor Augen führen möchtest.',
    en: '<strong>Tip:</strong> Hide the other motives so that you can focus on one motive in particular '
      + '(in this case, SAFETY). Restore the other motives one by one if you want to get a sense for how they interact.'
  },
  'team.infoHeader3': {
    de: 'Funktionalitäten der ID37 Teamanalyse',
    en: 'Functionalities of the ID37 team analysis'
  },
  'team.infoText3.a': {
    de: '<strong>Die interaktiven Funktionalitäten helfen dabei, Klarheit zu gewinnen:</strong>',
    en: '<strong>The interactive functions help to provide clarity:</strong>'
  },
  'team.infoText3.listItem1': {
    de: 'Bringe Motive in eine neue Reihenfolge<br>'
      + 'Schiebe beispielsweise die 3-6 Motive untereinander, auf denen es besonders '
      + 'viele Gemeinsamkeiten oder Unterschiede gibt. Das sorgt für Klarheit.',
    en: 'Rearrange the motives<br>'
      + 'For example, position the three to six motives for which there are an above-average number of similarities or '
      + 'differences together. This can offer greater clarity.'
  },
  'team.infoText3.listItem2': {
    de: 'Blende einzelne Motive aus<br>'
      + 'Fokussiere Dich auf die Motive, die in Deinem Kontext eine Relevanz haben, '
      + 'z.B. die im Bereich Führung oder in der Beziehungsgestaltung.',
    en: 'Hide individual motives<br>'
      + 'Focus on the motives that are most relevant within your specific context, such as those that relate to '
      + 'leadership or relationship-building.'
  },
  'team.infoText3.listItem3': {
    de: 'Ändere die Zusammensetzung des Teams<br>'
      + 'Blende temporär die Mitglieder aus, die für eine bestimmte Fragestellung keine Rolle spielen, das hilft Dir '
      + 'zu fokussieren. Z. B. nur Mitglieder mit direktem Kundenkontakt oder nur Mitglieder ohne Kundenkontakt.',
    en: 'Change the composition of the team<br>'
      + 'Temporarily hide the team members who aren’t relevant for a certain question, as this will help you focus. '
      + 'For example, display only those team members that have direct customer contact, or the members without '
      + 'any customer contact.'
  },
  'team.infoText3.b': {
    de: 'Nutze alle interaktiven Möglichkeiten, um die Reflexion im Team anzustoßen, '
      + 'um Teamziele zu verfolgen und um passgenaue Maßnahmen dafür zu entwickeln.',
    en: 'Use all the available interactive options to generate reflection within the team, to pursue team goals and '
      + 'to develop measures appropriate to reaching these goals.'
  },
  'team.infoText3.c': {
    de: 'Erstelle so viele Teams, wie Du benötigst. Es lohnt sich bei jedem Team. Die Mitglieder werden aufeinander '
      + 'neugierig, erkennen, wo sie selbst stehen, wo das Team als Ganzes  steht und steigern ihr gegenseitiges '
      + 'Verständnis. Gemeinsamkeiten und Unterschieden  werden geklärt, halten Einzug in den Alltag und werden '
      + 'immer wieder reflektiert. Jedes  Team ist vielfältig - nutzt Euer Potenzial als Team.',
    en: 'Create as many teams as you need. The system will produce benefits for every team. The members will be '
      + 'curious about one another, and the application will help them understand where they themselves stand and '
      + 'where the team as a whole stands, while also increasing their mutual understanding. It will clarify '
      + 'commonalities and differences, and this knowledge in turn will smooth everyday interactions and be an ongoing '
      + 'subject of reflection. Every team is diverse – use your potential as a team.'
  },
  'team.infoHeader4': {
    de: 'Haben alle Mitglieder ein ID37 Profil?',
    en: 'Do all team members have an ID37 profile? '
  },
  'team.infoText4.link': {
    de: 'Empfehle',
    en: 'Recommend'
  },
  'team.infoText4': {
    de: ' neuen Mitgliedern die ID37 Persönlichkeitsanalyse oder lade sie ein. Du kannst alle Kontakte zu Deinen Teams '
      + 'hinzufügen, die ihr Profil vollständig erstellt haben und mit Dir vernetzt sind.',
    en: ' the ID37 personality assessment to new team members, or invite them. Any contact that has fully created his '
      + 'or her profile, and to whom you are already connected, can be added to your team.'
  },
  'team.infoHeader5': {
    de: 'Fragen oder Anmerkungen?',
    en: 'Questions or comments? '
  },
  'team.infoText5': {
    de: 'Schreibe eine E-Mail an: <a href = "mailto: help@id37.io">help@id37.io</a>',
    en: 'Send an email to: <a href = "mailto: help@id37.io">help@id37.io</a>'
  },
  'team.recommend': {
    de: 'ID37 empfehlen',
    en: 'Recommend ID37'
  },
  'teamModal.create.title': {
    de: 'Team anlegen',
    en: 'Create team'
  },
  'teamModal.edit.title': {
    de: 'Team bearbeiten',
    en: 'Edit team'
  },
  'teamModal.name.label': {
    de: 'Team Name',
    en: 'Team name'
  },
  'teamModal.name.placeholder': {
    de: 'Name wählen',
    en: 'Choose Name'
  },
  'teamModal.member.label': {
    de: 'Mitglieder hinzufügen',
    en: 'Add members'
  },
  'teamModal.member.placeholder': {
    de: 'Alle Gruppen und Kontakte',
    en: 'All groups and contacts'
  },
  'teamModal.member': {
    de: 'Mitglied',
    en: 'Member'
  },
  'teamModal.members': {
    de: 'Mitglieder',
    en: 'Members'
  },
  'teamModal.remove.team': {
    de: 'Entfernen',
    en: 'Delete'
  },
  'teamModal.remove.member': {
    de: 'Mitglied löschen',
    en: 'Delete member'
  },
  'teamModal.greeting': {
    de: 'Hey',
    en: 'Hey'
  },
  'teamModal.text': {
    de: 'Hier werden bald die Mitglieder angezeigt, die Du ausgewählt hast.',
    en: 'This is where you\'ll soon see the members you have added.'
  },
  'teamModal.save.button': {
    de: 'Änderungen speichern',
    en: 'Save changes'
  },
  'teamModal.continue.button': {
    de: 'Weiter',
    en: 'Continue'
  },
  'teamModal.delete.message': {
    de: 'Willst Du dieses Team wirklich löschen?',
    en: 'Do you really want to delete this team?'
  },
  'teamModal.delete.decline': {
    de: 'Nein',
    en: 'No'
  },
  'teamModal.delete.approve': {
    de: 'Ja',
    en: 'Yes'
  },

  /* Team Nav */
  'teamNav.tooltip.edit': {
    de: 'Bearbeiten',
    en: 'Edit'
  },

  /* Team Graphic */
  'teamGraphic.svg.low': {
    de: 'Niedrig',
    en: 'Low'
  },
  'teamGraphic.svg.medium': {
    de: 'Mittel',
    en: 'Moderate'
  },
  'teamGraphic.svg.high': {
    de: 'Hoch',
    en: 'High'
  },
  'teamGraphic.tooltip.download-icon': {
    de: 'Download: Teamdaten in Excel',
    en: 'Download: Excel spreadsheet with team data'
  },
  'teamGraphic.tooltip.reset-icon': {
    de: 'Zurücksetzen',
    en: 'Reset'
  },
  'teamGraphic.tooltip.fade-in': {
    de: 'Motiv einblenden',
    en: 'Show motive'
  },
  'teamGraphic.tooltip.fade-out': {
    de: 'Motiv ausblenden',
    en: 'Hide motive'
  },
  'teamGraphic.tooltip.motive-variation-14': {
    de: 'alle, ohne SINNLICHKEIT und FAMILIE',
    en: 'all, excluding the motives EROS and FAMILY'
  },
  'teamGraphic.tooltip.motive-variation-15': {
    de: 'alle, ohne SINNLICHKEIT',
    en: 'all, excluding the motive EROS'
  },
  'teamGraphic.tooltip.motive-variation-16': {
    de: 'alle 16 Motive',
    en: 'all 16 motives'
  },

  /* DiscountCode export */
  'discountCodeUsagesExport.heading': {
    de: 'Rabattcode Verwendungen exportieren',
    en: 'Export DiscountCode usages'
  },
  'discountCodeUsagesExport.export': {
    de: 'Export',
    en: 'Export'
  },
  'discountCodeUsagesExport.from': {
    de: 'Von',
    en: 'From'
  },
  'discountCodeUsagesExport.to': {
    de: 'Bis',
    en: 'To'
  },
  'discountCodeUsagesExport.codePattern': {
    de: 'Code Pattern',
    en: 'Code Pattern'
  },
  'discountCodeUsagesExport.categoryPattern': {
    de: 'Kategorie Pattern',
    en: 'Category Pattern'
  },
  /* PositionUser export */
  'positionUserReportExport.heading': {
    de: 'Position User Zuordnung Export',
    en: 'Export Position User Summary'
  },
  'positionUserReportExport.export': {
    de: 'Export',
    en: 'Export'
  },
  'positionUserReportExport.from': {
    de: 'Von',
    en: 'From'
  },
  'positionUserReportExport.to': {
    de: 'Bis',
    en: 'To'
  },
  /* Master -> Toolbox -> Chatbot Document Tools */
  'chatbot-documents-tools.title': {
    de: 'Import/Export von Chatbot Dokumenten',
    en: 'Import/Export of chatbot documents'
  },
  'chatbot-documents-tools.download-button': {
    de: 'Exportieren der Chatbot Dokumente als JSON-Datei',
    en: 'Export chatbot documents as a JSON file'
  },
  'chatbot-documents-tools.desc': {
    de: 'Die vom Chatbot verwendeten Dokumente können komplett aus der Datenbank exportiert werden.'
      + 'Diese Funktion dient hauptsächlich zur Datenbank Migration.'
      + 'Die Bearbeitung der Dokumente vor dem Importieren ist nicht empfohlen.',
    en: 'All the documents used by the chatbot can be exported from the database.'
      + 'This feature is primarily intended for database migration.'
      + 'It is not reccommended to edit the documents before importing them again.'
  },
  'chatbot-documents-tools.desc-headline': {
    de: 'Nutzung',
    en: 'Usage'
  },
  'chatbot-documents-tools.btnSelectFile': {
    de: 'Datei für Import auswählen',
    en: 'Select file for import',
    ru: 'Выберите файл для импорта'
  },
  'chatbot-documents-tools.btnDoUpload': {
    de: 'Upload!',
    en: 'Upload!',
    ru: 'Загрузить'
  },
  'chatbot-documents-tools.success': {
    de: 'Erfolgreich importiert',
    en: 'Your import was successful',
    ru: 'Импорт был завершен успешно'
  },
  'chatbot-documents-tools.errorInRow': {
    de: 'Fehler in Zeile',
    en: 'Error in row',
    ru: 'Ошибка в строке'
  },
  /* admin -> chatbot-documents */
  'chatbot-documents.heading': {
    de: 'Chatbot Dokumente Verwaltung',
    en: 'Chatbot documents management'
  },
  'chatbot-documents.existing-docs': {
    de: 'Aktuelle Chatbot Dokumente',
    en: 'Current chatbot documents'
  },
  'chatbot-documents.import-modal-title': {
    de: 'Wählen Sie bitte eine Excel-Datei zum importieren aus.',
    en: 'Please choose an Excel file to import.'
  },
  'chatbot-documents.import-modal-body': {
    de: 'Es werden Embeddings für alle Dokumente in der Datei von der OpenAI-Api erzeugt. Hierdurch können zusätzliche Kosten entstehen.',
    en: 'Embeddings are generated for all documents in the file using the OpenAI API. This may create additional costs.'
  },
  'chatbot-documents.import-modal-btnSelectFile': {
    de: 'Datei für Import auswählen',
    en: 'Choose file for import.'
  },
  'chatbot-documents.import-modal-btnDoUpload': {
    de: 'Upload starten',
    en: 'Start upload.'
  },
  'chatbot-documents.import-modal-success': {
    de: 'Import war erfolgreich',
    en: 'Import was successful.'
  },
  'chatbot-documents.button-add-document': {
    de: 'Neues Dokument hinzufügen',
    en: 'Add new document'
  },
  'chatbot-documents.import-modal-errorInRow': {
    de: 'Fehler in Zeile',
    en: 'Error in line.'
  },
  'chatbot-documents.button-import-document': {
    de: 'Dokumente importieren',
    en: 'Import documents'
  },
  'chatbot-documents.button-toggle-content-less': {
    de: 'Weniger anzeigen',
    en: 'Show less'
  },
  'chatbot-documents.button-toggle-content-more': {
    de: 'Mehr anzeigen',
    en: 'Show more'
  },
  'chatbot-documents.button-edit': {
    de: 'Bearbeiten',
    en: 'Edit'
  },
  'chatbot-documents.button-delete': {
    de: 'Löschen',
    en: 'Delete'
  },
  'chatbot-documents.search-field': {
    de: 'Suchen',
    en: 'Search'
  },
  'chatbot-documents.column-id': {
    de: 'ID',
    en: 'ID'
  },
  'chatbot-documents.column-title': {
    de: 'Title',
    en: 'Title'
  },
  'chatbot-documents.column-summary': {
    de: 'Zusammenfassung',
    en: 'Summary'
  },
  'chatbot-documents.column-content': {
    de: 'Inhalt',
    en: 'Content'
  },
  'chatbot-documents.column-tokens': {
    de: 'Tokens',
    en: 'Tokens'
  },
  'chatbot-documents.column-actions': {
    de: 'Aktionen',
    en: 'Actions'
  },
  'chatbot-documents.page': {
    de: 'Seite:',
    en: 'Page'
  },
  'chatbot-documents.of': {
    de: 'von',
    en: 'of'
  },
  'chatbot-documents.edit-modal-title': {
    de: 'Dokument ändern',
    en: 'Edit document'
  },
  'chatbot-documents.delete-modal-title': {
    de: 'Möchten Sie das Dokument wirklich löschen?',
    en: 'Do you really want to delete this document?'
  },
  'chatbot-documents.delete-modal-body': {
    de: 'Diese Entscheidung kann nicht rückgängig gemacht werden.',
    en: 'This decision can not be reversed.'
  },
  'chatbot-documents.delete-modal-button-delete': {
    de: 'Löschen',
    en: 'Delete'
  },
  'chatbot-documents.delete-modal-button-cancel': {
    de: 'Abbrechen',
    en: 'Cancel'
  },
  /* admin -> chatbot-documents -> chatbot-documents-form */
  'chatbot-documents-form.title': {
    de: 'Title',
    en: 'Title'
  },
  'chatbot-documents-form.summary': {
    de: 'Zusammenfassung',
    en: 'Summary'
  },
  'chatbot-documents-form.content': {
    de: 'Inhalt',
    en: 'Content'
  },
  'chatbot-documents-form.not-empty': {
    de: 'Dieses Feld darf nicht leer sein!',
    en: 'This field must not be empty!'
  },
  'chatbot-documents-form.button-cancel': {
    de: 'Abbrechen',
    en: 'Cancel'
  },
  'chatbot-documents-form.button-ok': {
    de: 'OK',
    en: 'OK'
  },
  /* Admin -> Chatbot System Prompts */
  'chatbot-system-prompts.title': {
    de: 'Chatbot System-Prompt ändern',
    en: 'Change chatbot system-prompt'
  },
  'chatbot-system-prompts.btn_update': {
    de: 'Änderungen speichern',
    en: 'Save changes'
  },
  'chatbot-system-prompts.btn_cancel': {
    de: 'Zurücksetzen',
    en: 'Reset'
  },
  'chatbot-system-prompts.description': {
    de: 'Ändert den aktuellen Systemprompt für den Chatbot.'
      + 'Diese Änderung ist nicht persistent und soll nur zu Testzwecken dienen.'
      + 'Zurücksetzten bewirkt eine Rückkehr zu der letzten gespeicherten Version.',
    en: 'Changes the current system-prompt of the chatbot. '
      + 'This change is not persistent and is only is only intended for testing purposes. '
      + 'The reset causes a return to the last saved version.'
  },
  'chatbot-system-prompts.description_2': {
    de: 'Aktueller Prompt:',
    en: 'Current Prompt:'
  },
  /* chatbot->chat-selections */
  'chatbot-chat-selections.Today': {
    de: 'Heute',
    en: 'Today'
  },
  'chatbot-chat-selections.Recent': {
    de: 'Letzte 7 Tage',
    en: 'Last 7 days'
  },
  'chatbot-chat-selections.Older': {
    de: 'Älter',
    en: 'Older'
  },
  'chatbot-chat-selections.Empty': {
    de: 'Leer',
    en: 'Empty'
  },
  'chatbot-chat-selections.newChatTooltip': {
    de: 'Neuen Chat starten',
    en: 'Start new chat'
  },
  /* chatbot->chatbot-startscreen */
  'chatbot-startscreen.Hello': {
    de: 'Hi, ich bin Jay!',
    en: 'Hi, I am Jay!'
  },
  'chatbot-startscreen.Message': {
    de: 'Was möchtest du wissen?',
    en: 'What do you want to know?'
  },
  'chatbot-startscreen.Prompt-1-Title': {
    de: 'Mein Profil und Verhalten',
    en: 'My profile and behaviour'
  },
  'chatbot-startscreen.Prompt-1-Content': {
    de: 'Bitte analysiere mein ID37 Persönlichkeitsprofil detailliert, ' +
      'indem du jedes der 16 Lebensmotive und seine individuellen Werte im Detail betrachtest. ' +
      'Konzentriere dich darauf, wie diese Motive bestimmte Persönlichkeitsmerkmale und Verhaltensweisen prägen. ' +
      'Nutze konkrete Alltagsbeispiele, um zu zeigen, wie unterschiedliche Ausprägungen dieser Motive ' +
      'Entscheidungen, soziale Interaktionen und Reaktionen auf alltägliche Herausforderungen beeinflussen. ' +
      'Deine Antwort sollte die Analyse zusammenfassen, flüssig und leicht lesbar sein, ohne an Tiefe zu verlieren. ' +
      'Vereinfache komplexe Informationen, aber behalte die wesentlichen Details bei, ' +
      'um ein umfassendes Verständnis zu ermöglichen. Hebe besonders interessante Aspekte durch Fettschrift hervor.',
    en: 'Please analyse my ID37 personality profile in detail by looking at each of the 16 life motives  ' +
      'and their individual values in detail. ' +
      'Focus on how these motives shape certain personality traits and behaviours. ' +
      'Use concrete everyday examples to show how different expressions of these motives influence decisions, ' +
      'social interactions, and reactions to everyday challenges. ' +
      'Your answer should summarise the analysis, be fluent and easy to read without losing depth. ' +
      'Simplify complex information but keep the essential details to enable a comprehensive understanding. ' +
      'Highlight particularly interesting aspects in bold.'
  },
  'chatbot-startscreen.Prompt-2-Title': {
    de: 'Einfluss auf Kommunikation',
    en: 'Influence on communication'
  },
  'chatbot-startscreen.Prompt-2-Content': {
    de: 'Fasse die folgenden Motive zu einer Motivkonstellation zusammen:\n' +
      'Soziale Anerkennung, Einfluss, Autonomie, Sozialkontakte, Sicherheit und Revanche.\n' +
      'Erkläre mir die Wechselwirkung dieser Motivkonstellation in Bezug auf mein Kommunikationsverhalten. ' +
      'Achte bei den Motivausprägungen auf graduelle Unterschiede. ' +
      'Diskutiere, welche Vor- und Nachteile sich daraus für mein Kommunikationsverhalten ergeben. ' +
      'Gib drei Denkanstöße, um über dieses Thema nachzudenken. ' +
      'Schlage drei gezielte Strategien vor, um möglichen negativen Folgen entgegenzuwirken. ' +
      'Verwende eine lockere Sprache.',
    en: 'Summarise the following motives into a motive constellation: \n' +
      'Social acceptance, influence, autonomy, social participation, safety, and revenge. \n' +
      'Explain to me the interaction of this motive constellation in relation to my communication behaviour. ' +
      'Pay attention to gradual differences in the motives. ' +
      'Discuss the advantages and disadvantages of my communication behaviour. ' +
      'Give three thought-provoking ideas for thinking about this topic. ' +
      'Suggest three targeted strategies to counteract possible negative consequences.' +
      'Use a casual language.'
  },
  'chatbot-startscreen.Prompt-3-Title': {
    de: 'Alle Motive grau?',
    en: 'All the motives grey?'
  },
  'chatbot-startscreen.Prompt-3-Content': {
    de: 'Bei meinem Kollegen sind alle Motive grau! Was bedeutet das?',
    en: 'All the motives of my colleague are grey! What does that mean?'
  },
  'chatbot-startscreen.Prompt-4-Title': {
    de: 'Stress-Alarm!',
    en: 'Stress Alert!'
  },
  'chatbot-startscreen.Prompt-4-Content': {
    de: 'Ich fühle mich im Moment unwohl, sogar gestresst. ' +
      'Analysiere dazu alle Gründe und erläutere das Ergebnis ausführlich und anschaulich. ' +
      'Schlage drei gezielte Strategien zur Stressprävention vor.',
    en: 'I’m feeling uncomfortable and even stressed at the moment. ' +
      'Analyse all the reasons for this and explain the result in detail and vividly. ' +
      'Suggest three targeted strategies for stress prevention.'
  },
  /* chatbot->chatbot-send-message-service */
  'chatbot-send-message.errorMessage': {
    de: 'Es ist ein Fehler aufgetreten. Das tut uns leid. ' +
      'Versuche es in ein paar Minuten nochmal oder schreibe eine E-Mail mit dem Betreff „Jay error“ an jay@id37.io.',
    en: 'An error has occurred. We are sorry. ' +
      'Try again in a few minutes or send an email with the subject “Jay error” to jay@id37.io.'
  },
  'chatbot-send-message.errorMessageLength': {
    de: 'Fehler: Maximale Länge erreicht. Die Antwort passt nicht innerhalb der erlaubten Länge. ' +
      'Um dieses Problem zu vermeiden, sollten Sie Ihre Anfrage kürzen oder in kleinere Teile aufteilen.',
    en: 'Error: Maximum length reached. The answer does not fit within the allowed length. ' +
      'To avoid this problem, consider shortening your request or breaking it up into smaller parts.'
  },
  'chatbot-chat-selections.deleteAllChats': {
    de: 'Hier kannst du alle Chats auf einmal löschen!',
    en: 'Here you can delete all Chats at once!'
  },
  'chatbot-chat-selection.deleteChat': {
    de: 'Dieser Chat wird gelöscht: ',
    en: 'This chat will be deleted:  '
  },
  'chatbot-chat-selections.cancel': {
    de: 'Abbrechen',
    en: 'Cancel'
  },
  'chatbot-chat-selections.delete': {
    de: 'Löschen',
    en: 'Delete'
  },
  'chatbot-chat-selections.delete-all-btn': {
    de: 'Alle Chats löschen',
    en: 'Delete all chats'
  },
  'chatbot-chat-selections.new-chat-field': {
    de: 'Chatbot Jay',
    en: 'Chatbot Jay'
  },
  'chatbot-chat-selections.deleteTitle': {
    de: 'Alle Chats löschen?',
    en: 'Delete all chats?'
  },
  'chatbot-chat-selection.cancel': {
    de: 'Abbrechen',
    en: 'Cancel'
  },
  'chatbot-chat-selection.delete': {
    de: 'Löschen',
    en: 'Delete'
  },
  'chatbot-chat-selection.edit': {
    de: 'Umbenennen',
    en: 'Rename'
  },
  'chatbot-chat-selection.deleteTitle': {
    de: 'Chat wirklich löschen?',
    en: 'Really delete chat?'
  },
  /* chatbot->chatbot-message */
  'chatbot-message.feedbackPlaceholder': {
    de: 'Wenn du deine Bewertung begründen möchtest, schreibe sie hier rein ...',
    en: 'If you want to add a reason for your rating, write it down here ...'
  },
  'chatbot-message.feedbackTitle': {
    de: 'Zusätzlicher Kommentar',
    en: 'Additional comment'
  },
  'chatbot-message.copyTooltip': {
    de: 'In Zwischenablage kopieren',
    en: 'Copy to clipboard'
  },
  'chatbot-message.cancel': {
    de: 'Keine zusätzlichen Kommentare hinzufügen ',
    en: 'Do not add additional comments'
  },
  'chatbot-message.send': {
    de: 'Abschicken',
    en: 'Submit'
  },
  /* chatbot->chat */
  'chatbot-chat.free-trial-title': {
    de: 'Entdecke Jay: Dein KI-Berater',
    en: 'Discover Jay: Your AI Advisor'
  },
  'chatbot-chat.free-trial-text': {
    de: 'Jay ist dein persönlicher KI-Berater, der rund um die Uhr bereit steht, um dir zu helfen. ' +
      'Du kannst nun Jay 14 Tage lang kostenlos testen. ' +
      'Klick einfach auf ‘Start’',
    en: 'Jay is your personal AI advisor, ready around the clock to assist you. ' +
      'You can now test Jay for 14 days free of charge. ' +
      'Just click on ‘Start’.'
  },
  'chatbot-chat.free-trial-start': {
    de: 'Start',
    en: 'Start'
  },
  'chatbot-chat.return-to-dashboard': {
    de: 'Zurück zum Dashboard',
    en: 'Back to Dashboard'
  },
  'chatbot-chat.purchase-title': {
    de: 'Zugang abgelaufen',
    en: 'Access expired'
  },
  'chatbot-chat.purchase-buy-button': {
    de: 'Jetzt abonnieren',
    en: 'Subscribe now'
  },
  'chatbot-chat.purchase-text': {
    de: 'Um weiterhin die Vorteile unseres Dienstes zu genießen, ' +
      'aktualisiere bitte auf das Abonnement für nur €5.95/Monat.',
    en: 'To continue enjoying the benefits of our service, ' +
      'please upgrade to the subscription for only €5.95/per month. '
  },



  /* OPT-INS2 */
  'emailSettings.networking.labelEnabled': {
    de: '<b>Zustimmung zur ID37 Suche</b>',
    en: '<b>Opt in to the ID37 search feature</b>'
  },
  'emailSettings.networking.labelDisabled': {
    de: '<b>Keine Zustimmung zur ID37 Suche</b>',
    en: '<b>Opt out of the ID37 search feature</b>'
  },
  'emailSettings.advertising.labelDisabled': {
    de: '<b>Keine Werbungen bekommen</b>',
    en: '<b>Deny advertising</b>'
  },
  'emailSettings.networking.enabledText': {
    de: 'Du wirst von Nutzern, die ihr ID37 Persönlichkeitsprofil erstellt haben, gefunden. So können Dich ' +
      'Deine Arbeitskollegen, Businesspartner, Freunde und Lebenspartner  kontaktieren und ihr Profil mit Dir ' +
      'teilen. <b>Dein Persönlichkeitsprofil ist nicht automatisch sichtbar - Du entscheidest im Einzelfall.</b> ' +
      'Nur wenn Du der ID37 Suche zustimmst, holst Du das Optimum aus den Beziehungen in Deinem Netzwerk heraus.',
    en: 'Opting in allows users who’ve created their ID37 personality profile to find you. Your colleagues at work, '
      + 'business partners, friends and life partners can contact you and share their profile with you. '
      + '<b>Your personality profile is not automatically viewable – you decide who gets to see it on a case-by-case basis.</b>'
      + ' Opting in to the ID37 search feature lets you get the most out of the relationships in your network.'
  },
  'emailSettings.networking.disabledText': {
    de: 'Du wirst von Nutzern, die ihr ID37 Persönlichkeitsprofil erstellt haben, <b>nicht</b> gefunden. So ' +
      'können Dich Deine Arbeitskollegen, Businesspartner, Freunde und Lebenspartner nicht kontaktieren, um ihr ' +
      'Profil mit Dir zu teilen. <b>Stimme der ID37 Suche zu, um das Optimum aus den Beziehungen in Deinem Netzwerk ' +
      'herauszuholen.</b>',
    en: 'Opting out means users who’ve created their ID37 personality profile won’t be able to find you. Your colleagues '
      + 'at work, business partners, friends and life partners cannot contact you and share their profile with you. '
      + '<b>By opting in to the ID37 search feature, you can get the most out of the relationships in your network. </b> '
  },
  'emailSettings.advertising.enabledText': {
    de: 'Du erhältst Neuigkeiten und Inspirationen, um das Beste aus Deinem Motivprofil im Leben und beim Arbeiten zu machen.',
    en: 'You\'ll receive news and inspiration on how to better make use of your motives profile in life and work.'
  },
  'emailSettings.advertising.disabledText': {
    de: 'Du erhälst <b>keine</b> Neuigkeiten und Inspirationen, um das Beste aus Deinem Motivprofil im Leben um beim Arbeiten zu machen.',
    en: 'You <b>will not</b> receive news and inspiration on how to better make use of your motives profile in life and work.'

  },
  'opt-in.networking.heading': {
    de: 'Netzwerken',
    en: 'Networking'
  },
  'opt-in.advertising.heading': {
    de: 'Neuigkeiten',
    en: 'News'
  },
  'opt-in.networking.message': {
    de: 'Es gibt eine neue Funktion, mit der Du Profilvergleiche mit Deinen Kontakten anstellen kannst,'
      + ' wenn beide dies wollen. Bitte stimme der Funktion „Mein Netzwerk” einmalig zu.\n'
      + 'In „Mein Account” kannst du diese Zustimmung jederzeit wieder ändern.',
    en: 'There\'s a new feature that allows you to compare your profile with those of your contacts who’ve agreed '
      + 'to sharing theirs. Please give your one-time opt-in to the “My Network” feature.\n'
      + 'You can change your status at any time under “My Account”.'

  },
  'opt-in.networking.decline': {
    de: 'Ablehnen',
    en: 'Deny'
  },
  'opt-in.networking.accept': {
    de: 'Annehmen',
    en: 'Accept'
  },
  'opt-in.advertising.decline': {
    de: 'Ablehnen',
    en: 'Deny'
  },
  'opt-in.advertising.accept': {
    de: 'Annehmen',
    en: 'Accept'
  },
  /* chatbot->chat->locked */
  'chatbot-chat-locked.free-trial.header': {
    de: 'Chatbot Jay',
    en: 'Chatbot Jay'
  },
  'chatbot-chat-locked.free-trial-expired.header': {
    de: 'Chatbot Jay',
    en: 'Chatbot Jay'
  },
  'chatbot-chat-locked.free-trial.hello': {
    de: 'Hallo',
    en: 'Hello'
  },
  'chatbot-chat-locked.free-trial-expired.hello': {
    de: 'Hallo',
    en: 'Hello'
  },
  'chatbot-chat-locked.free-trial.subheader-cta': {
    de: 'Teste den KI-Coach Jay 14 Tage kostenlos!',
    en: 'Try the AI coach Jay for 14 days, for free!'
  },
  'chatbot-chat-locked.free-trial-expired.subheader-cta': {
    de: 'Dein Zugang ist abgelaufen – Schalte Jay frei und nutze ihn, solange Du willst!',
    en: 'It looks like your access has expired – unlock Jay and use him as long as you want!'
  },
  'chatbot-chat-locked.free-trial.cta-description': {
    de: 'Entdecke Deinen KI-Coach Jay und überzeuge Dich von seinem Wert als persönlichen Sparringspartner, ' +
      'mit dem Du jederzeit chatten kannst. Er kennt Dein Persönlichkeitsprofil und berücksichtigt es in seinen Antworten. Starte jetzt!',
    en: 'Get to know your AI coach Jay and benefit from the value he brings as a personal sparring partner you can chat with anytime. ' +
      'Equipped with knowledge of your personality profile, he’s able to tailor his responses to your questions accordingly. Start now!'
  },
  'chatbot-chat-locked.free-trial-expired.cta-description': {
    de: 'Bleibe mit Deinem Sparringspartner Jay in Verbindung und chatte mit ihm unbegrenzt. \n' +
      'Für nur 5€ pro Monat (zzgl. USt.) kannst Du den KI-Chatbot dauerhaft nutzen. Los geht’s.',
    en: 'Stay connected with your sparring partner Jay and chat with him as much as you want. \n' +
      'You can access the AI chatbot unlimitedly for €5 per month (plus VAT). Get started now!'
  },
  'chatbot-chat-locked.free-trial.btn-cancel': {
    de: 'Jetzt nicht',
    en: 'Not now'
  },
  'chatbot-chat-locked.free-trial-expired.btn-cancel': {
    de: 'Jetzt nicht',
    en: 'Not now'
  },
  'chatbot-chat-locked.free-trial.btn-subscribe': {
    de: 'Jay freischalten',
    en: 'Unlock Jay '
  },
  'chatbot-chat-locked.free-trial-expired.btn-subscribe': {
    de: 'Jay freischalten',
    en: 'Unlock Jay '
  },
  'chatbot-chat-locked.free-trial.flatrate-information': {
    de: '<a href="/ui/orders" target="_blank">Hier</a> findest Du alle Informationen zur Chatbot-Flatrate.\n' +
      'Auf der <a href="https://www.id37.io/produkte/ki-coach" target="_blank">Website</a> findest Du inspirierende' +
      ' Anwendungsbeispiele für KI-Coach Jay.',
    en: '<a href="/ui/orders" target="_blank">Here’s</a> information about the chatbot flat rate.\n' +
      'On the <a href="https://www.id37.io/en/products/ai-coach" target="_blank">website</a> you’ll find inspiring' +
      ' use cases for AI coach Jay.  '
  },
  'chatbot-chat-locked.free-trial-expired.flatrate-information': {
    de: '<a href="/ui/orders">Hier</a> findest Du alle Informationen zur Chatbot-Flatrate.\n',
    en: '<a href="/ui/orders">Here’s</a> information about the chatbot flat rate.\n'
  },
  'chatbot-chat-locked.free-trial.terms': {
    de: 'Die Nutzung des KI-Coachs erfolgt auf Basis unserer <a href="https://www.id37.io/agb" target="_blank">' +
      'Allgemeinen Geschäftsbedingungen</a> für die Nutzung von Online-Dienste von ID37. Informationen darüber,' +
      ' wie wir im Rahmen der Nutzung des KI-Coachs Jay deine personenbezogenen Daten verarbeiten, findest du in der ' +
      '<a href="https://www.id37.io/datenschutz-ki-coach" target="_blank">Datenschutzerklärung für ' +
      'KI-Coach Jay</a>.',
    en: 'The use of the AI coach is based on our <a href="https://www.id37.io/en/en-agbs" target="_blank">' +
      'General Terms and Conditions</a>' +
      ' for the use of online services from ID37. Information on how we process your personal data when using AI ' +
      'coach Jay can be found in the <a href="https://www.id37.io/en/privacy-ai-coach" target="_blank">Privacy Policy for AI Coach Jay</a>.'
  },
  'chatbot-chat-locked.free-trial-expired.terms': {
    de: 'Du wirst auf die Bestellseite weitergeleitet. Dort findest Du alle Informationen und kannst den KI-Coach Jay erwerben.',
    en: 'You are being redirected to the order page, where you’ll find more information and can purchase your access to the AI coach Jay.'
  },
  'chatbot-error-screen.header': {
    de: 'Ein Fehler ist aufgetreten!',
    en: 'An error has occurred!'
  },
  'chatbot-error-screen.errorText': {
    de: 'Bitte wende dich mit einer kurzen Beschreibung des Problems an <a href="mailto:jay@id37.io">jay@id37.io</a>',
    en: 'Please contact <a href="mailto:jay@id37.io">jay@id37.io</a> with a brief description of the issue.'
  },
  'chatbot-access.start-date': {
    de: 'Startdatum',
    en: 'Start Date'
  },
  'chatbot-access.name': {
    de: 'Name',
    en: 'Name'
  },
  'chatbot-access.email': {
    de: 'E-Mail',
    en: 'Email'
  },
  'chatbot-access.active-accounts': {
    de: 'User Status Jay',
    en: 'Jay User Status'
  },
  'chatbot-access.activate': {
    de: 'Jay aktivieren',
    en: 'Activate Jay'
  },
  'chatbot-access.deactivate': {
    de: 'Jay deaktivieren',
    en: 'Deactivate Jay'
  },
  'chatbot-access.search-placeholder': {
    de: 'Benutzer Suchen...',
    en: 'Search Users...'
  },
  'chatbot-access.result': {
    de: 'Ergebnis',
    en: 'result'
  },
  'chatbot-access.results': {
    de: 'Ergebnisse',
    en: 'results'
  },
  'chatbot-access.jay-activated': {
    de: 'Jay wurde aktiviert für:',
    en: 'Jay was activated for:'
  },
  'chatbot-access.jay-deactivated': {
    de: 'Jay wurde deaktiviert für:',
    en: 'Jay was deactivated for:'
  },
  'chatbot-access.jay-toggled-1': {
    de: 'Der Einladungsstatus des Chatbots wurde für ',
    en: 'Chatbot invitation status for '
  },
  'chatbot-access.jay-toggled-2': {
    de: ' geändert.',
    en: ' was changed.'
  }
};

@Injectable({
  providedIn: 'root'
})
export class TranslatorService {
  private readonly baseLanguage = 'en';
  private readonly baseGender = 'diverse';

  constructor(private currentUser: CurrentUser) {
  }

  public translate(key: string, language?: string, gender?: string): string {
    // Get the translation in the locale of the currently logged in user if not otherwise specified.
    if (!language) {
      language = this.currentUser.locale;
    }
    language = language.toLowerCase();
    // Get the gender if not otherwise specified.
    if (!gender) {
      gender = this.currentUser.gender;
    }
    gender = gender.toLowerCase();
    const translations = TRANSLATIONS[key];
    if (translations) {
      let translationIntoLanguage;
      if (translations[language]) { // eslint-disable-line @typescript-eslint/no-unsafe-member-access
        // Use the translation into the requested language if possible.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        translationIntoLanguage = translations[language];
      }
      else if (translations[this.baseLanguage]) { // eslint-disable-line @typescript-eslint/no-unsafe-member-access
        // Fall back to the translation into the base language if a translation into the requested language is not available.
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        translationIntoLanguage = translations[this.baseLanguage];
      }
      if (translationIntoLanguage) {
        if (typeof translationIntoLanguage === 'string') {
          return translationIntoLanguage;
        }
        else if (translationIntoLanguage[gender]) { // eslint-disable-line @typescript-eslint/no-unsafe-member-access
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-return
          return translationIntoLanguage[gender];
        }
        else if (translationIntoLanguage[this.baseGender]) { // eslint-disable-line @typescript-eslint/no-unsafe-member-access
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-member-access
          return translationIntoLanguage[this.baseGender];
        }
      }
    }
    // Either the key did not match any translations or neither a translation into the requested language nor the base language has benn
    // specified for the given key. Or, if a gender based translation is requested, neither a translation into the requested gender nor a
    // translation into the base gender has been specified.
    return '{{' + key + '[' + language + '][' + gender + ']}}';
  }
}
