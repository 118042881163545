import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslatorService} from '../../translation/translator.service';
import {UserService} from '../../user/user.service';
import {ChatbotUserStatus} from '../../user-data/chatbot-user-status';
import {OrderService} from '../order.service';
import {Order} from '../order';
import {OrderStatus} from '../order-status';
import {CurrentUser} from '../../user/current-user.service';
import {takeUntil} from 'rxjs/operators';
import {Test} from '../../test/state/test';
import {TestQuery} from '../../test/state/test.query';
import {Subject} from 'rxjs';


@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit, OnDestroy {
  chatbotUserStatus: ChatbotUserStatus;
  orders: Order[];
  tests: Test[] | undefined = undefined;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private testQuery: TestQuery,
              private currentUser: CurrentUser,
              private translatorService: TranslatorService,
              private userService: UserService,
              private orderService: OrderService) {
  }

  ngOnInit() {
    this.getUserData();
    this.getOrderData();
    this.testQuery.selectTestsForCurrentUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((tests: Test[]) => {
        this.tests = tests;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  getUserData(): void {
    this.userService.getChatbotUserStatus().subscribe({
      next: (chatbotUserStatus: ChatbotUserStatus) => {
        this.chatbotUserStatus = chatbotUserStatus;
      }
    });
  }

  getOrderData() {
    this.orderService.loadOrdersForCurrentUser().subscribe(orders => {
      this.orders = orders
        .filter(order => this.canShowOrder(order))
        .sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime());
    });
  }

  private canShowOrder(order: Order) {
    return order.status === OrderStatus.COMPLETED || order.status === OrderStatus.UNSUBSCRIBED
      || order.status === OrderStatus.SUBSCRIPTION_TERMINATED;
  }

  canShowTestJayComponent(): boolean {
    return this.chatbotUserStatus === 'CHATBOT_NOT_TRIED_YET' &&
      (!!this.tests && this.tests[this.tests.length - 1].accessGranted);
  }

  canShowBuyJayComponent(): boolean {
    return !!this.tests && this.tests[this.tests.length - 1].accessGranted;
  }

  __(key: string): string {
    return this.translatorService.translate('orders.' + key);
  }
}
