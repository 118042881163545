import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {OrderAddressRepresentation} from './address/order-address-representation';
import {OrderRepresentation} from './order-representation';
import {Order} from './order';
import {map} from 'rxjs/operators';
import {OrderAddress} from './address/order-address';
import {UnzerPaymentResourceRepresentation} from './payment/unzer-payment-resource-representation';
import {Charge} from './payment/charge/charge';
import {ChargeRepresentation} from './payment/charge/charge-representation';
import {FileDownloadService} from '../shared/file-download-service';
import {MediaType} from '../shared/media-type';
import {OrderAndOrderProcessingResultRepresentation} from './order-and-order-processing-result-representation';
import {OrderAndOrderProcessingResult} from './order-and-order-processing-result';
import {RecurringRedirectRepresentation} from './payment/recurring-redirect-representation';

const API_URL = environment.API_URL;

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private http: HttpClient,
              private fileDownloadService: FileDownloadService) {
  }

  public loadOrdersForCurrentUser(): Observable<Order[]> {
    return this.http.get<OrderRepresentation[]>(
      API_URL + '/orders',
      {
        headers: new HttpHeaders({
          Accept: 'application/json; version=1'
        })
      }
    ).pipe(map((orderRepresentations: OrderRepresentation[]) =>
      orderRepresentations.map(orderRepresentation => Order.buildFrom(orderRepresentation))
    ));
  }

  public getErrorMessages(orderId: string): Observable<string[]> {
    return this.http.get<string[]>(API_URL + '/orders/' + orderId + '/error-code',
      {
        headers: new HttpHeaders({

          Accept: 'application/json; version=1'
        })
      });
  }

  public loadOrderForCurrentUser(orderId: string): Observable<Order> {
    return this.http.get<OrderRepresentation>(
      API_URL + '/orders/' + orderId,
      {
        headers: new HttpHeaders({
          Accept: 'application/json; version=1'
        })
      }
    ).pipe(map((orderRepresentation: OrderRepresentation) =>
      Order.buildFrom(orderRepresentation)
    ));
  }

  public initializeOrder(invoiceAddress: OrderAddress): Observable<Order> {
    return this.http.post<OrderRepresentation>(
      API_URL + '/orders',
      OrderAddressRepresentation.buildFrom(invoiceAddress),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; version=1',
          Accept: 'application/json; version=1'
        })
      }
    ).pipe(map((orderRepresentation: OrderRepresentation) =>
      Order.buildFrom(orderRepresentation)
    ));
  }

  public patchOrderAddress(orderId: string, invoiceAddress: OrderAddress): Observable<Order> {
    return this.http.patch<OrderRepresentation>(
      API_URL + '/orders/' + orderId + '/invoice-address',
      OrderAddressRepresentation.buildFrom(invoiceAddress),
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; version=1',
          Accept: 'application/json; version=1'
        })
      }
    ).pipe(map((orderRepresentation: OrderRepresentation) =>
      Order.buildFrom(orderRepresentation)
    ));
  }

  public completeOrder(orderId: string): Observable<Order> {
    return this.http.post<OrderRepresentation>(
      API_URL + '/orders/' + orderId + '/finished',
      {},
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; version=1',
          Accept: 'application/json; version=1'
        })
      }
    ).pipe(map(orderRepresentation =>
      Order.buildFrom(orderRepresentation)
    ));
  }

  public chargeCreditCard(orderId: string, paymentResource: UnzerPaymentResourceRepresentation): Observable<Charge> {
    return this.http.post<ChargeRepresentation>(
      API_URL + '/orders/' + orderId + '/payments/charges/credit-card',
      paymentResource,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; version=1',
          Accept: 'application/json; version=1'
        })
      }
    ).pipe(map(chargeRepresentation =>
      Charge.buildFrom(chargeRepresentation)
    ));
  }

  public chargePayPal(orderId: string, paymentResource: UnzerPaymentResourceRepresentation): Observable<Charge> {
    return this.http.post<ChargeRepresentation>(
      API_URL + '/orders/' + orderId + '/payments/charges/paypal',
      paymentResource,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; version=1',
          Accept: 'application/json; version=1'
        })
      }
    ).pipe(map(chargeRepresentation =>
      Charge.buildFrom(chargeRepresentation)
    ));
  }

  public initiateRecurringPaypalCharge(
    orderId: string,
    paymentResource: UnzerPaymentResourceRepresentation): Observable<RecurringRedirectRepresentation> {
    return this.http.post<RecurringRedirectRepresentation>(
      API_URL + '/orders/' + orderId + '/payments/charges/initiate-paypal',
      paymentResource,
      {
        headers: new HttpHeaders({
          'Content-Type': 'application/json; version=1',
          Accept: 'application/json; version=1'
        })
      }
    ).pipe(map(response => RecurringRedirectRepresentation.fromResponse(response)));
  }

  public processOrder(orderId: string): Observable<OrderAndOrderProcessingResult> {
    return this.http.post<OrderAndOrderProcessingResultRepresentation>(
      API_URL + '/orders/' + orderId + '/process',
      {
        headers: new HttpHeaders({
          Accept: 'application/json; version=1'
        })
      }
    ).pipe(map((orderAndOrderProcessingResultRepresentation: OrderAndOrderProcessingResultRepresentation) =>
      OrderAndOrderProcessingResult.buildFrom(orderAndOrderProcessingResultRepresentation)
    ));
  }

  public downloadInvoicePdf(orderId: string, invoiceNr: number): void {
    this.fileDownloadService.downloadFile(
      API_URL + '/orders/' + orderId + '/invoices/' + invoiceNr + '/invoice-pdf',
      MediaType.PDF,
      'invoice-' + invoiceNr + '.pdf'
    );
  }

  public cancelChatbotSubscription(orderId: string): Observable<any> {
    return this.http.post<any>(
      API_URL + '/orders/' + orderId + '/cancel-recurring',
      null,
      {
        headers: new HttpHeaders({
          Accept: 'application/json; version=1'
        })
      }
    );
  }

}
