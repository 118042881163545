import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {TranslatorService} from '../../../translation/translator.service';
import {TestQuery} from '../../../test/state/test.query';
import {CurrentUser} from '../../../user/current-user.service';
import {takeUntil} from 'rxjs/operators';
import {Test} from '../../../test/state/test';
import {Subject} from 'rxjs';
import {NgClass} from '@angular/common';

@Component({
  selector: 'app-chatbot-locked-screen',
  standalone: true,
  imports: [
    NgClass
  ],
  templateUrl: './chatbot-locked-screen.component.html',
  styleUrl: './chatbot-locked-screen.component.scss'
})
export class ChatbotLockedScreenComponent implements OnInit, OnDestroy {
  @Input()
    translatorKey: string;
  @Output()
    subscribeToChatbotJayEvent: EventEmitter<void> = new EventEmitter<void>(null);
  @Output()
    cancelSubscriptionEvent: EventEmitter<void> = new EventEmitter<void>(null);

  hasID37Test: boolean;
  tests: Test[] | undefined = undefined;
  userName: string;
  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(private testQuery: TestQuery,
              private currentUser: CurrentUser,
              private translatorService: TranslatorService,
              private keycloakService: KeycloakService) {
  }

  ngOnInit(): void {
    this.keycloakService.loadUserProfile()
      .then(profile => this.userName = profile?.firstName);
    this.testQuery.selectTestsForCurrentUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((tests: Test[]) => {
        this.tests = tests;
      });
    this.hasID37Test = this.hasFinishedTest();
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  subscribeToChatbot() {
    this.subscribeToChatbotJayEvent.emit();
  }

  cancelSubscription() {
    this.cancelSubscriptionEvent.emit();
  }

  __(key: string): string {
    return this.translatorService.translate('chatbot-chat-locked.' + this.translatorKey + '.' + key);
  }

  hasFinishedTest(): boolean {
    return this.currentUser.hasRole('administrator') ||
      (!!this.tests && this.tests[this.tests.length - 1].accessGranted);
  }
}
