import {Component, Input, OnInit} from '@angular/core';
import {TranslatorService} from '../../../translation/translator.service';
import {ChatbotUserStatus} from '../../../user-data/chatbot-user-status';
import {NgIf} from '@angular/common';
import {OrderStatus} from '../../order-status';
import {OrderService} from '../../order.service';
import {Order} from '../../order';
import {catchError, filter, map} from 'rxjs/operators';
import {EMPTY, forkJoin} from 'rxjs';

@Component({
  selector: 'app-orders-error',
  standalone: true,
  imports: [
    NgIf
  ],
  templateUrl: './orders-error.component.html',
  styleUrl: './orders-error.component.scss'
})
export class OrdersErrorComponent implements OnInit {

  @Input()
    chatbotUserStatus: ChatbotUserStatus;

  errorMessages: string[] = [];
  orders: Order[] = [];

  creditCardError: boolean = false;
  defaultError: boolean = false;

  private readonly CREDIT_CARD_ERROR_CODES: string[] = ['000.100.251', '100.100.303', '123.456.789'] as const;


  constructor(private translatorService: TranslatorService,
              private orderService: OrderService) {
  }

  ngOnInit() {
    this.getOrderData();
  }

  getOrderData() {
    this.orderService.loadOrdersForCurrentUser()
      .pipe(
        catchError(error => {
          console.error('Failed to load orders:', error);
          return EMPTY;
        }),
        filter(orders => orders?.length > 0)
      )
      .subscribe(orders => {
        this.orders = orders;
        this.checkForErrors(orders);
      });
  }

  checkForErrors(orders: Order[]) {
    const errorRequests = orders
      .filter(order => order.status === OrderStatus.ERROR)
      .map(order =>
        this.orderService.getErrorMessages(order.orderId).pipe(
          map(errorCodes => errorCodes.map(this.normalizeErrorCode)),
          catchError(error => {
            console.error(`Failed to get error messages for order ${order.orderId}:`, error);
            return EMPTY;
          })
        )
      );

    forkJoin(errorRequests)
      .subscribe(errorArrays => {
        this.errorMessages = errorArrays.flat();
        this.categorizeErrors();
      });
  }

  __(key: string): string {
    return this.translatorService.translate('orders.error.' + key);
  }

  private categorizeErrors(): void {
    if (!this.errorMessages.length) {
      return;
    }

    this.creditCardError = false;
    this.defaultError = false;

    this.errorMessages.forEach(errorCode => {
      if (this.CREDIT_CARD_ERROR_CODES.includes(errorCode)) {
        this.creditCardError = true;
      }
      else {
        this.defaultError = true;
      }
    });
  }

  // Because Unzer returns error codes with a prefix, which is not defined in the unzer docs, alle prefixes are trimmed
  private normalizeErrorCode(errorCode: string): string {
    const firstDotIndex = errorCode.indexOf('.');
    return firstDotIndex !== -1 ? errorCode.substring(firstDotIndex + 1) : errorCode;
  }

}
